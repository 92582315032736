//used
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Button, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { URL_CONSTANTS } from "../../../../../APIVar/URLConstants";
import BulkUploadForm from "../../../../../Components/Common/BulkUploadFormSP/BulkUploadForm";
import PostQueryForm from "../../../../../Components/Common/PostQuerySP/PostQueryForm";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";
import { contactsFormDTO } from "../../../../../Services/DTO/ContactsDTO";
import { EMPLOYEE_SERVICES } from "../../../../../Services/Employee/EmployeeService";
import { Get, Patch } from "../../../../../Supporting files/CallAPI";
import CheckBox from "../../../../../Supporting files/CheckBox/CheckBox";
import CurrencyTextField from "../../../../../Supporting files/Currency/CurrencyTextField";
import DatePicker from "../../../../../Supporting files/DatePicker/DatePicker";
import {
  get_city_list_via_name,
  get_country_list,
  get_state_list,
  validateEmail,
  validateUrl,
} from "../../../../../Supporting files/HelpingFunction";
import MultipleSearchDropdown from "../../../../../Supporting files/MultipleSearchDropdown/MultipleSearchDropdown";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import SearchDropdown from "../../../../../Supporting files/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import TextAreaField from "../../../../../Supporting files/TextAreaField/TextAreaField";
import "../../../../../formLayout.scss";
import { useAppSelector } from "../../../../../Store/hooks";
import NewLoader from "../../../../../Components/NewLoader/NewLoader";
import { useFormatCurrency } from "../../../../../Supporting files/CustomHooks";

function EditContact() {
  const { formatCurrency } = useFormatCurrency();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<contactsFormDTO[]>([]);
  const [countryData, setCountryData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [stateData, setStateData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [clientId, setClientId] = useState<{
    id: string;
    label: string;
  }>({
    id: "",
    label: "",
  });
  const [countryId, setcountryId] = useState("");
  const [intialCountry, setintialCountry] = useState("");
  const [intialState, setintialState] = useState({});
  const [intialCity, setintialCity] = useState("");
  const [clientIdData, setClientIdData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [cityData, setCityData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [clientName, setClientName] = useState("");
  const [addClass, setAddClass] = useState<boolean>(false);
  const [postQueryForm, setPostQueryForm] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [sectionStatus, SetSectionStatus] = useState<any>("Personal Details");
  const [subsectionStatus, SetsubSectionStatus] = useState<any>("Basic Info");
  const OrganisationDetail = useRef(null);
  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const [subSection, setSubSection] = useState<string>("basics");
  const [clientWarn, setClientWarn] = useState(false);
  const [uploadFile, setUploadFile] = useState(false);
  const [id, setId] = useState("");
  const userState = useAppSelector((state: any) => state.userDetailsSlice);

  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setShowLoader(true);
  }, []);
  let timeout: any;

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");
    setId(id ?? "");

    if (timeout) {
      clearTimeout(timeout);
    }
    const delayedApiCall = () => {
      getLayout(id ?? "");
      get_country_list((cb: any) => {
        setCountryData(cb);
      });
      get_client_list();
    }
    timeout = setTimeout(delayedApiCall, 500);
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };

  }, []);

  useEffect(() => {

  }, []);

  useEffect(() => {
    if (intialCountry && intialState) {
      get_city_list_via_name(intialCountry, intialState, (cb: any) => {
        setCityData(cb);
      });
    }
  }, []);

  // get client list
  const get_client_list = () => {
    const onSuccess = (res: any) => {
      if (res) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      let response = res.data.data;
      response.unshift({
        label: "None of these",
        id: "None of these",
      });
      setClientIdData(response);
    };
    const onError = (err: any) => { };
    EMPLOYEE_SERVICES.get_client_list(
      userState?.userType?.toLowerCase(),
      onSuccess,
      onError
    );
  };

  const getLayout = (id: string) => {
    Get(`${URL_CONSTANTS.detail_contact}${id}`).then((res: any) => {
      let response = res.data.data[0].layout;
      setClientId({
        id: res.data.data[0].organisationId.id,
        label: res.data.data[0].organisationId.label,
      });
      setClientName(res.data.data[0].organisationName);
      let newData = response.map((dataMainsection: contactsFormDTO) => {
        let newMainSection = { ...dataMainsection };
        newMainSection.children = dataMainsection.children.map(
          (dataSubsection) => {
            let newSubsection = { ...dataSubsection };
            newSubsection.children = dataSubsection.children.map((dataRow) => {
              let newRow = { ...dataRow };
              newRow.children = dataRow.children.map((dataColumn) => {
                let newColumn = { ...dataColumn };
                newColumn.children = dataColumn.children.map((dataInput) => {
                  if (dataInput.field_lable === "Country") {
                    setintialCountry(dataInput.value);
                    get_state_list(dataInput.value, (cb: any) => {
                      setStateData(cb);
                    });
                  } else if (dataInput.field_lable === "State") {
                    setintialState({ label: dataInput.value });
                  } else if (dataInput.field_lable === "City") {
                    setintialCity(dataInput.value);
                  }
                  if (dataInput.field_type === "contactId") {
                    return {
                      ...dataInput,
                      field_lable: "hide",
                      field_type: "hide",
                    };
                  } else {
                    return { ...dataInput, error: false };
                  }
                });
                return newColumn;
              });
              return newRow;
            });
            return newSubsection;
          }
        );
        return newMainSection;
      });
      setFormData(newData);
    });
  };

  const updateMasterState = (attrName: any, value: any) => {
    let data: contactsFormDTO[] = formData;
    //For required fields
    if (attrName === setPostQueryForm) {
      attrName(value);
    } else if (attrName === setClientId) {
      if (!value || value.label === null) {
        return;
      }
      setClientId({ id: value.id, label: value.label });
    } else if (attrName === setClientName) {
      setClientWarn(false);
      return clientId.label === "None of these"
        ? setClientName(value)
        : setClientName("");
    } else {
      if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].required === true &&
        value.length === 0
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].error = true;
      } else {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].error = false;
      }
      //update value
      if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "country"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value.label;
        get_state_list(value ? value : intialState, (cb: any) => {
          setStateData(cb);
        });
        setcountryId(value);
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3] + 1
        ].children[attrName[4]].value = "";
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3] + 2
        ].children[attrName[4]].value = "";
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "state"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value.label;
        get_city_list_via_name(
          countryId ? countryId : intialCountry,
          value ? value : intialState,
          (cb: any) => {
            setCityData(cb);
          }
        );
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value === "N.A."
          ? (data[attrName[0]].children[attrName[1]].children[
            attrName[2]
          ].children[attrName[3] + 1].children[attrName[4]].value = "N.A.")
          : (data[attrName[0]].children[attrName[1]].children[
            attrName[2]
          ].children[attrName[3] + 1].children[attrName[4]].value = "");
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "city"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value.label;
      } else {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value;
      }
      setFormData([...data]);
    }
  };

  const validate_form = () => {
    // set form error for required true field
    let hasError = false;
    let newData = formData.map((dataMainsection: contactsFormDTO) => {
      let newMainSection = { ...dataMainsection };
      newMainSection.children = dataMainsection.children.map(
        (dataSubsection) => {
          let newSubsection = { ...dataSubsection };
          newSubsection.children = dataSubsection.children.map((dataRow) => {
            let newRow = { ...dataRow };
            newRow.children = dataRow.children.map((dataColumn) => {
              let newColumn = { ...dataColumn };
              newColumn.children = dataColumn.children.map((dataInput) => {
                if (
                  dataInput.required === true &&
                  dataInput.value.length === 0
                ) {
                  hasError = true;
                  return { ...dataInput, error: true };
                } else if (
                  dataInput.characterLimit &&
                  dataInput.value.length > dataInput.characterLimit
                ) {
                  hasError = true;
                  return { ...dataInput, error: true };
                } else if (dataInput.field_lable === "First Name" || dataInput.field_lable === "Last Name") {
                  return { ...dataInput, value: dataInput.value.trim() }
                } else if (
                  dataInput.required === true &&
                  dataInput.field_type === "email"
                ) {
                  if (validateEmail(dataInput.value.toString()) === true) {
                    return { ...dataInput, error: false };
                  } else {
                    hasError = true;
                    return { ...dataInput, error: true };
                  }
                } else if (dataInput.field_type === "email") {
                  if (
                    validateEmail(dataInput.value.toString()) === false &&
                    dataInput.value.length >= 1
                  ) {
                    hasError = true;
                    return { ...dataInput, error: true };
                  } else {
                    return { ...dataInput, error: false };
                  }
                } else if (
                  dataInput.required === true &&
                  dataInput.field_type === "url"
                ) {
                  if (validateUrl(dataInput.value.toString()) === true) {
                    return { ...dataInput, error: false };
                  } else {
                    hasError = true;
                    return { ...dataInput, error: true };
                  }
                } else if (dataInput.field_type === "url") {
                  if (
                    validateUrl(dataInput.value.toString()) === false &&
                    dataInput.value.length >= 1
                  ) {
                    hasError = true;
                    return { ...dataInput, error: true };
                  } else {
                    return { ...dataInput, error: false };
                  }
                } else if (
                  dataInput.required === true &&
                  dataInput.field_type === "address"
                ) {
                  hasError = true;
                  return { ...dataInput, error: true };
                } else {
                  return { ...dataInput, error: false };
                }
              });
              return newColumn;
            });
            return newRow;
          });
          return newSubsection;
        }
      );
      return newMainSection;
    });

    setFormData(newData);

    // api call code
    if (
      hasError === false &&
      clientId.label === "None of these" &&
      clientWarn === true
    ) {
      return;
    } else if (hasError === false) {
      let params = {
        layout: formData,
        organisationId: clientId,
        organisationName: clientName,
      };
      Patch(`${URL_CONSTANTS.edit_contact}${id}`, params).then((data: any) => {
        let status = data[0];
        let response = data[1];
        if (status === "success") {
          toast.success("Updated");
          navigate(ALL_ROUTES.CONTACTS);
        } else {
          let param = status.response.data.errors[0].param;
          let error = status.response.data.errors[0].message;
          alert(param + " " + " " + error);
        }
      });
    }
  };

  const handleClicks = (id: any) => {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      {showLoader ? (
        <NewLoader />
      ) : (
        <Box className="layoutWrapper">
          <BulkUploadForm
            open_status={uploadFile}
            attrName={setUploadFile}
            value_update={updateMasterState}
          />
          {/* Page header */}
          <Grid container spacing={3} className="pageHeader">
            <Grid xs={6} className="pageTitle">
              <Link
                className="backStep"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ChevronLeftIcon />
              </Link>

              <div>
                <h3>Edit Contact</h3>
              </div>
            </Grid>
            <Grid xs={6}></Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid xs={12}>
              <Grid container spacing={3}>
                <Grid xs className="leftSection">
                  {formData.map(
                    (dataMainSection: contactsFormDTO, index: number) => (
                      <>
                        <Link
                          className={
                            sectionStatus === dataMainSection.name
                              ? `active sectionLink`
                              : "sectionLink"
                          }
                          onClick={() => {
                            handleClicks(dataMainSection.name);
                            setAddClass(true);
                            SetSectionStatus(
                              dataMainSection.name === undefined
                                ? ""
                                : dataMainSection.name
                            );
                          }}
                        >
                          <span>{dataMainSection.name}</span>
                        </Link>
                        {dataMainSection.children.map((dataSubsection) => (
                          <ul className="accordion_list">
                            <li>
                              <Link
                                className={
                                  subsectionStatus === dataSubsection.name
                                    ? `inner_link active`
                                    : `inner_link`
                                }
                                onClick={() => {
                                  handleClicks(dataSubsection.name);
                                  SetsubSectionStatus(dataSubsection.name);
                                  SetSectionStatus(
                                    dataMainSection.name === undefined
                                      ? ""
                                      : dataMainSection.name
                                  );
                                }}
                              >
                                {dataSubsection.name}
                              </Link>
                            </li>
                          </ul>
                        ))}
                      </>
                    )
                  )}

                  <Link
                    className={
                      sectionStatus === "OrganisationDetail"
                        ? "active sectionLink OrganisationDetail"
                        : "sectionLink OrganisationDetail"
                    }
                    onClick={() => {
                      scrollToSection(OrganisationDetail);
                      SetSectionStatus("OrganisationDetail");
                    }}
                  >
                    <span>Organisation Detail</span>
                  </Link>
                </Grid>
                <Grid xs className="rightSection">
                  {formData.map(
                    (
                      dataMainSection: contactsFormDTO,
                      indexMainSection: number
                    ) => (
                      // Main section
                      <Box
                        onMouseEnter={() => {
                          SetSectionStatus(dataMainSection.name);
                        }}
                        className="detailSection"
                      >
                        <div id={dataMainSection.name}>
                          <div className="sectionTitle">
                            <h4>{dataMainSection.name}</h4>
                          </div>
                          {dataMainSection.children.map(
                            (dataSubsection, indexSubsection) => (
                              // Sub Section
                              <Grid container>
                                <Grid xs={12}>
                                  <h5
                                    id={dataSubsection.name}
                                    key={dataSubsection.type}
                                    className="subHeading"
                                  >
                                    {dataSubsection.name}
                                  </h5>
                                  {dataSubsection.children.map(
                                    (dataRow, indexRow) => (
                                      // Row
                                      <Grid
                                        onMouseEnter={() => {
                                          SetsubSectionStatus(
                                            dataSubsection.name
                                          );
                                        }}
                                        container
                                        spacing={3}
                                      >
                                        {dataRow.children.map(
                                          (dataColumn, indexColumn) => (
                                            // Columns created
                                            <Grid xs>
                                              {dataColumn.children.map(
                                                (dataInput, indexInput) => (
                                                  // Component will be here
                                                  <>
                                                    {dataInput.field_type ===
                                                      "singleselect" ? (
                                                      <SelectDropdown
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        value={dataInput.value}
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        dropdown_data={
                                                          dataInput.pickliststep
                                                            ? dataInput.pickliststep
                                                            : []
                                                        }
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "phone" ? (
                                                      <RKTextField
                                                        validation_type="numeric"
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value={dataInput.value}
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        error_messg={
                                                          dataInput.value
                                                            .length === 0
                                                            ? `Enter ${dataInput.field_lable}`
                                                            : dataInput.characterLimit &&
                                                              dataInput.value
                                                                .length >
                                                              dataInput.characterLimit
                                                              ? `Maximum ${dataInput.characterLimit} limit`
                                                              : ""
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        charcterlimit={
                                                          dataInput.field_lable ===
                                                            "Office Phone Number" ? 15 :
                                                            !dataInput.characterLimit
                                                              ? 11
                                                              : dataInput.characterLimit
                                                        }
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "email" ? (
                                                      <RKTextField
                                                        validation_type="email"
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value={dataInput.value}
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        error_messg={
                                                          dataInput.value
                                                            .length === 0
                                                            ? `Enter ${dataInput.field_lable}`
                                                            : validateEmail(
                                                              dataInput.value
                                                            ) === false
                                                              ? `Enter a valid email Id`
                                                              : ""
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        charcterlimit={
                                                          !dataInput.characterLimit
                                                            ? 50
                                                            : dataInput.characterLimit
                                                        }
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "datepicker" ? (
                                                      <DatePicker
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value={
                                                          dataInput.value ||
                                                          null
                                                        }
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        error_message="Enter a Valid Date"
                                                        warn_status={
                                                          dataInput.error ===
                                                            undefined
                                                            ? false
                                                            : dataInput.error
                                                        }
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        max_date={new Date()}
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "searchselect" ? (
                                                      <SearchDropdown
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        value={dataInput.value}
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        data={
                                                          dataInput.pickliststep
                                                            ? dataInput.pickliststep
                                                            : []
                                                        }
                                                        warn_status={
                                                          dataInput.error ===
                                                            undefined
                                                            ? false
                                                            : dataInput.error
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        error_message={
                                                          "Select the value"
                                                        }
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "checkbox" ? (
                                                      <CheckBox
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        name={
                                                          dataInput.field_lable
                                                        }
                                                        id={dataInput._id}
                                                        value={dataInput.value ? JSON.parse(dataInput.value) : false}
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        default_check={true}
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "number" ? (
                                                      <RKTextField
                                                        validation_type="numeric"
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value={dataInput.value}
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        error_messg={
                                                          dataInput.value
                                                            .length === 0
                                                            ? `Enter ${dataInput.field_lable}`
                                                            : dataInput.characterLimit &&
                                                              dataInput.value
                                                                .length >
                                                              dataInput.characterLimit
                                                              ? `Maximum ${dataInput.characterLimit} limit`
                                                              : ""
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        charcterlimit={
                                                          !dataInput.maxDigits
                                                            ? 12
                                                            : dataInput.maxDigits
                                                        }
                                                      />
                                                    ) : dataInput.field_type ==
                                                      "currency" ? (
                                                      <CurrencyTextField
                                                        validation_type="numeric"
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value={formatCurrency(dataInput.value)}
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        error_messg={
                                                          dataInput.value
                                                            .length === 0
                                                            ? `Enter ${dataInput.field_lable}`
                                                            : dataInput.characterLimit &&
                                                              dataInput.value
                                                                .length >
                                                              dataInput.characterLimit
                                                              ? `Maximum ${dataInput.characterLimit} Limit`
                                                              : ""
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        charcterlimit={
                                                          !dataInput.maxDigits
                                                            ? 12
                                                            : dataInput.maxDigits
                                                        }
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "multiselect" ? (
                                                      <MultipleSearchDropdown
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        value={dataInput.value}
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        multiple_data={
                                                          dataInput.pickliststep
                                                            ? dataInput.pickliststep
                                                            : []
                                                        }
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        warn_status={
                                                          dataInput.error ===
                                                            undefined
                                                            ? false
                                                            : dataInput.error
                                                        }
                                                        error_messg={
                                                          "Select the value"
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "multiline" ? (
                                                      <TextAreaField
                                                        specialcharacter={
                                                          !dataInput.speacialChar
                                                            ? false
                                                            : dataInput.speacialChar
                                                        }
                                                        alphanumeric={
                                                          !dataInput.alphaNumeric
                                                            ? false
                                                            : dataInput.alphaNumeric
                                                        }
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        value={dataInput.value}
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        warn_status={
                                                          dataInput.error ===
                                                            undefined
                                                            ? false
                                                            : dataInput.error
                                                        }
                                                        error_messg={
                                                          dataInput.value
                                                            .length === 0
                                                            ? `Enter ${dataInput.field_lable}`
                                                            : dataInput.characterLimit &&
                                                              dataInput.value
                                                                .length >
                                                              dataInput.characterLimit
                                                              ? `Maximum ${dataInput.characterLimit} charcter`
                                                              : ""
                                                        }
                                                        charcterlimit={
                                                          !dataInput.characterLimit
                                                            ? 30
                                                            : dataInput.characterLimit
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "url" ? (
                                                      <RKTextField
                                                        validation_type="url"
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value={dataInput.value}
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        error_messg={
                                                          dataInput.value
                                                            .length === 0
                                                            ? `Enter ${dataInput.field_lable}`
                                                            : validateUrl(
                                                              dataInput.value
                                                            ) === false
                                                              ? `Enter a valid Url`
                                                              : ""
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        charcterlimit={
                                                          !dataInput.characterLimit
                                                            ? 50
                                                            : dataInput.characterLimit
                                                        }
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "country" ? (
                                                      <SearchDropdown
                                                        class_name="inputField"
                                                        title="Country"
                                                        value={dataInput.value}
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        data={countryData}
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        error_message="Please select country"
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "state" ? (
                                                      <SearchDropdown
                                                        class_name="inputField"
                                                        title="State"
                                                        value={dataInput.value}
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        data={stateData}
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        error_message="Please select state"
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "city" ? (
                                                      <SearchDropdown
                                                        class_name="inputField"
                                                        title="City"
                                                        value={dataInput.value}
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        data={cityData}
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        error_message="Please select city"
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "hide" ? null : (
                                                      <RKTextField
                                                        specialcharacter={
                                                          (dataInput.field_lable === "First Name" || dataInput.field_lable === "Last Name") ? false :
                                                            !dataInput.speacialChar
                                                              ? false
                                                              : dataInput.speacialChar
                                                        }
                                                        alphanumeric={
                                                          (dataInput.field_lable === "First Name" || dataInput.field_lable === "Last Name") ? false :
                                                            !dataInput.alphaNumeric
                                                              ? false
                                                              : dataInput.alphaNumeric
                                                        }
                                                        validation_type="name"
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value={dataInput.value}
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        error_messg={
                                                          dataInput.value
                                                            .length === 0
                                                            ? `Enter ${dataInput.field_lable}`
                                                            : dataInput.characterLimit &&
                                                              dataInput.value
                                                                .length >
                                                              dataInput.characterLimit
                                                              ? `Maximum ${dataInput.characterLimit} charcter`
                                                              : ""
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        charcterlimit={
                                                          !dataInput.characterLimit
                                                            ? 30
                                                            : dataInput.characterLimit
                                                        }
                                                      />
                                                    )}
                                                  </>
                                                )
                                              )}
                                            </Grid>
                                          )
                                        )}
                                      </Grid>
                                    )
                                  )}
                                </Grid>
                              </Grid>
                            )
                          )}
                        </div>
                      </Box>
                    )
                  )}
                  <Box
                    onMouseEnter={() => {
                      SetSectionStatus("OrganisationDetail");
                    }}
                    className="detailSection"
                    ref={OrganisationDetail}
                  >
                    <div>
                      <div className="sectionTitle">
                        <h4>Organisation Detail</h4>
                      </div>
                      <Grid container>
                        <Grid xs={6}>
                          <SearchDropdown
                            class_name="inputField"
                            title="Select Organisation"
                            value={clientId.label}
                            attrName={setClientId}
                            value_update={updateMasterState}
                            data={clientIdData ? clientIdData : []}
                            warn_status={false}
                            error_message="Select state"
                          // required={true}
                          />
                        </Grid>
                        {clientId.id === "None of these" ||
                          clientId.label === "None of these" ? (
                          <Grid xs={6}>
                            <RKTextField
                              validation_type="name"
                              class_name="inputField"
                              title="Name of Organsiation"
                              value={clientName}
                              attrName={setClientName}
                              value_update={updateMasterState}
                              warn_status={clientWarn}
                              error_messg="Select Field"
                            // required={true}
                            />
                          </Grid>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Footer Section */}
          <Grid container spacing={3} className="footerSection">
            <Grid xs={12} className="ctaBtn">
              <Button
                onClick={() => {
                  navigate(-1);
                }}
                variant="outlined"
                className="borderBtn"
              >
                Cancel
              </Button>
              <Button
                onClick={validate_form}
                variant="contained"
                className="submitBtn"
              >
                Update
              </Button>
            </Grid>
          </Grid>

          <PostQueryForm
            attrName={"postQueryForm"}
            open_status={postQueryForm}
            value_update={updateMasterState}
          />

          <Modal open={deleteStatus} className="modalWrapper">
            <Box className="modalInner">
              <Grid container spacing={1}>
                <Grid xs={12}>
                  <Link
                    className="close-button"
                    onClick={() => {
                      setDeleteStatus(false);
                    }}
                  />
                  <h5 className="popup-heading">Are you sure ?</h5>
                </Grid>
                <Grid xs={12}>
                  <Link className="md-link" underline="none">
                    You are about to Delete this Attatchment.
                  </Link>
                </Grid>
                <Grid xs={12} className="ctaBtn" textAlign={"center"}>
                  <Button variant="contained" className="submitBtn">
                    Yes, Delete
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </Box>
      )}
    </>
  );
}

export default EditContact;
