import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Link, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import RKTextField from "../RKTextField/RKTextField";
interface Props {
  title: string;
  value: any;
  attrName: any;
  value_update: any;
  warn_status: boolean | undefined;
  info_status?: boolean;
  info_message?: string;
  class_name: string;
  error_messg?: string;
  validation_type?: string;
  ref?: any;
  password_status?: boolean;
  type?: string;
  required?: boolean;
  charcterlimit?: number;
  alphanumeric?: boolean;
  specialcharacter?: boolean;
  params?: any;
  id?: string;
  disabled?: boolean;
}

const RKTextFieldDisable: React.FC<Props> = ({
  title,
  type,
  value,
  value_update,
  attrName,
  warn_status,
  class_name,
  error_messg,
  info_status,
  info_message,
  validation_type,
  required,
  password_status,
  charcterlimit,
  alphanumeric,
  specialcharacter,
  id,
  params,
  disabled,
}) => {
  const [disbaleToggle, setDisableToggle] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);
  const refinput = useRef(null);

  useEffect(() => {
    // if (refinput.current) refinput.current?.focus();
  }, [refinput]);

  const edit = () => {
    setDisableToggle(!disbaleToggle);
    setFocus(!focus);
    if (disbaleToggle === true) {
      if (attrName) return params(attrName);
      params();
    }
  };

  const updateMasterState = (attrName: any, value: any) => {
    value_update(attrName,value);
  };

  return (
    <Box className="individualEdit">
      {disbaleToggle === false ? (
        <div
          className={
            value != null
              ? value.length === 0
                ? "staticField nullValue"
                : "staticField"
              : "staticField"
          }
        >
          <label>{title}</label>
          <p className="truncate_text">{value}</p>
        </div>
      ) : (
        <RKTextField
          validation_type={validation_type}
          specialcharacter={specialcharacter}
          alphanumeric={alphanumeric}
          class_name={class_name}
          title={title}
          value={value}
          info_status={info_status}
          info_message={info_message}
          attrName={attrName}
          value_update={updateMasterState}
          warn_status={warn_status}
          error_messg={error_messg}
          required={required}
          charcterlimit={charcterlimit}
          disabled={disabled ? true : false}
        />
      )}
      {info_status === true ? (
        <Tooltip title={info_message === undefined ? "" : info_message}>
          <span className="infoMessage">
            <InfoOutlinedIcon />
          </span>
        </Tooltip>
      ) : null}
      {!disabled && (
        <Link
          className={
            disbaleToggle === true ? "editUpdate visible" : "editUpdate"
          }
          onClick={edit}
        >
          {disbaleToggle === false ? <EditIcon /> : <CheckIcon />}
        </Link>
      )}
    </Box>
  );
};

export default RKTextFieldDisable;
