import React, { useRef, useState } from "react";
import { useDrag } from "react-dnd";
import { COLUMN } from "./constants";
import DropZone from "./DropZone";
import Component from "./Component";
import { Link } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useSelector } from "react-redux";
import { GetLayoutAction, openRemoveModal, RemoveItem, UpdateLayoutPropertiesAction } from "../../../../../Store/actions/layoutAction";
import { useDispatch } from "react-redux";
import Row from "./Row";
import Grid from "@mui/material/Unstable_Grid2";

const style = {};
const SubSection = ({ data, components, handleDrop, path, childrenCount }) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const layoutData = useSelector(({layoutReducer})=>{return layoutReducer});
  const [headingText, setHeadingText] = useState('');
  const [currentIndex, setcurrentIndex] = useState();

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: "section",
      id: data.id,
      children: data.children,
      path
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    })
  });

  const opacity = isDragging ? 0 : 1;
  //drag(ref);

  const renderRow = (row, currentPath, childrenCount) => {
    //console.log(currentPath)
    return (
      <Row
        key={row.id}
        data={row}
        handleDrop={handleDrop}
        components={components}
        path={currentPath}
        childrenCount={childrenCount}
      />
    );
  };

  const removeSubSection = ()=>{
    // dispatch(RemoveItem(data, components, path, layoutData));
    dispatch(openRemoveModal({data, components, path, layoutData, modalType:"SSR" }));
    //dispatch(RemoveItem(data, components, path, layoutData));
  } 

  const handleHeadingChange = function (event) {
    const index = path.split("-");
    setHeadingText(event.target.innerText);
    setcurrentIndex(index);
  }
 
  const handleBlur = ()=>{
    layoutData.layout[currentIndex[0]].children[currentIndex[1]].name = headingText
    dispatch(UpdateLayoutPropertiesAction(layoutData.layout,{})); 
  }

  const index = path.split("-");
  return (
    <div
      ref={ref}
      style={{ ...style, opacity }}
      //className="draggable column"
    >
     <h6 className="subTitle" >
       <span contentEditable={true} onInput={handleHeadingChange} onBlur={handleBlur}>{data.name}</span>
        <div className="actionBtns text-right">
          {
            data.preDefiend!==true?<Link
            onClick={removeSubSection}
            className="delete_btn">
              <DeleteForeverIcon />
            </Link>:""
          }
        </div>
      </h6>
     {/* <Grid container spacing={0} className="sectionTitle">
        <Grid xs={6}>
          <h5 contentEditable={true} onInput={handleHeadingChange} onBlur={handleBlur}>{data.name}</h5>
        </Grid>
        {path!=="0"? <Grid xs={6}>
          <div className="actionBtns text-right">
            <Link className="delete_btn" onClick={removeMainSection}>
              <DeleteForeverIcon />
              Remove
            </Link>
          </div>
        </Grid>:""} 
      </Grid> */}
      {data.children.map((component, index) => {
        const currentPath = `${path}-${index}`;

        return (
          <React.Fragment key={component.id}>
            <DropZone
              data={{
                path: currentPath,
                childrenCount: data.children.length
              }}
              onDrop={handleDrop}
            />
            {renderRow(component, currentPath, data.children.length)}
          </React.Fragment>
        );
      })}
      <DropZone
        data={{
          path: `${path}-${data.children.length}`,
          childrenCount: data.children.length
        }}
        onDrop={handleDrop}
        isLast
      />
    </div>
  );
};
export default SubSection;
