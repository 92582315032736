import React, {useState, useEffect} from "react";
import "./RemovePopup.scss";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Box, Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { closeRemoveModal, RemoveItem } from "../../../../../../Store/actions/layoutAction";

const RemoveSectionPopup = ({
  title_stepOne,
  title_value_stepOne,
  content_stepOne,
  btnText_stepOne,
  title_stepTwo,
  content_stepTwo
}) => {
  const [showStep, SetshowStep] = useState(true);
  const dispatch = useDispatch();
  const isOpen = useSelector((state)=> {return state.layoutReducer.removeModalOpen});
  const dataToRemove = useSelector((state)=> {return state.layoutReducer.dataToRemove});

  useEffect(()=>{
    if(!isOpen){
      SetshowStep(true)
    }
  },[isOpen])
  
  return (<>
    {isOpen?(<Box className="removePopupWrapper">
      <Box className="innerWrapper">
        <Box className="popupContent">
          {showStep ? (
            // Step One
            <Grid container textAlign="center">
              <Grid xs={12}>
                <div className="binImg">
                  {/* <img src="/images/bin_lid.svg" className="lid" /> */}
                  <img src="/images/frame_bin.svg" className="bin" />
                </div>
                <h4>
                  {title_stepOne} - {title_value_stepOne}
                </h4>
              </Grid>
              <Grid xs={12} className="mb-6">
                {content_stepOne}
              </Grid>
              <Grid xs={12} className="ctaBtn">
                <Button
                  className="submitBtn"
                  onClick={() => {
                    SetshowStep(!showStep)
                    dispatch(RemoveItem(dataToRemove.data, dataToRemove.components, dataToRemove.path, dataToRemove.layoutData));
                    //dispatch(RemoveItem(dataToRemove.data, dataToRemove.components, dataToRemove.path, dataToRemove.layoutData, dataToRemove.component))
                  }}
                >
                  {btnText_stepOne}
                </Button>
              </Grid>
              <Grid xs={12} className="mt-4">
                <Button className="cancelBtn" onClick={()=>{
                  dispatch(closeRemoveModal())
                }}>Cancel</Button>
              </Grid>
            </Grid>
          ) : (
            // Step Two
            <Grid container textAlign="center">
              <Grid xs={12}>
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 130.2 130.2"
                >
                  <circle
                    className="path circle"
                    fill="none"
                    stroke="#73AF55"
                    stroke-width="6"
                    stroke-miterlimit="10"
                    cx="65.1"
                    cy="65.1"
                    r="62.1"
                  />
                  <polyline
                    className="path check"
                    fill="none"
                    stroke="#73AF55"
                    stroke-width="6"
                    stroke-linecap="round"
                    stroke-miterlimit="10"
                    points="100.2,40.2 51.5,88.8 29.8,67.5 "
                  />
                </svg>
                <h4>{title_stepTwo}</h4>
              </Grid>
              <Grid xs={12} className="mb-6">
                {content_stepTwo}
              </Grid>
              <Grid xs={12} className="ctaBtn">
                <Button className="submitBtn" onClick={()=>{
                  dispatch(closeRemoveModal())
                }}>Ok</Button>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </Box>):""}
    </>
  );
};

export default RemoveSectionPopup;
