import { URL_CONSTANTS } from '../../APIVar/URLConstants';
import { Get, Post } from '../../Supporting files/CallAPI';
import { GET_LAYOUT, UPDATE_LAYOUT_FIELD_PROPERTIES, NEW_SECTION_OR_SUBSECTION_ADDED, CLEAR, DELETE, ITEMSWAPPED, GET_UNUSED_FIELDS, SET_UNUSED_FIELDS, REMOVE_UNUSED_FIELDS, CALL_REMOVE_MODAL, CLOSE_REMOVE_MODAL } from './../constants/layoutConstants';
import Swal from 'sweetalert2';
import { ToggelModalAction } from './modalAction';
import { COMPONENT, ROW, COLUMN, SECTION, SUBSECTION, SIDEBAR_ITEM } from "../../Pages/Desktop/Broker/CustomModules/CustomModuleV2/constants";
import { addNewSection, addNewSubSection } from "../../Pages/Desktop/Broker/CustomModules/CustomModuleV2/helpers";

//reuseable functions
const makeComponentObject = (arrayLayout) =>
{
  const components = {};

  for (let section of arrayLayout) {
    for (let subSection of section.children) {
      for (let row of subSection.children) {
        for (let column of row.children) {
          for (let component of column.children) {
            //console.log(component.field_type)
            components[component.id] = {
              id: component.id,
              type: component.field_type,
              field_type: component.field_type,
              field_lable: component.field_lable || '',
              content: `${component.field_type === "contactId" || component.field_type === "hideid" ? "Auto Number" : component.field_type}`,
              isLocked: component.isLocked,
              lockType: component.lockType,
              required: component.required,
              speacialChar: component.speacialChar,
              alphaNumeric: component.alphaNumeric,
              toolTip: component.toolTip,
              tipText: component.tipText,
              unique: component.unique,
              decimalPlaces: component.decimalPlaces,
              maxDigits: component.maxDigits,
              roundingOption: component.roundingOption,
              _id: component._id ? component._id : null,
              maxDigits: component.maxDigits ? component.maxDigits : null,
              preDefiend: component.preDefiend ? component.preDefiend : null,
              pickliststep: component.pickliststep ? component.pickliststep : null,
              characterLimit: component.characterLimit
            }
          }
        }
      }
    }
  }
  console.log("com-> ",components)
  return components
}

const pushComponentObject = (componentToAdd, componentData) =>
{
  console.log(componentToAdd, componentData)
  if (componentData !== undefined) {
    componentToAdd.forEach(e =>
    {
      componentData[e.id] = e;
    })
    console.log(componentData)
    return componentData
  }
}

const getWarningPopUp = (layoutData, components, type, item_to_remove, dispatch, index, updated, componentToRemove) =>
{

  if (type === COLUMN) {
    layoutData.unusedFields.push({
      id: componentToRemove.id,
      type: componentToRemove.type,
      type: SIDEBAR_ITEM,
      isArchived: true,
      class_name: componentToRemove.field_type,
      component: componentToRemove
    });
    layoutData.layout[index[0]].children[index[1]].children[index[2]].children = updated
  } else if (type === SUBSECTION) {
    layoutData.layout[index[0]].children = updated
  } else if (type === COMPONENT) {
    layoutData.layout[index[0]].children[index[1]].children[index[2]].children[index[3]].children = updated
  } else if (type === SECTION) {
    layoutData.layout = updated;
  } else if (type === ROW) {
    layoutData.unusedFields.push({
      id: componentToRemove.id,
      type: componentToRemove.type,
      type: SIDEBAR_ITEM,
      isArchived: true,
      class_name: componentToRemove.field_type,
      component: componentToRemove
    });
    layoutData.layout[index[0]].children[index[1]].children = updated;
  }

  type = type === ROW || type === COLUMN || type === COMPONENT ? "Field" : type

  dispatch(UpdateLayoutPropertiesAction(layoutData.layout, components))

  // Swal.fire({
  //   title: 'Are you sure?',
  //   text: "You won't be able to revert this!",
  //   icon: 'warning',
  //   showCancelButton: true,
  //   confirmButtonColor: '#3085d6',
  //   cancelButtonColor: '#d33',
  //   confirmButtonText: 'Yes, delete it!'
  // }).then((result) => {
  //   if (result.isConfirmed) {

  //     if(type === COLUMN){
  //       layoutData.unusedFields.push({
  //         id: componentToRemove.id,
  //         type: componentToRemove.type,
  //         type: SIDEBAR_ITEM,
  //         isArchived: true,
  //         class_name: componentToRemove.field_type,
  //         component: componentToRemove
  //       });
  //       layoutData.layout[index[0]].children[index[1]].children[index[2]].children = updated 
  //     }else if(type === SUBSECTION){
  //       layoutData.layout[index[0]].children = updated
  //     }else if(type === COMPONENT){
  //       layoutData.layout[index[0]].children[index[1]].children[index[2]].children[index[3]].children = updated
  //     }else if(type === SECTION){
  //       layoutData.layout = updated;
  //     }else if(type === ROW){
  //       layoutData.unusedFields.push({
  //         id: componentToRemove.id,
  //         type: componentToRemove.type,
  //         type: SIDEBAR_ITEM,
  //         isArchived: true,
  //         class_name: componentToRemove.field_type,
  //         component: componentToRemove
  //       });
  //       layoutData.layout[index[0]].children[index[1]].children = updated;
  //     }

  //     type = type===ROW|| type===COLUMN || type===COMPONENT? "Field" : type

  //     Swal.fire(
  //       'Deleted!',
  //       `${type} has been deleted.`,
  //       'success'
  //     ).then(()=>{
  //       dispatch({
  //         type: DELETE,
  //         layout: layoutData.layout, 
  //         deleteItem: item_to_remove
  //       })
  //       dispatch(UpdateLayoutPropertiesAction(layoutData.layout, components))
  //     })

  //   }
  // })
}

const autoDetectAndDeleteFunctionality = (layoutData, index, components, data, dispatch, componentToRemove) =>
{

  if (layoutData.layout[index[0]].children[index[1]].children[index[2]].children[index[3]].children.length === 1) {

    if (layoutData.layout[index[0]].children[index[1]].children[index[2]].children.length === 1) {
      const itemIndexToRmv = index[2];
      let arrayToBeUpdated = layoutData.layout[index[0]].children[index[1]].children;
      let item_to_remove = layoutData.layout[index[0]].children[index[1]].children[index[itemIndexToRmv]];
      data = item_to_remove

      const updated = arrayToBeUpdated.filter((data) =>
      {
        return data.id !== arrayToBeUpdated[itemIndexToRmv].id
      })
      return getWarningPopUp(layoutData, components, ROW, item_to_remove, dispatch, index, updated, componentToRemove)
    }
    else {
      const itemIndexToRmv = index[3];
      let arrayToBeUpdated = layoutData.layout[index[0]].children[index[1]].children[index[2]].children;
      let item_to_remove = layoutData.layout[index[0]].children[index[1]].children[index[2]].children[index[itemIndexToRmv]];
      data = item_to_remove

      const updated = arrayToBeUpdated.filter((data) =>
      {
        return data.id !== arrayToBeUpdated[itemIndexToRmv].id
      })
      return getWarningPopUp(layoutData, components, COLUMN, item_to_remove, dispatch, index, updated, componentToRemove)
    }
  }

}

///reusable functions ends here///

export const GetLayoutAction = (type) => async (dispatch) =>
{
  //console.log("type from get layout ------- ",type)
  let url
  if (type === "contact") {
    url = URL_CONSTANTS.get_contact_layout
  } else if (type === "client") {
    url = URL_CONSTANTS.get_client_layout
  } else if (type === "member") {
    url = URL_CONSTANTS.get_employee_layout
  } else {
    url = URL_CONSTANTS.get_contact_layout
  }

  Get(url)
    .then((res) =>
    {
      let response = res.data.data[0].layout;
      localStorage.setItem("layoutid", res.data.data[0]._id);        //console.log(response);
      let components = makeComponentObject(response)
        dispatch({
          type: GET_LAYOUT,
          layout: response,
          components:components,
        })
    })
    .catch((error) =>
    {
      console.log(error);
      return [error, {}];
    });

  // dispatch({
  //     type:GET_LAYOUT, 
  //     layout: data
  // })
  //dispatch({type: CLEAR, layout: data})
}

export const GetUnusedFields = (componentData, type) => (dispatch) =>
{
  let url;
  if (type === "contact") {
    url = URL_CONSTANTS.get_contact_custom_module_archive_data
  } else if (type === "client") {
    url = URL_CONSTANTS.get_client_custom_module_archive_data
  } else if (type === "member") {
    url = URL_CONSTANTS.get_employee_custom_module_archive_data
  } else {
    url = URL_CONSTANTS.get_contact_custom_module_archive_data
  }

  Get(url)
    .then((res) =>
    {
      let response = res.data.data.length > 0 ? res.data.data[0].unusedFields : [];
      let components = pushComponentObject(response, componentData);
      return dispatch({
        type: GET_UNUSED_FIELDS,
        unsuedFields:response,
        components
      })
    })
    .catch((error) =>
    {
      console.log(error);
      return [error, {}];
    });

  // dispatch({
  //       type:GET_UNUSED_FIELDS, 
  //       unsuedFields: data
  // })
}

export const SetUnusedFields = (data) => (dispatch) =>
{
  dispatch({
    type: SET_UNUSED_FIELDS,
    unsuedFields: data
  })
}

export const RemoveUnusedFields = (layoutData, item) => (dispatch) =>
{
  let filterData = layoutData.unusedFields.filter(e => e.id !== item.id);
  dispatch({
    type: REMOVE_UNUSED_FIELDS,
    unsuedFields: filterData
  })
  // Swal.fire({
  //   title: 'Are you sure?',
  //   text: "You won't be able to revert this!",
  //   icon: 'warning',
  //   showCancelButton: true,
  //   confirmButtonColor: '#3085d6',
  //   cancelButtonColor: '#d33',
  //   confirmButtonText: 'Yes, delete it!'
  // }).then((result) => {
  //   if (result.isConfirmed) {

  //     let filterData = layoutData.unusedFields.filter(e => e.id!==item.id);

  //     Swal.fire(
  //       'Deleted!',
  //       `Unused Field has been Permanently deleted.`,
  //       'success'
  //     ).then(()=>{
  //       dispatch({
  //         type:REMOVE_UNUSED_FIELDS, 
  //         unsuedFields: filterData
  //       })
  //     })

  //   }
  // })
}

export const CreateOrUpdateUnusedField = (data, type) => async (dispatch) =>
{

  let url
  if (type === "contact") {
    url = URL_CONSTANTS.post_contact_custom_module_archive_data
  } else if (type === "client") {
    url = URL_CONSTANTS.post_client_custom_module_archive_data
  } else if (type === "employee") {
    url = URL_CONSTANTS.post_employee_custom_module_archive_data
  } else {
    url = URL_CONSTANTS.post_contact_custom_module_archive_data
  }

  const param = {
    unusedFields: data
  }
  Post(url, param)
    .then((res) =>
    {
      let response = res.data.data[0].unusedFields;
      return dispatch({
        type: GET_UNUSED_FIELDS,
        unsuedFields: response,
      })
    })
    .catch((error) =>
    {
      console.log(error);
      return [error, {}];
    });

}

export const SetOnSwapLayoutAction = (data) => async (dispatch) =>
{
  //console.log(data)

  let components = makeComponentObject(data)
  // console.log(components)
  dispatch({
    type: ITEMSWAPPED,
    layout: data,
    components
  })
  //dispatch({type: CLEAR, layout: data})
}

export const UpdateLayoutPropertiesAction = (data, component) => async (dispatch) =>
{
  const param = {
    name: "Contact Module",
    layout: data
  }
  let components = makeComponentObject(data)
  dispatch({
    type: UPDATE_LAYOUT_FIELD_PROPERTIES,
    layout: data,
    component,
    components
  });

  // Post(URL_CONSTANTS.post_contact_layout, param)
  //   .then(async(res) => {
  //     console.log(res);
  //     let components = makeComponentObject(data)
  //     await dispatch({
  //       type:UPDATE_LAYOUT_FIELD_PROPERTIES, 
  //       layout: data,
  //       component,
  //       components
  //     });
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //     return [error, {}];
  //   }); 
}

export const RemoveItem = (data, components, path, layoutData, componentToRemove) => (dispatch) =>
{
  const index = path.split("-");

  if (data.type === COLUMN) {
    const itemIndexToRmv = index[2];
    let arrayToBeUpdated = layoutData.layout[index[0]].children[index[1]].children;
    let item_to_remove = layoutData.layout[index[0]].children[index[1]].children[itemIndexToRmv];

    if (arrayToBeUpdated.length === 1) return false

    const updated = arrayToBeUpdated.filter((data) =>
    {
      return data.id !== arrayToBeUpdated[itemIndexToRmv].id
    })

    // layoutData.layout[index[0]].children[index[1]].children = updated 

    getWarningPopUp(layoutData, components, COLUMN, item_to_remove, dispatch, index, updated)

    //console.log(layoutData.layout[index[0]].children[index[1]].children)


  } else if (data.type === COMPONENT || index.length === 5) {

    const itemIndexToRmv = index[4];
    let arrayToBeUpdated = layoutData.layout[index[0]].children[index[1]].children[index[2]].children[index[3]].children
    let item_to_remove = layoutData.layout[index[0]].children[index[1]].children[index[2]].children[index[3]].children[itemIndexToRmv];

    //console.log("i m in component...");
    const updated = arrayToBeUpdated.filter((data) =>
    {
      return data.id !== arrayToBeUpdated[itemIndexToRmv].id
    })

    if (layoutData.layout[index[0]].children[index[1]].children[index[2]].children[index[3]].children.length === 1) {
      autoDetectAndDeleteFunctionality(layoutData, index, components, data, dispatch, componentToRemove)
    } else {
      getWarningPopUp(layoutData, components, COMPONENT, item_to_remove, dispatch, index, updated, componentToRemove)
    }

  } else if (data.type === ROW) {
    const itemIndexToRmv = index[2];
    let arrayToBeUpdated = layoutData.layout[index[0]].children[index[1]]
    let item_to_remove = layoutData.layout[index[0]].children[index[1]].children[itemIndexToRmv];

    const updated = arrayToBeUpdated.filter((data) =>
    {
      //console.log(data.id, arrayToBeUpdated[itemIndexToRmv].id);
      return data.id !== arrayToBeUpdated[itemIndexToRmv].id
    })

    //console.log("In ROw ...", itemIndexToRmv, arrayToBeUpdated, updated);

    // layoutData.layout[index[0]].children = updated
    getWarningPopUp(layoutData, components, ROW, item_to_remove, dispatch, index, updated)
    //console.log(updated, layoutData.layout);
  } else if (data.type === SECTION) {
    const itemIndexToRmv = index[0];
    let arrayToBeUpdated = layoutData.layout
    let item_to_remove = layoutData.layout[index[0]];

    const updated = arrayToBeUpdated.filter((data) =>
    {
      return data.id !== arrayToBeUpdated[itemIndexToRmv].id
    })

    getWarningPopUp(layoutData, components, SECTION, item_to_remove, dispatch, index, updated)

  } else if (data.type === SUBSECTION) {
    const itemIndexToRmv = index[1];
    let arrayToBeUpdated = layoutData.layout[index[0]].children
    let item_to_remove = layoutData.layout[index[0]].children[itemIndexToRmv];

    const updated = arrayToBeUpdated.filter((data) =>
    {
      return data.id !== arrayToBeUpdated[itemIndexToRmv].id
    })

    getWarningPopUp(layoutData, components, SUBSECTION, item_to_remove, dispatch, index, updated)
  } else {
    return false
  }
}

export const saveSection = (data, component, unusedFields, type) => (dispatch) =>
{
  let url
  if (type === "contact") {
    url = URL_CONSTANTS.post_contact_layout
  } else if (type === "client") {
    url = URL_CONSTANTS.post_client_layout
  } else if (type === "member") {
    url = URL_CONSTANTS.post_employee_layout
  } else {
    url = URL_CONSTANTS.post_contact_layout
  }
  const param = {
    layout: data
  }
  if(data?.some((section)=>section.name === "")){
    return Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Section Name Should Not Empty!',
    })
  }

  let isSubsectionName = data.some((main) => 
    main.children.some((child) => child.name.trim() === "")
  );
  if(isSubsectionName){
    return Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Sub Section Name Should Not Empty!',
    })
  }
  Post(url, param)
    .then((res) =>
    {
      console.log(res);
      Swal.fire(
        'Layout Updated',
        'Successfully!',
        'success'
      ).then(() =>
      {
        dispatch({
          type: UPDATE_LAYOUT_FIELD_PROPERTIES,
          layout: data,
          components:component
        });
        dispatch(CreateOrUpdateUnusedField(unusedFields, type))
        dispatch(ToggelModalAction(true, "", null));
        dispatch(GetLayoutAction(type));
      })
    })
    .catch((error) =>
    {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
      return [error, {}];
    });
}

export const addSection = (layoutData, mainSectionName, subSectionName, components) => (dispatch) =>
{
  let updated = addNewSection(layoutData.layout, mainSectionName, subSectionName);
  layoutData.layout = updated

  dispatch({
    type: NEW_SECTION_OR_SUBSECTION_ADDED,
    layout: layoutData.layout
  })
  dispatch(UpdateLayoutPropertiesAction(layoutData.layout, components))
}

export const addsubSection = (layoutData, mainSectionId, subSectionName, components) => (dispatch) =>
{
  let updated = addNewSubSection(layoutData.layout, mainSectionId, subSectionName);
  layoutData.layout = updated
  dispatch({
    type: NEW_SECTION_OR_SUBSECTION_ADDED,
    layout: layoutData.layout
  })
  dispatch(UpdateLayoutPropertiesAction(layoutData.layout, components))
}

export const clear = () => (dispatch) =>
{
  dispatch({ type: CLEAR });
}

export const openRemoveModal = (data) => (dispatch) =>
{
  dispatch({ type: CALL_REMOVE_MODAL, dataToRemove: data })
}

export const closeRemoveModal = () => (dispatch) =>
{
  dispatch({ type: CLOSE_REMOVE_MODAL, dataToRemove: null })
}