import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import React from "react";
interface Props {
  title: string;
  value: string | Number | any;
  attrName: any;
  value_update: Function;
  dropdown_data: Array<any>;
  warn_status?: boolean;
  class_name?: string;
  error_messg?: string;
  info_status?: boolean;
  info_message?: string;
  required?: boolean;
  value_type_id_value?: boolean;
  disabled?: boolean;
  selectStyle?: any;
  module?: string;
}

const defaultProps: Props = {
  title: "",
  value: "",
  attrName: "",
  warn_status: false,
  value_update: () => {},
  dropdown_data: [],
  info_status: false,
  info_message: "This is dummy text for select dropdown.",
  value_type_id_value: false,
  disabled: false,
  module: "",
};

const SelectDropdown: React.FC<Props> = ({
  title,
  value,
  value_update,
  attrName,
  dropdown_data,
  warn_status,
  class_name,
  error_messg,
  info_status,
  info_message,
  required,
  value_type_id_value,
  disabled,
  module
}) => {
  return (
    <div className={class_name}>
      <FormControl fullWidth required={required}>
        <InputLabel>{title}</InputLabel>
        <Select
          value={value}
          label={title}
          disabled={disabled}
          onChange={(e) => {
            value_type_id_value
              ? value_update(attrName, e.target)
              : value_update(attrName, e.target.value);
          }}
          MenuProps={!module ? {
            PaperProps: {
              style: {
                maxWidth: 500,
                whiteSpace:"nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            },
          } : {}}
        >
          {dropdown_data?.map((data , index) => (
            <MenuItem key={index} value={data.key || data.label || data.name}>
              {data.value || data.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {info_status === true ? (
        <Tooltip title={info_message === undefined ? "" : info_message}>
          <span className="infoMessage">
            <InfoOutlinedIcon />
          </span>
        </Tooltip>
      ) : null}
      {warn_status === true ? (
        <span className="error">{error_messg ?? `Select ${title}`}</span>
      ) : null}
    </div>
  );
};

// SelectDropdown.defaultProps = defaultProps;

export default SelectDropdown;
