import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link, Modal } from "@mui/material";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import CheckBox from "../../../../../Supporting files/CheckBox/CheckBox";
import SlidingPanel from "react-sliding-side-panel";
import "./../CustomModules.scss";
import { ToggelModalAction } from "../../../../../Store/actions/modalAction";
import { useSelector, useDispatch } from "react-redux";
import { checkErrors } from "../CustomModuleV1/helpers";
import { UpdateLayoutPropertiesAction } from "../../../../../Store/actions/layoutAction";
import { ROW, COLUMN } from "../CustomModuleV1/constants";
import CloseIcon from "@mui/icons-material/Close";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import shortid from "shortid";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const SearchSelectProperties = ({ open_status }) => {
  const pickListLastInput = useRef(null);
  const modal = useSelector((state) => {
    return state.modalReducer;
  });
  const layoutData = useSelector(({ layoutReducer }) => {
    return layoutReducer;
  });
  const dispatch = useDispatch();
  const [title, setTitle] = React.useState(
    modal.data.field_lable ? modal.data.field_lable : ""
  );
  const [defaultValue, setDefaultValue] = React.useState("Select Policy");
  const [listOption1, setListOption1] = React.useState("Health Policy");
  const [tooltiptext, setTooltipText] = React.useState(
    modal.data.tipText ? modal.data.tipText : ""
  );
  const checkPicklistdataExist = modal.data.pickliststep
    ? modal.data.pickliststep.map((obj) => ({ ...obj, id: obj._id }))
    : [
        {
          id: Math.random() + "dndid",
          sr_no: 0,
          field_name: "",
          field_type: "",
          label: "",
          value: "",
          action_btns: "Edit",
          deleteable: false,
        },
      ];

  const [pickliststep, setPickListstep] = useState(checkPicklistdataExist);
  const [isError, setError] = React.useState(false);
  const [isSuccess, setSuccess] = React.useState(false);
  const [required, setRequired] = React.useState(
    modal.data.required ? modal.data.required : false
  );
  const [tooltip, setTooltip] = React.useState(
    modal.data.toolTip ? modal.data.toolTip : false
  );
  const [dropdownValue, setDropdownValue] = React.useState(
    modal.data.value ? modal.data.value : "None"
  );

  const addFieldType_data = [{ key: "None", value: "None" }, ...pickliststep];

  const updateMasterState = (attrName, value) => {
    attrName(value);
  };

  const updateMasterStateArray = (attrName, value) => {
    const array_data = [...pickliststep];
    if (attrName[1] === "field_name") {
      array_data[attrName[0]].field_name = value;
      array_data[attrName[0]].label = value;
      array_data[attrName[0]].value = value;
      array_data[attrName[0]].key = value;
    } else if (attrName[1] === "field_type") {
      array_data[attrName[0]].field_type = value;
    } else {
      attrName(value);
    }

    setPickListstep(array_data);
  };

  const submitHandler = () => {
    let objectToUpdate = {
      ...modal.data,
      field_lable: title,
      pickliststep,
      required,
      tipText: tooltiptext,
      value: "",
      toolTip: tooltip,
    };

    if (dropdownValue !== "None") {
      objectToUpdate = {
        ...modal.data,
        field_lable: title,
        pickliststep,
        required,
        toolTip: tooltip,
        tipText: tooltiptext,
        value: dropdownValue,
      };
    }
    let path = modal.path.split("-");
    //layoutData.layout[path[0]].children[path[1]].children[path[2]].children[path[3]]= objectToUpdate

    let columnObj = {
      id: shortid.generate(),
      type: COLUMN,
      children: [objectToUpdate],
    };


    let isError = checkErrors(title);

    if (!isError) {

      if (!objectToUpdate.from) {
        if (path.length === 5) {
          layoutData.layout[path[0]].children[path[1]].children[path[2]].children[
            path[3]
          ].children.splice(path[4], 0, objectToUpdate);
        } else if (path.length === 4) {
          layoutData.layout[path[0]].children[path[1]].children[
            path[2]
          ].children.splice(path[3], 0, columnObj);
        } else if (path.length === 3) {
          layoutData.layout[path[0]].children[path[1]].children.splice(
            path[2],
            0,
            { id: shortid.generate(), type: ROW, children: [columnObj] }
          );
        } else if (path.length === 2) {
          layoutData.layout[path[0]].children.splice(path[1], 0, {
            id: shortid.generate(),
            type: ROW,
            name: "New Sub Section",
            children: [
              {
                id: shortid.generate(),
                type: COLUMN,
                children: [objectToUpdate],
              },
            ],
          });
        }
      } else {
        layoutData.layout[path[0]].children[path[1]].children[path[2]].children[
          path[3]
        ].children[path[4]] = objectToUpdate; //[path[3]]
      }

      dispatch(UpdateLayoutPropertiesAction(layoutData.layout, objectToUpdate));
      setError(false);
      setSuccess(true);
      dispatch(ToggelModalAction(modal.isOpen, "", null));
    } else {
      setError(true);
      setSuccess(false);
    }
  };

  useEffect(() => {
    if (isError || isSuccess) {
      setTimeout(() => {
        setError(false);
        setSuccess(false);
      }, 2000);
    }

    let filterPickListSteps = pickliststep.map(e=>{
      if(e.id===undefined){
        return {...e,  id: Math.random() + "dndid"}
      }else{
        return e
      }
    })
    setPickListstep(filterPickListSteps)
  }, [isError, isSuccess]);

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(pickliststep);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    items.forEach((e, i) => {
      if (i === 0) {
        items[i].deleteable = false;
      } else {
        items[i].deleteable = true;
      }
    });

    setPickListstep(items);
  }

  return (
    <SlidingPanel
      type={"right"}
      isOpen={open_status}
      size={75}
      backdropClicked={() => {
        let isError = checkErrors(title);
        if (!isError) {
          //dispatch(ToggelModalAction(modal.isOpen, "", null))
          setError(false);
        } else {
          setError(true);
        }
      }}
    >
      <div>
        <Grid container spacing={3} alignItems="center">
          <Grid xs={11} className="pt-3">
            <h4 className="mb-0">Field Properties - Search Select Dropdown</h4>
          </Grid>

          {/* {modal.data.from ? (
            <Grid
              xs={1}
              className="pt-3"
              onClick={() => {
                dispatch(ToggelModalAction(modal.isOpen, "", null));
              }}
            >
              <CloseIcon />
            </Grid>
          ) : (
            ""
          )} */}

            <Grid
                xs={1}
                className="pt-3"
                onClick={() => {
                  dispatch(ToggelModalAction(modal.isOpen, "", null));
                }}
              >
                <CloseIcon />
            </Grid>

          <Grid xs={12} className="mb-4">
            <hr />
          </Grid>
        </Grid>
        <div className="scrollable_area pt-3">
          <Grid container spacing={3}>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title="Field Label"
                value={title}
                attrName={setTitle}
                value_update={updateMasterState}
                error_messg="Field label is required!"
                warn_status={isError}
              />
            </Grid>

            <Grid xs={8}>
              <p>Pick List Options</p>
            </Grid>
            <Grid xs={4} sx={{ textAlign: "end" }}>
              {/* <Link
                underline="none"
                className="blueBtn addfile"
                onClick={() => {
                  setPickListstep((pickliststep) => [
                    ...pickliststep,
                    {
                      id: Math.random() + "dndid",
                      sr_no: 0,
                      field_name: "",
                      field_type: "",
                      action_btns: "Edit",
                      deleteable: true,
                    },
                  ]);

                  setTimeout(() => {
                    pickListLastInput.current.children[0].childNodes[0].childNodes[1].childNodes[0].focus();
                  }, 50);
                }}
              >
                Add More
              </Link> */}
            </Grid>

            <Grid xs={12}>
              <Box className="pickListView">
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="pickliststep">
                    {(provided) => (
                      <div
                        className="pickliststep"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {pickliststep.map((data, index) => {
                          return (
                            <Draggable
                              key={data.id}
                              draggableId={data.id}
                              index={index}
                            >
                              {(provided) => (
                                // <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                <Grid
                                  container
                                  spacing={1}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  alignItems="center"
                                >
                                  <Grid
                                    ref={pickListLastInput}
                                    xs={10}
                                    className="dragField"
                                  >
                                    <RKTextField
                                      class_name="inputField"
                                      title={"Field Label"}
                                      value={data.field_name}
                                      attrName={[index, "field_name"]}
                                      value_update={updateMasterStateArray}
                                      warn_status={false}
                                    />
                                  </Grid>
                                  {data.deleteable === false ? (<Grid xs={2} sx={{ textAlign: "center" }}>
                                  <Link
                                          underline="none"
                                          className="add"
                                          onClick={() => {
                                            setPickListstep((pickliststep) => {
                                              const newItem = {
                                                id: Math.random() + "dndid",
                                                sr_no: 2,
                                                field_name: "",
                                                field_type: "",
                                                action_btns: "Edit",
                                                deleteable: true,
                                              };
                                              const updatedList = [
                                                ...pickliststep,
                                              ];
                                              updatedList.splice(
                                                index + 1,
                                                0,
                                                newItem
                                              );
                                              return updatedList;
                                            });
                                          }}
                                        >
                                          <AddCircleOutlineIcon />
                                        </Link></Grid>) : (
                                    <Grid xs={2} sx={{ textAlign: "end" }}>
                                      {index >= 0 ? (
                                        <Link
                                          underline="none"
                                          className="add"
                                          onClick={() => {
                                            setPickListstep((pickliststep) => {
                                              const newItem = {
                                                id: Math.random() + "dndid",
                                                sr_no: 1,
                                                field_name: "",
                                                field_type: "",
                                                action_btns: "Edit",
                                                deleteable: true,
                                              };
                                              const updatedList = [
                                                ...pickliststep,
                                              ];
                                              updatedList.splice(
                                                index + 1,
                                                0,
                                                newItem
                                              );
                                              return updatedList;
                                            });
                                          }}
                                        >
                                          <AddCircleOutlineIcon />
                                        </Link>
                                      ) : (
                                        ""
                                      )}
                                      <Link
                                        underline="none"
                                        className="remove"
                                        onClick={() => {
                                          setPickListstep((pickliststep) =>
                                            pickliststep.filter(
                                              (_, i) => i !== index
                                            )
                                          );
                                        }}
                                      >
                                        <RemoveCircleOutlineIcon />
                                      </Link>
                                    </Grid>
                                  )}
                                </Grid>
                                // </li>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Box>
            </Grid>

            {/* <Grid xs={12}>
              <Box className="pickListView">
                {pickliststep.map((data, index) => (
                  <Grid container spacing={1}>
                    <Grid xs={10}>
                      <RKTextField
                        class_name="inputField"
                        title={"Field Label"}
                        value={data.field_name}
                        attrName={[index, "field_name"]}
                        value_update={updateMasterStateArray}
                        warn_status={false}
                      />
                    </Grid>
                    {data.deleteable === false ? null : (
                      <Grid xs={2} sx={{ textAlign: "end" }}>
                        <Link
                          underline="none"
                          className="redBtn delete"
                          onClick={() => {
                            setPickListstep((pickliststep) =>
                              pickliststep.filter((_, i) => i !== index)
                            );
                          }}
                        ></Link>
                      </Grid>
                    )}
                  </Grid>
                ))}
              </Box>
            </Grid> */}

            <Grid xs={12}>
              <SelectDropdown
                class_name="inputField"
                title="Set Default Value"
                value={dropdownValue}
                attrName={setDropdownValue}
                value_update={updateMasterStateArray}
                dropdown_data={addFieldType_data}
                warn_status={false}
                module="custom"
              />
            </Grid>

            <Grid xs={12} className="checkbox_feature ">
              <CheckBox
                title="Required"
                name="required"
                id="required"
                value={required}
                attrName={setRequired}
                value_update={updateMasterState}
                default_check={true}
              />
            </Grid>

            <Grid xs={12} className="checkbox_feature ">
              <CheckBox
                title="Show Tooltip"
                name="tooltip"
                id="tooltip"
                value={tooltip}
                attrName={setTooltip}
                value_update={updateMasterState}
                default_check={false}
              />
            </Grid>

            {tooltip === true ? (
              <Grid xs={12}>
                <RKTextField
                  class_name="inputField"
                  title="Enter Tooltip Text"
                  value={tooltiptext}
                  attrName={setTooltipText}
                  value_update={updateMasterState}
                  warn_status={false}
                />
              </Grid>
            ) : null}
          </Grid>
        </div>
        <Grid container spacing={3}>
          <Grid xs={12} className="ctaBtn mt-4 text-center">
            <Button
              variant="contained"
              className="submitBtn "
              onClick={submitHandler}
            >
              Done
            </Button>
          </Grid>
        </Grid>
        {isSuccess ? (
          <div className="successMessage">
            <h4>Field Property Updated!</h4>
          </div>
        ) : (
          ""
        )}
      </div>
    </SlidingPanel>
  );
};

export default SearchSelectProperties;
