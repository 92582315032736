// in used
import { Button, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { GridCloseIcon } from "@mui/x-data-grid/components/icons";
import React, { useEffect, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import { EMPLOYEE_URL_CONSTANTS } from "../../../../APIVar/EmployeeUrlConstant";
import { EMPLOYER_URL_CONSTANTS } from "../../../../APIVar/EmployerUrlConstant";
import { URL_CONSTANTS } from "../../../../APIVar/URLConstants";
import { Claim_SERVICES } from "../../../../Services/Claim/ClaimServices";
import { ENDORSEMENT_SERVICES } from "../../../../Services/Endorsement/EndorsementService";
import { TSearchDropdown } from "../../../../Services/Types/Dropdown";
import { TAddEndorsement } from "../../../../Services/Types/Endorsement/TAddEndorsement";
import { TMemberDetail } from "../../../../Services/Types/Endorsement/TMemberDetail";
import { useAppSelector } from "../../../../Store/hooks";
import DatePicker from "../../../../Supporting files/DatePicker/DatePicker";
import {
  allowFileExtention,
  Check_endorsement,
  get_city_list_via_name,
  get_client_list,
  get_country_list,
  get_state_list,
  uploadImage,
  validateEmail,
  validateMobileNumber,
} from "../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../Supporting files/RKTextField/RKTextField";
import SearchDropdown from "../../../../Supporting files/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../../Supporting files/SelectDropdown/SelectDropdown";
import {
  gender_data,
  supporting_document_data,
  type_data,
} from "./dropdownDataJson";

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  callApi?: Function;
  shortcutDisable?: boolean;
  setShortcutDisable?: Function;
}

const AddNewRequest: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  callApi,
  shortcutDisable,
  setShortcutDisable,
}) => {

  const [disableSave, setDisableSave] = useState<boolean>(false);
  const { id, clientId, Client, userType, Organisation, userToken, Organization } = useAppSelector(
    (state: any) => state.userDetailsSlice
  );

  let addFormInitial: TAddEndorsement = {
    clientId: {
      label: "",
      id: "",
      warning: false,
    },
    memberId: { id: "", label: "", warning: false },
    policyId: { id: "", label: "", warning: false },
    endorsementFor: {
      oldName: "",
      firstName: { value: "", warning: false },
      lastName: { value: "", warning: false },
      oldPersonalAddress: "",
      buildingName: { value: "", warning: false },
      areaName: { value: "", warning: false },
      country: { id: "", label: "", warning: false },
      state: { id: "", label: "", warning: false },
      city: { id: "", label: "", warning: false },
      pincode: { value: "", warning: false },
      oldContactNumber: "",
      newContactNumber: { value: "", warning: false },
      oldgender: "",
      newGender: { value: "", warning: false },
      oldEmail: "",
      newEmail: { value: "", warning: false },
      oldDob: "",
      newDob: { value: null, warning: false },
    },
    endorsementValue: { value: "", warning: false },
    supportDoc: { value: "", warning: false },
    uploadDoc: { value: "", name: "", warning: false },
  };
  const [pageStatus, setPageStatus] = useState<number>(1);
  const [employeename, setEmployeeName] = useState("");
  const [endorsementType, setEndorsementType] = useState(
    "Non Commercial Endorsement"
  );
  const [endorsementTypeOne, setEndorsementTypeOne] = useState(
    "Commercial Endorsement"
  );
  const userDetails = useAppSelector((state: any) => state.userDetailsSlice);
  const [addFormData, setAddFormData] =
    useState<TAddEndorsement>(addFormInitial);
  const [client_id_data, setClient_id_data] = useState<TSearchDropdown[]>([]);
  const [country_data, setCountry_data] = useState<TSearchDropdown[]>([]);
  const [employee_id_data, setEmployee_id_data] = useState<any[]>([]);
  const [state_data, setState_data] = useState<TSearchDropdown[]>([]);
  const [city_data, setCity_data] = useState<TSearchDropdown[]>([]);
  const [policy_data, setPolicy_data] = useState<TSearchDropdown[]>([]);
  const [typeData, setTuypeData] = useState<string>();
  const [dependent, setDependent] = useState<any>({
    _id: "",
    memberId: "",
    label: "",
  });
  useEffect(() => {
    if (userType === "EMPLOYER" && open_status) {
      setAddFormData({
        ...addFormData,
        clientId: {
          label: clientId,
          id: id,
          warning: false,
        },
      });
    } else if (userType === "EMPLOYEE" && open_status) {
      getMemberDependent();
      setAddFormData({
        ...addFormData,
        clientId: {
          label: Client,
          id: clientId,
          warning: false,
        },
      });
    } else {
      setAddFormData(addFormInitial);
    }
  }, [open_status]);

  useEffect(() => {
    if (open_status) {
      if (userType === "BROKER") {
        get_client();
        setEmployee_id_data([]);
        setClient_id_data([]);
      }
      get_country_list((cb: any) => {
        setCountry_data(cb);
      });
      if (userType != "BROKER") {
        get_employee_data(userType === "EMPLOYEE" ? clientId : id);
      }
    }
  }, [open_status]);

  // get_client list
  const get_client = () => {
    const onSuccess = (res: any) => {
      setClient_id_data(res.data.data);
    };
    const onError = (err: any) => { };
    get_client_list(userDetails?.userType?.toLowerCase(), onSuccess, onError);
  };

  // updatemasterstate to update the current state of the form which contain two arguments attrname and value
  const updateMasterState = (attrName: any, value: any) => {
    if (attrName[0] === "addFormData") {
      if (value && value?.label) {
        if (attrName[1] === "clientId") {
          setAddFormData(addFormInitial);
        } else if (attrName[1] === "memberId") {
          let { memberId, clientId, ...newData } = addFormInitial;
          setAddFormData({ ...addFormData, ...newData });
        }
        attrName[1] === "endorsementFor"
          ? setAddFormData((prev) => ({
            ...prev,
            endorsementFor: {
              ...prev.endorsementFor,
              [attrName[2]]: {
                id: value && value.id ? value.id : value._id,
                label: value.label,
                warning: false,
              },
            },
          }))
          : setAddFormData((prev) => ({
            ...prev,
            [attrName[1]]: {
              id: value && value.id ? value.id : value._id,
              label: value.label,
              warning: false,
            },
          }));
      } else {
        attrName[1] === "endorsementFor"
          ? setAddFormData((prev) => ({
            ...prev,
            endorsementFor: {
              ...prev.endorsementFor,
              [attrName[2]]: {
                value: value,
                warning: false,
              },
            },
          }))
          : setAddFormData((prev) => ({
            ...prev,
            [attrName[1]]: {
              value: value,
              warning: false,
            },
          }));
      }
      if (attrName[1] === "clientId") {
        get_employee_data(value.id);
      } else if (attrName[1] === "memberId") {
        setDependent(value);
        if (userType !== "EMPLOYEE") {
          member_detail_data(value._id);
          const onSuccess = (res: any) => {
            // setEmployee_id_data(res.data.data);
            setPolicy_data(res.data.data);
          };
          const onError = (err: any) => { };
          let api_url =
            userType === "BROKER"
              ? `${URL_CONSTANTS.get_active_policy_by_employe}${value._id}`
              : userType === "EMPLOYEE"
                ? `${EMPLOYEE_URL_CONSTANTS.get_policy_list_by_employee}${value._id}`
                : `${EMPLOYER_URL_CONSTANTS.get_active_policy_by_employe}${value._id}`;

          Claim_SERVICES.get_dropdown_data(api_url, onSuccess, onError);
        }
        // function for show the old data filled by the member
      } else if (attrName[1] === "endorsementValue") {
        // setAddFormData((prev) => ({
        //   ...prev,
        //   endorsementFor: {
        //     ...prev.endorsementFor,
        //     firstName: { value: "", warning: false },
        //     lastName: { value: "", warning: false },
        //     buildingName: { value: "", warning: false },
        //     areaName: { value: "", warning: false },
        //     country: { id: "", label: "", warning: false },
        //     state: { id: "", label: "", warning: false },
        //     city: { id: "", label: "", warning: false },
        //     pincode: { value: "", warning: false },
        //     newContactNumber: { value: "", warning: false },
        //     newGender: { value: "", warning: false },
        //     oldEmail: "",
        //     newEmail: { value: "", warning: false },
        //     newDob: { value: "", warning: false },
        //   },
        // }));
      } else if (attrName[2] === "country") {
        setCity_data([])
        // get state as per the country selected
        get_state_list(value.label, (cb: any) => {
          setState_data(cb);
        });

        setAddFormData((prev) => ({
          ...prev,
          endorsementFor: {
            ...prev.endorsementFor,
            country: { label: value.label, id: value.id, warning: false },
            state: {
              label: value.label === "N.A." ? "N.A." : "",
              id: value.label === "N.A." ? "N.A." : "",
              warning: false,
            },
            city: {
              label: value.label === "N.A." ? "N.A." : "",
              id: value.label === "N.A." ? "N.A." : "",
              warning: false,
            },
          },
        }));
      } else if (attrName[2] === "state") {
        // get city as per the country selected and state selected
        get_city_list_via_name(
          addFormData.endorsementFor.country.label,
          value.label,
          (cb: any) => {
            setCity_data(cb);
          }
        );
        setAddFormData((prev) => ({
          ...prev,
          state: { label: value.label, id: value.id, warning: false },
          endorsementFor: {
            ...prev.endorsementFor,
            city: {
              label: value.label === "N.A." ? "N.A." : "",
              id: value.label === "N.A." ? "N.A." : "",
              warning: false,
            },
          },
        }));
      }
    } else {
      attrName(value);
    }
  };

  // get dropdowndata of employe as per employee when usertype in employer
  const get_employee_data = (empid: string) => {
    const onSuccess = (res: any) => {
      setEmployee_id_data(res.data.data);
    };
    const onError = (err: any) => { };
    let api_url = "";
    if (userType === "BROKER") {
      api_url = `${URL_CONSTANTS.get_employee_by_client}${empid}`;
    } else if (userType === "EMPLOYER") {
      api_url = `${EMPLOYER_URL_CONSTANTS.get_employee_by_client}${empid}`;
    }
    // api_url =
    //   userType === "BROKER"
    //     ? `${URL_CONSTANTS.get_employee_by_client}${empid}`
    //     : userType === "EMPLOYER"
    //       ? `${EMPLOYEE_URL_CONSTANTS.get_employee_list_by_client}${empid}`
    //       : `${EMPLOYER_URL_CONSTANTS.get_employee_by_client}${empid}`;
    if (userType === "BROKER" || (userType === "EMPLOYER" && api_url)) {
      Claim_SERVICES.get_dropdown_data(api_url, onSuccess, onError); // it is similar as per the claim
    }
  };

  // get member detail as per the member selected
  const member_detail_data = (id: string) => {
    const onSuccess = (res: any) => {
      let resopnse: TMemberDetail = res.data.data[0];
      setAddFormData((prev) => ({
        ...prev,
        endorsementFor: {
          ...prev.endorsementFor,
          oldName: `${resopnse["First Name"]} ${resopnse["Last Name"]}`,
          oldDob: resopnse["Date Of Birth"],
          oldContactNumber: resopnse["Contact Number"],
          oldEmail: resopnse.Email,
          oldgender: resopnse.Gender,
          oldPersonalAddress: Object.values(resopnse.personalAddress)
            .filter(Boolean)
            .join(" , "),
        },
      }));
    };
    const onError = (err: any) => { };

    let url = `${URL_CONSTANTS.get_member_detail(id, userType.toLowerCase())}`;
    // userType === "BROKER"
    //   ? `${URL_CONSTANTS.get_member_detail}${id}`
    //   : userType === "EMPLOYEE"
    //     ? `${EMPLOYEE_URL_CONSTANTS.get_member_detail}${id}`
    //     : `${EMPLOYER_URL_CONSTANTS.get_member_detail}${id}`;

    ENDORSEMENT_SERVICES.member_detail(url, onSuccess, onError);
  };

  // form submission as per the validate the form
  const validateForm = async () => {
    let validateData: TAddEndorsement = addFormData;
    validateData.uploadDoc.warning =
      addFormData.uploadDoc.name.length < 1 ? true : false;
    validateData.clientId.warning = addFormData.clientId.label === "";
    validateData.memberId.warning = addFormData.memberId.label === "";
    validateData.policyId.warning = addFormData.policyId.label === "";
    validateData.endorsementValue.warning =
      addFormData.endorsementValue.value === "";
    validateData.supportDoc.warning = addFormData.supportDoc.value === "";

    //validation set as per used for the endorsement value selected by the user
    switch (addFormData.endorsementValue.value) {
      case "Name":
        validateData.endorsementFor.firstName.warning =
          addFormData.endorsementFor.firstName?.value.trim() === "";
        // validateData.endorsementFor.lastName.warning = addFormData.endorsementFor.lastName?.value === "";
        break;
      case "Personal Address":
        validateData.endorsementFor.buildingName.warning =
          addFormData.endorsementFor.buildingName.value === "";
        validateData.endorsementFor.country.warning =
          addFormData.endorsementFor.country.label === "";
        validateData.endorsementFor.state.warning =
          addFormData.endorsementFor.state.label === "";
        validateData.endorsementFor.city.warning =
          addFormData.endorsementFor.city.label === "";
        validateData.endorsementFor.pincode.warning =
          addFormData.endorsementFor.pincode.value === "";
        break;
      case "Contact Number":
        validateData.endorsementFor.newContactNumber.warning =
          !validateMobileNumber(
            addFormData.endorsementFor.newContactNumber.value
          );
        break;
      case "Date of Birth":
        validateData.endorsementFor.newDob.warning =
          addFormData.endorsementFor.newDob.value === null;
        break;
      case "Email":
        validateData.endorsementFor.newEmail.warning = !validateEmail(addFormData.endorsementFor.newEmail.value)

        break;
      case "Gender":
        validateData.endorsementFor.newGender.warning =
          addFormData.endorsementFor.newGender.value === "";
        break;
      default:
        break;
    }
    // validateData.uploadDoc.warning = validateData.supportDoc.value === "";
    setAddFormData({ ...validateData });

    if (
      validateData.clientId.warning === false &&
      validateData.memberId.warning === false &&
      validateData.policyId.warning === false &&
      validateData.endorsementValue.warning === false &&
      validateData.supportDoc.warning === false &&
      validateData.uploadDoc.warning === false
    ) {
      //validation set as per used for the endorsement value selected by the user
      switch (addFormData.endorsementValue.value) {
        case "Name":
          if (
            validateData.endorsementFor.firstName.warning === true
            // || validateData.endorsementFor.lastName.warning === true
          ) {
            return;
          }
          break;
        case "Personal Address":
          if (
            validateData.endorsementFor.buildingName.warning === true ||
            validateData.endorsementFor.country.warning === true ||
            validateData.endorsementFor.state.warning === true ||
            validateData.endorsementFor.city.warning === true ||
            validateData.endorsementFor.pincode.warning === true
          ) {
            return;
          }
          break;
        case "Contact Number":
          if (validateData.endorsementFor.newContactNumber.warning === true) {
            return;
          }
          break;
        case "Date of Birth":
          if (validateData.endorsementFor.newDob.warning === true) {
            return;
          }
          break;
        case "Email":
          if (validateData.endorsementFor.newEmail.warning === true) {
            return;
          }
          break;
        case "Gender":
          if (validateData.endorsementFor.newGender.warning === true) {
            return;
          }
          break;
        default:
          break;
      }
      let check_data = await Check_endorsement(addFormData.memberId.id, addFormData.clientId.id, addFormData.endorsementValue.value, userToken ?? "", userType)
      if (check_data.isExist) {
        return toast.error(check_data.message)
      }
      addEndorsement();
    }
  };

  // api service call as per the validate the dat of the form
  const addEndorsement = () => {
    if (disableSave) return;
    const onSuccess = (res: any) => {
      value_update(attrName, false);
      setAddFormData({
        clientId: { id: "", label: "", warning: false },
        memberId: { id: "", label: "", warning: false },
        policyId: { id: "", label: "", warning: false },
        endorsementFor: {
          oldName: "",
          firstName: { value: "", warning: false },
          lastName: { value: "", warning: false },
          oldPersonalAddress: "",
          buildingName: { value: "", warning: false },
          areaName: { value: "", warning: false },
          country: { id: "", label: "", warning: false },
          state: { id: "", label: "", warning: false },
          city: { id: "", label: "", warning: false },
          pincode: { value: "", warning: false },
          oldContactNumber: "",
          newContactNumber: { value: "", warning: false },
          oldgender: "",
          newGender: { value: "", warning: false },
          oldEmail: "",
          newEmail: { value: "", warning: false },
          oldDob: "",
          newDob: { value: null, warning: false },
        },
        endorsementValue: { value: "", warning: false },
        supportDoc: { value: "", warning: false },
        uploadDoc: { value: "", name: "", warning: false },
      });
      toast.success(res.data.message);
      if (setShortcutDisable) {
        setShortcutDisable(false);
      }
      if (callApi) {
        callApi();
      }
      setTimeout(() => {
        setDisableSave(false);
      }, 1500);
    };
    const onError = (err: any) => {
      setDisableSave(false);
      toast.error(
        err?.response?.data.errors[0].param +
        " " +
        err?.response?.data.errors[0].message
      );
    };
    // let api_url = //api url manage as per the usertype
    //   userType === "BROKER"
    //     ? URL_CONSTANTS.add_endorsement
    //     : userType === "EMPLOYEE"
    //     ? EMPLOYEE_URL_CONSTANTS.add_endoresement
    //     : EMPLOYER_URL_CONSTANTS.add_endorsement;
    let params = {
      update_key: typeData ?? addFormData.endorsementValue.value,
      memberId: addFormData.memberId.id,
      member: addFormData.memberId.label,
      policyId: addFormData.policyId.id,
      policy: addFormData.policyId.label.split("-").slice(0, -1).join("-"),
      clientId: addFormData.clientId.id,
      client: `${addFormData.clientId.label} ${userType !== "BROKER" ? `- ${Organization || Organisation}` : ""}`,
      type:
        addFormData.endorsementValue.value === "Date Of Birth"
          ? "COMMERCIAL"
          : "NON COMMERCIAL",
      endorsementFor: addFormData.endorsementValue.value,
      old:
        addFormData.endorsementValue.value === "Date of Birth"
          ? addFormData.endorsementFor.oldDob
          : addFormData.endorsementValue.value === "Name"
            ? addFormData.endorsementFor.oldName
            : addFormData.endorsementValue.value === "Personal Address"
              ? addFormData.endorsementFor.oldPersonalAddress
              : addFormData.endorsementValue.value === "Contact Number"
                ? addFormData.endorsementFor.oldContactNumber
                : addFormData.endorsementValue.value === "Gender"
                  ? addFormData.endorsementFor.oldgender
                  : addFormData.endorsementValue.value === "Email"
                    ? addFormData.endorsementFor.oldEmail
                    : "",
      new: {
        dob: addFormData.endorsementFor.newDob.value,
        firstName: addFormData.endorsementFor.firstName.value,
        lastName: addFormData.endorsementFor.lastName.value,
        contactNumber: Number(
          addFormData.endorsementFor.newContactNumber.value
        ),
        gender: addFormData.endorsementFor.newGender.value,
        email: addFormData.endorsementFor.newEmail.value,
        street: addFormData.endorsementFor.buildingName.value,
        area: addFormData.endorsementFor.areaName.value,
        country: addFormData.endorsementFor.country.label,
        state: addFormData.endorsementFor.state.label,
        city: addFormData.endorsementFor.city.label,
        zip: Number(addFormData.endorsementFor.pincode.value),
      },
      attachment: {
        doc: addFormData.uploadDoc.value,
        description: "",
        doc_name: addFormData.uploadDoc.name,
        docType: addFormData.supportDoc.value,
      },
    };
    setDisableSave(true);
    ENDORSEMENT_SERVICES.create_endorsement(
      URL_CONSTANTS.add_endorsement(userType.toLowerCase()),
      params,
      onSuccess,
      onError
    );
  };

  const get_type = (type: string) => {
    const onSuccess = (res: any) => {
      setTuypeData(res.data.data.path);
    };
    const onError = (err: any) => {
      console.log(err, "err");
    };
    let dto = {
      type: type,
    };
    ENDORSEMENT_SERVICES.get_broker_path(
      dto,
      onSuccess,
      onError,
      userType.toLowerCase()
    );
  };

  useEffect(() => {
    if (
      addFormData.endorsementValue.value &&
      addFormData.endorsementValue.value !== "Personal Address"
    ) {
      get_type(addFormData.endorsementValue.value);
    }
  }, [addFormData.endorsementValue.value]);

  const getMemberDependent = () => {
    const onSuccess = (res: any) => {
      setEmployee_id_data(res.data.data);
    };
    const onError = (err: any) => {
      console.log(err, "err");
    };
    let url = `${URL_CONSTANTS.get_member_dependents(userType.toLowerCase())}`;
    ENDORSEMENT_SERVICES.get_member_dependent(url, onSuccess, onError);
  };

  useEffect(() => {
    if (userType === "EMPLOYEE" && dependent) {
      const onSuccess = (res: any) => {
        if (res.data.data) {
          setPolicy_data(res?.data?.data);
        }
      };
      const onError = (err: any) => {
        console.log(err, "err");
      };
      //////////////////////////////
      const urlSearchParams = new URLSearchParams("");
      const memberId = dependent?.memberId
        ? dependent?.memberId
        : dependent?._id;
      urlSearchParams.append("memberId", memberId);
      const userType = dependent?.memberId ? "DEPENDENT" : "EMPLOYEE";
      urlSearchParams.append("type", userType);

      if (dependent?._id !== "") {
        member_detail_data(dependent._id);
        Claim_SERVICES.get_dropdown_data(
          `${EMPLOYEE_URL_CONSTANTS.get_policy_list_by_employee_dependent
          }?${urlSearchParams.toString()}`, //?${urlSearchParams.toString()}`,
          onSuccess,
          onError
        );
      }
    }
  }, [dependent]);

  return (
    <SlidingPanel type={"right"} isOpen={open_status} size={75}>
      <div>
        <Grid container spacing={3} alignItems="center">
          <Grid xs={10}>
            <h4 className="mb-0 mt-4">Raise Endorsements</h4>
          </Grid>
          <Grid xs={2} textAlign="end" className="mt-3">
            <GridCloseIcon
              onClick={() => {
                value_update(attrName, false);
                setAddFormData({
                  clientId: { id: "", label: "", warning: false },
                  memberId: { id: "", label: "", warning: false },
                  policyId: { id: "", label: "", warning: false },
                  endorsementFor: {
                    oldName: "",
                    firstName: { value: "", warning: false },
                    lastName: { value: "", warning: false },
                    oldPersonalAddress: "",
                    buildingName: { value: "", warning: false },
                    areaName: { value: "", warning: false },
                    country: { id: "", label: "", warning: false },
                    state: { id: "", label: "", warning: false },
                    city: { id: "", label: "", warning: false },
                    pincode: { value: "", warning: false },
                    oldContactNumber: "",
                    newContactNumber: { value: "", warning: false },
                    oldgender: "",
                    newGender: { value: "", warning: false },
                    oldEmail: "",
                    newEmail: { value: "", warning: false },
                    oldDob: "",
                    newDob: { value: null, warning: false },
                  },
                  endorsementValue: { value: "", warning: false },
                  supportDoc: { value: "", warning: false },
                  uploadDoc: { value: "", name: "", warning: false },
                });
                if (setShortcutDisable) {
                  setShortcutDisable(false);
                }
                setDisableSave(false);
              }}
            />
          </Grid>
          <Grid xs={12} className="mb-4 pt-0">
            <hr />
          </Grid>
        </Grid>
        <div className="scrollable_area">
          {pageStatus === 1 ? (
            <div>
              <Grid container spacing={2} className="mb-4">
                <Grid xs={6}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Client ID"
                    value={addFormData.clientId.label}
                    attrName={["addFormData", "clientId"]}
                    value_update={updateMasterState}
                    data={client_id_data}
                    warn_status={addFormData.clientId.warning}
                    error_message="Select Client"
                    disabled={userType === "BROKER" ? false : true}
                    required
                  />
                </Grid>
                <Grid xs={6}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Member ID"
                    value={addFormData.memberId.label}
                    attrName={["addFormData", "memberId"]}
                    value_update={updateMasterState}
                    data={employee_id_data.filter((item: any) => item?.label)}
                    warn_status={addFormData.policyId.warning}
                    error_message="Select Member"
                    required
                  />
                </Grid>
                <Grid xs={6}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Select Policy"
                    value={addFormData.policyId.label}
                    attrName={["addFormData", "policyId"]}
                    value_update={updateMasterState}
                    data={policy_data}
                    warn_status={addFormData.policyId.warning}
                    error_message="Select Policy"
                    required
                  />
                </Grid>
                <Grid xs={6}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Endorsement Value"
                    value={addFormData.endorsementValue.value}
                    attrName={["addFormData", "endorsementValue"]}
                    value_update={updateMasterState}
                    dropdown_data={type_data}
                    warn_status={addFormData.endorsementValue.warning}
                  />
                </Grid>
                {addFormData.endorsementValue.value &&
                  addFormData.endorsementValue.value === "Date of Birth" ? (
                  <Grid xs={6}>
                    <RKTextField
                      class_name="inputField"
                      title="Endorsement Type"
                      value={endorsementTypeOne}
                      attrName={setEndorsementTypeOne}
                      value_update={updateMasterState}
                      warn_status={false}
                      disabled
                    />
                  </Grid>
                ) : addFormData.endorsementValue.value &&
                  addFormData.endorsementValue.value !== "Date of Birth" ? (
                  <Grid xs={6}>
                    <RKTextField
                      class_name="inputField"
                      title="Endorsement Type"
                      value={endorsementType}
                      attrName={setEndorsementType}
                      value_update={updateMasterState}
                      warn_status={false}
                      disabled

                    />
                  </Grid>
                ) : null}
              </Grid>

              {addFormData.endorsementValue.value === "Name" ? (
                <Grid container spacing={2} className="mb-4">
                  <Grid xs={12}>
                    <RKTextField
                      class_name="inputField"
                      title="Old Name"
                      value={addFormData.endorsementFor.oldName}
                      value_update={updateMasterState}
                      attrName={setEmployeeName}
                      warn_status={false}
                      disabled={true}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <h5 className="mb-0 font-sm">New Name</h5>
                  </Grid>
                  <Grid xs={6}>
                    <RKTextField
                      class_name="inputField"
                      title="First Name"
                      value={addFormData.endorsementFor.firstName?.value}
                      value_update={updateMasterState}
                      attrName={["addFormData", "endorsementFor", "firstName"]}
                      warn_status={
                        addFormData.endorsementFor.firstName?.warning
                      }
                      error_messg="Enter the first name"
                      required
                      validation_type="name"
                    />
                  </Grid>
                  <Grid xs={6}>
                    <RKTextField
                      class_name="inputField"
                      title="Last Name"
                      value={addFormData.endorsementFor.lastName?.value}
                      value_update={updateMasterState}
                      attrName={["addFormData", "endorsementFor", "lastName"]}
                      warn_status={addFormData.endorsementFor.lastName?.warning}
                      error_messg="Enter the last name"
                      validation_type="name"
                    />
                  </Grid>
                </Grid>
              ) : null}

              {addFormData.endorsementValue.value === "Personal Address" ? (
                <Grid container spacing={2} className="mb-4">
                  <Grid xs={12} className="inputField">
                    <RKTextField
                      class_name="inputField"
                      title="Old Personal Address"
                      value={addFormData.endorsementFor.oldPersonalAddress}
                      value_update={updateMasterState}
                      attrName={""}
                      warn_status={false}
                      disabled={true}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <h5 className="mb-0 font-sm">New Personal Address</h5>
                  </Grid>
                  <Grid xs={6} className="inputField">
                    <RKTextField
                      class_name="inputField"
                      title="Building/Street Name"
                      value={addFormData.endorsementFor.buildingName?.value}
                      value_update={updateMasterState}
                      attrName={[
                        "addFormData",
                        "endorsementFor",
                        "buildingName",
                      ]}
                      warn_status={
                        addFormData.endorsementFor.buildingName?.warning
                      }
                      required
                      error_messg="Enter the Building/Street Name"
                    />
                  </Grid>
                  <Grid xs={6}>
                    <RKTextField
                      class_name="inputField"
                      title="Area Name"
                      value={addFormData.endorsementFor.areaName?.value}
                      attrName={["addFormData", "endorsementFor", "areaName"]}
                      value_update={updateMasterState}
                      warn_status={addFormData.endorsementFor.areaName?.warning}
                    />
                  </Grid>
                  <Grid xs={6}>
                    <SearchDropdown
                      class_name="inputField"
                      title="Country"
                      value={
                        addFormData.endorsementFor.country?.label === undefined
                          ? ""
                          : addFormData.endorsementFor.country?.label
                      }
                      attrName={["addFormData", "endorsementFor", "country"]}
                      value_update={updateMasterState}
                      data={country_data}
                      warn_status={addFormData.endorsementFor.country?.warning}
                      error_message="Select country"
                      required
                    />
                  </Grid>
                  <Grid xs={6}>
                    <SearchDropdown
                      class_name="inputField"
                      title="State"
                      value={
                        addFormData.endorsementFor.state?.label === undefined
                          ? ""
                          : addFormData.endorsementFor.state?.label
                      }
                      attrName={["addFormData", "endorsementFor", "state"]}
                      value_update={updateMasterState}
                      data={state_data}
                      warn_status={addFormData.endorsementFor.state?.warning}
                      error_message="Select State"
                      required
                    />
                  </Grid>
                  <Grid xs={6}>
                    <SearchDropdown
                      class_name="inputField"
                      title="City"
                      value={
                        addFormData.endorsementFor.city?.label === undefined
                          ? ""
                          : addFormData.endorsementFor.city?.label
                      }
                      attrName={["addFormData", "endorsementFor", "city"]}
                      value_update={updateMasterState}
                      data={city_data}
                      warn_status={addFormData.endorsementFor.city?.warning}
                      error_message="Select City"
                      required
                    />
                  </Grid>
                  <Grid xs={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"Zipcode"}
                      value={addFormData.endorsementFor.pincode.value}
                      attrName={["addFormData", "endorsementFor", "pincode"]}
                      value_update={updateMasterState}
                      warn_status={addFormData.endorsementFor.pincode?.warning}
                      validation_type="numeric"
                      charcterlimit={6}
                      required
                    />
                  </Grid>
                </Grid>
              ) : null}

              {addFormData.endorsementValue.value === "Contact Number" ? (
                <Grid container spacing={2} className="mb-4">
                  <Grid xs={6} className="inputField">
                    <RKTextField
                      class_name="inputField"
                      title="Contact Number "
                      value={addFormData.endorsementFor.oldContactNumber}
                      value_update={updateMasterState}
                      attrName={""}
                      warn_status={false}
                      disabled={true}
                    />
                  </Grid>
                  <Grid xs={6} className="inputField">
                    <RKTextField
                      class_name="inputField"
                      title="New Contact Number"
                      value={addFormData.endorsementFor.newContactNumber?.value}
                      value_update={updateMasterState}
                      attrName={[
                        "addFormData",
                        "endorsementFor",
                        "newContactNumber",
                      ]}
                      warn_status={
                        addFormData.endorsementFor.newContactNumber?.warning
                      }
                      required
                      charcterlimit={12}
                    />
                  </Grid>
                </Grid>
              ) : null}

              {addFormData.endorsementValue.value === "Gender" ? (
                <Grid container spacing={2} className="mb-4">
                  <Grid xs={6}>
                    <RKTextField
                      class_name="inputField"
                      title="Old Gender"
                      value={addFormData.endorsementFor.oldgender}
                      attrName={""}
                      value_update={updateMasterState}
                      warn_status={false}
                      disabled={true}
                    />
                  </Grid>
                  <Grid xs={6}>
                    <SelectDropdown
                      class_name="inputField"
                      title="New Gender"
                      value={
                        addFormData.endorsementFor.newGender?.value ===
                          undefined
                          ? ""
                          : addFormData.endorsementFor.newGender?.value
                      }
                      attrName={["addFormData", "endorsementFor", "newGender"]}
                      value_update={updateMasterState}
                      dropdown_data={gender_data}
                      warn_status={
                        addFormData.endorsementFor.newGender?.warning
                      }
                      required
                      error_messg="Select Gender"
                    />
                  </Grid>
                </Grid>
              ) : null}

              {addFormData.endorsementValue.value === "Email" ? (
                <Grid container spacing={2} className="mb-4">
                  <Grid xs={6} className="inputField">
                    <RKTextField
                      class_name="inputField"
                      title="Current Email"
                      value={addFormData.endorsementFor.oldEmail}
                      value_update={updateMasterState}
                      attrName={""}
                      warn_status={false}
                      disabled={true}
                    />
                  </Grid>
                  <Grid xs={6} className="inputField">
                    <RKTextField
                      class_name="inputField"
                      title="New Email"
                      value={addFormData.endorsementFor.newEmail?.value}
                      value_update={updateMasterState}
                      attrName={["addFormData", "endorsementFor", "newEmail"]}
                      warn_status={addFormData.endorsementFor.newEmail?.warning}
                      error_messg={addFormData.endorsementFor.newEmail?.value ? "Enter Valid Email" : "Enter the Email"}
                      required
                    />
                  </Grid>
                </Grid>
              ) : null}

              {addFormData.endorsementValue.value === "Date of Birth" ? (
                <Grid container spacing={2} className="mb-4">
                  <Grid xs={6} className="inputField">
                    <DatePicker
                      class_name="inputField"
                      title="Current Date of Birth"
                      value={
                        addFormData?.endorsementFor?.oldDob === undefined
                          ? ""
                          : addFormData?.endorsementFor?.oldDob
                      }
                      attrName={""}
                      value_update={updateMasterState}
                      error_message="Please enter Date of Birth"
                      warn_status={false}
                      disabled={true}
                    />
                  </Grid>
                  <Grid xs={6} className="inputField">
                    <DatePicker
                      class_name="inputField"
                      title="New Date of Birth"
                      value={addFormData?.endorsementFor?.newDob?.value}
                      attrName={["addFormData", "endorsementFor", "newDob"]}
                      value_update={updateMasterState}
                      error_message="Enter  Date of Birth"
                      required
                      warn_status={addFormData?.endorsementFor?.newDob?.warning}
                      max_date={new Date()}
                    />
                  </Grid>
                </Grid>
              ) : null}

              <Grid container spacing={3}>
                <Grid xs={6}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Supporting Documents"
                    value={addFormData.supportDoc.value}
                    attrName={["addFormData", "supportDoc"]}
                    value_update={updateMasterState}
                    dropdown_data={supporting_document_data}
                    warn_status={addFormData.supportDoc.warning}
                  />
                </Grid>

                {addFormData.supportDoc.value ? (
                  <>
                    <Grid
                      xs={10}
                      className="inputField uploadFile"
                      paddingRight={0}
                    >
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        className="textarea"
                        placeholder="Upload document"
                        value={addFormData.uploadDoc.name}
                        required
                        disabled
                      />
                    </Grid>
                    <Grid xs={2} paddingLeft={0}>
                      <Button
                        variant="contained"
                        className="browsebtn"
                        component="label"
                      >
                        Browse
                        <input
                          hidden
                          multiple
                          type="file"
                          onChange={(e: any) => {
                            const file = e.target.files[0];
                            const fileSize = file?.size;
                            const maxSizeBytes = 8 * 1024 * 1024;
                            if (fileSize && fileSize > maxSizeBytes) {
                              toast.warn("File size exceeds 8 MB limit");
                              return (e.target.value = null);
                            }
                            const allowExt = allowFileExtention(file);
                            if (!allowExt) {
                              toast.warn(
                                "Invalid file type. Please upload a PDF, CSV, Excel, DOC, or Image file."
                              );
                              return (e.target.value = null);
                            }
                            uploadImage(
                              file,
                              (base64Image: any, base64String: any) => {
                                setAddFormData((prevDisplayData: any) => ({
                                  ...prevDisplayData,
                                  uploadDoc: {
                                    name: e.target.files[0]?.name,
                                    value: base64Image,
                                    warning: false,
                                  },
                                }));
                              }
                            );
                          }}
                        />
                      </Button>
                      {addFormData.uploadDoc.warning === true ? (
                        <span
                          style={{
                            color: "#eb5757",
                            fontSize: "11px",
                          }}
                        >
                          Upload the doc
                        </span>
                      ) : null}
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </div>
          ) : pageStatus === 2 ? (
            <Grid
              container
              spacing={2}
              sx={{
                displau: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            >
              <Grid xs={12}>
                <svg
                  className="checkmark"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52"
                >
                  <circle
                    className="checkmark__circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                  />
                  <path
                    className="checkmark__check"
                    fill="none"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                  />
                </svg>
                <p className="quote_success text-center">
                  New request has been submitted successfully!
                </p>
              </Grid>
            </Grid>
          ) : null}
          {/* <Grid container spacing={2}>
            {pageStatus === 2 ? null : (
              <Grid
                xs={12}
                style={{ textAlign: "center", position: "relative" }}
                className="ctaBtn">
                <Button
                  variant="contained"
                  className="submitBtn"
                  onClick={() => {
                    changePageStatus(true);
                  }}>
                  {pageStatus === 1 ? "Submit" : "Continue"}
                </Button>

                {pageStatus === 1 ? null : (
                  <h5 className="steps">
                    {" "}
                    {pageStatus} <span>/1</span>{" "}
                  </h5>
                )}
              </Grid>
            )}
          </Grid> */}
        </div>
        {pageStatus !== 4 ? (
          <Grid container spacing={3}>
            <Grid xs={12} className="ctaBtn mt-4">
              <Button
                variant="contained"
                className="submitBtn fixedbtn"
                onClick={validateForm}
                disabled={disableSave}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        ) : null}
      </div>
    </SlidingPanel>
  );
};

export default AddNewRequest;
