//used
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Button, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import tickicon from "../../../../../../src/images/deletePopup_tickicon.svg";
import deleteimage from "../../../../../../src/images/deletepopup_deleteicon.svg";
import { URL_CONSTANTS } from "../../../../../APIVar/URLConstants";
import SalaryBracketInputs from "../../../../../Components/Broker/SalaryBracketInput/SalaryBracketInput";
import BulkUploadForm from "../../../../../Components/Common/BulkUploadFormSP/BulkUploadForm";
import PostQueryForm from "../../../../../Components/Common/PostQuerySP/PostQueryForm";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";
import { CLIENT_SERVICES } from "../../../../../Services/Clients/ClientService";
import { clientsFormDTO } from "../../../../../Services/DTO/ClientDTO";
import { TUserData } from "../../../../../Services/Types/TUserData";
import { RootState } from "../../../../../Store/Store";
import { useAppSelector } from "../../../../../Store/hooks";
import { Patch } from "../../../../../Supporting files/CallAPI";
import CheckBox from "../../../../../Supporting files/CheckBox/CheckBox";
import CurrencyTextField from "../../../../../Supporting files/Currency/CurrencyTextField";
import DatePicker from "../../../../../Supporting files/DatePicker/DatePicker";
import {
  get_city_list_via_name,
  get_country_list,
  get_industry_list,
  get_state_list,
  get_subindustry_list,
  uploadImage,
  validateEmail,
  validateMobileNumber,
  validateUrl,
} from "../../../../../Supporting files/HelpingFunction";
import MultipleSearchDropdown from "../../../../../Supporting files/MultipleSearchDropdown/MultipleSearchDropdown";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import SearchDropdown from "../../../../../Supporting files/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import TextAreaField from "../../../../../Supporting files/TextAreaField/TextAreaField";
import UGEmailInput from "../../../../../Supporting files/UGEmailInput/UGEmailInput";
import "../AddNewClient/AddNewClient.scss";
import ARMultiInputField from "../../../../../Supporting files/UGEmailInput/ARMultiInputField";
import NewLoader from "../../../../../Components/NewLoader/NewLoader";

function EditClient() {
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const windowURL = window.location.search;
  const params = new URLSearchParams(windowURL);
  const id = params.get("id");
  const navigate = useNavigate();
  const userState: TUserData = useAppSelector(
    (state: RootState) => state.userDetailsSlice
  );
  const [registerSpocId, setregisterSpocId] = useState("");
  const [sectionStatus, SetSectionStatus] = useState<any>(
    "Organisation Details"
  );
  const [upperWarnStatus, setUpperWarnStatus] = useState<boolean>(false);
  const [subsectionStatus, SetsubSectionStatus] = useState<any>("Basic Info");
  const [selectedState, setSelectedState] = useState({});
  const [selectedCountry, setSelectedcountry] = useState({});
  const [uploadFile, setUploadFile] = useState(false);
  //my state
  const [clientaddnewlayout, setclientaddnewlayout] = useState<
    clientsFormDTO[]
  >([]);
  const [sidepanelLayout, setsidepanelLayout] = useState<clientsFormDTO[]>([]);
  const [addClass, setAddClass] = useState<boolean>(false);
  const [countryData, setCountryData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [stateData, setStateData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [cityData, setCityData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [postQueryForm, setPostQueryForm] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [attachment, setAttachment] = useState(false);
  const [IndustryData, setIndustryData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [subIndustryData, setSubIndustryData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [spocdata, setspocdata] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [locationId, setlocationId] = useState("");
  const [featureEnable, setfeatureEnable] = useState<{
    enableEmployeePortal: any;
    enableEmployerPortal: any;
  }>({ enableEmployeePortal: false, enableEmployerPortal: false });
  const [notesData, setNotesData] = useState<
    Array<{ name: string; date: Date; description: string }>
  >([]);
  const [attachmentData, setAttachmentData] = useState<
    Array<{
      doc: string;
      description: string;
      date: Date;
      time: string;
      doc_name?: string;
    }>
  >([]);
  const [addClientLogo, setAddClientLogo] = useState<any[]>([]);
  const [countryId, setcountryId] = useState("");
  const [deleteStatusStep, setDeleteStatusStep] = useState(1);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [displayLogo, setDisplayLogo] = useState<{
    logo: string;
    warning: boolean;
  }>({ logo: "clientstaticlogo.jpg", warning: false });
  const [salaryBracketsInfo, setSalaryBracketsInfo] = useState<{
    invalidSalaryBrackets: boolean;
    salaryBracketsString: string;
  }>({ invalidSalaryBrackets: true, salaryBracketsString: "" });
  const [dept, setdept] = useState<{ _id?: string; label: string }[]>([]);
  const [firstNameChar, setFirstNameChar] = useState("");
  const [isClientAssigned, setIsClientAssigned] = useState(false);
  const [otherIndustrySubindustry, setotherIndustrySubindustry] = useState<{
    industry: string;
    subIndustry: string;
    industryWarn: boolean;
  }>({ industry: "", subIndustry: "", industryWarn: false });
  const [showLoader, setShowLoader] = useState(true);
  // useEffect(() => {
  //   const onSuccess = (res: any) => {
  //     setIsClientAssigned(res.data.data.founded);
  //   };

  //   const onError = (err: any) => {
  //     console.log("err", err);
  //   };

  //   CLIENT_SERVICES.check_isclient_attached_toPolicy(onSuccess, onError, id);
  // }, []);
  useEffect(() => {
    setShowLoader(true);
  }, []);
  // for scrolling
  const handleClicks = (id: any) => {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const updateMasterState = (attrName: any, value: any) => {
    let data: clientsFormDTO[] = clientaddnewlayout;
    let key = attrName[5];
    if (attrName[0] === "otherIndustrySubindustry") {
      let otherIndustry = { ...otherIndustrySubindustry };
      if (attrName[1] === "Industry") {
        otherIndustry.industry = value;
      } else if (attrName[1] === "SubIndustry") {
        otherIndustry.subIndustry = value;
      }
      setotherIndustrySubindustry(otherIndustry);
    }
    if (attrName === "notes_new_data") {
      setNotesData((notesData) => [
        ...notesData,

        { name: "Umesh", date: new Date(), description: value },
      ]);
    } else if (attrName === "attachment_new_data") {
      const date = new Date();

      let day: any = date.getDate();
      let month: any = date.getMonth() + 1;
      let year: any = date.getFullYear();

      // This arrangement can be altered based on how we want the date's format to appear.
      let currentDate: any = `${day}-${month}-${year}`;
      setAttachmentData((prevState) => [
        ...prevState,

        {
          doc: value.doc,

          description: value.description,

          date: currentDate,

          time: new Date().toLocaleTimeString(),

          doc_name: value.doc_name,
        },
      ]);
    } else if (attrName === setPostQueryForm) {
      attrName(value);
    } else if (attrName === setUploadFile) {
      attrName(value);
    } else if (key == "value") {
      //For required fields
      if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].required === true &&
        (value === undefined || value === null
          ? 0
          : value.toString().length) === 0
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].error = true;
      } else {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].error = false;
      }
      //update value
      if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type == "industry"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value && value.label;
        get_subindustry_list(value, (cb: any) => {
          setSubIndustryData(cb);
        });
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3] + 1
        ].children[attrName[4]].value = "";
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type == "subIndustry"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value && value.label;
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type == "country"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value && value.label;
        get_state_list(value, (cb: any) => {
          setStateData(cb);
        });
        setcountryId(value);
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3] + 1
        ].children[attrName[4]].value = "";
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3] + 2
        ].children[attrName[4]].value = "";
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type == "state"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value && value.label;

        get_city_list_via_name(
          countryId ? countryId : selectedCountry,
          value,
          (cb: any) => {
            setCityData(cb);
          }
        );
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value === "N.A."
          ? (data[attrName[0]].children[attrName[1]].children[
              attrName[2]
            ].children[attrName[3] + 1].children[attrName[4]].value = "N.A.")
          : (data[attrName[0]].children[attrName[1]].children[
              attrName[2]
            ].children[attrName[3] + 1].children[attrName[4]].value = "");
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type == "city"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value && value.label;
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type == "spoc"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value.label;
        setregisterSpocId(value.id);
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "domainSelect" &&
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_lable === "Domain"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value.join(",");
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "domainSelect"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value;
      } else {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value;
      }
      setclientaddnewlayout([...data]);
    }
  };
  let timeout: any;
  useEffect(() => {
    if (timeout) {
      clearTimeout(timeout);
    }
    const delayedApiCall = () => {
      isPolicyAttached();
    };

    timeout = setTimeout(delayedApiCall, 900);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  const isPolicyAttached = () => {
    const onSuccess = (res: any) => {
      setIsClientAssigned(res.data.data.founded);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    CLIENT_SERVICES.check_isclient_attached_toPolicy(onSuccess, onError, id);
  };
  //get spoc list
  const getSpoc = () => {
    const onSuccess = (res: any) => {
      setspocdata(res.data.data);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    CLIENT_SERVICES.get_Spoc(onSuccess, onError);
  };

  //delete location
  const delete_location_api = () => {
    setDeleteStatusStep(2);
    const onSuccess = (res: any) => {
      setDeleteStatusStep(2);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    CLIENT_SERVICES.delete_location_client(onSuccess, onError, id, locationId);
  };

  //get one client data
  const get_client_one_data = () => {
    const onSuccess = (res: any) => {
      if (res) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      setDisplayLogo({
        logo: res.data.data[0].logo ?? "clientstaticlogo.jpg",
        warning: false,
      });
      setregisterSpocId(res.data.data[0].registerSpocId);
      let newData = res.data.data[0].layout?.map(
        (dataMainsection: clientsFormDTO) => {
          let newMainSection = { ...dataMainsection };
          newMainSection.children = dataMainsection.children?.map(
            (dataSubsection) => {
              let newSubsection = { ...dataSubsection };
              newSubsection.children = dataSubsection.children.map(
                (dataRow) => {
                  let newRow = { ...dataRow };
                  newRow.children = dataRow.children?.map((dataColumn) => {
                    let newColumn = { ...dataColumn };
                    newColumn.children = dataColumn.children?.map(
                      (dataInput) => {
                        if (dataInput.field_lable === "Organization") {
                          setFirstNameChar(dataInput.value);
                        }
                        if (dataInput.field_lable === "Country") {
                          setSelectedcountry(dataInput.value);
                        }
                        if (dataInput.field_lable === "State") {
                          setSelectedState(dataInput.value);
                        }
                        if (
                          dataInput.field_type === "domainSelect" // && dataInput.field_lable === "Departments"
                        ) {
                          return { ...dataInput };
                        } else if (
                          dataInput.field_type === "domainSelect" &&
                          dataInput.field_lable === "Salary Brackets"
                        ) {
                          return { ...dataInput };
                        } else if (
                          (dataInput.field_lable === "Departments" ||
                            dataInput.field_lable === "Designations") &&
                          dataInput.value[0].label === ""
                        ) {
                          return {
                            ...dataInput,
                            value: [],
                          };
                        } else {
                          return { ...dataInput };
                        }
                      }
                    );
                    return newColumn;
                  });
                  return newRow;
                }
              );
              return newSubsection;
            }
          );
          return newMainSection;
        }
      );

      setclientaddnewlayout(newData);
      setAttachmentData(res.data.data[0].attachments);
      setfeatureEnable(res.data.data[0].settings);
      let arr: any = [
        ...res.data.data[0].layout,
        { type: "section", id: "Settings", name: "Settings", children: [] },
      ];
      setsidepanelLayout(arr);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };
    CLIENT_SERVICES.get_edit_one_data(onSuccess, onError, id);
  };

  // form submission of contact
  const detail_form_submit = async () => {
    if (disableSave) return;
    let hasError = false;
    let newData = clientaddnewlayout?.map((dataMainsection: clientsFormDTO) => {
      let newMainSection = { ...dataMainsection };
      newMainSection.children = dataMainsection.children?.map(
        (dataSubsection) => {
          let newSubsection = { ...dataSubsection };
          newSubsection.children = dataSubsection.children?.map((dataRow) => {
            let newRow = { ...dataRow };
            newRow.children = dataRow.children?.map((dataColumn) => {
              let newColumn = { ...dataColumn };
              newColumn.children = dataColumn.children?.map(
                (dataInput, ind) => {
                  if (dataInput.field_lable === "Salary Brackets") {
                    const result = salaryBracketsInfo.salaryBracketsString
                      .split(",")
                      .map((item: any) => {
                        const [lowerRange, upperRange] = item.split("-");
                        return { lowerRange: parseInt(lowerRange), upperRange };
                      });
                    let lastLower = Number(
                      `${result[result.length - 1].lowerRange}`
                    );
                    let lastUpper = Number(
                      `${result[result.length - 1].upperRange}`
                    );
                    if (
                      result.length === 1 &&
                      lastUpper === 0 &&
                      lastLower === 0
                    ) {
                      return {
                        ...dataInput,
                        error: false,
                        value: salaryBracketsInfo.salaryBracketsString,
                      };
                    }
                    if (lastUpper === 0) {
                      if (!hasError) {
                        setUpperWarnStatus(true);
                        hasError = true;
                      }
                    } else if (lastLower >= lastUpper) {
                      if (!hasError) {
                        setUpperWarnStatus(true);
                        hasError = true;
                      }
                    }

                    return {
                      ...dataInput,
                      error: hasError,
                      value: salaryBracketsInfo.salaryBracketsString,
                    };
                  } else if (dataInput.field_type === "industry") {
                    if (dataInput.value === "Other") {
                      if (!otherIndustrySubindustry.industry) {
                        hasError = true;
                        setotherIndustrySubindustry((prev) => ({
                          ...prev,
                          industryWarn: true,
                        }));
                        return { ...dataInput, value: "Other" };
                      }
                      return {
                        ...dataInput,
                        value: otherIndustrySubindustry.industry,
                      };
                    }
                  } else if (dataInput.field_type === "subIndustry") {
                    if (dataInput.value === "Other") {
                      return {
                        ...dataInput,
                        value: otherIndustrySubindustry.subIndustry,
                      };
                    }
                  } else if (dataInput.field_type === "domainSelect") {
                    console.log("mainSection", dataInput.value);
                    let final =
                      Array.isArray(dataInput?.value) &&
                      dataInput?.value?.map((item: any) => {
                        return { ...item, label: item.label.trim() };
                      });
                    console.log("final", final);
                    return {
                      ...dataInput,
                      value: final,
                    };
                  }
                  if (
                    dataInput.required === true &&
                    dataInput.value.length === 0
                  ) {
                    hasError = true;
                    return { ...dataInput, error: true };
                  } else if (
                    dataInput.characterLimit &&
                    dataInput.value.length > dataInput.characterLimit
                  ) {
                    hasError = true;
                    return { ...dataInput, error: true };
                  } else if (
                    dataInput.required === true &&
                    dataInput.field_type === "email"
                  ) {
                    if (validateEmail(dataInput.value.toString()) === true) {
                      return { ...dataInput, error: false };
                    } else {
                      hasError = true;
                      return { ...dataInput, error: true };
                    }
                  } else if (dataInput.field_type === "email") {
                    if (
                      validateEmail(dataInput.value.toString()) === false &&
                      dataInput.value.length >= 1
                    ) {
                      hasError = true;
                      return { ...dataInput, error: true };
                    } else {
                      return { ...dataInput, error: false };
                    }
                  } else if (
                    dataInput.required === true &&
                    dataInput.field_type === "url"
                  ) {
                    if (validateUrl(dataInput.value.toString()) === true) {
                      // return { ...dataInput, error: false };
                      return {
                        ...dataInput,
                        error: false,
                        value: `https://` + dataInput?.value,
                      };
                    } else {
                      hasError = true;
                      return { ...dataInput, error: true };
                    }
                  } else if (dataInput.field_type === "url") {
                    if (
                      validateUrl(dataInput.value.toString()) === false &&
                      dataInput.value.length >= 1
                    ) {
                      hasError = true;
                      return { ...dataInput, error: true };
                    } else {
                      return { ...dataInput, error: false };
                    }
                  } else if (dataInput.field_type === "phone") {
                    if (
                      dataInput.required === true &&
                      dataInput.value.length === 0
                    ) {
                      hasError = true;
                      return { ...dataInput, error: true };
                    } else if (
                      validateMobileNumber(dataInput.value) === false &&
                      dataInput.value.length >= 1
                    ) {
                      hasError = true;
                      return { ...dataInput, error: true };
                    } else {
                      return { ...dataInput, error: false };
                    }
                  } else if (
                    dataInput.required === true &&
                    dataInput.field_type === "address"
                  ) {
                    hasError = true;
                    return { ...dataInput, error: true };
                  } else {
                    return { ...dataInput, error: false };
                  }
                }
              );
              return newColumn;
            });
            return newRow;
          });
          return newSubsection;
        }
      );
      return newMainSection;
    });

    setclientaddnewlayout(newData);

    let islogo = false;
    if (!addClientLogo[0] && !displayLogo.logo) {
      setDisplayLogo((prev) => ({
        ...prev,
        warning: true,
      }));
      return toast.error("Please upload logo");
    }
    // api call code
    if (hasError === false) {
      let params = {
        logo: addClientLogo[0] ? addClientLogo[0] : displayLogo.logo,
        layout: newData,
        attachments: attachmentData,
        settings: featureEnable,
        registerSpocId: registerSpocId,
      };
      setDisableSave(true);
      Patch(`${URL_CONSTANTS.update_one_client}${id}`, params)
        .then((data: any) => {
          let status = data[0];
          let response = data[1];
          if (status === "success") {
            toast.success("Data added successfully");
            navigate(ALL_ROUTES.CLIENTS);
          }
          if (status.response?.data?.status === "failed") {
            setDisableSave(false);
            let param = status.response.data.errors[0].param;
            let error = status.response.data.errors[0].message;
            toast.error(param + " " + " " + error);
          }
        })
        .catch(() => setDisableSave(false));
    } else {
      toast.error("Enter valid value");
    }
  };

  // useEffect(() => {
  //   get_state_list(selectedCountry, (cb: any) => {
  //     setStateData(cb);
  //   });

  //   get_city_list_via_name(selectedCountry, selectedState, (cb: any) => {
  //     setCityData(cb);
  //   });
  // }, [selectedCountry, selectedState]);
  let checkCountry: any;
  let timeoutIdData1: any;

  useEffect(() => {
    if (timeoutIdData1) {
      clearTimeout(timeoutIdData1);
    }

    const delayedApiCall = () => {
      console.log(
        "selectCountry---",
        selectedCountry,
        checkCountry,
        checkCountry !== selectedCountry
      );

      if (selectedCountry && checkCountry !== selectedCountry) {
        checkCountry = selectedCountry;
        get_state_list(selectedCountry, (cb: any) => {
          setStateData(cb);
        });
      }
    };
    timeoutIdData1 = setTimeout(delayedApiCall, 2000);

    return () => {
      if (timeoutIdData1) {
        clearTimeout(timeoutIdData1);
      }
    };
  }, [selectedCountry]);

  let timeoutIdData2: any;

  useEffect(() => {
    if (timeoutIdData2) {
      clearTimeout(timeoutIdData2);
    }

    const delayedApiCall = () => {
      if (selectedCountry && selectedState) {
        get_city_list_via_name(selectedCountry, selectedState, (cb: any) => {
          setCityData(cb);
        });
      }
    };
    timeoutIdData2 = setTimeout(delayedApiCall, 2000);

    return () => {
      if (timeoutIdData2) {
        clearTimeout(timeoutIdData2);
      }
    };
  }, [selectedState]);
  let timeoutId: any;
  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const delayedApiCall = () => {
      get_client_one_data();
      get_industry_list((cb: any) => {
        setIndustryData(cb);
      });
      getSpoc();
      get_country_list((cb: any) => {
        setCountryData(cb);
      });
    };

    timeoutId = setTimeout(delayedApiCall, 900);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [deleteStatus]);

  const handleClick = (e: any) => {
    const { name, checked } = e.target;
    setfeatureEnable((prevState) => {
      return {
        ...prevState,
        [name]: checked,
      };
    });
    setToggle(true);
  };


  return (
    <>
      {showLoader ? (
        <NewLoader />
      ) : (
        <Box className="layoutWrapper">
          <BulkUploadForm
            open_status={uploadFile}
            attrName={setUploadFile}
            value_update={updateMasterState}
          />
          <Modal open={deleteStatus} className="modalWrapper">
            <>
              {deleteStatusStep === 1 ? (
                <Box className="modalInner deletepopup">
                  <Grid container spacing={1}>
                    <Grid xs={12}>
                      <Link
                        className="close-button"
                        onClick={() => setDeleteStatus(false)}
                      />
                      <div className="img-prt">
                        <img src={deleteimage} alt="" className="img" />
                      </div>

                      <h5 className="popup-heading">
                        Are you sure you want
                        <br /> to delete this Attachment?
                      </h5>
                    </Grid>

                    <Grid
                      xs={12}
                      className="ctaBtn"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        variant="contained"
                        className="submitBtn"
                        onClick={delete_location_api}
                      >
                        Yes, Delete
                      </Button>
                      <Button
                        onClick={() => {
                          setDeleteStatus(false);
                        }}
                        variant="contained"
                        className="cancelBtn"
                      >
                        cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              ) : deleteStatusStep === 2 ? (
                <Box className="modalInner deletepopup">
                  <Grid container spacing={1}>
                    <Grid xs={12}>
                      <Link
                        className="close-button"
                        onClick={() => setDeleteStatus(false)}
                      />
                      <div className="img-prt">
                        <img src={tickicon} alt="" className="img" />
                      </div>
                      <h5 className="popup-heading">Deleted Successfully!</h5>
                    </Grid>
                    <Grid xs={12}></Grid>
                    <Grid
                      xs={12}
                      className="ctaBtn"
                      style={{ textAlign: "center" }}
                    >
                      <Button
                        onClick={() => {
                          setDeleteStatus(false);
                          setDeleteStatusStep(1);
                        }}
                        variant="contained"
                        className="submitBtn"
                      >
                        Okay
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              ) : null}
            </>
          </Modal>
          {/* Page header */}
          <Grid container spacing={3} className="pageHeader">
            <Grid xs={6} className="pageTitle">
              <Link
                className="backStep"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ChevronLeftIcon />
              </Link>

              <div>
                <h3>Edit - {firstNameChar}</h3>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid xs={12}>
              <Grid container spacing={3}>
                <Grid xs className="leftSection h-100">
                  <>
                    {sidepanelLayout.map(
                      (dataMainSection: clientsFormDTO, index: number) => (
                        <>
                          <Link
                            className={
                              sectionStatus === dataMainSection.name
                                ? `active sectionLink`
                                : "sectionLink personalDetailsSection"
                            }
                            onClick={() => {
                              handleClicks(dataMainSection.name);
                              setAddClass(true);
                              SetSectionStatus(
                                dataMainSection.name == undefined
                                  ? ""
                                  : dataMainSection.name
                              );
                            }}
                          >
                            <span>{dataMainSection.name}</span>
                          </Link>
                          {dataMainSection.children.map((dataSubsection) => (
                            <ul className="accordion_list">
                              <li>
                                <Link
                                  className={
                                    subsectionStatus === dataSubsection.name
                                      ? `inner_link active`
                                      : `inner_link`
                                  }
                                  onClick={() => {
                                    handleClicks(dataSubsection.name);
                                    SetsubSectionStatus(dataSubsection.name);
                                    SetSectionStatus(
                                      dataMainSection.name == undefined
                                        ? ""
                                        : dataMainSection.name
                                    );
                                  }}
                                >
                                  {dataSubsection.name}
                                </Link>
                              </li>
                            </ul>
                          ))}
                        </>
                      )
                    )}
                  </>
                </Grid>
                <Grid xs className="rightSection">
                  <Grid container spacing={3}>
                    <Grid xs={12} sx={{ paddingBottom: "16px" }}>
                      <Box className="upload-logo">
                        <Grid
                          container
                          sx={{ paddingLeft: 0 }}
                          className="mb-3"
                        >
                          <Grid xs={12}>
                            <img
                              src={
                                displayLogo.logo
                                  ? displayLogo.logo
                                  : "clientstaticlogo.jpg"
                              }
                              alt=""
                              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                e.currentTarget.src = "clientstaticlogo.jpg"; // Replace with your fallback image path
                              }}
                              className="upload_company_logo add_logo"
                            />
                            <Link className="upload_img" component="label">
                              <input
                                hidden
                                accept="image/*"
                                multiple
                                type="file"
                                onChange={(e: any) => {
                                  const file = e.target.files[0];
                                  const fileSize = file?.size;
                                  const maxSizeBytes = 2 * 1024 * 1024;
                                  if (fileSize && fileSize > maxSizeBytes) {
                                    toast.error("File size exceeds 2 MB limit");
                                    return (e.target.value = null);
                                  }
                                  const allowedFormats = [
                                    "image/png",
                                    "image/jpeg",
                                    "image/jpg",
                                  ];
                                  if (allowedFormats?.includes(file?.type)) {
                                    uploadImage(
                                      file,
                                      (base64Image: any, base64String: any) => {
                                        setAddClientLogo((prevState: any) => [
                                          ...prevState,
                                          base64Image,
                                        ]);
                                        setDisplayLogo({
                                          logo: base64String,
                                          warning: false,
                                        });
                                      }
                                    );
                                  } else {
                                    toast.error(
                                      "Uploaded format not allowed. Only .png, .jpeg, .jpg allowed"
                                    );
                                  }
                                }}
                              />
                              {displayLogo.warning === true ? (
                                <span className="error">Upload logo</span>
                              ) : null}
                              <img src="images/upload_logo_icon.svg" alt="" />
                              <span>
                                {displayLogo.logo === "clientstaticlogo.jpg"
                                  ? "Replace Sample Logo"
                                  : "Update Logo"}
                              </span>
                              <div className="mt-1">
                                <span>Maximum size of logo is 2 MB*</span>
                              </div>
                            </Link>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>

                  {clientaddnewlayout.map(
                    (
                      dataMainSection: clientsFormDTO,
                      indexMainSection: number
                    ) => (
                      // Main section
                      <Box
                        onMouseEnter={() => {
                          SetSectionStatus(dataMainSection.name);
                        }}
                        className="detailSection"
                      >
                        <div id={dataMainSection.name}>
                          <div className="sectionTitle">
                            <h4>{dataMainSection.name}</h4>
                          </div>
                          {dataMainSection.children.map(
                            (dataSubsection, indexSubsection) => (
                              // Sub Section
                              <Grid container>
                                <Grid xs={12}>
                                  <h5
                                    id={dataSubsection.name}
                                    key={dataSubsection.type}
                                    className="subHeading"
                                  >
                                    {dataSubsection.name}
                                  </h5>
                                  {dataSubsection.children.map(
                                    (dataRow, indexRow) => (
                                      // Row
                                      <>
                                        <Grid
                                          onMouseEnter={() => {
                                            SetsubSectionStatus(
                                              dataSubsection.name
                                            );
                                          }}
                                          container
                                          className="subRow"
                                          spacing={3}
                                        >
                                          {dataRow.children?.map(
                                            (dataColumn, indexColumn) => (
                                              <>
                                                {/* // Columns created */}

                                                {dataColumn?.children?.map(
                                                  (dataInput, indexInput) => {
                                                    if (
                                                      dataInput?.field_lable ===
                                                      "Salary Brackets"
                                                    ) {
                                                      return null;
                                                    }
                                                    let departments = "";
                                                    for (
                                                      let i = 0;
                                                      i <
                                                      dataInput?.value?.length;
                                                      i++
                                                    ) {
                                                      if (
                                                        dataInput.value[i]
                                                          ?.label
                                                      ) {
                                                        departments +=
                                                          dataInput.value[i]
                                                            .label;
                                                        if (
                                                          i <
                                                          dataInput.value
                                                            .length -
                                                            1
                                                        ) {
                                                          departments += ", ";
                                                        }
                                                      }
                                                    }

                                                    // input Component will be here
                                                    return (
                                                      <Grid
                                                        xs={
                                                          dataInput.field_lable ===
                                                            "Departments" ||
                                                          dataInput.field_lable ===
                                                            "Designations"
                                                            ? 6
                                                            : true
                                                        }
                                                        className="subColumn"
                                                      >
                                                        {dataInput.field_type ===
                                                        "singleselect" ? (
                                                          <SelectDropdown
                                                            class_name="inputField"
                                                            title={
                                                              dataInput?.field_lable
                                                            }
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            value={
                                                              dataInput.value
                                                            }
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            info_status={
                                                              dataInput.toolTip
                                                            }
                                                            info_message={
                                                              dataInput.tipText
                                                            }
                                                            dropdown_data={
                                                              dataInput.pickliststep ??
                                                              []
                                                            }
                                                            warn_status={
                                                              dataInput.error
                                                            }
                                                            required={
                                                              dataInput.required
                                                            }
                                                            error_messg={`Enter ${dataInput.field_lable}`}
                                                          />
                                                        ) : dataInput.field_type ===
                                                          "phone" ? (
                                                          <RKTextField
                                                            validation_type="numeric"
                                                            class_name="inputField"
                                                            title={
                                                              dataInput.field_lable
                                                            }
                                                            value={
                                                              dataInput.value
                                                            }
                                                            info_status={
                                                              dataInput.toolTip
                                                            }
                                                            info_message={
                                                              dataInput.tipText
                                                            }
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            warn_status={
                                                              dataInput.error
                                                            }
                                                            error_messg={
                                                              validateMobileNumber(
                                                                dataInput.value
                                                              ) === false
                                                                ? `Enter ${dataInput.field_lable}`
                                                                : dataInput.characterLimit &&
                                                                  dataInput
                                                                    .value
                                                                    .length >
                                                                    dataInput.characterLimit
                                                                ? `Maximum ${dataInput.characterLimit} limit`
                                                                : ""
                                                            }
                                                            required={
                                                              dataInput.required
                                                            }
                                                            charcterlimit={
                                                              dataInput.field_lable ===
                                                              "Organization Phone Number"
                                                                ? 15
                                                                : !dataInput.characterLimit
                                                                ? 11
                                                                : dataInput.characterLimit
                                                            }
                                                          />
                                                        ) : dataInput.field_type ===
                                                          "email" ? (
                                                          <RKTextField
                                                            validation_type="email"
                                                            type="email"
                                                            class_name="inputField"
                                                            title={
                                                              dataInput.field_lable
                                                            }
                                                            value={
                                                              dataInput.value
                                                            }
                                                            info_status={
                                                              dataInput.toolTip
                                                            }
                                                            info_message={
                                                              dataInput.tipText
                                                            }
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            warn_status={
                                                              dataInput.error
                                                            }
                                                            error_messg={
                                                              dataInput.value
                                                                .length === 0
                                                                ? `Enter ${dataInput.field_lable}`
                                                                : validateEmail(
                                                                    dataInput.value
                                                                  ) === false
                                                                ? `Enter a valid email Id`
                                                                : ""
                                                            }
                                                            required={
                                                              dataInput.required
                                                            }
                                                            charcterlimit={
                                                              !dataInput.characterLimit
                                                                ? 50
                                                                : dataInput.characterLimit
                                                            }
                                                            disabled={
                                                              dataInput.field_lable ===
                                                              "Organization Email"
                                                            }
                                                          />
                                                        ) : dataInput.field_type ===
                                                          "clientId" ? null : dataInput.field_type ===
                                                          "datepicker" ? (
                                                          <DatePicker
                                                            class_name="inputField"
                                                            title={
                                                              dataInput.field_lable
                                                            }
                                                            value={
                                                              dataInput.value ||
                                                              null
                                                            }
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            error_message="Enter a Valid Date"
                                                            warn_status={
                                                              dataInput.error ===
                                                              undefined
                                                                ? false
                                                                : dataInput.error
                                                            }
                                                            info_status={
                                                              dataInput.toolTip
                                                            }
                                                            info_message={
                                                              dataInput.tipText
                                                            }
                                                            required={
                                                              dataInput.required
                                                            }
                                                          />
                                                        ) : dataInput.field_type ===
                                                          "searchselect" ? (
                                                          <SearchDropdown
                                                            class_name="inputField"
                                                            title={
                                                              dataInput.field_lable
                                                            }
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            value={
                                                              dataInput.value
                                                            }
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            data={
                                                              dataInput.pickliststep
                                                                ? dataInput.pickliststep
                                                                : []
                                                            }
                                                            warn_status={
                                                              dataInput.error ===
                                                              undefined
                                                                ? false
                                                                : dataInput.error
                                                            }
                                                            required={
                                                              dataInput.required
                                                            }
                                                            error_message={
                                                              "Select the value"
                                                            }
                                                            info_status={
                                                              dataInput.toolTip
                                                            }
                                                            info_message={
                                                              dataInput.tipText
                                                            }
                                                          />
                                                        ) : dataInput.field_type ===
                                                          "checkbox" ? (
                                                          <CheckBox
                                                            title={
                                                              dataInput.field_lable
                                                            }
                                                            name={
                                                              dataInput.field_lable
                                                            }
                                                            id={dataInput._id}
                                                            value={Boolean(
                                                              dataInput.value
                                                            )}
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            default_check={true}
                                                          />
                                                        ) : dataInput.field_type ===
                                                          "number" ? (
                                                          <RKTextField
                                                            validation_type="numeric"
                                                            class_name="inputField"
                                                            title={
                                                              dataInput.field_lable
                                                            }
                                                            value={
                                                              dataInput.value
                                                            }
                                                            info_status={
                                                              dataInput.toolTip
                                                            }
                                                            info_message={
                                                              dataInput.tipText
                                                            }
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            warn_status={
                                                              dataInput.error
                                                            }
                                                            error_messg={
                                                              dataInput.value
                                                                .length === 0
                                                                ? `Enter ${dataInput.field_lable}`
                                                                : dataInput.characterLimit &&
                                                                  dataInput
                                                                    .value
                                                                    .length >
                                                                    dataInput.characterLimit
                                                                ? `Maximum ${dataInput.characterLimit} limit`
                                                                : ""
                                                            }
                                                            required={
                                                              dataInput.required
                                                            }
                                                            charcterlimit={
                                                              !dataInput.maxDigits
                                                                ? 12
                                                                : dataInput.maxDigits
                                                            }
                                                          />
                                                        ) : dataInput.field_type ==
                                                          "currency" ? (
                                                          <CurrencyTextField
                                                            validation_type="numeric"
                                                            class_name="inputField"
                                                            title={
                                                              dataInput.field_lable
                                                            }
                                                            value={
                                                              dataInput.value
                                                            }
                                                            info_status={
                                                              dataInput.toolTip
                                                            }
                                                            info_message={
                                                              dataInput.tipText
                                                            }
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            warn_status={
                                                              dataInput.error
                                                            }
                                                            error_messg={
                                                              dataInput.value
                                                                .length === 0
                                                                ? `Enter ${dataInput.field_lable}`
                                                                : dataInput.characterLimit &&
                                                                  dataInput
                                                                    .value
                                                                    .length >
                                                                    dataInput.characterLimit
                                                                ? `Maximum ${dataInput.characterLimit} Limit`
                                                                : ""
                                                            }
                                                            required={
                                                              dataInput.required
                                                            }
                                                            charcterlimit={
                                                              !dataInput.maxDigits
                                                                ? 12
                                                                : dataInput.maxDigits
                                                            }
                                                          />
                                                        ) : dataInput.field_type ===
                                                          "multiselect" ? (
                                                          <MultipleSearchDropdown
                                                            class_name="inputField"
                                                            title={
                                                              dataInput.field_lable
                                                            }
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            value={
                                                              dataInput?.value
                                                            }
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            multiple_data={
                                                              dataInput.pickliststep
                                                                ? dataInput.pickliststep
                                                                : []
                                                            }
                                                            info_status={
                                                              dataInput.toolTip
                                                            }
                                                            info_message={
                                                              dataInput.tipText
                                                            }
                                                            warn_status={
                                                              dataInput.error ===
                                                              undefined
                                                                ? false
                                                                : dataInput.error
                                                            }
                                                            error_messg={
                                                              "Select the value"
                                                            }
                                                            required={
                                                              dataInput.required
                                                            }
                                                          />
                                                        ) : dataInput.field_type ===
                                                          "multiline" ? (
                                                          <TextAreaField
                                                            specialcharacter={
                                                              !dataInput.speacialChar
                                                                ? false
                                                                : dataInput.speacialChar
                                                            }
                                                            alphanumeric={
                                                              !dataInput.alphaNumeric
                                                                ? false
                                                                : dataInput.alphaNumeric
                                                            }
                                                            class_name="inputField"
                                                            title={
                                                              dataInput.field_lable
                                                            }
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            value={
                                                              dataInput.value
                                                            }
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            info_status={
                                                              dataInput.toolTip
                                                            }
                                                            info_message={
                                                              dataInput.tipText
                                                            }
                                                            warn_status={
                                                              dataInput.error ===
                                                              undefined
                                                                ? false
                                                                : dataInput.error
                                                            }
                                                            error_messg={
                                                              dataInput.value
                                                                .length === 0
                                                                ? `Enter ${dataInput.field_lable}`
                                                                : dataInput.characterLimit &&
                                                                  dataInput
                                                                    .value
                                                                    .length >
                                                                    dataInput.characterLimit
                                                                ? `Maximum ${dataInput.characterLimit} charcter`
                                                                : ""
                                                            }
                                                            charcterlimit={
                                                              !dataInput.characterLimit
                                                                ? 30
                                                                : dataInput.characterLimit
                                                            }
                                                            required={
                                                              dataInput.required
                                                            }
                                                          />
                                                        ) : dataInput.field_type ===
                                                          "url" ? (
                                                          <RKTextField
                                                            validation_type="url"
                                                            class_name="inputField"
                                                            title={
                                                              dataInput.field_lable
                                                            }
                                                            value={
                                                              dataInput.value
                                                            }
                                                            info_status={
                                                              dataInput.toolTip
                                                            }
                                                            info_message={
                                                              dataInput.tipText
                                                            }
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            warn_status={
                                                              dataInput.error
                                                            }
                                                            error_messg={
                                                              dataInput.value
                                                                .length === 0
                                                                ? `Enter ${dataInput.field_lable}`
                                                                : validateUrl(
                                                                    dataInput.value
                                                                  ) === false
                                                                ? `Enter a valid Url`
                                                                : ""
                                                            }
                                                            required={
                                                              dataInput.required
                                                            }
                                                            charcterlimit={
                                                              !dataInput.characterLimit
                                                                ? 50
                                                                : dataInput.characterLimit
                                                            }
                                                          />
                                                        ) : dataInput.field_type ===
                                                          "country" ? (
                                                          <SearchDropdown
                                                            class_name="inputField"
                                                            title="Country"
                                                            value={
                                                              dataInput.value
                                                            }
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            data={countryData}
                                                            warn_status={
                                                              dataInput.error
                                                            }
                                                            required={
                                                              dataInput.required
                                                            }
                                                            error_message="Select country"
                                                          />
                                                        ) : dataInput.field_type ===
                                                          "state" ? (
                                                          <SearchDropdown
                                                            class_name="inputField"
                                                            title="State"
                                                            value={
                                                              dataInput.value
                                                            }
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            data={stateData}
                                                            warn_status={
                                                              dataInput.error
                                                            }
                                                            required={
                                                              dataInput.required
                                                            }
                                                            error_message="Select state"
                                                          />
                                                        ) : dataInput.field_type ===
                                                          "city" ? (
                                                          <SearchDropdown
                                                            class_name="inputField"
                                                            title="City"
                                                            value={
                                                              dataInput.value
                                                            }
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            data={cityData}
                                                            warn_status={
                                                              dataInput.error
                                                            }
                                                            required={
                                                              dataInput.required
                                                            }
                                                            error_message="Select city"
                                                          />
                                                        ) : dataInput.field_type ===
                                                          "spoc" ? (
                                                          <SearchDropdown
                                                            class_name="inputField"
                                                            title="Spoc"
                                                            value={
                                                              dataInput.value
                                                            }
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            data={spocdata}
                                                            warn_status={
                                                              dataInput.error
                                                            }
                                                            required={
                                                              dataInput.required
                                                            }
                                                            error_message="Select spoc"
                                                          />
                                                        ) : dataInput.field_type ===
                                                            "domainSelect" &&
                                                          dataInput.field_lable ===
                                                            "Domain" ? (
                                                          <UGEmailInput
                                                            class_name="inputField"
                                                            title={
                                                              dataInput.field_lable
                                                            }
                                                            value={
                                                              dataInput.value
                                                            }
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            calledFrom={null}
                                                            warn_status={
                                                              dataInput?.error
                                                            }
                                                            required={
                                                              dataInput?.required
                                                            }
                                                            error_messg={`Please enter ${dataInput.field_lable}`}
                                                          />
                                                        ) : dataInput.field_type ===
                                                          "domainSelect" ? (
                                                          <ARMultiInputField
                                                            class_name="inputField"
                                                            title={
                                                              dataInput.field_lable
                                                            }
                                                            value={
                                                              dataInput.value
                                                            }
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            warn_status={
                                                              dataInput?.error
                                                            }
                                                            required={
                                                              dataInput?.required
                                                            }
                                                            error_messg={`Please enter ${dataInput.field_lable}`}
                                                            stopEdit={
                                                              isClientAssigned
                                                            }
                                                          />
                                                        ) : dataInput.field_type ==
                                                          "industry" ? (
                                                          <>
                                                            <Box>
                                                              <Grid
                                                                container
                                                                columnSpacing={
                                                                  3
                                                                }
                                                                padding={0}
                                                              >
                                                                <Grid
                                                                  xs={
                                                                    dataInput.value ===
                                                                    "Other"
                                                                      ? 6
                                                                      : 12
                                                                  }
                                                                >
                                                                  <SearchDropdown
                                                                    class_name="inputField"
                                                                    title="Industry"
                                                                    value={
                                                                      dataInput.value
                                                                    }
                                                                    attrName={[
                                                                      indexMainSection,
                                                                      indexSubsection,
                                                                      indexRow,
                                                                      indexColumn,
                                                                      indexInput,
                                                                      "value",
                                                                    ]}
                                                                    value_update={
                                                                      updateMasterState
                                                                    }
                                                                    data={
                                                                      IndustryData
                                                                    }
                                                                    error_message="Select Industry"
                                                                    required={
                                                                      dataInput.required
                                                                    }
                                                                    warn_status={
                                                                      dataInput.error
                                                                    }
                                                                  />
                                                                </Grid>

                                                                {dataInput.value ===
                                                                  "Other" && (
                                                                  <Grid xs={6}>
                                                                    <RKTextField
                                                                      class_name="inputField"
                                                                      title="Industry"
                                                                      value={
                                                                        otherIndustrySubindustry.industry
                                                                      }
                                                                      attrName={[
                                                                        "otherIndustrySubindustry",
                                                                        "Industry",
                                                                      ]}
                                                                      value_update={
                                                                        updateMasterState
                                                                      }
                                                                      warn_status={
                                                                        otherIndustrySubindustry.industryWarn
                                                                      }
                                                                      required={
                                                                        true
                                                                      }
                                                                      charcterlimit={
                                                                        !dataInput.characterLimit
                                                                          ? 50
                                                                          : dataInput.characterLimit
                                                                      }
                                                                    />
                                                                  </Grid>
                                                                )}
                                                              </Grid>
                                                            </Box>
                                                          </>
                                                        ) : dataInput.field_type ==
                                                          "subIndustry" ? (
                                                          <>
                                                            <Box>
                                                              <Grid
                                                                container
                                                                columnSpacing={
                                                                  3
                                                                }
                                                                padding={0}
                                                              >
                                                                <Grid
                                                                  xs={
                                                                    dataInput.value ===
                                                                    "Other"
                                                                      ? 6
                                                                      : 12
                                                                  }
                                                                >
                                                                  <SearchDropdown
                                                                    class_name="inputField"
                                                                    title="Sub Industry"
                                                                    value={
                                                                      dataInput.value
                                                                    }
                                                                    attrName={[
                                                                      indexMainSection,
                                                                      indexSubsection,
                                                                      indexRow,
                                                                      indexColumn,
                                                                      indexInput,
                                                                      "value",
                                                                    ]}
                                                                    value_update={
                                                                      updateMasterState
                                                                    }
                                                                    data={
                                                                      subIndustryData
                                                                    }
                                                                    error_message="Select Sub Industry"
                                                                    required={
                                                                      dataInput.required
                                                                    }
                                                                    warn_status={
                                                                      dataInput.error
                                                                    }
                                                                  />
                                                                </Grid>

                                                                {dataInput.value ===
                                                                  "Other" && (
                                                                  <Grid xs={6}>
                                                                    <RKTextField
                                                                      class_name="inputField"
                                                                      title="Sub Industry"
                                                                      value={
                                                                        otherIndustrySubindustry.subIndustry
                                                                      }
                                                                      attrName={[
                                                                        "otherIndustrySubindustry",
                                                                        "SubIndustry",
                                                                      ]}
                                                                      value_update={
                                                                        updateMasterState
                                                                      }
                                                                      warn_status={
                                                                        false
                                                                      }
                                                                      charcterlimit={
                                                                        !dataInput.characterLimit
                                                                          ? 50
                                                                          : dataInput.characterLimit
                                                                      }
                                                                    />
                                                                  </Grid>
                                                                )}
                                                              </Grid>
                                                            </Box>
                                                          </>
                                                        ) : dataInput.field_type ===
                                                          "hide" ? null : dataInput.field_type ==
                                                            "singleline" &&
                                                          dataInput.field_lable ==
                                                            "Organisation" ? (
                                                          <RKTextField
                                                            class_name="inputField"
                                                            title={
                                                              dataInput.field_lable
                                                            }
                                                            value={
                                                              dataInput.value
                                                            }
                                                            info_status={
                                                              dataInput.toolTip
                                                            }
                                                            info_message={
                                                              dataInput.tipText
                                                            }
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            warn_status={
                                                              dataInput.error
                                                            }
                                                            error_messg={
                                                              dataInput.value
                                                                .length === 0
                                                                ? `Enter ${dataInput.field_lable}`
                                                                : dataInput.characterLimit &&
                                                                  dataInput
                                                                    .value
                                                                    .length >
                                                                    dataInput.characterLimit
                                                                ? `Maximum ${dataInput.characterLimit} charcter`
                                                                : ""
                                                            }
                                                            required={
                                                              dataInput.required
                                                            }
                                                            charcterlimit={
                                                              !dataInput.characterLimit
                                                                ? 30
                                                                : dataInput.characterLimit
                                                            }
                                                          />
                                                        ) : (
                                                          <RKTextField
                                                            specialcharacter={
                                                              !dataInput.speacialChar
                                                                ? false
                                                                : dataInput.speacialChar
                                                            }
                                                            alphanumeric={
                                                              dataInput.field_lable ===
                                                                "Chief Contact Name" ||
                                                              dataInput.field_lable ===
                                                                "Chief Contact Designation"
                                                                ? true
                                                                : !dataInput.alphaNumeric
                                                                ? false
                                                                : dataInput.alphaNumeric
                                                            }
                                                            validation_type="name"
                                                            class_name="inputField"
                                                            title={
                                                              dataInput.field_lable
                                                            }
                                                            value={
                                                              dataInput.value
                                                            }
                                                            info_status={
                                                              dataInput.toolTip
                                                            }
                                                            info_message={
                                                              dataInput.tipText
                                                            }
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            warn_status={
                                                              dataInput.error
                                                            }
                                                            error_messg={
                                                              dataInput.value
                                                                .length === 0
                                                                ? `Enter ${dataInput.field_lable}`
                                                                : dataInput.characterLimit &&
                                                                  dataInput
                                                                    .value
                                                                    .length >
                                                                    dataInput.characterLimit
                                                                ? `Maximum ${dataInput.characterLimit} charcter`
                                                                : ""
                                                            }
                                                            required={
                                                              dataInput.required
                                                            }
                                                            charcterlimit={
                                                              dataInput.field_lable ===
                                                              "Company Id"
                                                                ? undefined
                                                                : !dataInput.characterLimit
                                                                ? 30
                                                                : dataInput.characterLimit
                                                            }
                                                          />
                                                        )}
                                                      </Grid>
                                                    );
                                                  }
                                                )}

                                                {dataColumn.children?.map(
                                                  (dataInput, indexInput) => {
                                                    if (
                                                      dataInput.field_lable ===
                                                      "Salary Brackets"
                                                    ) {
                                                      return (
                                                        <Grid
                                                          xs={12}
                                                          className="subColumn"
                                                        >
                                                          <form>
                                                            <SalaryBracketInputs
                                                              dbValue={
                                                                dataInput.value
                                                              }
                                                              getSalaryBrackets={
                                                                setSalaryBracketsInfo
                                                              }
                                                              editMode
                                                              submit={
                                                                upperWarnStatus
                                                              }
                                                              submit2={
                                                                setUpperWarnStatus
                                                              }
                                                              stopEditOldValues={
                                                                isClientAssigned
                                                              }
                                                            />
                                                          </form>
                                                        </Grid>
                                                      );
                                                    }
                                                  }
                                                )}
                                              </>
                                            )
                                          )}
                                        </Grid>
                                      </>
                                    )
                                  )}
                                </Grid>
                              </Grid>
                            )
                          )}
                        </div>
                      </Box>
                    )
                  )}

                  <Box
                    onMouseEnter={() => {
                      SetSectionStatus("Settings");
                    }}
                    className="detailSection"
                    id="Settings"
                  >
                    <div className="sectionTitle">
                      <h4>Settings</h4>
                    </div>

                    <Grid container spacing={3} className="emp_switchFields">
                      <Grid xs={4} display={"flex"} alignItems="center">
                        <span> Enable Employer Portal</span>
                        <input
                          type="checkbox"
                          className="switch"
                          name="enableEmployerPortal"
                          onClick={(e: any) => {
                            if (!e.target.checked) {
                              toast.info(
                                "Disabling a toogle will pause the access to the Employee platform, enabling the toggle will resume the access again.",
                                {
                                  autoClose: false,
                                }
                              );
                            }
                            handleClick(e);
                          }}
                          checked={featureEnable.enableEmployerPortal}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Footer Section */}
          <Grid container spacing={3} className="footerSection">
            <Grid xs={12} className="ctaBtn">
              <Button
                onClick={() => {
                  navigate(ALL_ROUTES.CLIENTS);
                }}
                variant="outlined"
                className="borderBtn"
              >
                Cancel
              </Button>
              <Button
                onClick={detail_form_submit}
                variant="contained"
                className="submitBtn"
                disabled={disableSave}
              >
                Update
              </Button>
            </Grid>
          </Grid>
          <PostQueryForm
            attrName={"postQueryForm"}
            open_status={postQueryForm}
            value_update={updateMasterState}
          />
        </Box>
      )}
    </>
  );
}

export default EditClient;
