import { TextField } from "@mui/material";
import "./SearchBox.scss";
import SearchIcon from "@mui/icons-material/Search";
import { useState, useEffect, useRef } from "react";

interface Props {
  value_update: Function;
  custom?: boolean;
  fullWidth?: boolean;
  value?: string;
}

const SearchBox: React.FC<Props> = ({ value_update, custom, fullWidth, value }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onchange = (e: any) => {
    value_update("searchBarfilter", e.target.value);
  };

  useEffect(() => {
    if (value && inputRef.current) {
      // Focus on the input field when there is a value
      inputRef.current.focus();
    }
  }, [value]); // Trigger the effect when `value` changes

  return (
    <div
      className="searchBox"
      style={{
        width: "100%",
        maxWidth: fullWidth ? "650px" : custom ? "400px" : "",
      }}
    >
      <SearchIcon />
      <TextField
        fullWidth
        variant="outlined"
        type="text"
        placeholder="Search..."
        onChange={onchange}
        value={value}
        inputRef={inputRef} // Attach the ref to the input element
      />
    </div>
  );
};

export default SearchBox;