import GridViewIcon from "@mui/icons-material/GridView";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Community from "../Pages/Desktop/Broker/Community/Community";
import TrendingQuestions from "../Pages/Desktop/Broker/TrendingQuestions/TrendingQuestions";
import Dashboard from "../Pages/Desktop/Employer/Dashboard/Dashboard";
import Employees from "../Pages/Desktop/Employer/Employees/Empolyees";
import Support from "../Pages/Desktop/Employer/Support/Support";
// import Endosement from "../Pages/Desktop/Broker/Endorsement/Endorsement";
import Header from "../Components/Broker/Home/Header/Header";
import BulkUploadList from "../Components/BulkUpload/BulkUploadList/BulkUploadList";
import BulkUploadPage from "../Components/BulkUpload/BulkUploadPage";
import SideNavBar from "../Components/Employer/SideNavBar/SideNavBar";
import KeyboardShotcuts from "../Components/KeyboardShotcuts/KeyboardShotcuts";
import AddNewEmployee from "../Pages/Desktop/Employer/Employees/AddNewEmployee/AddNewEmployee";
import EmployeeDetails from "../Pages/Desktop/Employer/Employees/EmployeeDetails/EmployeeDetails";
import Policies from "../Pages/Desktop/Employer/Policies/Policies";
import PolicyDetail from "../Pages/Desktop/Employer/Policies/PolicyDetailView/PolicyDetail";
import Report from "../Pages/Desktop/Employer/Report/Report";
import SettingModulesForm from "../Pages/Desktop/Employer/Settings/SettingModulesForm/SettingModulesForm";
import Settings from "../Pages/Desktop/Employer/Settings/Settings";
import { EMPLOYER_ALL_ROUTES } from "./employer_all_routes";
// import BulkUploadPage from "../Pages/Desktop/Broker/BulkUpload/BulkUploadPage";
import EndorsementMassUpdate from "../Components/Broker/Endorsment/EndorsementMassUpdate/EndorsemenrMassUpdate";
import NetworkHospital from "../Components/Broker/NetworkHospital/NetworkHospital";
import Claimassistant from "../Pages/Desktop/Employer/Claimassistant/Claimassistant";
import ClaimAssistantDetail from "../Pages/Desktop/Employer/Claimassistant/ClaimAssistantDetail/ClaimAssistantDetail";
import Endosement from "../Pages/Desktop/Employer/Endorsement/Endorsement";
import EndorsementDetail from "../Pages/Desktop/Employer/Endorsement/EndorsementDetail/EndorsementDetail";
import EndorsementDetailMemberAddition from "../Pages/Desktop/Employer/Endorsement/EndorsementDetail/EndorsmentMemberAddition";
import EditPersonalDetails from "../Pages/Desktop/Employer/Settings/GeneralSettings/PersonalSettings/EditPersonalDetails/EditPersonalDetails";
import CreateBranch from "../Pages/Desktop/Employer/Settings/OrganisationSettings/Branches/CreateBranch/CreateBranch";
import CreateRole from "../Pages/Desktop/Employer/Settings/UserAndControl/CreateRole/CreateRole";
import CreateUsers from "../Pages/Desktop/Employer/Settings/UserAndControl/CreateUsers/CreateUsers";
import EditCreateRole from "../Pages/Desktop/Employer/Settings/UserAndControl/EditCreateRole/EditCreateRole";
import EditCreateUsers from "../Pages/Desktop/Employer/Settings/UserAndControl/EditCreateUser/EditCreateUser";

const EmployerRoutes = () => {
  const location = useLocation();
  const [shortcutOpen, setShortcutOpen] = useState(false);
  const [allEmployerRoutes, setAllEmployerRoutes] = useState<
    Array<{
      path: EMPLOYER_ALL_ROUTES;
      element: any;
      add_permission: boolean;
      view_permission: boolean;
    }>
  >([]);

  const [shortcutDisable, setShortcutDisable] = useState<boolean>(false);

  useEffect(() => {
    const routes: Array<{
      path: EMPLOYER_ALL_ROUTES;
      element: any;
      add_permission: boolean;
      view_permission: boolean;
    }> = [
      {
        path: EMPLOYER_ALL_ROUTES.DASHBOARD,
        element: <Dashboard />,
        view_permission: true,
        add_permission: true,
      },
      {
        path: EMPLOYER_ALL_ROUTES.EMPLOYEE,
        element: <Employees />,
        view_permission: true,
        add_permission: true,
      },
      {
        path: EMPLOYER_ALL_ROUTES.EMPLOYEE_DETAILS_VIEW,
        element: <EmployeeDetails />,
        view_permission: true,
        add_permission: true,
      },
      {
        path: EMPLOYER_ALL_ROUTES.ADD_EMPLOYEE,
        element: <AddNewEmployee />,
        view_permission: true,
        add_permission: true,
      },
      {
        path: EMPLOYER_ALL_ROUTES.POLICY_DETAILS,
        element: <PolicyDetail />,
        view_permission: true,
        add_permission: true,
      },
      {
        path: EMPLOYER_ALL_ROUTES.POLICIES,
        element: <Policies />,
        view_permission: true,
        add_permission: true,
      },
      {
        path: EMPLOYER_ALL_ROUTES.BULK_UPLOAD_PAGE,
        element: <BulkUploadPage />,
        view_permission: true,
        add_permission: true,
      },
      {
        path: EMPLOYER_ALL_ROUTES.BULK_UPLOAD_LIST,
        element: <BulkUploadList />,
        view_permission: true,
        add_permission: true,
      },
      {
        path: EMPLOYER_ALL_ROUTES.REPORT,
        element: <Report />,
        view_permission: true,
        add_permission: true,
      },
      {
        path: EMPLOYER_ALL_ROUTES.CLAIM_DETAIL,
        element: <ClaimAssistantDetail />,
        add_permission: true,
        view_permission: true,
      },
      {
        path: EMPLOYER_ALL_ROUTES.CLIAM_ASSISTANT,
        element: (
          <Claimassistant
            shortcutDisable={shortcutDisable}
            setShortcutDisable={setShortcutDisable}
          />
        ),
        add_permission: true,
        view_permission: true,
      },
      {
        path: EMPLOYER_ALL_ROUTES.ENDORSEMENT,
        element: (
          <Endosement
            shortcutDisable={shortcutDisable}
            setShortcutDisable={setShortcutDisable}
          />
        ),
        view_permission: true,
        add_permission: true,
      },
      {
        path: EMPLOYER_ALL_ROUTES.ENDORSEMENT_BULK_UPDATE_STATUS,
        element: <EndorsementMassUpdate />,
        view_permission:true,
        add_permission: true,
      },
      {
        path: EMPLOYER_ALL_ROUTES.TRENDING_QUESTION,
        element: <TrendingQuestions />,
        view_permission: true,
        add_permission: true,
      },
      {
        path: EMPLOYER_ALL_ROUTES.COMMUNITY,
        element: <Community />,
        view_permission: true,
        add_permission: true,
      },
      {
        path: EMPLOYER_ALL_ROUTES.SUPPORT,
        element: <Support />,
        view_permission: true,
        add_permission: true,
      },
      {
        path: EMPLOYER_ALL_ROUTES.SETTING,
        element: <Settings />,
        view_permission: true,
        add_permission: true,
      },
      {
        path: EMPLOYER_ALL_ROUTES.SETTING_MODULE_FORM,
        element: <SettingModulesForm />,
        view_permission: true,
        add_permission: true,
      },
      {
        path: EMPLOYER_ALL_ROUTES.EDIT_USER,
        element: <EditCreateUsers />,
        view_permission: true,
        add_permission: true,
      },
      {
        path: EMPLOYER_ALL_ROUTES.CREATE_USER,
        element: <CreateUsers />,
        view_permission: true,
        add_permission: true,
      },
      {
        path: EMPLOYER_ALL_ROUTES.CREATE_ACCESS_CONTROL,
        element: <CreateRole />,
        view_permission: true,
        add_permission: true,
      },
      {
        path: EMPLOYER_ALL_ROUTES.EDIT_ACCESS_CONTROL,
        element: <EditCreateRole />,
        view_permission: true,
        add_permission: true,
      },
      {
        path: EMPLOYER_ALL_ROUTES.ADD_BRANCH,
        element: <CreateBranch />,
        view_permission: true,
        add_permission: true,
      },
      {
        path: EMPLOYER_ALL_ROUTES.EDIT_PERSONAL_DETAILS,
        element: <EditPersonalDetails />,
        view_permission: true,
        add_permission: true,
      },
      {
        path: EMPLOYER_ALL_ROUTES.ENDORSEMENT_DETAILS,
        element: <EndorsementDetail />,
        view_permission: true,
        add_permission: true,
      },
      {
        path: EMPLOYER_ALL_ROUTES.ENDORSEMENT_DETAILS_MEMBER_ADDITION,
        element: <EndorsementDetailMemberAddition />,
        view_permission: true,
        add_permission: true,
      },
      {
        path: EMPLOYER_ALL_ROUTES.NETWORK_HOSPITAL,
        element: <NetworkHospital />,
        view_permission: true,
        add_permission: true,
      },
    ];

    setAllEmployerRoutes(routes);
  }, [shortcutDisable]);

  const full_page_routes = [
    EMPLOYER_ALL_ROUTES.EMPLOYEE_DETAILS_VIEW,
    EMPLOYER_ALL_ROUTES.POLICY_DETAILS,
    EMPLOYER_ALL_ROUTES.ADD_EMPLOYEE,
    EMPLOYER_ALL_ROUTES.SETTING,
    EMPLOYER_ALL_ROUTES.SETTING_MODULE_FORM,
    EMPLOYER_ALL_ROUTES.EMPLOYEE_DETAILS_VIEW,
    EMPLOYER_ALL_ROUTES.BULK_UPLOAD_PAGE,
    EMPLOYER_ALL_ROUTES.BULK_UPLOAD_LIST,
    EMPLOYER_ALL_ROUTES.CREATE_ACCESS_CONTROL,
    EMPLOYER_ALL_ROUTES.EDIT_ACCESS_CONTROL,
    EMPLOYER_ALL_ROUTES.CREATE_USER,
    EMPLOYER_ALL_ROUTES.EDIT_USER,
    EMPLOYER_ALL_ROUTES.ADD_BRANCH,
    EMPLOYER_ALL_ROUTES.EDIT_PERSONAL_DETAILS,
    EMPLOYER_ALL_ROUTES.CLAIM_DETAIL,
    EMPLOYER_ALL_ROUTES.ENDORSEMENT_DETAILS,
    EMPLOYER_ALL_ROUTES.ENDORSEMENT_DETAILS_MEMBER_ADDITION,
    EMPLOYER_ALL_ROUTES.NETWORK_HOSPITAL,
    EMPLOYER_ALL_ROUTES.ENDORSEMENT_BULK_UPDATE_STATUS,
  ];

  function updateMasterState(attrName: string, value: any) {
    switch (attrName) {
      case "shortcutOpen":
        setShortcutOpen(value);
        break;
      default:
        console.log(attrName, value);
        break;
    }
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      {full_page_routes.includes(location.pathname as EMPLOYER_ALL_ROUTES) ? (
        <Routes>
          {allEmployerRoutes.map((data, index) =>
            data.view_permission &&
            data.add_permission &&
            full_page_routes.includes(data.path) ? (
              <Route path={data.path} element={data.element} />
            ) : null
          )}
        </Routes>
      ) : (
        <Box className="dashboardWrapper">
          {!shortcutDisable ? (
            <>
              <Button
                className="shortcutBtn"
                onClick={() => {
                  setShortcutOpen(true);
                }}
              />
              <KeyboardShotcuts
                openStatus={shortcutOpen}
                attrName="shortcutOpen"
                valueUpdate={updateMasterState}
              />
            </>
          ) : null}

          <Button
            className="quickLaunch"
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClicked}
          >
            <GridViewIcon />
          </Button>
          <Header />
          <Grid container spacing={3}>
            <Grid className="menuCol" xs>
              <SideNavBar />
            </Grid>
            <Grid className="rightSection" xs>
              <Routes>
                {allEmployerRoutes.map((data, index) =>
                  data.view_permission ? (
                    <Route path={data.path} element={data.element} />
                  ) : null
                )}
              </Routes>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default EmployerRoutes;
