/* eslint-disable no-loop-func */
import { InputAdornment } from "@material-ui/core";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import EmailIcon from "@mui/icons-material/Email";
import Grid from "@mui/material/Unstable_Grid2";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PolicyHistory from "../../../../../Components/Broker/PolicyHistory/PolicyHistory";
import SelectvoluntryPlans from "../../../../../Components/Broker/SelectVoluntryPlans/SelectVoluntryPlans";
import PoliciesWarningpopup from "../../../../../Components/Broker/WarningForPlicies/PoliciesWarningpopup";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";
import { coverageType } from "../../../../../Services/DTO/PoliciesDTO";
import { quotesDTONew } from "../../../../../Services/DTO/QuotesDTO";
import { one_to_ten_data } from "../../../../../Services/DropDowndata";
import { EMPLOYEE_SERVICES } from "../../../../../Services/Employee/EmployeeService";
import { PolicyCoverageType } from "../../../../../Services/Enum/EPolicy";
import { premiumType } from "../../../../../Services/Enum/EPremiumPaymentType";
import { SumInsuredLimit } from "../../../../../Services/Enum/ESumInsuredLimits";
import { POLICIES_SERVICES } from "../../../../../Services/Policies/PoliciesServices";
import { QUOTES_SERVICES } from "../../../../../Services/Quotes/QuotesServices";
import { TUserData } from "../../../../../Services/Types/TUserData";
import { RootState } from "../../../../../Store/Store";
import { useAppSelector } from "../../../../../Store/hooks";
import { useFormatCurrency } from "../../../../../Supporting files/CustomHooks";
import {
  add_days_in_date_getDateObject,
  areAgeBandsConsistent,
  calculateDaysDifference,
  extractNumbersFromString,
  formatCurrencyAccToUser,
  getCurrencyCodeRegex,
  get_industry_list,
  get_subindustry_list,
  subtract_days_in_date_getDateObject,
  titleCase,
  validateEmail,
  validateMobileNumber,
  validateUrl,
} from "../../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import SearchDropdown from "../../../../../Supporting files/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import TextAreaField from "../../../../../Supporting files/TextAreaField/TextAreaField";
import Exclusion from "../../Quotes/Exclusion/Exclusion";
import SelectFeature from "../../Quotes/SelectFeature/SelectFeature";
import NewLoader from "../../../../../Components/NewLoader/NewLoader";
import { differenceInDays } from "date-fns";
import { styled } from "@mui/material/styles";

function EditQuotes() {
  const CustomRadio = styled(Radio)({
    color: "#2160aa",
    "&.Mui-checked": {
      color: "#2160aa",
    },
  });

  const [disableSave, setDisableSave] = useState<boolean>(false);
  const { formatCurrency } = useFormatCurrency();
  const userState: TUserData = useAppSelector(
    (state: RootState) => state.userDetailsSlice
  );
  const windowURL = window.location.search;
  const params = new URLSearchParams(windowURL);
  const id = params.get("id");
  const navigate = useNavigate();
  const [others, setOthers] = useState("");
  const taxPercentage = 0.18;
  const [ageBandData, setAgeBandData] = useState<
    Array<{ min_age: string; max_age: string }>
  >([{ min_age: "", max_age: "" }]);
  const [sectionStatus, SetSectionStatus] = useState<string>(
    "organizationDetails"
  );
  const [warningpopup, setwarningpopup] = useState<boolean>(false);
  const [IndustryData, setIndustryData] = React.useState<
    Array<{ label: string; id: string }>
  >([]);
  const [subIndustryData, setSubIndustryData] = React.useState<
    Array<{ label: string; id: string }>
  >([]);
  const [indivisualGradeLives, setindivisualGradeLives] = useState(0);
  const [hide, setHide] = useState(false);
  const [planType, setPlanType] = useState(0);
  const [quotesStatus, setquotesStatus] = useState(1);
  const [quotesId, setquotesId] = useState(null);
  const [livesSelectedGrade, setlivesSelectedGrade] = useState<
    Array<{ selectedGrade: number }>
  >([{ selectedGrade: 0 }]);
  const [policyType, setPolicyType] = useState(0);
  const [toggle, setToggle] = useState(true);
  const [show, setShow] = useState(false);
  const [showNominee, setShowNominee] = useState(false);
  const [showhealth, setShowhealth] = useState(false);
  const [showcorporate, setShowcorporate] = useState(false);
  const [addFeature, setAddFeature] = useState(false);
  const [selectvoluntryPlans, setSelectVoluntryPlans] = useState(false);
  const [Addexclusion, setAddExclusion] = useState(false);
  const [AddFeatureexclusionIndex, setAddFeatureExclusionIndex] =
    useState<number>(0);
  const [taxDropDown, settaxDropDown] = useState<any>([]);
  const [clientListdata, setClientListdata] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [organizationFullDetails, setorganizationFullDetails] = useState<any>(
    {}
  );
  const [maxage_data, setmaxage_data] = useState<any>([]);
  const [empGradeData, setEmpGradeData] = useState<
    Array<{
      sum_insured: string;
      emp_count: string;
      deleteable: boolean;
      features: Array<string>;
    }>
  >([{ sum_insured: "", emp_count: "", deleteable: false, features: [] }]);
  const [clientLocationList, setClientLocationList] = useState<any>({});
  const [insurerList, setInsurerList] = useState<any>([]);
  const [insurerAddressList, setInsurerAddressList] = useState<any>([]);
  const [featuresListdata, setfeaturesListdata] = useState<any>([]);
  const [exclusionsListdata, setexclusionsListdata] = useState<any>([]);
  const [featuresListdataGraded, setfeaturesListdataGraded] = useState<any>([]);
  const [featuresListdataGradedCopy, setfeaturesListdataGradedCopy] =
    useState<any>([]);
  const [selectedClientId, setSelectedClientId] = useState<any>("");
  const [dropdownSalarydesignationDep, setdropdownSalarydesignationDep] =
    useState<any>([]);
  const [
    showdropdownSalarydesignationDep,
    setshowdropdownSalarydesignationDep,
  ] = useState<any>([]);
  const [maxage_dataClone, setmaxage_dataClone] = useState<any>([]);
  const [minage_data, setminage_data] = useState<any>([]);
  const [exclusionsListdataGraded, setexclusionsListdataGraded] = useState<any>(
    []
  );
  const [dynamicLocationOffice, setdynamicLocationOffice] = useState<
    Array<Array<{ key: string; value: string }>>
  >([]);
  const [exclusionsListdataGradedCopy, setexclusionsListdataGradedCopy] =
    useState<any>([]);
  const [modifiedArrayAgeBand, setModifiedArrayAgeBand] = useState<any>([]);
  console.log("modifiedAgeBand", modifiedArrayAgeBand);
  const [liveGraded, setliveGraded] = useState(0);
  const [showLoader, setShowLoader] = useState(true);
  const [basicConfig_data, setbasicConfig_data] = useState<any>([
    { key: "1E", value: "1E" },
  ]);
  const [editMode, setEditMode] = useState(false);

  const [indvConfig_data, setindvConfig_data] = useState<any>([
    { key: "Nill", value: "Nill" },
  ]);
  const ownership_data = [
    { id: "Private Limited", label: "Private Limited" },
    { id: "Public Limited", label: "Public Limited" },
    {
      id: "Limited liability partnership",
      label: "Limited liability partnership",
    },
    { id: "Private Companies", label: "Private Companies" },
    { id: "Sole Proprietorship", label: "Sole Proprietorship" },
    { id: "Public Companies", label: "Public Companies" },
  ];
  const [addPoliciesdata, setAddPoliciesdata] = useState<quotesDTONew>({
    organisationDetails: {
      id: "55555a555555a555a55aaa55",
      clientId: "",
      organisationName: "",
      ownership: "",
      webSite: "",
      industry: "",
      sub_industry: "",
      email: "",
      mobile: "",
      location: [
        {
          name: "",
          city: "nill",
          state: "nill",
          country: "nill",
        },
      ],
      industrySub: "",
    },
    productType: {
      name: "",
      code: "",
    },
    policyCase: {
      name: "FRESH",
      code: 0,
    },
    insurerDetails: [
      {
        id: "",
        name: "",
        office: "",
      },
    ],
    is_rider: false,
    rider: [
      {
        name: "",
        description: "",
      },
    ],
    benefits_detail_flat: [
      {
        label: "",
        enable: false,
        amount: "",
        description: "",
      },
    ],
    benefits_detail_graded: [
      {
        grade_name: "Grade 1",
        riders: [
          {
            label: "",
            enable: false,
            amount: "",
            description: "",
          },
        ],
      },
    ],
    rider_premium_graded: [
      {
        proreta_by: "YEARLY",
        rider_name: "",
        premium_split: "",
        livesInfoGrade: [
          {
            gradeName: "Grade 1",
            data: [
              {
                min: "",
                max: "",
                coverageTypeData: [
                  {
                    name: "amount",
                    value: "500",
                  },
                  {
                    code: "0",
                    name: "EMPLOYEE",
                    value: "",
                  },
                  {
                    name: "total Live",
                    value: 0,
                  },
                ],
                name: "age band",
              },
            ],
          },
        ],
      },
    ],
    rider_premium_flat: [
      {
        proreta_by: "YEARLY",
        premium_split: "",
        rider_name: "",
        livesInfoFlat: [
          {
            min: "",
            max: "",
            name: "",
            coverageTypeData: [
              {
                code: "",
                name: "",
                value: 0,
              },
            ],
          },
        ],
      },
    ],
    planType: {
      name: PolicyCoverageType.FLAT,
      coverageType: [
        {
          code: 0,
          name: "EMPLOYEE",
        },
      ],
      basicConfig: "1E",
      parentConfig: "Nill",
      familyDefinition: "",
      gradeCriteria: "nill",
      setOfParents: "both",
    },
    payType: {
      type: premiumType.SPLIT,
      data: [
        {
          name: "EMPLOYEE",
          value: 0,
        },
      ],
    },
    ageBand: [
      {
        name: "",
        max: "",
        min: "",
      },
    ],
    maxChildAge: "",
    benefitsDetailsflat: {
      sumInsured: "",
      deductible: "",
      features: [
        {
          name: "",
          value: "",
          featureId: "",
          groupId: "",
        },
      ],
    },
    benefitsDetailsGrade: [
      {
        gradeDivisions: [{ id: "", label: "" }],
        name: "",
        sumInsured: "",
        deductible: "",
        features: [
          {
            name: "",
            value: "",
            featureId: "",
            groupId: "",
          },
        ],
        exclusion: [
          {
            name: "",
            description: "",
            exclusionId: "",
          },
        ],
      },
    ],
    premiumDetails: {
      netPremium: 0,
      taxPercentage: [{ id: "", label: "" }],
      taxAmount: "nill",
      grossPremium: "nill",
    },
    policyDetails: {
      startDate: new Date(),
      endDate: new Date(),
      cdBalance: "nill",
    },
    policy: {
      nomination: false,
      maxNomination: 0,
      healthCard: false,
      cardType: "",
      healthCardIssued: "",
      isCorporateBuffer: false,
      corporateBuffer: null,
      proreta_by: "",
    },
    livesInfoFlat: [
      {
        min: "",
        max: "",
        name: "",
        coverageTypeData: [
          {
            code: "",
            name: "",
            value: "",
          },
        ],
      },
    ],
    livesInfoGrade: [],
  });
  const [ageBandMax, setageBandMax] = useState<string>("");
  const [expanded, setExpanded] = useState<Array<{ expanded: boolean }>>([]);
  const [premiumPaymentType, setPremiumPaymentType] = useState<premiumType>(
    premiumType.SPLIT
  );
  const [addPoliciesdataWarn, setAddPoliciesdataWarn] = useState<any>({
    employeeId: false,
    organisationDetails: {
      clientId: false,
      parentClientId: false,
      parentOrganistion: false,
      email: false,
      website: false,
      organisationName: false,
      ownership: false,
      sub_industry: false,
      industry: false,
      mobile: false,
      location: false,
    },

    productType: {
      name: false,
      code: false,
    },
    policyCase: {
      name: false,
      code: false,
    },

    insurerDetails: [
      {
        name: false,
        office: false,
      },
    ],
    planType: {
      name: false,
      coverageType: [
        {
          name: false,
          code: false,
        },
      ],
      basicConfig: false,
      parentConfig: false,
      familyDefinition: false,
    },
    ageBand: [
      {
        name: false,
        max: false,
        min: false,
      },
    ],
    benefitsDetailsGrade: [
      {
        designation: false,
        deductible: false,
        name: false,
        sumInsured: false,
        features: [
          {
            name: false,
            value: false,
            featureId: false,
            groupId: false,
          },
        ],
        exclusion: [
          {
            name: false,
            description: false,
            exclusionId: false,
          },
        ],
      },
    ],
    benefitsDetailsflat: {
      deductible: false,
      sumInsured: false,
      features: false,
      exclusion: [
        {
          name: false,
          description: false,
        },
      ],
    },
    premiumDetails: {
      netPremium: false,
      taxPercentage: false,
      grossPremium: false,
    },
    policyDetails: {
      startDate: false,
      endDate: false,
      cdBalance: false,
    },
    policy: {
      criticalCd: false,
      criticalPercentage: false,
      enrolmentStartDate: false,
      enrolmentEndDate: false,
      period: false,
      gracePeriod: false,
      maxMemberPerFamily: false,
      totalMemberPerFamily: false,
      newMemberPeriod: false,
      nomination: false,
      maxNomination: false,
      corporateBuffer: false,
      isCorporateBuffer: false,
      healthCard: false,
      cardType: "",
      healthCardIssued: "",
    },
    liveswarning: false,
  });
  console.log("quoteData", addPoliciesdata);
  const [errorMaxChildAge, seterrorMaxChildAge] = useState(false);

  function isNumberInAgeBand(number: number): boolean {
    let error = true; // Initialize as true, assume there's an error

    addPoliciesdata.ageBand.some((band: any) => {
      let min = band.min.includes("Up to")
        ? 0
        : band.min
          ? parseInt(band.min)
          : 0;
      let max = band.max ? parseInt(band.max) : Infinity;

      // If the number is within the range, set error to false
      if (number >= min && number <= max) {
        error = false;
        return true; // Stop further iteration as a valid band is found
      }

      // Continue searching other bands if the number is not within the current band
      return false;
    });

    // Set the error state based on the final result
    seterrorMaxChildAge(error);

    // Return true if there is an error, false otherwise
    return error;
  }

  useEffect(() => {
    if (addPoliciesdata.planType.coverageType.some((obj) => obj.code == 2)) {
      isNumberInAgeBand(
        Number(extractNumbersFromString(addPoliciesdata.maxChildAge || ""))
      );
    } else {
      seterrorMaxChildAge(false);
    }
  }, [
    ...addPoliciesdata.ageBand,
    ...addPoliciesdata.maxChildAge,
    ...addPoliciesdata.planType.coverageType,
  ]);

  // for scrolling
  const organizationDetails = useRef(null);
  const products = useRef(null);
  const quoteCase = useRef(null);
  const plantype = useRef(null);
  const premiumPayment = useRef(null);
  const AgeBand = useRef(null);
  const EmployeesDetails = useRef(null);
  const PlansSettings = useRef(null);
  const InsurerDetails = useRef(null);
  const RelationshipLives = useRef(null);
  const Riders = useRef(null);
  const RiderDetail = useRef(null);
  useEffect(() => {
    filterDropDownData();
  }, [dropdownSalarydesignationDep]);

  useEffect(() => {
    setHide(!(quotesStatus === 1 || quotesStatus === 2 || quotesStatus === 3));
  }, [quotesStatus]);

  useEffect(() => {
    if (hide) {
      setwarningpopup(hide);
    }
  }, [hide]);
  let timeout: any;

  useEffect(() => {
    setShowLoader(true);

    if (timeout) {
      clearTimeout(timeout);
    }
    const delayedApiCall = () => {
      get_quotes_details(id);
      get_client_list();
      YearOptions();
      get_industry_list((cb: any) => {
        setIndustryData(cb);
      });
    };

    timeout = setTimeout(delayedApiCall, 900);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);
  const prorata = [
    { key: "YEARLY", value: "Yearly" },
    { key: "MONTHLY", value: "Monthly" },
    { key: "WEEKLY", value: "Weekly" },
    { key: "DAILY", value: "Daily" },
  ];
  const gradedDesignation_data = [
    { key: "Salary Bracket", value: "Salary Bracket" },
    { key: "Designation", value: "Designation" },
    { key: "Department", value: "Department" },
    { key: "Custom", value: "Custom" },
  ];
  //exclusion
  useEffect(() => {
    if (addPoliciesdata?.benefitsDetailsGrade) {
      const updatedFeatureList = addPoliciesdata?.benefitsDetailsGrade?.map(
        (apiObject: any) => {
          const updatedFeatures = exclusionsListdata.map(
            (exclusionGroup: any) => {
              const matchingFeature = apiObject.exclusion.find(
                (apiFeature: any) =>
                  apiFeature.exclusionId === exclusionGroup.exclusion.id
              );

              const updatedExclusion = {
                ...exclusionGroup,
                exclusion: {
                  ...exclusionGroup.exclusion,
                  check: !!matchingFeature,
                },
              };

              return updatedExclusion;
            }
          );

          return updatedFeatures;
        }
      );

      setexclusionsListdataGraded(updatedFeatureList);
      //setfeaturesListdataGraded(updatedFeatureList)
    }
  }, [exclusionsListdata, addPoliciesdata.planType]);

  useEffect(() => {
    if (addPoliciesdata?.benefitsDetailsGrade) {
      const updatedFeatureList = addPoliciesdata?.benefitsDetailsGrade?.map(
        (apiObject: any) => {
          const updatedFeatures = featuresListdata.map((featureGroup: any) => {
            const updatedGroup = {
              ...featureGroup,
              features: featureGroup.features.map((feature: any) => {
                const matchingFeature = apiObject.features.find(
                  (apiFeature: any) => apiFeature.featureId === feature.id
                );
                if (matchingFeature) {
                  return {
                    ...feature,
                    value: matchingFeature.value,
                    check: true,
                  };
                } else {
                  return {
                    ...feature,
                    value: "",
                    check: false,
                  };
                }
              }),
            };
            return updatedGroup;
          });
          return updatedFeatures;
        }
      );

      setfeaturesListdataGraded(updatedFeatureList);
      setfeaturesListdataGradedCopy(updatedFeatureList);

      let updated_warn: Array<any> = [];

      if (addPoliciesdata?.benefitsDetailsGrade) {
        updated_warn = Array(addPoliciesdata?.benefitsDetailsGrade?.length)
          .fill(null)
          .map(() => ({
            designation: false,
            name: false,
            sumInsured: false,
            deductible: false,
            features: [
              {
                name: false,
                value: false,
                featureId: false,
                groupId: false,
              },
            ],
            exclusion: [
              {
                name: false,
                description: false,
                exclusionId: false,
              },
            ],
          }));
      }

      let updated_warnAgeband = Array(addPoliciesdata?.ageBand?.length)
        .fill(null)
        .map(() => ({
          name: false,
          max: false,
          min: false,
        }));
      setAddPoliciesdataWarn((prevState: any) => ({
        ...prevState,
        benefitsDetailsGrade: updated_warn,
        ageBand: updated_warnAgeband,
      }));
    }
  }, [featuresListdata, addPoliciesdata.planType]);

  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClicks = (ref: any) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const updateMasterState = (attrName: any, value: any) => {
    setEditMode(true);
    type ValueTypes = keyof any;
    let data: any = { ...addPoliciesdata };
    let dataWarn = { ...addPoliciesdataWarn };
    let sectionName: ValueTypes = attrName[1];
    let layout_name: any = attrName[0];
    let fieldname: any = attrName[2];
    let dropDown_key = attrName[3];
    let index_value = attrName[4];
    if (attrName !== "others") {
      if (sectionName === "organisationDetails") {
        if (dropDown_key === "dropdown") {
          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value?.label },
          };
          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
          };
          if (fieldname === "clientId") {
            setSelectedClientId(value?.id);
            get_organization_data(value?.id);
            get_client_location_list(value?.id);
          }
        }
        if (dropDown_key === "multiselect") {
          if (fieldname === "location") {
            let arr: any = value ? value : [];
            let newArr: any = [];
            arr.map((data: any) => {
              newArr.push({
                name: data.value,
                city: "nill",
                state: "nill",
                country: "nill",
              });
            });

            data = {
              ...data,
              [sectionName]: { ...data[sectionName], [fieldname]: newArr },
            };
            dataWarn = {
              ...dataWarn,
              [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
            };
          }
        }
        if (dropDown_key === "newclient") {
          if (fieldname === "organisationName") {
            data = {
              ...data,
              [sectionName]: { ...data[sectionName], [fieldname]: value },
            };
            dataWarn = {
              ...dataWarn,
              [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
            };
          } else if (fieldname === "ownership") {
            data = {
              ...data,
              [sectionName]: { ...data[sectionName], [fieldname]: value },
            };
            dataWarn = {
              ...dataWarn,
              [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
            };
          } else if (fieldname === "locationName") {
            data = {
              ...data,
              [sectionName]: {
                ...data[sectionName],
                location: [
                  { name: value, city: "nill", state: "nill", country: "nill" },
                ],
              },
            };
            dataWarn = {
              ...dataWarn,
              [sectionName]: { ...dataWarn[sectionName], location: false },
            };
          } else if (fieldname === "industry") {
            dataWarn = {
              ...dataWarn,
              [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
            };

            data = {
              ...data,
              [sectionName]: {
                ...data[sectionName],
                [fieldname]: value.label,
                sub_industry: "",
              },
            };

            get_subindustry_list(value, (cb: any) => {
              setSubIndustryData(cb);
            });
          } else if (fieldname === "sub_industry") {
            dataWarn = {
              ...dataWarn,
              [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
            };
            data = {
              ...data,
              [sectionName]: {
                ...data[sectionName],
                [fieldname]: value.label,
              },
            };
          } else if (fieldname === "email") {
            data = {
              ...data,
              [sectionName]: {
                ...data[sectionName],
                [fieldname]: value,
              },
            };
            dataWarn = {
              ...dataWarn,
              [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
            };
          } else if (fieldname === "webSite") {
            data = {
              ...data,
              [sectionName]: {
                ...data[sectionName],
                [fieldname]: value,
              },
            };
            dataWarn = {
              ...dataWarn,
              [sectionName]: { ...dataWarn[sectionName], website: false },
            };
          } else if (fieldname === "mobile") {
            data = {
              ...data,
              [sectionName]: {
                ...data[sectionName],
                [fieldname]: value,
              },
            };
            dataWarn = {
              ...dataWarn,
              [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
            };
          }
        }
      } else if (sectionName === "productType") {
        setPlanType(0);
        setAgeBandData([{ min_age: "", max_age: "" }]);
        setindvConfig_data([{ key: "Nill", value: "Nill" }]);
        YearOptions();
        data = {
          ...data,
          policyCase: {
            name: "FRESH",
            code: 0,
          },
          insurerDetails: [
            {
              id: "",
              name: "",
              office: "",
              email: "",
            },
          ],
          tpa: { id: "", name: "" },
          planType: {
            name: PolicyCoverageType.FLAT,
            coverageType: [
              {
                code: 0,
                name: "EMPLOYEE",
              },
            ],
            basicConfig: "1E",
            parentConfig: "Nill",
            familyDefinition: "",
            gradeCriteria: "",
          },
          is_rider: false,
          rider: [
            {
              name: "",
              description: "",
            },
          ],
          benefits_detail_flat: [
            {
              label: "",
              enable: false,
              amount: "",
              description: "",
            },
          ],
          benefits_detail_graded: [
            {
              grade_name: "Grade 1",
              riders: [
                {
                  label: "",
                  enable: false,
                  amount: "",
                  description: "",
                },
              ],
            },
          ],
          rider_premium_graded: [
            {
              proreta_by: "YEARLY",
              rider_name: "",
              premium_split: "",
              livesInfoGrade: [
                {
                  gradeName: "Grade 1",
                  data: [
                    {
                      min: "",
                      max: "",
                      coverageTypeData: [
                        {
                          name: "amount",
                          value: "500",
                        },
                        {
                          code: "0",
                          name: "EMPLOYEE",
                          value: "",
                        },
                        {
                          name: "total Live",
                          value: 0,
                        },
                      ],
                      name: "age band",
                    },
                  ],
                },
              ],
            },
          ],
          rider_premium_flat: [
            {
              proreta_by: "YEARLY",
              premium_split: "",
              rider_name: "",
              livesInfoFlat: [
                {
                  min: "",
                  max: "",
                  name: "",
                  coverageTypeData: [
                    {
                      code: "",
                      name: "",
                      value: 0,
                    },
                  ],
                },
              ],
            },
          ],
          payType: {
            type: premiumType.SPLIT,
            data: [
              {
                name: "EMPLOYEE",
                value: 0,
              },
            ],
          },
          ageBand: [
            {
              name: "",
              max: "",
              min: "",
            },
          ],
          benefitsDetailsflat: {
            sumInsured: "",
            deductible: "",
            features: [
              {
                name: "",
                value: "",
                featureId: "",
                groupId: "",
              },
            ],
          },
          benefitsDetailsGrade: [
            {
              gradeDivisions: [{ id: "", label: "" }],
              name: "Grade 1",
              sumInsured: "",
              deductible: "",
              features: [
                {
                  name: "",
                  value: "",
                  featureId: "",
                  groupId: "",
                },
              ],
              exclusion: [
                {
                  name: "",
                  description: "",
                  exclusionId: "",
                },
              ],
            },
          ],
          premiumDetails: {
            netPremium: "",
            taxPercentage: [],
            taxAmount: "",
            grossPremium: "",
          },
          policyDetails: {
            startDate: null,
            endDate: null,
            cdBalance: "0",
          },
          policy: {
            nomination: false,
            maxNomination: 0,
            healthCard: false,
            cardType: "",
            healthCardIssued: "",
            isCorporateBuffer: false,
            corporateBuffer: null,
            proreta_by: "",
          },
          livesInfoFlat: [
            {
              min: "",
              max: "",
              name: "",
              coverageTypeData: [
                {
                  code: "",
                  name: "",
                  value: "",
                },
              ],
            },
          ],
          livesInfoGrade: [],
        };
        const num =
          value === "Group Term"
            ? 0
            : value === "Group Health"
              ? 1
              : value === "Group Travel"
                ? 2
                : value === "Group Personal Accident"
                  ? 3
                  : value === "Group Critical Illness"
                    ? 4
                    : value === "Group Super TopUp"
                      ? 5
                      : null;
        let productType: any = { name: value, code: num };
        data = { ...data, [sectionName]: productType };
        dataWarn = {
          ...dataWarn,
          [sectionName]: { ...dataWarn[sectionName], name: false },
        };
        // Api for feature list
        features_list(num);
        // Api for exclusion list
        exclusion_list(num);
        // Api for insurer list
        insurance_name_dropdownlist(
          value === "Group Term" ? "LIFE" : "GENERAL"
        );
        setbasicConfig_data([{ key: "1E", value: "1E" }]);
        if (value === "Group Term") {
          data = {
            ...data,
            policy: {
              ...data.policy,
              maxMemberPerFamily: 1,
            },
          };
        }
      } else if (sectionName === "policyCase") {
        if (dropDown_key === "radio") {
          const num = value === "FRESH" ? 0 : value === "RENEWAL" ? 1 : null;
          let policyCase: any = { name: value, code: num };
          data = { ...data, [sectionName]: policyCase };
          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], name: false },
          };
        } else if (dropDown_key === "input_field") {
          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value },
          };
          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
          };
        }
      } else if (sectionName === "insurerDetails") {
        if (dropDown_key === "dropdown") {
          if (fieldname === "name") {
            const obj: any = {
              id: value?.id,
              name: value.label,
              email: data.insurerDetails[index_value]?.email.toLowerCase(),
              office: "",
            };
            const objwarn: any = {
              name: false,
              office: addPoliciesdataWarn.insurerDetails[index_value].office,
              email: addPoliciesdataWarn.insurerDetails[index_value].email,
            };
            data.insurerDetails.splice(index_value, 1, obj);
            dataWarn.insurerDetails.splice(index_value, 1, objwarn);

            insurance_address_dropdownlist(value?.id, index_value);
          }
          if (fieldname === "office") {
            const obj: any = {
              id: data.insurerDetails[index_value]?.id,
              name: data.insurerDetails[index_value]?.name,
              email: data.insurerDetails[index_value]?.email.toLowerCase(),
              office: value,
            };
            const objwarn: any = {
              name: addPoliciesdataWarn.insurerDetails[index_value].name,
              email: addPoliciesdataWarn.insurerDetails[index_value].email,
              office: false,
            };
            data.insurerDetails.splice(index_value, 1, obj);
            dataWarn.insurerDetails.splice(index_value, 1, objwarn);
          }
          if (fieldname === "email") {
            const obj: any = {
              id: data.insurerDetails[index_value]?.id,
              name: data.insurerDetails[index_value]?.name,
              office: data.insurerDetails[index_value]?.office,
              email: value.toLowerCase(),
            };
            const objwarn: any = {
              name: addPoliciesdataWarn.insurerDetails[index_value].name,
              office: addPoliciesdataWarn.insurerDetails[index_value].office,
              email: false,
            };
            data.insurerDetails.splice(index_value, 1, obj);
            dataWarn.insurerDetails.splice(index_value, 1, objwarn);
          }
        }
      } else if (sectionName === "planType") {
        if (dropDown_key === "radio") {
          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value },
          };
          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
          };
          setAgeBandData([{ min_age: "", max_age: "" }]);

          if (value === "GRADED") {
            data = {
              ...data,
              premiumDetails: {
                netPremium: "",
                taxPercentage: [],
                taxAmount: "",
                grossPremium: "",
              },
              policyDetails: {
                startDate: null,
                endDate: null,
                cdBalance: "0",
              },
              policyCase: {
                name: "FRESH",
                code: 0,
              },
              insurerDetails: [
                {
                  id: "",
                  name: "",
                  office: "",
                  email: "",
                },
              ],
              tpa: { id: "", name: "" },
              is_rider: false,
              rider: [
                {
                  name: "",
                  description: "",
                },
              ],
              payType: {
                type: premiumType.SPLIT,
                data: [
                  {
                    name: "EMPLOYEE",
                    value: 0,
                  },
                ],
              },
              ageBand: [
                {
                  name: "",
                  max: "",
                  min: "",
                },
              ],
              livesInfoGrade: [],
              policy: {
                nomination: false,
                maxNomination: 0,
                healthCard: false,
                cardType: "",
                healthCardIssued: "",
                isCorporateBuffer: false,
                corporateBuffer: null,
                proreta_by: "",
              },
              benefitsDetailsflat: {},
              benefitsDetailsGrade: [
                {
                  gradeDivisions: [{ id: "", label: "" }],
                  name: "Grade 1",
                  sumInsured: "",
                  deductible: "",
                  features: [
                    {
                      name: "",
                      value: "",
                      featureId: "",
                      groupId: "",
                    },
                  ],
                  exclusion: [
                    {
                      name: "",
                      description: "",
                      exclusionId: "",
                    },
                  ],
                },
              ],
              benefits_detail_graded: [
                {
                  grade_name: "Grade 1",
                  riders: [
                    {
                      label: "",
                      enable: false,
                      amount: "",
                      description: "",
                    },
                  ],
                },
              ],
              rider_premium_graded: [
                {
                  proreta_by: "YEARLY",
                  rider_name: "",
                  premium_split: "",
                  livesInfoGrade: [
                    {
                      gradeName: "Grade 1",
                      data: [
                        {
                          min: "",
                          max: "",
                          coverageTypeData: [
                            {
                              name: "amount",
                              value: "500",
                            },
                            {
                              code: "0",
                              name: "EMPLOYEE",
                              value: "",
                            },
                            {
                              name: "total Live",
                              value: 0,
                            },
                          ],
                          name: "age band",
                        },
                      ],
                    },
                  ],
                },
              ],
              benefits_detail_flat: [],
              rider_premium_flat: [],
            };
            dataWarn = {
              ...dataWarn,
              benefitsDetailsGrade: [
                {
                  designation: false,
                  deductible: false,
                  name: false,
                  sumInsured: false,
                  features: [
                    {
                      name: false,
                      value: false,
                      featureId: false,
                      groupId: false,
                    },
                  ],
                  exclusion: [
                    {
                      name: false,
                      description: false,
                      exclusionId: false,
                    },
                  ],
                },
              ],
            };
          } else if (value === "FLAT") {
            data = {
              ...data,
              tpa: { id: "", name: "" },
              premiumDetails: {
                netPremium: "",
                taxPercentage: [],
                taxAmount: "",
                grossPremium: "",
              },
              policyDetails: {
                startDate: null,
                endDate: null,
                cdBalance: "0",
              },
              policyCase: {
                name: "FRESH",
                code: 0,
              },
              is_rider: false,
              rider: [
                {
                  name: "",
                  description: "",
                },
              ],
              payType: {
                type: premiumType.SPLIT,
                data: [
                  {
                    name: "EMPLOYEE",
                    value: 0,
                  },
                ],
              },
              ageBand: [
                {
                  name: "",
                  max: "",
                  min: "",
                },
              ],
              benefitsDetailsGrade: [],
              rider_premium_graded: [],
              benefits_detail_graded: [],
              insurerDetails: [
                {
                  id: "",
                  name: "",
                  office: "",
                  email: "",
                },
              ],
              rider_premium_flat: [
                {
                  proreta_by: "YEARLY",
                  premium_split: "",
                  rider_name: "",
                  livesInfoFlat: [
                    {
                      min: "",
                      max: "",
                      name: "",
                      coverageTypeData: [
                        {
                          code: "",
                          name: "",
                          value: 0,
                        },
                      ],
                    },
                  ],
                },
              ],
              benefits_detail_flat: [
                {
                  label: "",
                  enable: false,
                  amount: "",
                  description: "",
                },
              ],
              benefitsDetailsflat: {
                sumInsured: "",
                deductible: "",
                features: [
                  {
                    name: "",
                    value: "",
                    featureId: "",
                    groupId: "",
                  },
                ],
              },
            };
          }
        } else if (dropDown_key === "checkbox") {
          if (value !== "EMPLOYEE") {
            let coverage_type_array: coverageType[] =
              data.planType.coverageType;
            const existingIndex = coverage_type_array.findIndex(
              (item: any) => item.name == value
            );
            if (existingIndex === -1) {
              // Add coverage type if it doesn't exist
              if (value === "KIDS") {
                if (
                  !coverage_type_array.some(
                    (item: any) => item.name === "SPOUSE"
                  )
                ) {
                  coverage_type_array = [
                    ...coverage_type_array,
                    {
                      code: 1,
                      name: "SPOUSE",
                    },
                  ];
                }
              }

              if (value === "SPOUSE") {
                if (
                  coverage_type_array.some((item: any) => item.name === "KIDS")
                ) {
                  //do nothing
                }
              }

              coverage_type_array = [
                ...coverage_type_array,
                {
                  code:
                    value === "EMPLOYEE"
                      ? 0
                      : value === "SPOUSE"
                        ? 1
                        : value === "KIDS"
                          ? 2
                          : value === "PARENTS"
                            ? 3
                            : value === "PARENTSINLAW"
                              ? 4
                              : null,
                  name: value,
                },
              ];
            } else {
              if (value === "SPOUSE") {
                if (
                  coverage_type_array.some((item: any) => item.name === "KIDS")
                ) {
                  //do nothing
                  return;
                }
              }

              // Remove coverage type if it exists
              const updatedCoverageTypes = [...coverage_type_array];
              updatedCoverageTypes.splice(existingIndex, 1);
              coverage_type_array = updatedCoverageTypes;
            }

            const newData = data.payType.data.filter((item: any) => {
              if (coverage_type_array.some((e: any) => e.name === item.name)) {
                return true;
              }
            });

            for (let i = 0; i < coverage_type_array.length; i++) {
              let pushObj = true;
              for (let j = 0; j < newData.length; j++) {
                if (coverage_type_array[i].name === newData[j].name) {
                  pushObj = false;
                  break;
                }
              }

              if (pushObj) {
                newData.push({ name: coverage_type_array[i].name, value: 0 });
              }
            }

            data = {
              ...data,
              payType: { ...data.payType, data: newData },
            };
            for (let i = 0; i < coverage_type_array.length; i++) {
              const { name } = coverage_type_array[i];

              // EMPLOYEE coverage type
              if (name === "EMPLOYEE") {
                //  data.planType.basicConfig = "1E";
                data.planType.basicConfig = "1E";
                setbasicConfig_data([{ key: "1E", value: "1E" }]);
              }

              // SPOUSE coverage type
              else if (name === "SPOUSE") {
                // data.planType.basicConfig = "1E+1S";
                data.planType.basicConfig = data.planType.basicConfig;
                setbasicConfig_data([{ key: "1E+1S", value: "1E+1S" }]);
              }

              // KIDS coverage type, handle spouse conditionally
              else if (name === "KIDS") {
                // If SPOUSE is not included, we add it conditionally
                if (
                  !coverage_type_array.some((item) => item.name === "SPOUSE")
                ) {
                  if (!newData.some((item: any) => item.name === "SPOUSE")) {
                    newData.splice(1, 0, { name: "SPOUSE", value: 0 });
                  }
                  coverage_type_array.push({ code: 1, name: "SPOUSE" });
                }
                data.planType.basicConfig = data.planType.basicConfig;

                // Set basic configuration for kids
                setbasicConfig_data([
                  { key: "1E+1S+1C", value: "1E+1S+1C" },
                  { key: "1E+1S+1C+1C", value: "1E+1S+1C+1C" },
                  { key: "1E+1S+1C+1C+1C", value: "1E+1S+1C+1C+1C" },
                  { key: "1E+1S+1C+1C+1C+1C", value: "1E+1S+1C+1C+1C+1C" },
                ]);
              }
            }

            // Update data based on whether parents are checked or unchecked
            const hasParents = coverage_type_array.some(
              (item) => item.name === "PARENTS"
            );
            const hasKids = coverage_type_array.some(
              (item) => item.name === "KIDS"
            );
            const hasSpouse = coverage_type_array.some(
              (item) => item.name === "SPOUSE"
            );
            if (!hasKids) {
              data.planType.basicConfig = "1E+1S";
            } if (hasKids) {
              data.planType.basicConfig = "1E+1S+1C";
            }
            if (!hasParents && !hasKids && !hasSpouse) {
              data.planType.basicConfig = "1E";
            }
            const hasParentsInLaw = coverage_type_array.some(
              (item) => item.name === "PARENTSINLAW"
            );

            if (hasParents && hasParentsInLaw) {
              data.planType.parentConfig = "1P+1P+1PIL+1PIL";
              setindvConfig_data([
                { key: "1P+1P+1PIL+1PIL", value: "1P+1P+1PIL+1PIL" },
              ]);
            } else if (hasParents) {
              data.planType.parentConfig = "1P+1P";
              setindvConfig_data([{ key: "1P+1P", value: "1P+1P" }]);
            } else if (hasParentsInLaw) {
              data.planType.parentConfig = "1PIL+1PIL";
              setindvConfig_data([{ key: "1PIL+1PIL", value: "1PIL+1PIL" }]);
            } else {
              // If neither parents nor parents-in-law are selected, clear the config
              data.planType.parentConfig = "Nill";
              setindvConfig_data([{ key: "Nill", value: "Nill" }]); // Clear the individual config data
            }

            // Set final coverage type data
            data.planType.coverageType = coverage_type_array;
          }
        } else if (dropDown_key === "input_fields") {
          if (value === "Department") {
            get_dep_designation_salary("", "DEPARTMENTS");
          } else if (value === "Designation") {
            get_dep_designation_salary("", "DESIGNATIONS");
          } else if (value === "Salary Bracket") {
            get_dep_designation_salary("", "SALARY_BRACKETS");
          }
          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value },
          };
        }
      } else if (sectionName === "rider") {
        if (fieldname === "name") {
          const obj = {
            description: data.rider[index_value]?.description,
            name: value,
          };
          const obj1 = {
            description: data.rider[index_value]?.description,
            label: value,
            amount: data.benefits_detail_flat[index_value]?.amount,
            enable: data.benefits_detail_flat[index_value]?.enable,
          };
          let objGraded1: any = {};
          if (data.planType.name === PolicyCoverageType.GRADED) {
            objGraded1 = {
              description:
                data.benefits_detail_graded[0].riders[index_value]?.description,
              label: value,
              amount:
                data.benefits_detail_graded[0].riders[index_value]?.amount,
              enable:
                data.benefits_detail_graded[0].riders[index_value]?.enable,
            };
          }

          const obj2 = {
            ...data.rider_premium_flat?.[index_value],
            rider_name: value,
          };
          data.rider.splice(index_value, 1, obj);

          if (data.planType.name === PolicyCoverageType.FLAT) {
            data.benefits_detail_flat.splice(index_value, 1, obj1);
            data.rider_premium_flat.splice(index_value, 1, obj2);
          } else if (data.planType.name === PolicyCoverageType.GRADED) {
            data.benefits_detail_graded = data.benefits_detail_graded.map(
              (grade: any) => {
                const updatedRiders = [...grade.riders];
                updatedRiders.splice(index_value, 1, objGraded1);
                return { ...grade, riders: updatedRiders };
              }
            );
          }
        } else if (fieldname === "description") {
          const obj = {
            description: value,
            name: data.rider[index_value]?.name,
          };

          data.rider.splice(index_value, 1, obj);

          if (data.planType.name === PolicyCoverageType.FLAT) {
            const obj1 = {
              description: value,
              label: data.benefits_detail_flat[index_value]?.label,
              amount: data.benefits_detail_flat[index_value]?.amount,
              enable: data.benefits_detail_flat[index_value]?.enable,
            };

            data.benefits_detail_flat.splice(index_value, 1, obj1);
          } else if (data.planType.name === PolicyCoverageType.GRADED) {
            const objGraded1 = {
              description: value,
              label: data.benefits_detail_graded[0].riders[index_value]?.label,
              amount:
                data.benefits_detail_graded[0].riders[index_value]?.amount,
              enable:
                data.benefits_detail_graded[0].riders[index_value]?.enable,
            };
            data.benefits_detail_graded = data.benefits_detail_graded.map(
              (grade: any) => {
                const updatedRiders = [...grade.riders];
                updatedRiders.splice(index_value, 1, objGraded1);
                return { ...grade, riders: updatedRiders };
              }
            );
          }
        }
      } else if (sectionName === "benefits_detail_flat") {
        if (fieldname === "amount") {
          const obj = {
            ...data.benefits_detail_flat[index_value],
            amount: value,
          };
          data.benefits_detail_flat.splice(index_value, 1, obj);
        }
      } else if (sectionName === "benefits_detail_graded") {
        if (fieldname === "amount") {
          data.benefits_detail_graded[index_value].riders[attrName[5]].amount =
            value;
        }
      } else if (sectionName === "rider_premium_flat") {
        if (fieldname === "proreta_by") {
          data.rider_premium_flat[attrName[3]].proreta_by = value;
        } else if (fieldname === "premium_split") {
          data.rider_premium_flat[attrName[3]].premium_split = value;
        } else {
          const updatedPremium = structuredClone(
            data.rider_premium_flat[attrName[2]]
          );
          updatedPremium.livesInfoFlat[attrName[3]].coverageTypeData[0].value =
            value;
          data.rider_premium_flat.splice(attrName[2], 1, updatedPremium);
        }

        // data.rider_premium_flat[attrName[2]].livesInfoFlat[attrName[3]].coverageTypeData[0].value = value
      } else if (sectionName === "rider_premium_graded") {
        if (fieldname === "proreta_by") {
          data.rider_premium_graded[attrName[3]].proreta_by = value;
        } else if (fieldname === "premium_split") {
          data.rider_premium_graded[attrName[3]].premium_split = value;
        } else {
          const updatedPremium = structuredClone(
            data.rider_premium_graded[attrName[2]]
          );
          updatedPremium.livesInfoGrade[attrName[3]].data[
            attrName[4]
          ].coverageTypeData[0].value = value;
          data.rider_premium_graded.splice(attrName[2], 1, updatedPremium);
        }

        // data.rider_premium_flat[attrName[2]].livesInfoFlat[attrName[3]].coverageTypeData[0].value = value
      } else if (sectionName === "ageBand") {
        if (fieldname === "min") {
          breakAgeBand(value.label, "onchange");
          setageBandMax(value.label);
          const obj: any = {
            min: value.label,
            max: "",
            name: "Age band ",
          };
          const objWarn: any = {
            min: false,
            max: dataWarn.ageBand[index_value]?.max,
            name: "Age band ",
          };
          data.ageBand.splice(index_value, 1, obj);
          //dataWarn.ageBand.splice(index_value, 1, objWarn);
        } else if (fieldname === "max") {
          // breakAgeBand(value.label)
          // setageBandMax(value.label)
          // const objWarn: any = {
          //   min: dataWarn.ageBand[index_value]?.min,
          //   max: false,
          //   name: "Age band ",
          // };
          const obj = {
            min: data.ageBand[index_value]?.min,
            max: value.label,
            name: "Age band ",
          };
          data.ageBand.splice(index_value, 1, obj);
          //dataWarn.ageBand.splice(index_value, 1, objWarn);
        }
        dataWarn = {
          ...dataWarn,
          [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
        };
      } else if (sectionName === "maxChildAge") {
        data = {
          ...data,
          [sectionName]: value.value,
        };
      } else if (layout_name === "slidepanelfeatures") {
        setAddFeature(false);
        setAddExclusion(false);
      } else if (sectionName === "premiumDetails") {
        if (fieldname === "netPremium") {
          value = extractNumbersFromString(value);
          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value },
          };
          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
          };

          let totalStandardTaxPercentage = 0;
          let cessTaxPercentage = 0;

          if (data?.premiumDetails?.taxPercentage) {
            // Calculate the total standard tax percentage from the taxPercentage array
            data.premiumDetails.taxPercentage.forEach((tax: any) => {
              if (tax.id !== "Cess") {
                totalStandardTaxPercentage += parseFloat(
                  tax.label.split("-")[1].trim()
                );
              } else {
                cessTaxPercentage = parseFloat(tax.label.split("-")[1].trim());
              }
            });
          }
          // Calculate the standard tax amount
          const standardTaxAmount = (value * totalStandardTaxPercentage) / 100;
          // Calculate the cess tax amount
          const cessTaxAmount = (standardTaxAmount * cessTaxPercentage) / 100;

          // Calculate the total tax amount
          const totalTaxAmount = standardTaxAmount + cessTaxAmount;

          // Calculate the gross premium
          const calculatedGrossPremium = (
            Number(value) + totalTaxAmount
          ).toFixed(2);

          // Set the calculated values in the state

          data = {
            ...data,
            [sectionName]: {
              ...data[sectionName],
              taxAmount: totalTaxAmount,
            },
          };
          data = {
            ...data,
            [sectionName]: {
              ...data[sectionName],
              grossPremium: calculatedGrossPremium,
            },
          };
        } else if (fieldname === "taxPercentage") {
          let totalStandardTaxPercentage = 0;
          let cessTaxPercentage = 0;

          // Calculate the total standard tax percentage from the taxPercentage array
          value.forEach((tax: any) => {
            if (tax.id !== "Cess") {
              totalStandardTaxPercentage += parseFloat(
                tax.label.split("-")[1].trim()
              );
            } else {
              cessTaxPercentage = parseFloat(tax.label.split("-")[1].trim());
            }
          });

          // Calculate the standard tax amount
          const standardTaxAmount =
            (data.premiumDetails.netPremium * totalStandardTaxPercentage) / 100;
          // Calculate the cess tax amount
          const cessTaxAmount = (standardTaxAmount * cessTaxPercentage) / 100;

          // Calculate the total tax amount
          const totalTaxAmount = (standardTaxAmount + cessTaxAmount).toFixed(2);

          // Calculate the gross premium
          const calculatedGrossPremium = (
            Number(data.premiumDetails.netPremium) + Number(totalTaxAmount)
          ).toFixed(2);

          // Set the calculated values in the state
          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value },
          };
          data = {
            ...data,
            [sectionName]: {
              ...data[sectionName],
              taxAmount: totalTaxAmount,
            },
          };
          data = {
            ...data,
            [sectionName]: {
              ...data[sectionName],
              grossPremium: calculatedGrossPremium,
            },
          };
        } else if (fieldname === "grossPremium") {
          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value },
          };
          let totalStandardTaxPercentage = 0;
          let cessTaxPercentage = 0;
          if (data?.premiumDetails?.taxPercentage) {
            // Calculate the total standard tax percentage and cess tax percentage from the taxPercentage array
            data?.premiumDetails?.taxPercentage?.forEach((tax: any) => {
              if (tax.id !== "Cess") {
                totalStandardTaxPercentage += parseFloat(
                  tax.label.split("-")[1].trim()
                );
              } else {
                cessTaxPercentage = parseFloat(tax.label.split("-")[1].trim());
              }
            });
          }
          let netPremium: number =
            (value * 10000) /
            (100 * totalStandardTaxPercentage +
              totalStandardTaxPercentage * cessTaxPercentage +
              10000);
          const roundedNetPremium = Math.round(netPremium);
          data = {
            ...data,
            [sectionName]: {
              ...data[sectionName],
              netPremium: roundedNetPremium,
            },
          };
          data = {
            ...data,
            [sectionName]: {
              ...data[sectionName],
              taxAmount: (value - roundedNetPremium).toFixed(2),
            },
          };
        }
      } else if (sectionName === "policyDetails") {
        if (fieldname === "startDate") {
          data = {
            ...data,
            [sectionName]: {
              ...data[sectionName],
              [fieldname]: moment(value).format(),
            },
          };
          let end_date = add_days_in_date_getDateObject(value, "365");
          data = {
            ...data,
            [sectionName]: {
              ...data[sectionName],
              endDate: moment(end_date).format(),
            },
          };

          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
          };
        } else if (fieldname === "endDate") {
          data = {
            ...data,
            [sectionName]: {
              ...data[sectionName],
              [fieldname]: moment(value).format(),
            },
          };
          let start_date = subtract_days_in_date_getDateObject(value, "365");
          data = {
            ...data,
            [sectionName]: {
              ...data[sectionName],
              startDate: moment(start_date).format(),
            },
          };
        } else if (fieldname === "cdBalance") {
          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value },
          };

          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
          };
        }
      } else if (sectionName === "policy") {
        if (fieldname === "criticalCd") {
          data = {
            ...data,
            [sectionName]: {
              ...data[sectionName],
              [fieldname]: parseInt(value),
            },
          };
          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
          };
          if (data.policyDetails.cdBalance) {
            const calculatedPercentage: any =
              (value / data.policyDetails.cdBalance) * 100;
            data = {
              ...data,
              [sectionName]: {
                ...data[sectionName],
                criticalPercentage: calculatedPercentage,
              },
            };
          }
        } else if (fieldname === "criticalPercentage") {
          data = {
            ...data,
            [sectionName]: {
              ...data[sectionName],
              [fieldname]: parseInt(value),
            },
          };
          if (data.policyDetails.cdBalance) {
            let Part: any = (value / 100) * data.policyDetails.cdBalance;
            data = {
              ...data,
              [sectionName]: {
                ...data[sectionName],
                criticalCd: parseInt(Part),
              },
            };
          }
        } else if (dropDown_key === "date_picker") {
          data = {
            ...data,
            [sectionName]: {
              ...data[sectionName],
              [fieldname]: moment(value).format(),
            },
          };
          if (
            data.policy.enrolmentStartDate &&
            data.policy.enrolmentStartDate
          ) {
            // let time_period = calculateDaysDifference(
            //   data.policy.enrolmentStartDate,
            //   data.policy.enrolmentEndDate
            // );
            let time_period: string = (
              differenceInDays(
                new Date(
                  new Date(data.policy.enrolmentEndDate).setHours(0, 0, 0, 0)
                ),
                new Date(
                  new Date(data.policy.enrolmentStartDate).setHours(0, 0, 0, 0)
                )
              ) + 1
            )?.toString();
            data = {
              ...data,
              [sectionName]: { ...data[sectionName], period: time_period },
            };
          }
          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
          };
        } else if (dropDown_key === "drop_down_common") {
          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value },
          };
          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
          };
        }
      } else if (layout_name === "FLAT") {
        data = {
          ...data,
          benefitsDetailsflat: {
            ...data["benefitsDetailsflat"],
            features: value,
          },
        };
        data = {
          ...data,
          benefitsDetailsGrade: [],
        };
        dataWarn = {
          ...dataWarn,
          benefitsDetailsflat: {
            ...data["benefitsDetailsflat"],
            features: false,
          },
        };
      } else if (layout_name === "sendaddExclusion") {
        data = {
          ...data,
          benefitsDetailsflat: {
            ...data["benefitsDetailsflat"],
            exclusion: value,
          },
        };
        dataWarn = {
          ...dataWarn,
          benefitsDetailsflat: {
            ...data["benefitsDetailsflat"],
            exclusion: false,
          },
        };
        if (dropDown_key === "gradedExclusion") {
          if (addPoliciesdata?.planType?.name === "GRADED") {
            let indexx: any = fieldname;
            let arr: any = [];
            value?.map((el: any) =>
              el.exclusion?.check === true
                ? arr.push({
                  description: el.exclusion.description,
                  exclusionId: el.exclusion.id,
                  name: el.exclusion.title,
                })
                : null
            );
            let warndata = {
              description: false,
              exclusionId: false,
              name: false,
            };
            data["benefitsDetailsGrade"][indexx].exclusion = arr;
            dataWarn["benefitsDetailsGrade"][indexx].exclusion = warndata;

            let graded_data: any = JSON.parse(
              localStorage.getItem("addPolicy") || ""
            );
            graded_data[indexx] = value;

            setexclusionsListdataGraded(graded_data);
            // setexclusionsListdataGradedCopy(graded_data);
          }
        }
      } else if (sectionName === "benefitsDetailsflat") {
        // Code for handling benefitsDetailsflat section
        if (fieldname === "sumInsured") {
          // Code for handling sumInsured field

          const val = Number(extractNumbersFromString(value));

          let warn = false;

          if (val > SumInsuredLimit.MAX_VALUE) {
            return;
          }

          if (!val) {
            warn = true;
          }

          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value },
          };
          dataWarn = {
            ...dataWarn,
            [sectionName]: {
              ...dataWarn[sectionName],
              [fieldname]: warn,
            },
          };
        } else if (fieldname === "deductible") {
          // Code for handling deductible field

          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value },
          };
          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
          };
        }
      } else if (sectionName === "benefitsDetailsGrade") {
        // Code for handling benefitsDetailsGrade section

        data = {
          ...data,
          benefitsDetailsflat: {},
        };
        if (fieldname === "gradeDivisions") {
          data[sectionName][index_value].gradeDivisions = value;
          dataWarn[sectionName][index_value].designation = false;
          filterDropDownData();
        } else if (fieldname === "sumInsured") {
          const val = Number(extractNumbersFromString(value));

          if (val > SumInsuredLimit.MAX_VALUE) {
            return;
          }

          let warn = false;
          if (index_value > 0) {
            if (val <= Number(data[sectionName][index_value - 1].sumInsured)) {
              // toast.warn(
              //   "Sum insured value cannot be less than or equal to previous grade."
              // );
              warn = true;
            } else {
              warn = false;
            }
          }

          if (data[sectionName][index_value + 1]?.sumInsured) {
            if (val >= Number(data[sectionName][index_value + 1].sumInsured)) {
              // toast.warn(
              //   "Sum insured value cannot be greater than or equal to next grade."
              // );
              warn = true;
            } else {
              warn = false;
            }
          }

          data[sectionName][index_value].sumInsured = val.toString();
          dataWarn[sectionName][index_value].sumInsured = warn;
        } else if (fieldname === "deductible") {
          // data[sectionName][index_value].deductible = value;
          data[sectionName][index_value].deductible =
            value.startsWith("0") && value.length > 1
              ? value.slice(1) // Remove the first 0
              : value;
          dataWarn[sectionName][index_value].deductible = false;
        }
      } else if (layout_name === "GRADED") {
        let indexx: any = sectionName;
        let arr: any = [];
        value?.map((data: any) =>
          data?.features?.map((el: any) =>
            el.check === true && el.value
              ? arr.push({
                name: el.name,
                value: el.value,
                featureId: el.id,
                groupId: data._id,
              })
              : null
          )
        );
        let warndata = {
          featureId: false,
          groupId: false,
          name: false,
          value: false,
        };
        data["benefitsDetailsGrade"][indexx].features = arr;
        // dataWarn["benefitsDetailsGrade"][indexx].features = warndata;

        let graded_data: any = gradedAddDataCopy();
        graded_data[indexx] = value;

        setfeaturesListdataGraded(graded_data);
        setfeaturesListdataGradedCopy(graded_data);
      }
    } else {
      setOthers(value);
    }
    setAddPoliciesdata(data);
    setAddPoliciesdataWarn(dataWarn);
  };

  function toggleEnableRidersFeatureGrade(
    e: any,
    gradeIndex: number,
    riderIndex: number
  ) {
    const newData = JSON.parse(JSON.stringify(addPoliciesdata));
    newData.benefits_detail_graded[gradeIndex].riders[riderIndex].enable =
      e.target.checked;
    if (!e.target.checked) {
      newData.benefits_detail_graded[gradeIndex].riders[riderIndex].amount = "";
    }
    setAddPoliciesdata(newData);
  }

  const updatePayTypeValues = (attrName: any, value: any) => {
    let data: any = addPoliciesdata;

    // if (attrName[1] === "payType") {

    //   const newData = data.payType.data.map((item: any) => {
    //     let retVal = item;
    //     if (item.name === attrName[3]) {
    //       const val = value.replace(/[^0-9.]/g, "");

    //       if (!(val < 0 || val > 100)) {
    //         retVal = { ...retVal, value: val ? val : 0 };
    //       }
    //     }
    //     return retVal;
    //   });

    //   data = {
    //     ...data,
    //     payType: { ...data.payType, data: newData },
    //   };
    // }
    if (attrName[1] === "payType") {
      const newData = data.payType.data.map((item: any) => {
        let retVal = item;

        if (item.name === attrName[3]) {
          // Remove all non-numeric characters except for a decimal point
          let val = value.replace(/[^0-9.]/g, "");

          // Ensure value doesn't start with leading zeros (unless it's '0' or a valid decimal like '0.5')
          if (val.startsWith("0") && val.length > 1 && !val.startsWith("0.")) {
            val = val.replace(/^0+/, "");
          }

          // Ensure the value is within range 0-100 and is numeric
          if (!(parseFloat(val) < 0 || parseFloat(val) > 100)) {
            retVal = { ...retVal, value: val ? val : 0 };
          }
        }

        return retVal;
      });

      data = {
        ...data,
        payType: { ...data.payType, data: newData },
      };
    }

    setAddPoliciesdata(data);
  };

  function toggleShowNominee(e: any) {
    let data: any = { ...addPoliciesdata };
    setShowNominee(e.target.checked);
    data = {
      ...data,
      policy: { ...data["policy"], nomination: e.target.checked },
    };
    if (e.target.checked === false) {
      data = {
        ...data,
        policy: { ...data["policy"], maxNomination: 0 },
      };
    }
    setAddPoliciesdata(data);
  }

  function toggleShowhealth(e: any) {
    setShowhealth(e.target.checked);
    let data: any = { ...addPoliciesdata };
    data = {
      ...data,
      policy: { ...data["policy"], healthCard: e.target.checked },
    };

    if (e.target.checked === false) {
      data = {
        ...data,
        policy: { ...data["policy"], healthCardIssued: "" },
      };
      data = {
        ...data,
        policy: { ...data["policy"], cardType: "" },
      };
    }
    setAddPoliciesdata(data);
  }

  function toggleShowcorporate(e: any) {
    let data: any = { ...addPoliciesdata };
    setShowcorporate(e.target.checked);
    data = {
      ...data,
      policy: {
        ...data["policy"],
        isCorporateBuffer: e.target.checked,
      },
    };

    if (e.target.checked === false) {
      data = {
        ...data,
        policy: {
          ...data["policy"],
          corporateBuffer: 0,
        },
      };
    }
    setAddPoliciesdata(data);
  }

  const cardType_data = [
    { key: "E - Card", value: "E - Card" },
    { key: "Physical Card", value: "Physical Card" },
  ];

  const healthcardIssued_data = [
    {
      key: "Per Individual Member of the Family",
      value: "Per Individual Member of the Family",
    },
    { key: "Per Employee Only", value: "Per Employee Only" },
  ];

  const add_band_for_lives = (index: number) => {
    let data = [...ageBandData];
    data.splice(index + 1, 0, { min_age: "", max_age: "" });
    setAgeBandData(data);

    let dataMain: any = addPoliciesdata;
    let dataMainwarn = addPoliciesdataWarn;

    dataMain.ageBand.splice(index + 1, 0, { min: "", max: "", name: "" });
    // dataMainwarn.ageBand.splice(index + 1, 0, {
    //   min: false,
    //   max: false,
    //   name: "",
    // });

    setAddPoliciesdata(dataMain);

    setAddPoliciesdataWarn(dataMainwarn);
  };

  const remove_band_for_lives = (index: number) => {
    let data = [...ageBandData];
    data.splice(index, 1);
    setAgeBandData(data);

    let dataMain: any = addPoliciesdata;
    dataMain.ageBand.splice(index, 1);
    setAddPoliciesdata(dataMain);
    setageBandMax(
      extractNumbersFromString(
        dataMain.ageBand[dataMain.ageBand.length - 1].max
      )
    );
    breakAgeBand(
      extractNumbersFromString(
        dataMain.ageBand[dataMain.ageBand.length - 1].max
      ),
      "ondelete"
    );
  };

  const YearOptions = () => {
    const options = [];
    options.push({ label: "Up to 1 Year", value: "Up to 1 Year" });
    for (let i = 2; i <= 100; i++) {
      const label = `${i} ${i === 1 ? "Year" : "Years"}`;
      const value = `${i} Years`;
      options.push({ label, value });
    }
    setminage_data(options);
    setmaxage_dataClone(options);
    setmaxage_data(options);
  };

  // Get Client list for dropdown
  const get_client_list = () => {
    const onSuccess = (res: any) => {
      setClientListdata(res.data.data);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    POLICIES_SERVICES.get_client_list(
      userState?.userType?.toLowerCase(),
      onSuccess,
      onError
    );
  };

  // Get organization data after select client
  const get_organization_data = (id: any) => {
    const onSuccess = (res: any) => {
      setorganizationFullDetails(res.data.data[0]);
      setShowLoader(false);
    };
    const onError = (err: any) => {
      setShowLoader(false);
    };
    POLICIES_SERVICES.get_organization_data(onSuccess, onError, id);
  };

  // GetLocation list data after select client
  const get_client_location_list = (id: any) => {
    const onSuccess = (res: any) => {
      let arr: any = res.data.data;
      let newArr: any = [];
      arr.map((data: any) => {
        newArr.push({ key: data.label, value: data.label });
      });
      setClientLocationList(newArr);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    POLICIES_SERVICES.get_client_location_list(onSuccess, onError, id);
  };

  // Get insurance_name_dropdownlist data
  const insurance_name_dropdownlist = (type: any) => {
    const onSuccess = (res: any) => {
      let arr: any = res.data.data;
      let newArr: any = [];
      arr.map((data: any) => {
        newArr.push({ id: data.id, label: data.label });
      });
      setInsurerList(newArr);
    };
    const onError = (err: any) => {
      console.log("insurance_name_dropdownlist", err);
    };
    POLICIES_SERVICES.insurance_name_dropdownlist(onSuccess, onError, type);
  };
  // Get insurance_address_dropdownlist data
  const insurance_address_dropdownlist = (id: any, index: number) => {
    const onSuccess = (res: any) => {
      let arr: any = res.data.data;
      let newArr: any = [];
      arr.map((data: any) => {
        newArr.push({ key: data.label, value: data.label });
      });
      setInsurerAddressList(newArr);
      let locationdata = [...dynamicLocationOffice];
      locationdata.splice(index, 1, newArr);
      setdynamicLocationOffice(locationdata);
    };
    const onError = (err: any) => {
      console.log("insurance_address_dropdownlist", err);
    };
    POLICIES_SERVICES.insurance_address_dropdownlist(onSuccess, onError, id);
  };

  // Get features list data
  const features_list = (code: any, ExistingFeatureData?: any) => {
    const onSuccess = (res: any) => {
      setfeaturesListdata(res.data.data);
      gradedAddData(res.data.data);
      update_feature_list(res.data.data, ExistingFeatureData);
    };
    const onError = (err: any) => {
      console.log("features_list", err);
    };
    POLICIES_SERVICES.features_list(onSuccess, onError, code);
  };

  const update_feature_list = (newFeatures: any, ExistingFeatureData: any) => {
    const arrToUse = newFeatures ? newFeatures : featuresListdata;
    const selectedFeatures = ExistingFeatureData;
    if (addPoliciesdata?.planType?.name === "FLAT") {
      const updatedFeatures = arrToUse.map((featureGroup: any) => {
        const updatedGroup = {
          ...featureGroup,
          features: featureGroup.features.map((feature: any) => {
            const matchingFeature = selectedFeatures?.find(
              (apiFeature: any) => apiFeature.featureId === feature.id
            );
            return {
              ...feature,
              value: matchingFeature?.value || "",
              check: !!matchingFeature,
            };
          }),
        };
        return updatedGroup;
      });

      setfeaturesListdata(updatedFeatures);
    }
  };

  const update_exclusion_list = (updatedData: any) => {
    const arrToUse = updatedData ? updatedData : exclusionsListdata; //whole exclusion coming from api
    if (addPoliciesdata?.planType?.name === "FLAT") {
      const updatedExclusions = arrToUse.map((exclusionGroup: any) => {
        const hasMatchingFeature =
          addPoliciesdata.benefitsDetailsflat.exclusion &&
          addPoliciesdata.benefitsDetailsflat.exclusion.some(
            (apiFeature: any) =>
              apiFeature.exclusionId === exclusionGroup.exclusion.id
          );

        const updatedExclusion = {
          ...exclusionGroup,
          exclusion: {
            ...exclusionGroup.exclusion,
            check: hasMatchingFeature,
          },
        };

        return updatedExclusion;
      });
      setexclusionsListdata(updatedExclusions);
      return updatedExclusions;
    }
  };
  const update_policy_details = () => {
    if (disableSave) return;
    const onSuccess = (res: any) => {
      toast.success(" Updated successfully");
      navigate(ALL_ROUTES.QUOTES);
    };
    const onError = (err: any) => {
      setDisableSave(false);
    };

    let data: any = {
      ...addPoliciesdata,
      premiumDetails: {
        ...addPoliciesdata.premiumDetails,
        taxPercentage: addPoliciesdata.premiumDetails.taxPercentage.map(
          (item: any) => {
            return {
              id: item.id,
              label: item.label,
            };
          }
        ),
      },
      organisationDetails: {
        ...addPoliciesdata.organisationDetails,
        location: [
          {
            name: addPoliciesdata.organisationDetails.location[0].name,
            city: "nill",
            state: "nill",
            country: "nill",
          },
        ],
      },
      policy: {
        ...addPoliciesdata.policy,
        corporateBuffer: addPoliciesdata.policy.isCorporateBuffer
          ? addPoliciesdata.policy.corporateBuffer
          : 0,
        maxNomination: addPoliciesdata.policy.nomination
          ? addPoliciesdata.policy.maxNomination
          : 0,
      },
      payType: {
        type: premiumPaymentType,
        data:
          premiumPaymentType === premiumType.SPLIT
            ? addPoliciesdata.payType.data.map((item: any) => {
              return { name: item.name, value: item.value };
            })
            : [],
      },
    };
    if (data.planType.gradeCriteria === "Others") {
      data.planType.gradeCriteria = others;
    }

    if (data.planType.name === PolicyCoverageType.FLAT) {
      data = {
        ...data,
        benefitsDetailsGrade: [],
        // {
        //   features: [{ featureId: "", groupId: "", name: "", value: "" }],
        //   name: "",
        //   sumInsured: "",
        // },
        // ],
      };
    } else if (data.planType.name === PolicyCoverageType.GRADED) {
      const newData = data.benefitsDetailsGrade.map((item: any) => {
        const newDivisions =
          item.gradeDivisions &&
          item.gradeDivisions.map((item1: any) => {
            return {
              id: item1.id,
              label: item1.label,
            };
          });

        return {
          exclusion: item.exclusion,
          features: item.features,
          gradeDivisions: newDivisions,
          name: item.name,
          sumInsured: item.sumInsured,
          deductible: item.deductible,
        };
      });
      if (!editMode) {
        data = {
          ...data,
          livesInfoGrade: modifiedArrayAgeBand,
        };
      }
      data = {
        ...data,
        benefitsDetailsflat: {},
        benefitsDetailsGrade: newData,
      };
    }
    if (data?.planType?.name === "GRADED") {
      data = {
        ...data,
        benefitsDetailsflat: {},
        benefits_detail_flat: [],
        rider_premium_flat: [],
      };
    } else {
      data = {
        ...data,
        benefitsDetailsGrade: [],
        benefits_detail_graded: [],
        rider_premium_graded: [],
      };
    }

    if (data.productType.name !== "Group Term") {
      data = {
        ...data,
        benefits_detail_graded: [],
        rider_premium_graded: [],
        rider_premium_flat: [],
        benefits_detail_flat: [],
        rider: [],
      };
    }
    if (!data.is_rider) {
      data = {
        ...data,
        rider: [],
        rider_premium_flat: [],
        rider_premium_graded: [],
        benefits_detail_flat: [],
        benefits_detail_graded: [],
      };
    }

    delete data.updatedAt;
    delete data.createdAt;
    delete data._id;
    delete data.premiumDetails;
    delete data.policyDetails;
    delete data.tpa;
    if (data.productType.name !== "Group Term") {
      data.rider_premium_flat = [];
      data.rider_premium_graded = [];
      data.rider = [];
      data.benefits_detail_graded = [];
      data.benefits_detail_flat = [];
    }
    const removeIdField = (data: any): any => {
      if (typeof data === "object") {
        if (Array.isArray(data)) {
          return data.map((item) => removeIdField(item));
        } else {
          const newData: any = {};
          for (let key in data) {
            if (key !== "_id") {
              newData[key] = removeIdField(data[key]);
            }
          }
          return newData;
        }
      }
      return data;
    };

    const removeIdFromData = JSON.parse(JSON.stringify(removeIdField(data)));
    let { preFix, status, userId, quoteId, ...updatedPayload } =
      removeIdFromData;

    if (!data.planType.coverageType.some((data: any) => data.name === "KIDS")) {
      updatedPayload = { ...updatedPayload, maxChildAge: "" };
    }

    updatedPayload?.livesInfoGrade?.forEach((grade: any) => {
      grade.data.forEach((item: any) => {
        item.coverageTypeData.forEach((coverage: any) => {
          if (
            typeof coverage.value === "object" &&
            Object.keys(coverage.value).length === 0
          ) {
            coverage.value = ""; // Replace empty object with an empty string
          }
        });
      });
    });
    updatedPayload?.livesInfoFlat?.forEach((item: any) => {
      item.coverageTypeData?.forEach((coverage: any) => {
        if (
          typeof coverage.value === "object" &&
          Object.keys(coverage.value).length === 0
        ) {
          coverage.value = ""; // Replace empty object with an empty string
        } else if (coverage.value === null) {
          coverage.value = ""; // Replace null with an empty string
        }
      });
    });

    if (!updatedPayload.is_rider) {
      updatedPayload = {
        ...updatedPayload,
        rider: [],
        benefits_detail_flat: [],
        rider_premium_flat: [],
      };
    }
    // return;
    setDisableSave(true);
    QUOTES_SERVICES.update_quotes_details(
      updatedPayload,
      onSuccess,
      onError,
      id
    );
  };

  function toggleEnableRiders(e: any) {
    let data = { ...addPoliciesdata };
    data = {
      ...data,
      is_rider: e.target.checked,
    };
    if (!e.target.checked) {
      data = {
        ...data,
        rider: [],
      };
    }
    if (e.target.checked) {
      data = {
        ...data,
        rider: [
          {
            name: "",
            description: "",
          },
        ],
      };
    }
    if (e.target.checked && data.benefits_detail_graded.length < 1) {
      data = {
        ...data,
        // benefits_detail_graded:[ {
        //   grade_name: "Grade 1",
        //   riders: [
        //     {
        //       label: "",
        //       enable: false,
        //       amount: "",
        //       description: "",
        //     },
        //   ],
        // },],

        benefits_detail_graded: data.livesInfoGrade.map((item) => {
          return {
            grade_name: item?.gradeName,
            riders: [
              {
                label: "",
                enable: false,
                amount: "",
                description: "",
              },
            ],
          };
        }),
      };
    }
    setAddPoliciesdata(data);
  }
  function toggleEnableRidersFeaure(e: any, index: number) {
    let data = { ...addPoliciesdata };
    data.benefits_detail_flat[index].enable = e.target.checked;

    if (!e.target.checked) {
      data.benefits_detail_flat[index].amount = "";
    }
    if (e.target.checked) {
    }
    setAddPoliciesdata(data);
  }

  // Get features list data
  const exclusion_list = (code: any) => {
    const onSuccess = (res: any) => {
      console.log("res", res.data.data);
      const updatedData = res.data.data.map((item: any) => {
        return {
          ...item,
          exclusion: {
            ...item.exclusion,
            check: false,
            id: item._id,
          },
        };
      });

      setexclusionsListdata(updatedData);
      exclusionsgradedAddData(updatedData);
      update_exclusion_list(updatedData);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    POLICIES_SERVICES.exclusion_list(onSuccess, onError, code);
  };
  const hasDuplicateCategory = (data: any[]): boolean => {
    const titles = new Set<string>(); // Use a set to track unique titles
    for (const item of data) {
      const category = item?.email; // Safely access the title
      if (category) {
        if (titles.has(category)) {
          return true; // Duplicate found
        }
        titles.add(category);
      }
    }
    return false; // No duplicates found
  };
  const submithandle = () => {
    let warningLives = false;
    const isDuplicateCategoryFound = hasDuplicateCategory(
      addPoliciesdata.insurerDetails
    );
    if (isDuplicateCategoryFound) {
      toast.error(
        "Duplicate insurer email found. Please remove the duplicate email."
      );
      return;
    }

    // Check the planType and update the corresponding data fields

    if (addPoliciesdata?.planType?.name === "FLAT") {
      let data: any = { ...addPoliciesdata };
      data = {
        ...data,
        benefitsDetailsGrade: [],
      };
      setAddPoliciesdata(data);
    } else if (addPoliciesdata?.planType?.name === "GRADED") {
      let data = { ...addPoliciesdata };
      data = {
        ...data,
        benefitsDetailsflat: {},
      };
      setAddPoliciesdata(data);
    }

    const data: any = addPoliciesdataWarn;

    data.organisationDetails.organisationName = addPoliciesdata
      .organisationDetails.organisationName
      ? false
      : true;
    data.organisationDetails.ownership = addPoliciesdata.organisationDetails
      .ownership
      ? false
      : true;

    data.organisationDetails.location = addPoliciesdata.organisationDetails
      .location
      ? false
      : true;

    data.organisationDetails.industry = addPoliciesdata.organisationDetails
      .industry
      ? false
      : true;

    data.organisationDetails.sub_industry = addPoliciesdata.organisationDetails
      .sub_industry
      ? false
      : true;
    data.organisationDetails.email = !validateEmail(
      addPoliciesdata?.organisationDetails?.email
        ? addPoliciesdata?.organisationDetails?.email
        : ""
    );
    data.organisationDetails.mobile = !validateMobileNumber(
      addPoliciesdata?.organisationDetails?.mobile
        ? addPoliciesdata?.organisationDetails?.mobile
        : ""
    );

    data.organisationDetails.website = !validateUrl(
      addPoliciesdata?.organisationDetails?.webSite
        ? addPoliciesdata?.organisationDetails?.webSite
        : ""
    );

    let check_status_agebandMin = false;
    let check_status_agebandMax = false;
    let check_status_graded = false;
    if (
      addPoliciesdata.planType.coverageType.some((data) => data.name === "KIDS")
    ) {
      data.maxChildAge = addPoliciesdata.maxChildAge ? false : true;
      check_status_agebandMin = data.maxChildAge;
    } else {
      data.maxChildAge = false;
      check_status_agebandMin = data.maxChildAge;
      setAddPoliciesdata({ ...addPoliciesdata, maxChildAge: "" });
    }

    // Iterate over ageBand data and check/update the min and max fields
    const dataError = (addPoliciesdata.ageBand || []).map(
      (dataa: any, index: any) => {
        if (!data.ageBand) {
          data.ageBand = []; // Initialize ageBand if it doesn't exist
        }
        if (!data.ageBand[index]) {
          data.ageBand[index] = {}; // Initialize the element at index if it doesn't exist
        }
        data.ageBand[index].min = dataa?.min
          ? false
          : ((check_status_agebandMin = true), true);
        data.ageBand[index].max = dataa?.max
          ? false
          : ((check_status_agebandMax = true), true);
      }
    );
    let check_status_insurername = false;
    let check_status_insureroffice = false;
    let check_status_insureremail = false;

    // Check if data exists in addPoliciesdata
    if (
      addPoliciesdata &&
      addPoliciesdata.insurerDetails &&
      addPoliciesdata.insurerDetails.length > 0
    ) {
      data.insurerDetails = addPoliciesdata.insurerDetails.map((dataa: any) => {
        const updatedData = {
          ...dataa,
          office: dataa.office
            ? false
            : ((check_status_insureroffice = true), true),
          name: dataa.name ? false : ((check_status_insurername = true), true),
          email: validateEmail(dataa.email)
            ? false
            : ((check_status_insureremail = true), true),
        };
        return updatedData;
      });

      // Now, you can use check_status_insurername and check_status_insureroffice
      // to determine if any warnings are present in the data.insurerDetails array.
      // If both variables are false, it means there are no warnings.

      // For example:
      if (check_status_insurername || check_status_insureroffice) {
        // Warnings are present in the data.insurerDetails array
        // Handle the warnings accordingly
      } else {
        // No warnings in the data.insurerDetails array
        // You can proceed with your API call or any other logic.

        // If you want to update the addPoliciesdataWarn state with the new data:
        setAddPoliciesdataWarn(data);
      }
    } else {
      // Handle the case when no data is present in addPoliciesdata
    }
    // Check and update the data fields based on the planType and productType
    if (addPoliciesdata?.planType?.name === "FLAT") {
      data.benefitsDetailsflat.features = addPoliciesdata.benefitsDetailsflat
        ?.features?.[0]?.value
        ? false
        : true;
      data.benefitsDetailsflat.exclusion = addPoliciesdata.benefitsDetailsflat
        ?.exclusion
        ? false
        : true;

      if (addPoliciesdata?.productType?.name === "Group Super TopUp") {
        data.benefitsDetailsflat.deductible = addPoliciesdata
          .benefitsDetailsflat?.deductible
          ? false
          : true;
      }
    } else if (addPoliciesdata?.planType?.name === "GRADED") {
      let i;
      let z = false;
      // Function to check if sumInsured is in ascending order

      function checkSumInsuredOrder(data: any) {
        if (!Array.isArray(data)) {
          return false;
        }

        for (let i = 1; i < data.length; i++) {
          // Attempt to convert sumInsured to a number if it's not already a number
          const sumInsuredCurrent = Number(data[i].sumInsured);
          const sumInsuredPrevious = Number(data[i - 1].sumInsured);

          // Check if the current and previous sumInsured are the same
          if (sumInsuredCurrent === sumInsuredPrevious && !z) {
            toast.warn(
              `Sum Insured of Grade ${i} & Grade ${i + 1} can't be the same`
            );
            z = true; // Set the flag to true after the warning is shown
            return false; // Return false to indicate an error
          }

          // Check if current sumInsured is less than the previous
          if (
            !isNaN(sumInsuredCurrent) &&
            !isNaN(sumInsuredPrevious) &&
            sumInsuredCurrent < sumInsuredPrevious
          ) {
            toast.warn(
              `Grade ${i + 1}'s Sum Insured is smaller than Grade ${i}`
            );
            return false; // Return false if the condition is met
          }
        }

        return true; // All checks passed
      }

      // Usage of checkSumInsuredOrder
      if (!checkSumInsuredOrder(addPoliciesdata.benefitsDetailsGrade)) {
        // Handle the case when the check fails
        return; // You can return or handle any further logic here
      }

      const dataError = addPoliciesdata?.benefitsDetailsGrade?.map(
        (value: any, index: any) => {
          if (data.benefitsDetailsGrade[index]?.gradeDivisions) {
            data.benefitsDetailsGrade[index].designation = value.gradeDivisions[
              index
            ]
              ? false
              : ((check_status_graded = true), true);
          }

          if (addPoliciesdata?.productType?.name === "Group Super TopUp") {
            data.benefitsDetailsGrade[index].deductible =
              value?.deductible !== ""
                ? false
                : ((check_status_graded = true), true);
          }

          value?.features?.map((el: any, indexx: any) => {
            if (
              data.benefitsDetailsGrade[index]?.features &&
              data.benefitsDetailsGrade[index].features[indexx]
            ) {
              data.benefitsDetailsGrade[index].features[indexx].name = el?.name
                ? false
                : ((check_status_graded = true), true);
            }
          });

          value?.exclusion?.map((el: any, indexx: any) => {
            if (
              data.benefitsDetailsGrade[index]?.exclusion &&
              data.benefitsDetailsGrade[index].exclusion[indexx]
            ) {
              data.benefitsDetailsGrade[index].exclusion[indexx].name = el?.name
                ? false
                : ((check_status_graded = true), true);
            }
          });
        }
      );
    }

    data.planType.familyDefinition = addPoliciesdata?.planType?.familyDefinition
      ? false
      : true;

    if (addPoliciesdata.policy.nomination === true) {
      data.policy.maxNomination = addPoliciesdata.policy.maxNomination
        ? false
        : true;
    } else {
      data.policy.maxNomination = false;
    }

    if (addPoliciesdata.policy.isCorporateBuffer === true) {
      if (addPoliciesdata.policy.corporateBuffer) {
        data.policy.corporateBuffer =
          Number(addPoliciesdata.policy.corporateBuffer) < 1 ||
            addPoliciesdata.policy.corporateBuffer === ""
            ? true
            : false;
      }
    } else {
      data.policy.corporateBuffer = false;
    }

    if (addPoliciesdata.policy.healthCard === true) {
      data.policy.cardType =
        addPoliciesdata.policy.cardType &&
          addPoliciesdata.policy.healthCardIssued
          ? false
          : true;
    } else {
      data.policy.cardType = false;
    }

    setAddPoliciesdataWarn({ ...data });

    // Check if all fields are valid and update policy details if they are
    if (
      (addPoliciesdata?.planType?.name === "FLAT"
        ? data.benefitsDetailsflat.features === false &&
        data.benefitsDetailsflat.exclusion === false
        : check_status_graded === false) &&
      data.organisationDetails.clientId === false &&
      data.organisationDetails.location === false &&
      data.premiumDetails.netPremium === false &&
      data.planType.familyDefinition === false &&
      data.policyDetails.startDate === false &&
      data.policy.enrolmentStartDate === false &&
      data.policy.enrolmentEndDate === false &&
      data.policyDetails.cdBalance === false &&
      data.policy.criticalCd === false &&
      data.policy.maxNomination === false &&
      data.policy.cardType === false &&
      data.policy.corporateBuffer === false &&
      check_status_agebandMin === false &&
      check_status_agebandMax === false &&
      check_status_insurername === false &&
      check_status_insureroffice === false &&
      check_status_insureremail === false &&
      warningLives === false &&
      errorMaxChildAge === false
    ) {
      update_policy_details();
    }
  };

  // Filtering data of get_dep_designation_salary
  const filterDropDownData = () => {
    let updatedDropdownSalarydesignationDep = dropdownSalarydesignationDep;

    // Iterate over each grade in benefitsDetailsGrade
    addPoliciesdata?.benefitsDetailsGrade?.forEach((grade: any) => {
      // Iterate over each designation in gradeDivisions
      grade?.gradeDivisions?.forEach((designation: any) => {
        // Filter the dropdown data based on the designation label
        updatedDropdownSalarydesignationDep =
          updatedDropdownSalarydesignationDep.filter((dropDownData: any) => {
            return dropDownData.label !== designation.label;
          });
      });
    });

    // Update the state with the filtered dropdown data
    setshowdropdownSalarydesignationDep(updatedDropdownSalarydesignationDep);
  };

  const get_dep_designation_salary = (clientID: any, type: any) => {
    const onSuccess = (res: any) => {
      setdropdownSalarydesignationDep(res.data.data[0].data);
    };
    const onError = (err: any) => {
      console.log(err);
    };
    EMPLOYEE_SERVICES.get_dep_designation_salary(
      onSuccess,
      onError,
      userState.userType.toLowerCase(),
      selectedClientId ? selectedClientId : clientID,
      type
    );
  };

  const updateMasterStateLives = (attrName: any, value: any) => {
    let dataArr: any = [...modifiedArrayAgeBand];
    if (attrName[2] === "flatData") {
      dataArr[attrName[0]].coverageTypeData[attrName[1]].value = `${Number(value)}`;
      let totalValue = 0;

      dataArr[attrName[0]].coverageTypeData?.forEach((obj: any) => {
        const name = obj.name;
        if (name !== "amount" && name !== "total Live") {
          const value = parseFloat(obj.value) || 0;
          totalValue += value;
        }
      });

      dataArr[attrName[0]].coverageTypeData[
        dataArr[attrName[0]].coverageTypeData.length - 1
      ].value = totalValue;
      setAddPoliciesdata({ ...addPoliciesdata, livesInfoFlat: dataArr });
    } else if (attrName[2] === "gradeData") {
      dataArr[liveGraded].data[attrName[0]].coverageTypeData[
        attrName[1]
      ].value = `${Number(value)}`;
      let total: number = 0;
      let totalValue = 0;

      dataArr[liveGraded].data[attrName[0]].coverageTypeData.forEach(
        (obj: any) => {
          const name = obj.name;
          if (name !== "amount" && name !== "total Live") {
            const value = parseFloat(obj.value) || 0;
            totalValue += value;
          }
        }
      );

      dataArr[liveGraded].data[attrName[0]].coverageTypeData[
        dataArr[liveGraded].data[attrName[0]].coverageTypeData.length - 1
      ].value = totalValue;
      setAddPoliciesdata({ ...addPoliciesdata, livesInfoGrade: dataArr });
    }
    setModifiedArrayAgeBand(dataArr);
  };

  const breakAgeBand = (value?: any, from?: any) => {
    let min;
    let max;
    if (addPoliciesdata.ageBand.length === 1) {
      min = maxage_dataClone.slice(0);
      if (from === "onchange") {
        max = maxage_dataClone.slice(
          Number(extractNumbersFromString(value ?? ageBandMax))
        );
      } else {
        max = maxage_dataClone.slice(
          Number(extractNumbersFromString(addPoliciesdata.ageBand[0]?.min)) ??
          ageBandMax
        );
      }
    } else {
      min = maxage_dataClone.slice(
        Number(
          extractNumbersFromString(
            addPoliciesdata.ageBand[addPoliciesdata.ageBand.length - 2]?.max ??
            ageBandMax
          )
        )
      );
      max = maxage_dataClone.slice(
        Number(extractNumbersFromString(value ?? ageBandMax))
      );
    }
    setminage_data(min);
    setmaxage_data(max);
  };

  // get_policy_tax data
  const get_policy_tax = () => {
    const onSuccess = (res: any) => {
      let arr: any = [];
      res.data.data?.taxList?.map((data: any, index: any) => {
        let name = arr.push({
          id: data.taxName,
          label: `${data.taxName}- ${data.taxRate}`,
        });
      });
      settaxDropDown(arr);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    POLICIES_SERVICES.get_policy_tax(onSuccess, onError);
  };

  const gradedAddData = (data: any) => {
    const modifiedData = data.map((item: any) => ({
      ...item,
      features: item.features.map((feature: any) => ({
        ...feature,
        value: "",
        check: false,
        groupid: feature._id,
        id: feature.id,
      })),
    }));
    let updated_data: any = [];
    updated_data = [
      ...Array(addPoliciesdata?.benefitsDetailsGrade?.length),
    ].map(() => modifiedData);
    setfeaturesListdataGraded((prevState: any) => [
      ...prevState,
      ...updated_data,
    ]);
    //setfeaturesListdataGraded(updated_data);
  };
  const gradedAddDataCopy = () => {
    if (addPoliciesdata?.planType?.name === "GRADED") {
      const modifiedData = featuresListdata.map((item: any) => ({
        ...item,
        features: item.features.map((feature: any) => ({
          ...feature,
          value: "",
          check: false,
          groupid: featuresListdata._id,
          id: feature.id,
        })),
      }));
      let updated_data: any = [];
      updated_data = [
        ...Array(addPoliciesdata?.benefitsDetailsGrade?.length),
      ].map(() => modifiedData);

      if (featuresListdataGradedCopy.length > 0) {
        return featuresListdataGradedCopy;
      }
      return updated_data;
    }
  };

  const exclusionsgradedAddData = (updatedData: any) => {
    let updated_data: any = [];
    updated_data = [
      ...Array(addPoliciesdata?.benefitsDetailsGrade?.length + 1),
    ].map(() => updatedData);
    setexclusionsListdataGraded((prevState: any) => [
      ...prevState,
      updated_data,
    ]);
    // setexclusionsListdataGraded(updated_data);
  };

  // Get get_quotes_details
  const get_quotes_details = (id: any) => {
    const onSuccess = (res: any) => {
      const newExpanded = res.data.data.benefitsDetailsGrade.map(
        (item: any) => {
          return { expanded: false };
        }
      );
      if (res.data.data?.benefits_detail_flat?.length < 1) {
        let newLivesSelectedGrade = res.data.data?.rider_premium_graded.map(
          (element: any) => ({ selectedGrade: 0 })
        );
        setlivesSelectedGrade(newLivesSelectedGrade);
      }
      setExpanded(newExpanded);
      setquotesStatus(res.data.data.status);
      setquotesId(res.data.data.preFix + res.data.data.quoteId);
      // setAddPoliciesdata(res.data.data);
      setPremiumPaymentType(res.data.data.payType.type);
      setToggle(
        res.data.data.payType.type === premiumType.STANDARD ? false : true
      );
      get_organization_data(res.data.data?.organisationDetails?.id);
      get_client_location_list(res.data.data?.organisationDetails?.id);
      setSelectedClientId(res.data.data?.organisationDetails?.id);
      if (res.data.data?.planType.gradeCriteria === "Department") {
        get_dep_designation_salary(
          res.data.data?.organisationDetails?.id,
          "DEPARTMENTS"
        );
      } else if (res.data.data?.planType.gradeCriteria === "Designation") {
        get_dep_designation_salary(
          res.data.data?.organisationDetails?.id,
          "DESIGNATIONS"
        );
      } else if (res.data.data?.planType.gradeCriteria === "Salary Bracket") {
        get_dep_designation_salary(
          res.data.data?.organisationDetails?.id,
          "SALARY_BRACKETS"
        );
      }
      insurance_name_dropdownlist(
        res.data.data?.productType.name === "Group Term" ? "LIFE" : "GENERAL"
      );
      setbasicConfig_data([
        {
          key: res.data.data?.planType?.basicConfig,
          value: res.data.data?.planType?.basicConfig,
        },
      ]);
      setindvConfig_data([
        {
          key: res.data.data?.planType?.parentConfig,
          value: res.data.data?.planType?.parentConfig,
        },
      ]);
      if (res.data.data?.planType?.name === "GRADED") {
        setPlanType(1);
      }
      setInsurerAddressList([
        {
          key: res.data.data?.insurerDetails?.office,
          value: res.data.data?.insurerDetails?.office,
        },
      ]);

      let dataWarn: any = { ...addPoliciesdataWarn };
      // res.data.data.benefitsDetailsGrade = [
      //   ...res.data.data.benefitsDetailsGrade,
      //   {
      //     designation: "",
      //     deductible:'',
      //     name: "",
      //     sumInsured: "",
      //     features: [
      //       {
      //         name: "",
      //         value: "",
      //         featureId: "",
      //         groupId: "",
      //       },
      //     ],
      //     exclusion: [
      //       {
      //         name: "",
      //         description: "",
      //         exclusionId: "",
      //       },
      //     ],
      //   },
      // ];

      dataWarn.benefitsDetailsGrade = [
        ...dataWarn.benefitsDetailsGrade,
        {
          designation: false,
          deductible: false,
          name: false,
          sumInsured: false,
          features: [
            {
              name: false,
              value: false,
              featureId: false,
              groupId: false,
            },
          ],
          exclusion: [
            {
              name: false,
              description: false,
              exclusionId: false,
            },
          ],
        },
      ];
      dataWarn = {
        ...dataWarn,
        rider: res.data.data.rider.map(() => ({
          name: false,
          description: false,
        })),
        benefits_detail_flat: res.data.data.benefits_detail_flat.map(() => ({
          label: false,
          enable: false,
          amount: false,
          description: false,
        })),
      };

      let dataToSave = res.data.data;

      if (
        res.data.data.payType.data.length !==
        res.data.data.planType.coverageType.length
      ) {
        const newData = res.data.data.planType.coverageType.map((item: any) => {
          return {
            name: item.name,
            value: 0,
          };
        });

        dataToSave = {
          ...res.data.data,
          payType: {
            data: newData,
          },
        };
      }

      dataToSave = {
        ...dataToSave,
        organisationDetails: {
          ...dataToSave.organisationDetails,
          location: dataToSave.organisationDetails.location.map(
            (locationData: any) => {
              return { name: locationData.name };
            }
          ),
        },
      };

      setAddPoliciesdata(dataToSave);
      setAddPoliciesdataWarn(dataWarn);
      gradedAddData(featuresListdata);
      exclusionsgradedAddData(exclusionsListdata);
      // Api for feature lists
      const num =
        res.data.data.productType.name === "Group Term"
          ? 0
          : res.data.data.productType.name === "Group Health"
            ? 1
            : res.data.data.productType.name === "Group Travel"
              ? 2
              : res.data.data.productType.name === "Group Personal Accident"
                ? 3
                : res.data.data.productType.name === "Group Critical Illness"
                  ? 4
                  : res.data.data.productType.name === "Group Super TopUp"
                    ? 5
                    : null;
      features_list(num, dataToSave.benefitsDetailsflat.features);
      // Api for exclusion list
      exclusion_list(num);
      // Function to push the data into dynamicLocationOffice
      // Assuming res.data.data.insurerDetails is an array of objects

      const arrs: any[] = [];
      let warningArr: any = [];

      for (let i = 0; i < res.data.data.insurerDetails.length; i++) {
        warningArr.push({
          name: false,
          office: false,
        });

        const onSuccess = (res: any) => {
          let arr: any = res.data.data;
          let newArr: any = [];
          arr.map((data: any) => {
            newArr.push({ key: data.label, value: data.label });
          });
          // Use the spread operator to add newArr as a new element in dynamicLocationOffice
          arrs.push(newArr);
        };

        const onError = (err: any) => {
          console.log("err", err);
        };

        POLICIES_SERVICES.insurance_address_dropdownlist(
          onSuccess,
          onError,
          res.data.data.insurerDetails[i].id
        );
      }

      setdynamicLocationOffice(arrs);
      setAddPoliciesdataWarn((prevState: any) => ({
        ...prevState,
        insurerDetails: warningArr,
      }));
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    QUOTES_SERVICES.get_quotes_details(onSuccess, onError, id);
  };

  useEffect(() => {
    get_graded_data();
  }, [
    ...addPoliciesdata.planType.coverageType,
    ...addPoliciesdata.benefitsDetailsGrade,
    ...addPoliciesdata.ageBand,
    ...addPoliciesdata.rider,
    ...addPoliciesdata?.planType?.name,
  ]);

  const get_graded_data = () => {
    let data = { ...addPoliciesdata };
    let dataWarn = { ...addPoliciesdataWarn };
    if (data?.planType?.name === "FLAT") {
      let data_arr: any = data?.ageBand?.map((dataItem: any) => ({
        min: dataItem.min,
        max: dataItem.max,
        coverageTypeData: [
          ...data?.planType?.coverageType
            .map((dataCover: any) => ({
              code: String(dataCover.code),
              name: dataCover.name,
              value: "",
            }))
            .slice(0, 0), // elements before the insertion point
          { code: "", name: "perDay", value: "" }, // element to insert
          ...data?.planType?.coverageType
            .map((dataCover: any) => ({
              code: String(dataCover.code),
              name: dataCover.name,
              value: "",
            }))
            .slice(0), // elements after the insertion point
        ],
      }));

      let data_arrWarn: any = data?.ageBand?.map((dataItem: any) => ({
        min: false,
        max: false,
        coverageTypeData: data?.planType?.coverageType.map(
          (dataCover: any) => ({
            code: false,
            name: false,
            value: false,
          })
        ),
      }));

      data = {
        ...data,
        rider_premium_flat: [...data.rider_premium_flat],
      };

      console.log("dataFlat", data);
      dataWarn = {
        ...dataWarn,
        rider_premium_flat: data.rider_premium_flat.map((riderdata) => ({
          proreta_by: false,
          premium_split: false,
          rider_name: false,
          livesInfoFlat: data_arrWarn,
        })),
      };
      setAddPoliciesdataWarn(dataWarn);

      let newDataArr = structuredClone(data_arr);
      if (newDataArr) {
        newDataArr.forEach((item: any) => {
          // Check if coverageTypeData exists before pushing items into it
          if (item?.coverageTypeData) {
            item?.coverageTypeData.unshift({
              name: "amount",
              value: "",
            });
          }
          if (item?.coverageTypeData) {
            item?.coverageTypeData.push({
              name: "total Live",
              value: "",
            });
          }
        });
      }
      if (editMode) {
        data = {
          ...data,
          livesInfoFlat: newDataArr,
        };
      }
      console.log("gradedData", data?.livesInfoFlat);
      setAddPoliciesdata(data);
      setModifiedArrayAgeBand(data?.livesInfoFlat);

      // setModifiedArrayAgeBand(data.livesInfoFlat);
    }
    if (
      data?.planType?.name === "GRADED" &&
      data.benefitsDetailsGrade.length > 0
    ) {
      let updatedArr: any = [];
      for (let i = 0; i < addPoliciesdata.benefitsDetailsGrade.length; i++) {
        let data_arr: any = data?.ageBand?.map((dataItem: any) => ({
          min: dataItem.min,
          max: dataItem.max,
          coverageTypeData: [
            ...data?.planType?.coverageType
              .map((dataCover: any) => ({
                code: String(dataCover.code),
                name: dataCover.name,
                value: "",
              }))
              .slice(0, 0),
            { code: "", name: "perDay", value: "" },
            ...data?.planType?.coverageType
              .map((dataCover: any) => ({
                code: String(dataCover.code),
                name: dataCover.name,
                value: "",
              }))
              .slice(0),
          ],
        }));

        if (data_arr) {
          data_arr.forEach((item: any) => {
            // Check if coverageTypeData exists before pushing items into it
            if (item?.coverageTypeData) {
              item?.coverageTypeData.unshift({
                name: "amount",
                value: "",
              });
            }
            if (item?.coverageTypeData) {
              item?.coverageTypeData.push({
                name: "total Live",
                value: "",
              });
            }
          });
        }

        data = {
          ...data,
          livesInfoFlat: data_arr,
        };

        // data = {
        //   ...data,
        //   rider_premium_graded: [...data.rider_premium_graded],
        // };

        updatedArr.push(data_arr);
        // perDay amount not display
        data = {
          ...data,
          rider_premium_graded: data.rider.map((riderdata, index) => ({
            proreta_by:
              addPoliciesdata?.rider_premium_graded[index]?.proreta_by,
            premium_split:
              addPoliciesdata?.rider_premium_graded[index]?.premium_split,
            rider_name: riderdata.name,
            livesInfoGrade: updatedArr.map((grade: any, idx: any) => ({
              gradeName: `Grade ${idx + 1}`, // Generating gradeName dynamically
              data: grade.map((element: any, eleIndex: any) => ({
                ...element,
                coverageTypeData: element?.coverageTypeData?.map(
                  (coverageItem: any, coverageIndex: any) => {
                    if (coverageItem.name === "amount") {
                      return {
                        ...coverageItem,
                        value:
                          addPoliciesdata?.rider_premium_graded?.[index]
                            ?.livesInfoGrade?.[idx]?.data?.[eleIndex]
                            ?.coverageTypeData?.[0]?.value ?? "",
                      };
                    }
                    return coverageItem;
                  }
                ),
              })),
            })),
          })),
        };

        setAddPoliciesdata(data);
      }

      let newArray = updatedArr?.map((dataArray: any, index: number) => {
        return {
          gradeName: "Grade " + (index + 1),
          data: dataArray.map((inner: any, index2: number) => {
            return {
              ...inner,
              name: "age band",
              coverageTypeData: inner.coverageTypeData
                .filter((data1: any) => data1.name !== "perDay") // Exclude perDay objects
                .map((data1: any, index3: number) => {
                  const existingValue =
                    data?.livesInfoGrade?.[index]?.data?.[index2]
                      ?.coverageTypeData?.[index3]?.value;

                  return {
                    ...data1,
                    value: existingValue ?? "0", // Default to 0 if undefined or null
                  };
                }),
            };
          }),
        };
      });
      setAddPoliciesdata((prevState) => ({
        ...prevState,
        livesInfoGrade: newArray,
        livesInfoFlat: [],
        benefitsDetailsflat: {},
      }));
      setModifiedArrayAgeBand(newArray);
    }
  };

  useEffect(() => {
    indivisualgradedlives();
  }, [modifiedArrayAgeBand]);

  const indivisualgradedlives = () => {
    let totalValue = 0;

    switch (addPoliciesdata?.planType?.name) {
      case "GRADED":
        modifiedArrayAgeBand?.forEach((array: any) => {
          array?.data?.forEach((data: any) => {
            data.coverageTypeData.forEach((coverageData: any) => {
              if (coverageData.name === "total Live") {
                totalValue += parseInt(coverageData.value);
              }
            });
          });
        });
        break;

      case "FLAT":
        modifiedArrayAgeBand?.forEach((array: any) => {
          array.coverageTypeData.forEach((coverageData: any) => {
            if (coverageData.name === "total Live") {
              totalValue += parseInt(coverageData.value);
            }
          });
        });
        break;
      default:
        break;
    }
    setindivisualGradeLives(totalValue);
  };

  return (
    <>
      {showLoader && <NewLoader />}
      <>
        <Box className="layoutWrapper">
          {/* Page header */}
          <Grid container spacing={3} className="pageHeader">
            <Grid xs={12} className="pageTitle">
              <Link
                className="backStep"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ChevronLeftIcon />
              </Link>
              <div>
                <h3>Edit Quotes</h3>
                <p> Quote Id: {quotesId}</p>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={12}>
              <>
                <Grid container spacing={3}>
                  <Grid xs className="leftSection h-100">
                    <Link
                      className={
                        sectionStatus === "organizationDetails"
                          ? "active sectionLink organizationDetails"
                          : "sectionLink organizationDetails"
                      }
                      onClick={() => {
                        scrollToSection(organizationDetails);
                        SetSectionStatus("organizationDetails");
                      }}
                    >
                      <span>Organization Details</span>
                    </Link>
                    <Link
                      className={
                        sectionStatus === "products"
                          ? "active sectionLink products"
                          : "sectionLink products"
                      }
                      onClick={() => {
                        scrollToSection(products);
                        SetSectionStatus("products");
                      }}
                    >
                      <span>Product Type</span>
                    </Link>
                    <Link
                      className={
                        sectionStatus === "quoteCase"
                          ? "active sectionLink quoteCase"
                          : "sectionLink quoteCase"
                      }
                      onClick={() => {
                        handleClicks(sectionStatus);
                        scrollToSection(quoteCase);
                        SetSectionStatus("quoteCase");
                      }}
                    >
                      <span>Quote Case</span>
                    </Link>
                    <Link
                      className={
                        sectionStatus === "plantype"
                          ? "active sectionLink plantype"
                          : "sectionLink plantype"
                      }
                      onClick={() => {
                        handleClicks(sectionStatus);
                        scrollToSection(plantype);
                        SetSectionStatus("plantype");
                      }}
                    >
                      <span>Plan Type</span>
                    </Link>
                    <Link
                      className={
                        sectionStatus === "premiumPayment"
                          ? "active sectionLink premiumPayment"
                          : "sectionLink premiumPayment"
                      }
                      onClick={() => {
                        handleClicks(sectionStatus);
                        scrollToSection(premiumPayment);
                        SetSectionStatus("premiumPayment");
                      }}
                    >
                      <span>Premium Payment</span>
                    </Link>
                    {addPoliciesdata.productType.name === "Group Term" && (
                      <Link
                        className={
                          sectionStatus === "Riders"
                            ? "active sectionLink Riders"
                            : "sectionLink Riders"
                        }
                        onClick={() => {
                          handleClicks(sectionStatus);
                          scrollToSection(Riders);
                          SetSectionStatus("Riders");
                        }}
                      >
                        {" "}
                        <span>Riders</span>
                      </Link>
                    )}
                    <Link
                      className={
                        sectionStatus === "AgeBand"
                          ? "active sectionLink AgeBand"
                          : "sectionLink AgeBand"
                      }
                      onClick={() => {
                        handleClicks(sectionStatus);
                        scrollToSection(AgeBand);
                        SetSectionStatus("AgeBand");
                      }}
                    >
                      <span>Age Bands for Lives</span>
                    </Link>
                    {planType === 0 || planType === 1 || planType === 2 ? (
                      <Link
                        className={
                          sectionStatus === "EmployeesDetails"
                            ? "active sectionLink EmployeesDetails"
                            : "sectionLink EmployeesDetails"
                        }
                        onClick={() => {
                          handleClicks(sectionStatus);
                          scrollToSection(EmployeesDetails);
                          SetSectionStatus("EmployeesDetails");
                        }}
                      >
                        <span>SI/SA, Features & Exclusions Details</span>
                      </Link>
                    ) : null}

                    <Link
                      className={
                        sectionStatus === "PlansSettings"
                          ? "active sectionLink PlansSettings"
                          : "sectionLink PlansSettings"
                      }
                      onClick={() => {
                        handleClicks(sectionStatus);
                        scrollToSection(PlansSettings);
                        SetSectionStatus("PlansSettings");
                      }}
                    >
                      <span>Plans Settings</span>
                    </Link>
                    <Link
                      className={
                        sectionStatus === "InsurerDetails"
                          ? "active sectionLink InsurerDetails"
                          : "sectionLink InsurerDetails"
                      }
                      onClick={() => {
                        handleClicks(sectionStatus);
                        scrollToSection(InsurerDetails);
                        SetSectionStatus("InsurerDetails");
                      }}
                    >
                      <span>Insurer Details</span>
                    </Link>
                    <Link
                      className={
                        sectionStatus === "RelationshipLives"
                          ? "active sectionLink RelationshipLives"
                          : "sectionLink RelationshipLives"
                      }
                      onClick={() => {
                        handleClicks(sectionStatus);
                        scrollToSection(RelationshipLives);
                        SetSectionStatus("RelationshipLives");
                      }}
                    >
                      <span>Relationship-Lives</span>
                    </Link>
                    {addPoliciesdata.is_rider && (
                      <Link
                        className={
                          sectionStatus === "RiderDetail"
                            ? "active sectionLink RiderDetail"
                            : "sectionLink RiderDetail"
                        }
                        onClick={() => {
                          handleClicks(sectionStatus);
                          scrollToSection(RiderDetail);
                          SetSectionStatus("RiderDetail");
                        }}
                      >
                        {" "}
                        <span>Rider Details</span>
                      </Link>
                    )}
                    {/* <Link
                className={
                  sectionStatus === "EmployeeDetails"
                    ? "active sectionLink EmployeeDetails"
                    : "sectionLink EmployeeDetails"
                }
                onClick={() => {
                  handleClicks(sectionStatus);
                  scrollToSection(EmployeeDetails);
                  SetSectionStatus("EmployeeDetails");
                }}
              >
                <span>Employee Details</span>
              </Link> */}

                    {/* <Link
                  className={
                    sectionStatus === "premiumdetails"
                      ? "active sectionLink premiumdetails"
                      : "sectionLink premiumdetails"
                  }
                  onClick={() => {
                    handleClicks(sectionStatus);
                    scrollToSection(premiumdetails);
                    SetSectionStatus("premiumdetails");
                  }}
                >
                  <span>Premium Details</span>
                </Link>

                <Link
                  className={
                    sectionStatus === "detailspolicy"
                      ? "active sectionLink detailspolicy"
                      : "sectionLink detailspolicy"
                  }
                  onClick={() => {
                    handleClicks(sectionStatus);
                    scrollToSection(detailspolicy);
                    SetSectionStatus("detailspolicy");
                  }}
                >
                  <span>Policy Details</span>
                </Link> */}
                  </Grid>
                  <Grid xs className="rightSection h-100">
                    <>
                      {/* Organization Details */}
                      <Grid container spacing={3} className="px-0">
                        {!addPoliciesdata?.organisationDetails?.clientId ? (
                          <Grid xs={12}>
                            <Box
                              className="detailSection mb-0"
                              ref={organizationDetails}
                            >
                              <div className="sectionTitle">
                                <h4>Organization Details</h4>
                              </div>
                              <Grid container spacing={3}>
                                <Grid xs={12} lg={6} xl={4}>
                                  <RKTextField
                                    class_name="inputField"
                                    title="Organisation Name"
                                    value={
                                      addPoliciesdata.organisationDetails
                                        .organisationName
                                    }
                                    attrName={[
                                      "addPoliciesdata",
                                      "organisationDetails",
                                      "organisationName",
                                      "newclient",
                                    ]}
                                    value_update={updateMasterState}
                                    warn_status={
                                      addPoliciesdataWarn.organisationDetails
                                        .organisationName
                                    }
                                    required
                                    disabled={
                                      !!addPoliciesdata.organisationDetails
                                        .clientId || hide
                                    }
                                  />
                                </Grid>
                                <Grid xs={12} lg={6} xl={3}>
                                  <SearchDropdown
                                    class_name="inputField"
                                    title="Ownership"
                                    value={
                                      addPoliciesdata.organisationDetails
                                        .ownership
                                    }
                                    attrName={[
                                      "addPoliciesdata",
                                      "organisationDetails",
                                      "ownership",
                                      "newclient",
                                    ]}
                                    value_update={updateMasterState}
                                    data={ownership_data}
                                    warn_status={
                                      addPoliciesdataWarn.organisationDetails
                                        .ownership
                                    }
                                    required
                                    value_type_id_value={true}
                                    disabled={
                                      !!addPoliciesdata.organisationDetails
                                        .clientId || hide
                                    }
                                  />
                                </Grid>
                                <Grid xs={12} lg={6} xl={3}>
                                  <RKTextField
                                    class_name="inputField"
                                    title="Location Name"
                                    value={
                                      addPoliciesdata.organisationDetails
                                        .location[0].name
                                    }
                                    attrName={[
                                      "addPoliciesdata",
                                      "organisationDetails",
                                      "locationName",
                                      "newclient",
                                    ]}
                                    value_update={updateMasterState}
                                    warn_status={
                                      addPoliciesdataWarn.organisationDetails
                                        .location
                                    }
                                    required
                                    disabled={
                                      !!addPoliciesdata.organisationDetails
                                        .clientId || hide
                                    }
                                  // disabled={
                                  //   hide
                                  // }
                                  />
                                </Grid>
                              </Grid>
                              <Grid container spacing={3}>
                                <Grid xs={6} lg={4}>
                                  <SearchDropdown
                                    class_name="inputField"
                                    title="Industry"
                                    value={
                                      addPoliciesdata.organisationDetails
                                        .industry
                                    }
                                    attrName={[
                                      "addPoliciesdata",
                                      "organisationDetails",
                                      "industry",
                                      "newclient",
                                    ]}
                                    value_update={updateMasterState}
                                    data={IndustryData}
                                    warn_status={
                                      addPoliciesdataWarn.organisationDetails
                                        .industry
                                    }
                                    required
                                    error_message="Please select industry"
                                    value_type_id_value={false}
                                    disabled={
                                      !!addPoliciesdata.organisationDetails
                                        .clientId || hide
                                    }
                                  />
                                </Grid>
                                <Grid xs={6} lg={4}>
                                  <SearchDropdown
                                    class_name="inputField"
                                    title="Sub-Industry"
                                    value={
                                      addPoliciesdata.organisationDetails
                                        .sub_industry
                                    }
                                    attrName={[
                                      "addPoliciesdata",
                                      "organisationDetails",
                                      "sub_industry",
                                      "newclient",
                                    ]}
                                    value_update={updateMasterState}
                                    data={subIndustryData}
                                    warn_status={
                                      addPoliciesdataWarn.organisationDetails
                                        .sub_industry
                                    }
                                    required
                                    error_message="Please select Subindustry"
                                    disabled={
                                      !!addPoliciesdata.organisationDetails
                                        .clientId || hide
                                    }
                                  />
                                </Grid>
                              </Grid>
                              <Grid container spacing={3}>
                                <Grid xs={12} lg={6} xl={3}>
                                  <RKTextField
                                    class_name="inputField"
                                    title="Email"
                                    value={
                                      addPoliciesdata.organisationDetails.email
                                    }
                                    attrName={[
                                      "addPoliciesdata",
                                      "organisationDetails",
                                      "email",
                                      "newclient",
                                    ]}
                                    value_update={updateMasterState}
                                    warn_status={
                                      addPoliciesdataWarn.organisationDetails
                                        .email
                                    }
                                    required
                                    disabled={
                                      !!addPoliciesdata.organisationDetails
                                        .clientId || hide
                                    }
                                  />
                                </Grid>
                                <Grid xs={12} lg={6} xl={3}>
                                  <RKTextField
                                    class_name="inputField"
                                    title="Website URL"
                                    value={
                                      addPoliciesdata.organisationDetails
                                        .webSite
                                    }
                                    attrName={[
                                      "addPoliciesdata",
                                      "organisationDetails",
                                      "webSite",
                                      "newclient",
                                    ]}
                                    value_update={updateMasterState}
                                    warn_status={
                                      addPoliciesdataWarn.organisationDetails
                                        .website
                                    }
                                    required
                                    disabled={
                                      !!addPoliciesdata.organisationDetails
                                        .clientId || hide
                                    }
                                  />
                                </Grid>
                                <Grid xs={12} lg={6} xl={3}>
                                  <RKTextField
                                    class_name="inputField"
                                    title="contact No."
                                    value={
                                      addPoliciesdata.organisationDetails.mobile
                                    }
                                    attrName={[
                                      "addPoliciesdata",
                                      "organisationDetails",
                                      "mobile",
                                      "newclient",
                                    ]}
                                    value_update={updateMasterState}
                                    warn_status={
                                      addPoliciesdataWarn.organisationDetails
                                        .mobile
                                    }
                                    required
                                    validation_type="numeric"
                                    disabled={
                                      !!addPoliciesdata.organisationDetails
                                        .clientId || hide
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        ) : (
                          <Grid xs={12}>
                            <Box
                              className="detailSection mb-0"
                              ref={organizationDetails}
                              onMouseEnter={() => {
                                SetSectionStatus("organizationDetails");
                              }}
                            >
                              <div className="sectionTitle">
                                <h4>Organization Details</h4>
                              </div>

                              <Grid
                                xs={12}
                                className="radio_group no-border mt-3"
                              >
                                <div className="client_div">
                                  <SearchDropdown
                                    class_name="inputField"
                                    title="Search Client ID"
                                    value={`${addPoliciesdata?.organisationDetails?.clientId}- ${addPoliciesdata?.organisationDetails?.organisationName}`}
                                    attrName={[
                                      "addPoliciesdata",
                                      "organisationDetails",
                                      "clientId",
                                      "dropdown",
                                    ]}
                                    value_update={updateMasterState}
                                    data={clientListdata}
                                    warn_status={
                                      addPoliciesdataWarn.organisationDetails
                                        .clientId
                                    }
                                    error_message="Please select client"
                                    required={true}
                                    disabled
                                  />
                                </div>
                              </Grid>
                              {addPoliciesdata?.organisationDetails
                                ?.clientId ? (
                                <>
                                  <Grid container spacing={3}>
                                    <Grid xs={12}>
                                      <p className="clientsID">
                                        Client ID{" "}
                                        <span>
                                          {organizationFullDetails?.clientId}
                                        </span>
                                      </p>
                                      {/* <p className="clientsID">
                          Parent Client ID <span>CLT23456789</span>
                        </p>
                        <p className="clientsID">
                          Parent Organization <span>CLT23456789</span>
                        </p> */}
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={3}>
                                    <Grid xs={3}>
                                      <p className="companyDetails">
                                        Organization Name
                                        <span>
                                          {
                                            organizationFullDetails?.Organisation
                                          }
                                        </span>
                                      </p>
                                    </Grid>
                                    <Grid xs={3}>
                                      <p className="companyDetails">
                                        Ownership{" "}
                                        <span>
                                          {organizationFullDetails?.Ownership}
                                        </span>
                                      </p>
                                    </Grid>
                                    <Grid xs={3}>
                                      <p className="companyDetails">
                                        Industry - Sub-industry
                                        <span>
                                          {
                                            organizationFullDetails[
                                            "Sub-Industry"
                                            ]
                                          }
                                        </span>
                                      </p>
                                    </Grid>
                                    <Grid xs={3}>
                                      <p className="contactNumber">
                                        <PhoneIphoneIcon />
                                        {
                                          organizationFullDetails[
                                          "Organization Phone Number"
                                          ]
                                        }
                                      </p>
                                      <Link
                                        className="email_list"
                                        href=""
                                        target="_blank"
                                      >
                                        <EmailIcon />
                                        {
                                          organizationFullDetails[
                                          "Organization Email"
                                          ]
                                        }
                                      </Link>
                                    </Grid>
                                  </Grid>
                                  {/* <Grid container spacing={3}>
                                  <Grid xs={12}>
                                    <MultipleSearchDropdown
                                      class_name="inputField"
                                      title="Location Name"
                                      value={
                                        addPoliciesdata?.organisationDetails
                                          ?.location
                                      }
                                      attrName={[
                                        "addPoliciesdata",
                                        "organisationDetails",
                                        "location",
                                        "multiselect",
                                      ]}
                                      value_update={updateMasterState}
                                      multiple_data={clientLocationList}
                                      required
                                      warn_status={
                                        addPoliciesdataWarn.organisationDetails
                                          .location
                                      }
                                      disabled={
                                        isPolicyAttachedToMember ||
                                        policyStatus !== 20
                                      }
                                      error_messg="Please select location"
                                    />
                                  </Grid>
                                </Grid> */}
                                </>
                              ) : (
                                ""
                              )}
                            </Box>
                          </Grid>
                        )}
                      </Grid>

                      {/* Product Type */}
                      <Grid container spacing={3} className="px-0">
                        <Grid xs={12}>
                          <Box
                            className="detailSection mb-0"
                            onMouseEnter={() => {
                              SetSectionStatus("products");
                            }}
                            ref={products}
                          >
                            <div className="sectionTitle">
                              <h4>Product Type</h4>
                            </div>
                            <Grid container spacing={3}>
                              <Grid className="productTypeSection">
                                <div className="radioBox productcategory">
                                  <input
                                    type="radio"
                                    value="Group Term"
                                    id="individual"
                                    name="plantype"
                                    checked={
                                      addPoliciesdata.productType.name ===
                                      "Group Term"
                                    }
                                    onChange={(e) => {
                                      updateMasterState(
                                        [
                                          "addPoliciesdata",
                                          "productType",
                                          "LIFE",
                                          "radio",
                                        ],
                                        e.target.value
                                      );
                                    }}
                                    disabled={hide}
                                  />
                                  <label className="producttype grpTerm">
                                    <hr />
                                    Group Term Insurance
                                    <p>
                                      This plan aim to offer financial
                                      protection to the nominee or the
                                      beneficiary.
                                    </p>
                                  </label>
                                </div>
                              </Grid>
                              <Grid className="productTypeSection">
                                <div className="radioBox productcategory">
                                  <input
                                    type="radio"
                                    value="Group Super TopUp"
                                    id="individual"
                                    name="plantype"
                                    checked={
                                      addPoliciesdata.productType.name ===
                                      "Group Super TopUp"
                                    }
                                    onChange={(e) => {
                                      updateMasterState(
                                        [
                                          "addPoliciesdata",
                                          "productType",
                                          "TOPUP",
                                          "radio",
                                        ],
                                        e.target.value
                                      );
                                    }}
                                    disabled={hide}
                                  />
                                  <label className="producttype grptopup">
                                    <hr />
                                    Group Super TopUp
                                    <p>
                                      This plan aim to offer financial
                                      protection to the nominee or the
                                      beneficiary.
                                    </p>
                                  </label>
                                </div>
                              </Grid>
                              <Grid className="productTypeSection">
                                <div className="radioBox">
                                  <input
                                    type="radio"
                                    value="Group Health"
                                    id="individual1"
                                    name="plantype"
                                    checked={
                                      addPoliciesdata.productType.name ===
                                      "Group Health"
                                    }
                                    onChange={(e) => {
                                      updateMasterState(
                                        [
                                          "addPoliciesdata",
                                          "productType",
                                          "GENERAL",
                                          "radio",
                                        ],
                                        e.target.value
                                      );
                                    }}
                                    disabled={hide}
                                  />
                                  <label className="producttype grpHealth">
                                    <hr />
                                    Group Health Insurance
                                    <p>
                                      Group critical Illness helps employees
                                      prepare financially for an unexpected life
                                      event.
                                    </p>
                                  </label>
                                </div>
                              </Grid>
                              {/* <Grid className="productTypeSection">
                            <div className="radioBox">
                              <input
                                type="radio"
                                value="Group Travel"
                                id="individual2"
                                name="plantype"
                                checked={addPoliciesdata.productType.name ==="Group Travel" }
                                onChange={(e) => {
                                  updateMasterState(
                                    [
                                      "addPoliciesdata",
                                      "productType",
                                      "HEALTH",
                                      "radio",
                                    ],
                                    e.target.value
                                  );
                                }}
                                                                                                    disabled={!(policyStatus === 20 && isTodayBeforeStartDate)}


                                disabled
                              />
                              <label className="producttype grpTravel">
                                <hr />
                                Group Travel Insurance
                                <p>
                                  A group health insurance plan provides medical
                                  coverage for a group of people.
                                </p>
                              </label>
                            </div>
                          </Grid> */}
                              <Grid className="productTypeSection">
                                <div className="radioBox">
                                  <input
                                    type="radio"
                                    value="Group Personal Accident"
                                    id="individual3"
                                    name="plantype"
                                    checked={
                                      addPoliciesdata.productType.name ===
                                      "Group Personal Accident"
                                    }
                                    onChange={(e) => {
                                      updateMasterState(
                                        [
                                          "addPoliciesdata",
                                          "productType",
                                          "TRAVEL",
                                          "radio",
                                        ],
                                        e.target.value
                                      );
                                    }}
                                    disabled={hide}
                                  />
                                  <label className="producttype grpPA">
                                    <hr />
                                    Group Personal Accident
                                    <p>
                                      That is offered to protect employees
                                      against the medical expenses incurred.
                                    </p>
                                  </label>
                                </div>
                              </Grid>
                              <Grid className="productTypeSection">
                                <div className="radioBox">
                                  <input
                                    type="radio"
                                    value="Group Critical Illness"
                                    id="individual4"
                                    name="plantype"
                                    checked={
                                      addPoliciesdata.productType.name ===
                                      "Group Critical Illness"
                                    }
                                    onChange={(e) => {
                                      updateMasterState(
                                        [
                                          "addPoliciesdata",
                                          "productType",
                                          "ACCIDENT",
                                          "radio",
                                        ],
                                        e.target.value
                                      );
                                    }}
                                    disabled={hide}
                                  />
                                  <label className="producttype grpCI">
                                    <hr />
                                    Group Critical Illness
                                    <p>
                                      Group critical Illness helps employees
                                      prepare financially for an unexpected life
                                      event.
                                    </p>
                                  </label>
                                </div>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>

                      {/* Policy Case */}
                      <Grid container spacing={3} className="px-0">
                        <Grid xs={12} lg={6} xl={6}>
                          <Box
                            className="detailSection"
                            ref={quoteCase}
                            onMouseEnter={() => {
                              SetSectionStatus("quoteCase");
                            }}
                          >
                            <div className="sectionTitle">
                              <h4>Quote Case</h4>
                            </div>
                            <Grid container spacing={3}>
                              <Grid className="PolicyTypeSection">
                                <div
                                  className="radioBox policytype"
                                  onClick={() => {
                                    setPolicyType(0);
                                  }}
                                >
                                  <input
                                    type="radio"
                                    id="fresh"
                                    value="FRESH"
                                    name="planstype"
                                    defaultChecked
                                    onChange={(e) => {
                                      updateMasterState(
                                        [
                                          "addPoliciesdata",
                                          "policyCase",
                                          "FRESH",
                                          "radio",
                                        ],
                                        e.target.value
                                      );
                                    }}
                                    disabled={hide}
                                  />
                                  <label className="policytype fresh">
                                    <hr />
                                    Fresh Quote
                                    <p>Buying quote for the first time.</p>
                                  </label>
                                </div>
                              </Grid>
                              {/* <>
                              <Grid className="PolicyTypeSection">
                                <div
                                  className="radioBox"
                                  onClick={() => {
                                    setPolicyType(1);
                                  }}
                                >
                                  <input
                                    type="radio"
                                    id="renewal"
                                    name="planstype"
                                    value="RENEWAL"
                                    onChange={(e) => {
                                      updateMasterState(
                                        [
                                          "addPoliciesdata",
                                          "policyCase",
                                          "RENEWAL",
                                          "radio",
                                        ],
                                        e.target.value
                                      );
                                    }}
                                    disabled={hide}

                                  />
                                  <label className="policytype renew">
                                    <hr />
                                    Renewal Policy
                                    <p>Existing Policy will be expiring.</p>
                                  </label>
                                </div>
                              </Grid>
                            </> */}
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                      {/* policy history */}
                      {policyType === 1 ? <PolicyHistory /> : null}

                      {/* Plan Type */}
                      <Grid container spacing={3} className="px-0">
                        <Grid xs={12}>
                          <Box
                            className="detailSection mb-0"
                            ref={plantype}
                            onMouseEnter={() => {
                              SetSectionStatus("plantype");
                            }}
                          >
                            {" "}
                            <div className="sectionTitle">
                              <h4>Plan Type</h4>
                            </div>
                            <Grid container spacing={3}>
                              <Grid xs={4} className="planTypeSection">
                                <div className="radioBox plantype">
                                  <input
                                    type="radio"
                                    value="FLAT"
                                    name="radioButtonName"
                                    onClick={() => {
                                      setPlanType(0);
                                    }}
                                    onChange={(e) => {
                                      updateMasterState(
                                        [
                                          "addPoliciesdata",
                                          "planType",
                                          "name",
                                          "radio",
                                        ],
                                        e.target.value
                                      );
                                    }}
                                    checked={
                                      addPoliciesdata.planType.name === "FLAT"
                                        ? true
                                        : false
                                    }
                                    disabled={hide}
                                  />
                                  <label>
                                    Flat Coverage
                                    <span>
                                      Common average amount for all employees in
                                      the policy.
                                    </span>
                                  </label>
                                </div>
                              </Grid>
                              <Grid xs={4} className="planTypeSection">
                                <div className="radioBox plantype">
                                  <input
                                    type="radio"
                                    value="GRADED"
                                    name="radioButtonName"
                                    onClick={() => {
                                      setPlanType(1);
                                    }}
                                    onChange={(e) => {
                                      updateMasterState(
                                        [
                                          "addPoliciesdata",
                                          "planType",
                                          "name",
                                          "radio",
                                        ],
                                        e.target.value
                                      );
                                    }}
                                    checked={
                                      addPoliciesdata.planType.name === "GRADED"
                                        ? true
                                        : false
                                    }
                                    disabled={hide}
                                  />
                                  <label>
                                    Graded Coverage
                                    <span>
                                      Different average amount for different
                                      employees in the policy.
                                    </span>
                                  </label>
                                </div>
                              </Grid>

                              <Grid xs={12}>
                                {/* Coverage Section */}
                                {planType === 0 || planType === 1 ? (
                                  <Box className="coverageDetail">
                                    <Grid container spacing={3}>
                                      <Grid xs={12}>
                                        <h5 className="empsecHeading">
                                          Select Coverage Type
                                        </h5>
                                      </Grid>
                                      <Grid xs={12}>
                                        <ul
                                          id="checkboxContainer"
                                          className="coverageAmt"
                                        >
                                          <li>
                                            <div
                                              className={
                                                addPoliciesdata?.planType?.coverageType.some(
                                                  (item: any) =>
                                                    item.name === "EMPLOYEE"
                                                )
                                                  ? "Checkbox_two checked"
                                                  : "Checkbox_two"
                                              }
                                            >
                                              <input
                                                type="checkbox"
                                                name="check"
                                                value="EMPLOYEE"
                                                onChange={(e) => {
                                                  updateMasterState(
                                                    [
                                                      "addPoliciesdata",
                                                      "planType",
                                                      "coverageType",
                                                      "checkbox",
                                                    ],
                                                    e.target.value
                                                  );
                                                }}
                                                checked
                                                disabled={hide}
                                              />
                                              <label className="employee">
                                                <p>Employee</p>
                                              </label>
                                            </div>
                                          </li>
                                          {addPoliciesdata.productType.name !==
                                            "Group Term" && (
                                              <>
                                                <li>
                                                  <div
                                                    className={
                                                      addPoliciesdata?.planType?.coverageType.some(
                                                        (item: any) =>
                                                          item.name === "SPOUSE"
                                                      )
                                                        ? "Checkbox_two checked"
                                                        : "Checkbox_two"
                                                    }
                                                  >
                                                    {addPoliciesdata.planType.coverageType.some(
                                                      (obj) => obj.code === 1
                                                    ) ? (
                                                      <input
                                                        type="checkbox"
                                                        name="check"
                                                        value="SPOUSE"
                                                        checked
                                                        disabled={hide}
                                                        onChange={(e) => {
                                                          updateMasterState(
                                                            [
                                                              "addPoliciesdata",
                                                              "planType",
                                                              "coverageType",
                                                              "checkbox",
                                                            ],
                                                            e.target.value
                                                          );
                                                        }}
                                                      />
                                                    ) : (
                                                      <input
                                                        type="checkbox"
                                                        name="check"
                                                        value="SPOUSE"
                                                        disabled={hide}
                                                        onChange={(e) => {
                                                          updateMasterState(
                                                            [
                                                              "addPoliciesdata",
                                                              "planType",
                                                              "coverageType",
                                                              "checkbox",
                                                            ],
                                                            e.target.value
                                                          );
                                                        }}
                                                      />
                                                    )}

                                                    <label className="spouse">
                                                      <p>Spouse</p>
                                                    </label>
                                                  </div>
                                                </li>
                                                <li>
                                                  <div
                                                    className={
                                                      addPoliciesdata?.planType?.coverageType.some(
                                                        (item: any) =>
                                                          item.name === "KIDS"
                                                      )
                                                        ? "Checkbox_two checked"
                                                        : "Checkbox_two"
                                                    }
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      name="check"
                                                      disabled={hide}
                                                      value="KIDS"
                                                      onChange={(e) => {
                                                        updateMasterState(
                                                          [
                                                            "addPoliciesdata",
                                                            "planType",
                                                            "coverageType",
                                                            "checkbox",
                                                          ],
                                                          e.target.value
                                                        );
                                                      }}
                                                    />
                                                    <label className="kids">
                                                      <p>Kids</p>
                                                    </label>
                                                  </div>
                                                </li>
                                                <li>
                                                  <div
                                                    className={
                                                      addPoliciesdata?.planType?.coverageType.some(
                                                        (item: any) =>
                                                          item.name === "PARENTS"
                                                      )
                                                        ? "Checkbox_two checked"
                                                        : "Checkbox_two"
                                                    }
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      name="check"
                                                      disabled={hide}
                                                      value="PARENTS"
                                                      onChange={(e) => {
                                                        updateMasterState(
                                                          [
                                                            "addPoliciesdata",
                                                            "planType",
                                                            "coverageType",
                                                            "checkbox",
                                                          ],
                                                          e.target.value
                                                        );
                                                      }}
                                                    />
                                                    <label className="parents">
                                                      <p>Parents</p>
                                                    </label>
                                                  </div>
                                                </li>
                                                <li>
                                                  <div
                                                    className={
                                                      addPoliciesdata?.planType?.coverageType.some(
                                                        (item: any) =>
                                                          item.name ===
                                                          "PARENTSINLAW"
                                                      )
                                                        ? "Checkbox_two checked"
                                                        : "Checkbox_two"
                                                    }
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      name="check"
                                                      value="PARENTSINLAW"
                                                      disabled={hide}
                                                      onChange={(e) => {
                                                        updateMasterState(
                                                          [
                                                            "addPoliciesdata",
                                                            "planType",
                                                            "coverageType",
                                                            "checkbox",
                                                          ],
                                                          e.target.value
                                                        );
                                                      }}
                                                    />
                                                    <label className="parents">
                                                      <p>Parents In Law</p>
                                                    </label>
                                                  </div>
                                                </li>
                                              </>
                                            )}
                                        </ul>
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                      <Grid xs={12}>
                                        <h5 className="subHeading">
                                          Coverage Configuration
                                        </h5>
                                      </Grid>
                                      <Grid sm={6} md={4} lg={3} xl={3}>
                                        <SelectDropdown
                                          class_name="inputField"
                                          title="Basic Configuration"
                                          value={
                                            addPoliciesdata.planType.basicConfig
                                          }
                                          attrName={[
                                            "addPoliciesdata",
                                            "planType",
                                            "basicConfig",
                                            "input_fields",
                                          ]}
                                          value_update={updateMasterState}
                                          dropdown_data={basicConfig_data}
                                          warn_status={false}
                                          required
                                          disabled={hide}
                                        />
                                      </Grid>
                                      <Grid sm={6} md={4} lg={3} xl={3}>
                                        <SelectDropdown
                                          class_name="inputField"
                                          title="Individual Configuration"
                                          value={
                                            addPoliciesdata.planType
                                              .parentConfig ?? "Nill"
                                          }
                                          attrName={[
                                            "addPoliciesdata",
                                            "planType",
                                            "parentConfig",
                                            "input_fields",
                                          ]}
                                          value_update={updateMasterState}
                                          dropdown_data={indvConfig_data}
                                          warn_status={false}
                                          required
                                          disabled={hide}
                                        />
                                      </Grid>
                                      {addPoliciesdata.planType.coverageType.some(
                                        (data) => data.name === "KIDS"
                                      ) && (
                                          <Grid sm={6} md={4} lg={3} xl={3}>
                                            <SearchDropdown
                                              class_name="inputField"
                                              title="Maximum Age for Children"
                                              value={addPoliciesdata.maxChildAge}
                                              attrName={[
                                                "addPoliciesdata",
                                                "maxChildAge",
                                                "maxChildAge",
                                              ]}
                                              value_update={updateMasterState}
                                              data={maxage_dataClone}
                                              error_message="select max child age"
                                              warn_status={
                                                addPoliciesdataWarn.maxChildAge
                                              }
                                            />
                                          </Grid>
                                        )}
                                    </Grid>
                                    <Grid container spacing={3}>
                                      <Grid sm={6} md={8} lg={5} xl={5}>
                                        <RKTextField
                                          class_name="inputField"
                                          title="Family Definition"
                                          value={
                                            addPoliciesdata.planType
                                              .familyDefinition
                                          }
                                          attrName={[
                                            "addPoliciesdata",
                                            "planType",
                                            "familyDefinition",
                                            "input_fields",
                                          ]}
                                          value_update={updateMasterState}
                                          warn_status={
                                            addPoliciesdataWarn.planType
                                              .familyDefinition
                                          }
                                          disabled={hide}
                                        />
                                      </Grid>
                                    </Grid>
                                    {addPoliciesdata.planType.parentConfig.includes(
                                      "1P+"
                                    ) &&
                                      addPoliciesdata.planType.parentConfig.includes(
                                        "1PIL"
                                      ) && (
                                        <Grid
                                          xs={12}
                                          className="payment_radio_group"
                                        >
                                          <>
                                            <RadioGroup row defaultValue="both">
                                              <FormControlLabel
                                                checked={
                                                  addPoliciesdata.planType
                                                    .setOfParents === "both"
                                                }
                                                value="both"
                                                control={<CustomRadio />}
                                                label="Include both the set of Parents and Parents in law"
                                                onClick={() => {
                                                  setAddPoliciesdata(
                                                    (prev) => ({
                                                      ...prev,
                                                      planType: {
                                                        ...prev.planType,
                                                        setOfParents: "both",
                                                      },
                                                    })
                                                  );
                                                }}
                                              />
                                              <FormControlLabel
                                                checked={
                                                  addPoliciesdata.planType
                                                    .setOfParents === "single"
                                                }
                                                value="single"
                                                control={<CustomRadio />}
                                                label="Include only a set of Parents or Parents in law"
                                                onClick={() => {
                                                  setAddPoliciesdata(
                                                    (prev) => ({
                                                      ...prev,
                                                      planType: {
                                                        ...prev.planType,
                                                        setOfParents: "single",
                                                      },
                                                    })
                                                  );
                                                }}
                                              />
                                            </RadioGroup>
                                          </>
                                        </Grid>
                                      )}
                                  </Box>
                                ) : null}
                              </Grid>
                            </Grid>
             
                          </Box>
                        </Grid>
                      </Grid>

                      {/* payment configuration */}
                      <Grid container spacing={3} className="px-0">
                        <Grid xs={12}>
                          <Box
                            className="detailSection mb-0"
                            onMouseEnter={() => {
                              SetSectionStatus("premiumPayment");
                            }}
                            ref={premiumPayment}
                          >
                            {" "}
                            <div className="sectionTitle">
                              <h4>Premium Payment Configuration</h4>
                            </div>
                            <div className="premium_payment_section">
                              <Grid container spacing={3}>
                                <Grid xs={12}>
                                  <h5 className="subHeading">
                                    Premium Payment Type
                                  </h5>
                                </Grid>
                                <Grid xs={12} className="payment_radio_group">
                                  <>
                                    <RadioGroup row defaultValue="new">
                                      <FormControlLabel
                                        disabled={hide}
                                        checked={!toggle}
                                        value="existing"
                                        control={<Radio />}
                                        label="Standard Premium"
                                        onClick={() => {
                                          setPremiumPaymentType(
                                            premiumType.STANDARD
                                          );
                                          setToggle(false);
                                        }}
                                      />

                                      <FormControlLabel
                                        disabled={hide}
                                        checked={toggle}
                                        value="new"
                                        control={<Radio />}
                                        label="Split Premium"
                                        onClick={() => {
                                          setPremiumPaymentType(
                                            premiumType.SPLIT
                                          );
                                          setToggle(true);
                                        }}
                                      />
                                    </RadioGroup>
                                  </>
                                </Grid>
                              </Grid>
                              {premiumPaymentType === premiumType.SPLIT ? (
                                <>
                                  <Grid container spacing={3}>
                                    <Grid sm={12} md={12} lg={6} xl={6}>
                                      <div className="payment_inner_section">
                                        {addPoliciesdata.payType.data.map(
                                          (pType: any, index: number) => {
                                            return (
                                              <RKTextField
                                                class_name="inputField"
                                                disabled={hide}
                                                // type="number"
                                                title={pType.name}
                                                value={
                                                  addPoliciesdata.payType.data.find(
                                                    (el: any) =>
                                                      el.name === pType.name
                                                  )?.value
                                                }
                                                attrName={[
                                                  "addPoliciesdata",
                                                  "payType",
                                                  "data",
                                                  pType.name,
                                                ]}
                                                value_update={
                                                  updatePayTypeValues
                                                }
                                                warn_status={false}
                                                inputProps={{
                                                  endAdornment: (
                                                    <InputAdornment position="end">
                                                      %
                                                    </InputAdornment>
                                                  ),
                                                }}
                                              />
                                            );
                                          }
                                        )}
                                      </div>
                                    </Grid>
                                  </Grid>
                                </>
                              ) : null}
                              <Grid container spacing={3}>
                                <>
                                  {toggle === false ? (
                                    <Grid xs={12} className="pt-0">
                                      <h6>
                                        100% of the premium value will be paid
                                        by the Client/Employer
                                      </h6>
                                    </Grid>
                                  ) : null}

                                  {toggle === true ? (
                                    <Grid xs={12} className="pt-0">
                                      <h6>
                                        Define % of the premium value to be paid
                                        by the employee for the respective
                                        associated relationship
                                      </h6>
                                    </Grid>
                                  ) : null}
                                </>
                              </Grid>
                            </div>
                          </Box>
                        </Grid>
                      </Grid>

                      {/* Rider section start */}
                      {addPoliciesdata.productType.name === "Group Term" && (
                        <Grid container spacing={3} className="px-0">
                          <Grid xs={12}>
                            <Box
                              className="detailSection mb-0"
                              ref={Riders}
                              onMouseEnter={() => {
                                SetSectionStatus("Riders");
                              }}
                            >
                              <div className="sectionTitle">
                                <h4>Riders</h4>
                              </div>

                              <Grid container spacing={3}>
                                <Grid
                                  xs
                                  lg={8}
                                  className="switchFields"
                                  display={"flex"}
                                  alignItems="center"
                                  sx={{ height: "70px" }}
                                >
                                  <span
                                    style={{
                                      fontSize: "16px",
                                      marginLeft: "12px",
                                    }}
                                  >
                                    Enable Riders
                                  </span>
                                  <input
                                    disabled={hide}
                                    type="checkbox"
                                    className="switch ml-3"
                                    checked={addPoliciesdata.is_rider}
                                    onClick={(e) => {
                                      toggleEnableRiders(e);
                                    }}
                                  />
                                </Grid>
                                <Grid xs="auto">
                                  {!hide && addPoliciesdata.is_rider && (
                                    <Link
                                      className="blueBtn addfile ml-1"
                                      sx={{
                                        textDecoration: "none",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        if (!addPoliciesdata.is_rider) {
                                          return toast.warn(
                                            "Please enable Rider"
                                          );
                                        }
                                        setAddPoliciesdata((prev) => ({
                                          ...prev,
                                          rider: [
                                            ...(prev.rider || []),
                                            {
                                              name: "",
                                              description: "",
                                            },
                                          ],
                                          benefits_detail_flat: [
                                            ...(prev.benefits_detail_flat ||
                                              []),
                                            {
                                              label: "",
                                              enable: false,
                                              amount: "",
                                              description: "",
                                            },
                                          ],
                                          rider_premium_flat: [
                                            ...prev.rider_premium_flat,
                                            {
                                              proreta_by: "YEARLY",
                                              premium_split: "",
                                              rider_name: "",
                                              livesInfoFlat: [
                                                {
                                                  min: "",
                                                  max: "",
                                                  name: "",
                                                  coverageTypeData: [
                                                    {
                                                      code: "",
                                                      name: "",
                                                      value: 0,
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        }));
                                        setAddPoliciesdataWarn((prev: any) => ({
                                          ...prev,
                                          rider: [
                                            ...(prev.rider || []),
                                            {
                                              name: false,
                                              description: false,
                                            },
                                          ],
                                          benefits_detail_flat: [
                                            ...(prev.benefits_detail_flat ||
                                              []),
                                            {
                                              label: false,
                                              enable: false,
                                              amount: false,
                                              description: false,
                                            },
                                          ],
                                          rider_premium_flat: [
                                            ...(prev?.rider_premium_flat || []),
                                            {
                                              proreta_by: false,
                                              premium_split: false,
                                              rider_name: false,
                                              livesInfoFlat: [
                                                {
                                                  min: false,
                                                  max: false,
                                                  name: false,
                                                  coverageTypeData: [
                                                    {
                                                      code: false,
                                                      name: false,
                                                      value: false,
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        }));
                                      }}
                                    >
                                      Add
                                    </Link>
                                  )}
                                </Grid>
                              </Grid>
                              {addPoliciesdata.is_rider &&
                                addPoliciesdata.rider?.map(
                                  (rider: any, index: number) => (
                                    <Grid container spacing={3}>
                                      <Grid xs lg={4}>
                                        <RKTextField
                                          class_name="inputField"
                                          title={"Rider Name"}
                                          disabled={hide}
                                          value={rider.name}
                                          attrName={[
                                            "addPoliciesdata",
                                            "rider",
                                            "name",
                                            "input_fields",
                                            index,
                                          ]}
                                          value_update={updateMasterState}
                                          warn_status={
                                            addPoliciesdataWarn?.rider &&
                                            addPoliciesdataWarn?.rider[index]
                                              ?.name
                                          }
                                        />
                                      </Grid>
                                      <Grid xs lg={4}>
                                        <TextAreaField
                                          class_name="inputField multiline"
                                          title={"Description"}
                                          value={rider.description}
                                          disable={hide}
                                          attrName={[
                                            "addPoliciesdata",
                                            "rider",
                                            "description",
                                            "input_fields",
                                            index,
                                          ]}
                                          value_update={updateMasterState}
                                          warn_status={
                                            addPoliciesdataWarn?.rider &&
                                            addPoliciesdataWarn?.rider[index]
                                              ?.description
                                          }
                                          required
                                          specialcharacter
                                          alphanumeric
                                        />
                                      </Grid>
                                      {!hide && index !== 0 && (
                                        <Grid xs="auto">
                                          <Link
                                            className="redBtn delete ml-1"
                                            sx={{
                                              textDecoration: "none",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              setAddPoliciesdata((prev) => {
                                                const newRider = prev.rider
                                                  ? [...prev.rider]
                                                  : [];
                                                const benefits_detail_flat =
                                                  prev.benefits_detail_flat
                                                    ? [
                                                      ...prev.benefits_detail_flat,
                                                    ]
                                                    : [];
                                                const rider_premium_flat =
                                                  prev.rider_premium_flat
                                                    ? [
                                                      ...prev.rider_premium_flat,
                                                    ]
                                                    : [];
                                                if (newRider) {
                                                  newRider.splice(index, 1);
                                                  benefits_detail_flat.splice(
                                                    index,
                                                    1
                                                  );
                                                  rider_premium_flat.splice(
                                                    index,
                                                    1
                                                  );
                                                }
                                                return {
                                                  ...prev,
                                                  rider: newRider,
                                                  benefits_detail_flat,
                                                  rider_premium_flat,
                                                };
                                              });
                                              setAddPoliciesdataWarn(
                                                (prev: any) => {
                                                  const newRider = prev.rider
                                                    ? [...prev.rider]
                                                    : [];
                                                  const benefits_detail_flat =
                                                    prev.benefits_detail_flat
                                                      ? [
                                                        ...prev.benefits_detail_flat,
                                                      ]
                                                      : [];
                                                  const rider_premium_flat =
                                                    prev.rider_premium_flat
                                                      ? [
                                                        ...prev.rider_premium_flat,
                                                      ]
                                                      : [];
                                                  if (newRider) {
                                                    newRider.splice(index, 1);
                                                    benefits_detail_flat.splice(
                                                      index,
                                                      1
                                                    );
                                                    rider_premium_flat.splice(
                                                      index,
                                                      1
                                                    );
                                                  }
                                                  return {
                                                    ...prev,
                                                    rider: newRider,
                                                    benefits_detail_flat,
                                                    rider_premium_flat,
                                                  };
                                                }
                                              );
                                            }}
                                          />
                                        </Grid>
                                      )}
                                    </Grid>
                                  )
                                )}
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                      {/* Rider section end */}

                      {/* hide this section  */}
                      <Grid container spacing={3} className="px-0">
                        <Grid xs={12}>
                          <Box
                            className="detailSection"
                            onMouseEnter={() => {
                              SetSectionStatus("AgeBand");
                            }}
                            ref={AgeBand}
                          >
                            <div className="sectionTitle">
                              <h4>Age Bands for Lives</h4>
                            </div>
                            <Box className="band_lives">
                              <Grid container spacing={3}>
                                {addPoliciesdata?.ageBand?.map(
                                  (data: any, index: number) => (
                                    <Grid sm={12} md={12} lg={6} xl={6}>
                                      <h5 className="mb-3 mt-2">
                                        Age Band {index + 1}
                                      </h5>
                                      <div className="bandlives_inner">
                                        <SearchDropdown
                                          class_name="inputField"
                                          title="Min Age"
                                          value={
                                            addPoliciesdata?.ageBand &&
                                            addPoliciesdata.ageBand[index]?.min
                                          }
                                          attrName={[
                                            "addPoliciesdata",
                                            "ageBand",
                                            "min",
                                            "drop_down",
                                            index,
                                          ]}
                                          value_update={updateMasterState}
                                          data={minage_data}
                                          warn_status={
                                            addPoliciesdataWarn?.ageBand[index]
                                              ?.min
                                          }
                                          error_message="Please select min age"
                                          disabled={
                                            hide ||
                                            index <= ageBandData.length - 2
                                          }
                                        />
                                        <SearchDropdown
                                          class_name="inputField"
                                          title="Max Age"
                                          value={
                                            addPoliciesdata?.ageBand &&
                                            addPoliciesdata?.ageBand[index]?.max
                                          }
                                          attrName={[
                                            "addPoliciesdata",
                                            "ageBand",
                                            "max",
                                            "drop_down",
                                            index,
                                          ]}
                                          value_update={updateMasterState}
                                          data={maxage_data}
                                          warn_status={
                                            addPoliciesdataWarn?.ageBand[index]
                                              ?.max
                                          }
                                          error_message="Please select max age"
                                          disabled={
                                            hide ||
                                            index <= ageBandData.length - 2 ||
                                            !addPoliciesdata.ageBand[
                                              ageBandData.length - 1
                                            ]?.min
                                          }
                                        />
                                        <div
                                          className="actionBtns"
                                          style={{ display: "flex" }}
                                        >
                                          <>
                                            {!hide &&
                                              index >
                                              ageBandData.length - 2 && (
                                                <Link
                                                  className="blueBtn addfile ml-1"
                                                  sx={{
                                                    textDecoration: "none",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    let check =
                                                      addPoliciesdata.ageBand.some(
                                                        (data) =>
                                                          !data.max || !data.min
                                                      );

                                                    if (!check) {
                                                      add_band_for_lives(index);
                                                      breakAgeBand("", "onadd");
                                                    } else {
                                                      return toast.warning(
                                                        "Please select empty Age band"
                                                      );
                                                    }
                                                  }}
                                                />
                                              )}
                                            {hide || index === 0 ? null : (
                                              <Link
                                                className="redBtn delete ml-1"
                                                sx={{
                                                  textDecoration: "none",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  remove_band_for_lives(index)
                                                }
                                              />
                                            )}
                                          </>
                                        </div>
                                      </div>
                                    </Grid>
                                  )
                                )}
                              </Grid>
                              {errorMaxChildAge && (
                                <span
                                  style={{
                                    color: "#ec3f3f",
                                    fontSize: "11px",
                                    padding: "12px 0 0 12px",
                                  }}
                                >
                                  Max child age not included in selected age
                                  bands
                                </span>
                              )}
                              {areAgeBandsConsistent(
                                addPoliciesdata.ageBand
                              ) ? (
                                ""
                              ) : (
                                <span
                                  style={{
                                    color: "#ec3f3f",
                                    fontSize: "14px",
                                    padding: "12px 0 0 12px",
                                  }}
                                >
                                  Note: Selected age bands are Inconsistent
                                </span>
                              )}
                            </Box>
                          </Box>
                          {planType === 0 ? (
                            <>
                              <Box className="detailSection mb-0">
                                <div className="sectionTitle">
                                  <h4>SI/SA, Features & Exclusions Details</h4>
                                  <div className="actionBtns ">
                                    {addPoliciesdata?.planType?.name ===
                                      "FLAT" ? (
                                      ""
                                    ) : (
                                      <>
                                        <Link
                                          className="rounded_add_icons mr-3 addfile blueBtn"
                                          sx={{
                                            textDecoration: "none",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setEmpGradeData((empGradeData) => [
                                              ...empGradeData,
                                              {
                                                sum_insured: "5000",
                                                emp_count: "5",
                                                features: ["sdf", "sdfsd"],
                                                deleteable: true,
                                              },
                                            ]);
                                          }}
                                        >
                                          Add Grade
                                        </Link>
                                      </>
                                    )}

                                    <p className="lives">
                                      {/* Total Lives - <span> 300</span> */}
                                    </p>
                                  </div>
                                </div>
                                <Box className="employeeDetail">
                                  {empGradeData.map((data, index) => (
                                    <Grid
                                      container
                                      spacing={0}
                                      className=" gradedSection ml-3 mr-3 mb-4"
                                    >
                                      <Grid xs={12} className="pb-3">
                                        <>
                                          <Grid container spacing={3}>
                                            <Grid xs={6}>
                                              <p>
                                                {" "}
                                                {addPoliciesdata?.productType
                                                  ?.name === "Group Super TopUp"
                                                  ? "Select Super Top-Up Sum Insured"
                                                  : addPoliciesdata?.productType
                                                    ?.name === "Group Term"
                                                    ? "Sum Assured"
                                                    : "Sum Insured"}
                                              </p>{" "}
                                            </Grid>
                                            <Grid
                                              xs={6}
                                              sx={{ textAlign: "right" }}
                                            >
                                              {hide || index === 0 ? (
                                                ""
                                              ) : (
                                                <Link
                                                  className="redBtn delete ml-1"
                                                  sx={{
                                                    textDecoration: "none",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    remove_band_for_lives(index)
                                                  }
                                                />
                                              )}
                                            </Grid>
                                            <Grid xs={12}>
                                              <RKTextField
                                                class_name="inputField"
                                                disabled={hide}
                                                title={
                                                  addPoliciesdata?.productType
                                                    ?.name === "Group Term"
                                                    ? "Sum Assured"
                                                    : "Sum Insured"
                                                }
                                                value={formatCurrencyAccToUser(
                                                  Number(
                                                    extractNumbersFromString(
                                                      addPoliciesdata
                                                        .benefitsDetailsflat
                                                        .sumInsured
                                                        ? addPoliciesdata
                                                          .benefitsDetailsflat
                                                          .sumInsured
                                                        : ""
                                                    )
                                                  ),
                                                  getCurrencyCodeRegex(
                                                    userState.localInformation
                                                      .currency
                                                  ),
                                                  userState.localInformation
                                                    .countryCurrencyCode,
                                                  0
                                                )}
                                                attrName={[
                                                  "addPoliciesdata",
                                                  "benefitsDetailsflat",
                                                  "sumInsured",
                                                  "input_fields",
                                                  index,
                                                ]}
                                                value_update={updateMasterState}
                                                warn_status={
                                                  addPoliciesdataWarn
                                                    .benefitsDetailsflat
                                                    .sum_insured
                                                }
                                                error_messg="Enter Sum Insured amount"
                                                required
                                              />
                                            </Grid>
                                            {addPoliciesdata?.productType
                                              ?.name === "Group Super TopUp" ? (
                                              <Grid
                                                xs={6}
                                                className="super_topup"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <h6 className="mb-0">
                                                  {" "}
                                                  Deductible
                                                </h6>
                                                <div
                                                  className="ml-3"
                                                  style={{ width: "300px" }}
                                                >
                                                  <RKTextField
                                                    class_name="inputField"
                                                    title="Enter value for Deductible"
                                                    disabled={hide}
                                                    value={
                                                      addPoliciesdata
                                                        .benefitsDetailsflat
                                                        .deductible
                                                    }
                                                    attrName={[
                                                      "addPoliciesdata",
                                                      "benefitsDetailsflat",
                                                      "deductible",
                                                      "input_fields",
                                                      index,
                                                    ]}
                                                    value_update={
                                                      updateMasterState
                                                    }
                                                    warn_status={
                                                      addPoliciesdataWarn
                                                        .benefitsDetailsflat
                                                        .deductible
                                                    }
                                                    error_messg="Enter deductible amount"
                                                    required
                                                  />
                                                </div>
                                              </Grid>
                                            ) : (
                                              ""
                                            )}
                                            {addPoliciesdata.productType.name ==
                                              "Group Term" && (
                                                <Grid xs={12}>
                                                  {addPoliciesdata.is_rider &&
                                                    addPoliciesdata?.benefits_detail_flat?.map(
                                                      (
                                                        riders: any,
                                                        index: number
                                                      ) =>
                                                        riders.label && (
                                                          <Grid
                                                            container
                                                            spacing={3}
                                                          >
                                                            <Grid
                                                              xs
                                                              lg={4}
                                                              className="switchFields"
                                                              display={"flex"}
                                                              alignItems="center"
                                                              sx={{
                                                                height: "70px",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    "14px",
                                                                  marginLeft:
                                                                    "12px",
                                                                  fontWeight: 600,
                                                                }}
                                                              >
                                                                {riders?.label}
                                                              </span>
                                                              <input
                                                                type="checkbox"
                                                                checked={
                                                                  riders.enable
                                                                }
                                                                disabled={hide}
                                                                className="switch ml-3"
                                                                onClick={(e) => {
                                                                  toggleEnableRidersFeaure(
                                                                    e,
                                                                    index
                                                                  );
                                                                }}
                                                              />
                                                            </Grid>
                                                            {riders.enable && (
                                                              <Grid xs lg={4}>
                                                                <RKTextField
                                                                  class_name="inputField"
                                                                  title={
                                                                    "Rider SA"
                                                                  }
                                                                  // value={formatCurrency(
                                                                  //   Number(
                                                                  //     riders?.amount
                                                                  //   )
                                                                  // )}
                                                                  value={formatCurrency(
                                                                    extractNumbersFromString(
                                                                      riders?.amount
                                                                    )
                                                                  )}
                                                                  disabled={hide}
                                                                  attrName={[
                                                                    "addPoliciesdata",
                                                                    "benefits_detail_flat",
                                                                    "amount",
                                                                    "input_fields",
                                                                    index,
                                                                  ]}
                                                                  value_update={
                                                                    updateMasterState
                                                                  }
                                                                  warn_status={
                                                                    addPoliciesdataWarn.benefits_detail_flat &&
                                                                    addPoliciesdataWarn
                                                                      ?.benefits_detail_flat[
                                                                      index
                                                                    ]?.amount
                                                                  }
                                                                />
                                                              </Grid>
                                                            )}
                                                          </Grid>
                                                        )
                                                    )}
                                                </Grid>
                                              )}
                                            <Grid xs={12}>
                                              <div className="sectionsHeading">
                                                <h6>Features</h6>
                                                {addPoliciesdataWarn
                                                  .benefitsDetailsflat
                                                  .features === true ? (
                                                  <span
                                                    className="inputField"
                                                    style={{
                                                      color: "#ec3f3f",
                                                      fontSize: "11px",
                                                      position: "relative",
                                                      right: "0",
                                                      bottom: "-20px",
                                                    }}
                                                  >
                                                    Please Update features
                                                  </span>
                                                ) : (
                                                  ""
                                                )}

                                                <div className="actionBtns ">
                                                  {!hide && (
                                                    <Link
                                                      className="rounded_add_icons mr-3 addfile blueBtn"
                                                      sx={{
                                                        textDecoration: "none",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        setShowLoader(true);
                                                        setTimeout(() => {
                                                          setAddFeature(true);
                                                        }, 300);
                                                      }}
                                                    >
                                                      Add/Edit
                                                    </Link>
                                                  )}
                                                </div>
                                              </div>
                                            </Grid>
                                            <Box className="featuresDetail px-0">
                                              <Grid container spacing={3}>
                                                <Grid xs={12}>
                                                  <div className="features_list">
                                                    <ul>
                                                      {addPoliciesdata
                                                        ?.benefitsDetailsflat
                                                        .features &&
                                                        addPoliciesdata
                                                          ?.benefitsDetailsflat
                                                          .features.length >
                                                        0 &&
                                                        addPoliciesdata?.benefitsDetailsflat.features?.map(
                                                          (data: any) => {
                                                            if (data.name) {
                                                              return (
                                                                <li>
                                                                  {data.name}
                                                                  <span>
                                                                    {data.value}{" "}
                                                                    {data.unit ===
                                                                      "Percentage"
                                                                      ? "%"
                                                                      : data.unit ===
                                                                        "Currency"
                                                                        ? ""
                                                                        : data.unit}
                                                                  </span>
                                                                </li>
                                                              );
                                                            }
                                                          }
                                                        )}
                                                    </ul>
                                                  </div>
                                                </Grid>
                                              </Grid>
                                            </Box>

                                            <Grid xs={12}>
                                              <div className="sectionsHeading">
                                                <h6>Exclusion</h6>
                                                {addPoliciesdataWarn
                                                  .benefitsDetailsflat
                                                  .exclusion === true ? (
                                                  <span
                                                    className="inputField"
                                                    style={{
                                                      color: "#ec3f3f",
                                                      fontSize: "11px",
                                                      position: "relative",
                                                      right: "0",
                                                      bottom: "-20px",
                                                    }}
                                                  >
                                                    Please Update exclusion
                                                  </span>
                                                ) : (
                                                  ""
                                                )}

                                                <div className="actionBtns ">
                                                  {!hide && (
                                                    <Link
                                                      className="rounded_add_icons mr-3 addfile blueBtn"
                                                      sx={{
                                                        textDecoration: "none",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        setShowLoader(true);
                                                        setTimeout(() => {
                                                          setAddExclusion(true);
                                                        }, 300);
                                                      }}
                                                    >
                                                      Add/Edit
                                                    </Link>
                                                  )}
                                                </div>
                                              </div>
                                            </Grid>
                                            <Box className="exclusionDetail px-0">
                                              <Grid container spacing={3}>
                                                <Grid xs={12}>
                                                  <div className="features_list">
                                                    <ul>
                                                      {addPoliciesdata?.benefitsDetailsflat?.exclusion?.map(
                                                        (data: any) => (
                                                          <li>
                                                            {data.title ||
                                                              data.name}
                                                            <span>
                                                              {data.description}
                                                            </span>
                                                          </li>
                                                        )
                                                      )}
                                                    </ul>
                                                  </div>
                                                </Grid>
                                              </Grid>
                                            </Box>
                                          </Grid>
                                        </>
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Box>
                              </Box>
                            </>
                          ) : planType === 1 || planType === 2 ? (
                            <Box
                              className="detailSection mb-0"
                              onMouseEnter={() => {
                                SetSectionStatus("EmployeesDetails");
                              }}
                              ref={EmployeesDetails}
                            >
                              <div className="sectionTitle">
                                <h4>SI/SA, Features & Exclusions Details</h4>
                                <div className="actionBtns ">
                                  <Link
                                    className="rounded_add_icons addfile blueBtn"
                                    sx={{
                                      textDecoration: "none",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      let data: any = JSON.parse(
                                        JSON.stringify(addPoliciesdata)
                                      );
                                      let dataWarn: any = {
                                        ...addPoliciesdataWarn,
                                      };
                                      data.benefitsDetailsGrade = [
                                        ...data.benefitsDetailsGrade,
                                        {
                                          gradeDivisions: [
                                            { id: "", label: "" },
                                          ],
                                          deductible: "",
                                          name: "",
                                          sumInsured: (
                                            Number(
                                              extractNumbersFromString(
                                                data.benefitsDetailsGrade[
                                                  data.benefitsDetailsGrade
                                                    .length - 1
                                                ].sumInsured
                                              )
                                            ) + 1
                                          ).toString(),
                                          // features: [
                                          //   {
                                          //     name: "",
                                          //     value: "",
                                          //     featureId: "",
                                          //     groupId: "",
                                          //   },
                                          // ],
                                          // exclusion: [
                                          //   {
                                          //     name: "",
                                          //     description: "",
                                          //     exclusionId: "",
                                          //   },
                                          // ],
                                          features:
                                            data.benefitsDetailsGrade[
                                              data.benefitsDetailsGrade.length -
                                              1
                                            ]?.features,
                                          exclusion:
                                            data.benefitsDetailsGrade[
                                              data.benefitsDetailsGrade.length -
                                              1
                                            ]?.exclusion,
                                        },
                                      ];
                                      data.benefits_detail_graded = [
                                        ...data.benefits_detail_graded,
                                        JSON.parse(
                                          JSON.stringify({
                                            grade_name: `Grade ${data.benefits_detail_graded
                                              .length + 1
                                              }`,
                                            riders:
                                              data?.productType?.name ===
                                                "GROUP TERM"
                                                ? [
                                                  ...data
                                                    .benefits_detail_graded[
                                                    data
                                                      .benefits_detail_graded
                                                      .length - 1
                                                  ]?.riders,
                                                ]
                                                : [],
                                            //this is for backup dont try to delete this
                                            //commented code
                                          })
                                        ),
                                      ];
                                      dataWarn.benefitsDetailsGrade = [
                                        ...dataWarn.benefitsDetailsGrade,
                                        {
                                          designation: false,
                                          deductible: false,
                                          name: false,
                                          sumInsured: false,
                                          features: [
                                            {
                                              name: false,
                                              value: false,
                                              featureId: false,
                                              groupId: false,
                                            },
                                          ],
                                          exclusion: [
                                            {
                                              name: false,
                                              description: false,
                                              exclusionId: false,
                                            },
                                          ],
                                        },
                                      ];
                                      setAddPoliciesdata(data);
                                      setAddPoliciesdataWarn(dataWarn);
                                      gradedAddData(featuresListdata);
                                      exclusionsgradedAddData(
                                        exclusionsListdata
                                      );
                                      filterDropDownData();
                                      setExpanded([
                                        ...expanded.map(() => {
                                          return { expanded: false };
                                        }),
                                        { expanded: true },
                                      ]);
                                      setlivesSelectedGrade((prev) => [
                                        ...prev,
                                        { selectedGrade: 0 },
                                      ]);
                                    }}
                                  >
                                    Add Grade
                                  </Link>

                                  <p className="lives">
                                    {/* Total Lives - <span> 300</span> */}
                                  </p>
                                </div>
                              </div>
                              <Box className="employeeDetail">
                                {addPoliciesdata?.benefitsDetailsGrade &&
                                  addPoliciesdata?.benefitsDetailsGrade.map(
                                    (data: any, index: any) => (
                                      <Accordion
                                        className="Accordion_section gradedSection gradepadding"
                                        onChange={() => {
                                          let newExpanded: Array<{
                                            expanded: boolean;
                                          }> = [...expanded];
                                          if (newExpanded[index]) {
                                            newExpanded[index].expanded =
                                              !expanded[index]?.expanded;
                                          }
                                          setExpanded(newExpanded);
                                        }}
                                        expanded={expanded[index]?.expanded}
                                      >
                                        <Grid container spacing={0}>
                                          <Grid xs={4} className="px-0">
                                            <AccordionSummary
                                              aria-controls="panel1a-content"
                                              id="panel1a-header"
                                              style={{
                                                display: "inline-block",
                                                width: "110px",
                                              }}
                                            >
                                              <Typography
                                                className="title"
                                                style={{ width: "150px" }}
                                              >
                                                <h5>Grade {index + 1} :-</h5>
                                              </Typography>
                                            </AccordionSummary>
                                          </Grid>
                                          <Grid
                                            xs={8}
                                            style={{
                                              alignSelf: "Center",
                                              display: "flex",
                                              justifyContent: "end",
                                            }}
                                          >
                                            <h4>Grade {index + 1} </h4>
                                            {index === 0 ? null : (
                                              <>
                                                <div
                                                  className="text-right"
                                                  style={{
                                                    alignSelf: "center",
                                                  }}
                                                >
                                                  {!hide && (
                                                    <Link
                                                      className="redBtn delete ml-3"
                                                      underline="none"
                                                      onClick={() => {
                                                        let data = {
                                                          ...addPoliciesdata,
                                                        };
                                                        let dataExpand = [
                                                          ...expanded,
                                                        ];
                                                        data.benefitsDetailsGrade.splice(
                                                          index,
                                                          1
                                                        );
                                                        dataExpand.splice(
                                                          index,
                                                          1
                                                        );
                                                        // data.benefitsDetailsGrade = data.benefitsDetailsGrade.filter((_,i)=>{return  i !== index});
                                                        setAddPoliciesdata(
                                                          data
                                                        );
                                                        setExpanded(dataExpand);
                                                      }}
                                                    >
                                                      Remove
                                                    </Link>
                                                  )}
                                                </div>
                                              </>
                                            )}
                                            <AccordionSummary
                                              expandIcon={<ExpandMoreIcon />}
                                              aria-controls="panel1a-content"
                                              id="panel1a-header"
                                            >
                                              <Typography></Typography>
                                            </AccordionSummary>
                                          </Grid>
                                        </Grid>

                                        <AccordionDetails
                                          style={{ padding: "0" }}
                                        >
                                          <Grid container spacing={0}>
                                            <Grid xs={12} className="pb-3">
                                              <Grid container spacing={3}>
                                                <Grid
                                                  xs={3}
                                                  style={{
                                                    alignSelf: "center",
                                                  }}
                                                >
                                                  <p>
                                                    {" "}
                                                    {addPoliciesdata
                                                      ?.productType?.name ===
                                                      "Group Super TopUp"
                                                      ? "Select Super Top-Up Sum Insured"
                                                      : addPoliciesdata
                                                        ?.productType
                                                        ?.name ===
                                                        "Group Term"
                                                        ? "Sum Assured"
                                                        : "Sum Insured"}
                                                  </p>
                                                </Grid>

                                                <Grid
                                                  xs={12}
                                                  style={{ width: "50%" }}
                                                >
                                                  <RKTextField
                                                    disabled={hide}
                                                    class_name="inputField"
                                                    title={
                                                      addPoliciesdata
                                                        ?.productType?.name ===
                                                        "Group Term"
                                                        ? "Sum Assured"
                                                        : "Sum Insured"
                                                    }
                                                    value={formatCurrencyAccToUser(
                                                      Number(data?.sumInsured),
                                                      getCurrencyCodeRegex(
                                                        userState
                                                          .localInformation
                                                          .currency
                                                      ),
                                                      userState.localInformation
                                                        .countryCurrencyCode,
                                                      0
                                                    )}
                                                    attrName={[
                                                      "addPoliciesdata",
                                                      "benefitsDetailsGrade",
                                                      "sumInsured",
                                                      "input_fields",
                                                      index,
                                                    ]}
                                                    value_update={
                                                      updateMasterState
                                                    }
                                                    warn_status={
                                                      addPoliciesdataWarn
                                                        .benefitsDetailsGrade[
                                                        index
                                                      ]?.sumInsured
                                                    }
                                                    // error_messg={
                                                    //   addPoliciesdataWarn
                                                    //     .benefitsDetailsGrade[
                                                    //     index
                                                    //   ]?.sumInsured
                                                    //     ? ""
                                                    //     : "Enter Sum Insured amounts"
                                                    // }
                                                    // required
                                                    // error_messg={
                                                    //   data?.sumInsured
                                                    //     ? "Sum insured value cannot be less than or equal to previous grade."
                                                    //     : "Enter Sum Insured amount"
                                                    // }
                                                    error_messg={
                                                      index === 0
                                                        ? ""
                                                        : data?.sumInsured
                                                          ? "Sum insured value cannot be less than or equal to previous grade."
                                                          : "Enter Sum Insured amount"
                                                    }
                                                    required
                                                  />
                                                </Grid>
                                                {addPoliciesdata?.productType
                                                  ?.name ===
                                                  "Group Super TopUp" ? (
                                                  <Grid
                                                    xs={6}
                                                    className="super_topup"
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <h6 className="mb-0">
                                                      {" "}
                                                      Deductible
                                                    </h6>
                                                    <div
                                                      className="ml-3"
                                                      style={{ width: "300px" }}
                                                    >
                                                      <RKTextField
                                                        class_name="inputField"
                                                        disabled={hide}
                                                        title="Enter value for Deductible"
                                                        value={
                                                          data?.deductible > 0
                                                            ? data?.deductible
                                                            : 0
                                                        }
                                                        attrName={[
                                                          "addPoliciesdata",
                                                          "benefitsDetailsGrade",
                                                          "deductible",
                                                          "input_fields",
                                                          index,
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        warn_status={
                                                          addPoliciesdataWarn
                                                            ?.benefitsDetailsGrade[
                                                            index
                                                          ]?.deductible
                                                        }
                                                        error_messg="Enter deductible amount"
                                                        required
                                                      />
                                                    </div>
                                                  </Grid>
                                                ) : (
                                                  ""
                                                )}

                                                <Grid xs={12} width={"100%"}>
                                                  {addPoliciesdata.productType
                                                    .name === "Group Term" && (
                                                      <Grid xs={12}>
                                                        {addPoliciesdata.is_rider &&
                                                          addPoliciesdata.benefits_detail_graded[
                                                            index
                                                          ]?.riders?.map(
                                                            (
                                                              riders: any,
                                                              RiderIndex: number
                                                            ) =>
                                                              riders.label && (
                                                                <Grid
                                                                  container
                                                                  spacing={3}
                                                                >
                                                                  <Grid
                                                                    xs
                                                                    lg={4}
                                                                    className="switchFields"
                                                                    display={
                                                                      "flex"
                                                                    }
                                                                    alignItems="center"
                                                                    sx={{
                                                                      height:
                                                                        "70px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "14px",
                                                                        marginLeft:
                                                                          "12px",
                                                                        fontWeight: 600,
                                                                      }}
                                                                    >
                                                                      {
                                                                        riders?.label
                                                                      }
                                                                    </span>
                                                                    <input
                                                                      type="checkbox"
                                                                      className="switch ml-3"
                                                                      checked={
                                                                        riders.enable
                                                                      }
                                                                      disabled={
                                                                        hide
                                                                      }
                                                                      onClick={(
                                                                        e
                                                                      ) => {
                                                                        toggleEnableRidersFeatureGrade(
                                                                          e,
                                                                          index,
                                                                          RiderIndex
                                                                        );
                                                                      }}
                                                                    />
                                                                  </Grid>
                                                                  {riders.enable && (
                                                                    <Grid
                                                                      xs
                                                                      lg={4}
                                                                    >
                                                                      <RKTextField
                                                                        class_name="inputField"
                                                                        title={
                                                                          "Rider SA"
                                                                        }
                                                                        value={
                                                                          riders?.amount
                                                                        }
                                                                        disabled={
                                                                          hide
                                                                        }
                                                                        attrName={[
                                                                          "addPoliciesdata",
                                                                          "benefits_detail_graded",
                                                                          "amount",
                                                                          "input_fields",
                                                                          index,
                                                                          RiderIndex,
                                                                        ]}
                                                                        value_update={
                                                                          updateMasterState
                                                                        }
                                                                        warn_status={
                                                                          addPoliciesdataWarn.benefits_detail_flat &&
                                                                          addPoliciesdataWarn
                                                                            .benefits_detail_flat[
                                                                            index
                                                                          ]
                                                                            ?.amount
                                                                        }
                                                                      />
                                                                    </Grid>
                                                                  )}
                                                                </Grid>
                                                              )
                                                          )}
                                                      </Grid>
                                                    )}
                                                </Grid>

                                                <Grid xs={12} width={"100%"}>
                                                  <div className="sectionsHeading">
                                                    <h6>Features</h6>

                                                    <div className="actionBtns ">
                                                      {!hide && (
                                                        <Link
                                                          className="rounded_add_icons mr-3"
                                                          sx={{
                                                            textDecoration:
                                                              "none",
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() => {
                                                            setShowLoader(true);
                                                            setTimeout(() => {
                                                              setAddFeature(
                                                                true
                                                              );
                                                            }, 300);
                                                            setAddFeatureExclusionIndex(
                                                              index
                                                            );
                                                            localStorage.setItem(
                                                              "selectedAddPolicy",
                                                              JSON.stringify(
                                                                featuresListdataGraded[
                                                                index
                                                                ]
                                                              )
                                                            );
                                                          }}
                                                        >
                                                          Add/Edit
                                                        </Link>
                                                      )}
                                                    </div>
                                                  </div>
                                                </Grid>

                                                <Box className="featuresDetail px-0">
                                                  <Grid container spacing={3}>
                                                    <Grid xs={12}>
                                                      <div className="features_list">
                                                        <ul>
                                                          {addPoliciesdata?.benefitsDetailsGrade[
                                                            index
                                                          ].features?.map(
                                                            (
                                                              data: any,
                                                              indexx: any
                                                            ) => (
                                                              <>
                                                                {addPoliciesdataWarn
                                                                  ?.benefitsDetailsGrade[
                                                                  index
                                                                ]?.features[
                                                                  indexx
                                                                ]?.name ===
                                                                  true ? (
                                                                  <span
                                                                    className="inputField"
                                                                    style={{
                                                                      color:
                                                                        "#ec3f3f",
                                                                      fontSize:
                                                                        "11px",
                                                                      position:
                                                                        "relative",
                                                                      right:
                                                                        "0",
                                                                      bottom:
                                                                        "-20px",
                                                                    }}
                                                                  >
                                                                    Please
                                                                    Update
                                                                    features
                                                                  </span>
                                                                ) : (
                                                                  ""
                                                                )}
                                                                {addPoliciesdata
                                                                  .benefitsDetailsGrade[
                                                                  index
                                                                ].features[
                                                                  indexx
                                                                ].value ? (
                                                                  <li>
                                                                    {data.name}
                                                                    <span>
                                                                      {
                                                                        data.value
                                                                      }{" "}
                                                                      {data.unit ===
                                                                        "Percentage"
                                                                        ? "%"
                                                                        : data.unit ===
                                                                          "Currency"
                                                                          ? ""
                                                                          : data.unit}
                                                                    </span>
                                                                  </li>
                                                                ) : null}
                                                              </>
                                                            )
                                                          )}
                                                        </ul>
                                                      </div>
                                                    </Grid>
                                                  </Grid>
                                                </Box>

                                                <Grid xs={12} width={"100%"}>
                                                  <div className="sectionsHeading">
                                                    <h6>Exclusion</h6>
                                                    <div className="actionBtns ">
                                                      {!hide && (
                                                        <Link
                                                          className="rounded_add_icons mr-3"
                                                          sx={{
                                                            textDecoration:
                                                              "none",
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() => {
                                                            setShowLoader(true);
                                                            setTimeout(() => {
                                                              setAddExclusion(
                                                                true
                                                              );
                                                            }, 300);
                                                            let updated_data: any =
                                                              [];
                                                            updated_data = [
                                                              ...Array(
                                                                addPoliciesdata
                                                                  .benefitsDetailsGrade
                                                                  .length + 1
                                                              ),
                                                            ].map(
                                                              () =>
                                                                exclusionsListdata
                                                            );

                                                            localStorage.setItem(
                                                              "addPolicy",
                                                              JSON.stringify(
                                                                updated_data
                                                              )
                                                            );

                                                            setexclusionsListdataGradedCopy(
                                                              updated_data
                                                            );

                                                            localStorage.setItem(
                                                              "selectedAddPolicy",
                                                              JSON.stringify(
                                                                exclusionsListdataGraded[
                                                                index
                                                                ]
                                                              )
                                                            );

                                                            setAddFeatureExclusionIndex(
                                                              index
                                                            );
                                                          }}
                                                        >
                                                          Add/Edit
                                                        </Link>
                                                      )}
                                                    </div>
                                                  </div>
                                                </Grid>
                                                <Box className="exclusionDetail px-0">
                                                  <Grid container spacing={3}>
                                                    <Grid xs={12}>
                                                      <div className="features_list">
                                                        <ul>
                                                          {addPoliciesdata?.benefitsDetailsGrade[
                                                            index
                                                          ].exclusion?.map(
                                                            (
                                                              data: any,
                                                              indexx: any
                                                            ) => (
                                                              <>
                                                                {addPoliciesdataWarn
                                                                  ?.benefitsDetailsGrade[
                                                                  index
                                                                ]?.exclusion[
                                                                  indexx
                                                                ]?.name ===
                                                                  true ? (
                                                                  <span
                                                                    className="inputField"
                                                                    style={{
                                                                      color:
                                                                        "#ec3f3f",
                                                                      fontSize:
                                                                        "11px",
                                                                      position:
                                                                        "relative",
                                                                      right:
                                                                        "0",
                                                                      bottom:
                                                                        "-20px",
                                                                    }}
                                                                  >
                                                                    Please
                                                                    Update
                                                                    features
                                                                  </span>
                                                                ) : (
                                                                  ""
                                                                )}

                                                                {data.name ? (
                                                                  <li>
                                                                    {data.name}
                                                                    <span>
                                                                      {
                                                                        data.description
                                                                      }
                                                                    </span>
                                                                  </li>
                                                                ) : (
                                                                  ""
                                                                )}
                                                              </>
                                                            )
                                                          )}
                                                        </ul>
                                                      </div>
                                                    </Grid>
                                                  </Grid>
                                                </Box>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </AccordionDetails>
                                      </Accordion>
                                    )
                                  )}
                              </Box>
                            </Box>
                          ) : null}
                          {addFeature ? (
                            <SelectFeature
                              setShowLoader={setShowLoader}
                              open_status={addFeature}
                              attrName={setAddFeature}
                              value_update={updateMasterState}
                              data_list={
                                addPoliciesdata?.planType?.name === "FLAT"
                                  ? featuresListdata
                                  : featuresListdataGraded[
                                  AddFeatureexclusionIndex
                                  ]
                              }
                              component_name={addPoliciesdata?.planType.name}
                              index_value={AddFeatureexclusionIndex}
                              leng={
                                addPoliciesdata?.benefitsDetailsGrade.length
                              }
                              productType={addPoliciesdata.productType.name}
                            />
                          ) : null}
                          {Addexclusion ? (
                            <Exclusion
                              open_status={Addexclusion}
                              attrName={setAddExclusion}
                              value_update={updateMasterState}
                              data_list={
                                addPoliciesdata?.planType?.name === "FLAT"
                                  ? exclusionsListdata
                                  : exclusionsListdataGraded[
                                  AddFeatureexclusionIndex
                                  ]
                              }
                              component_name={addPoliciesdata?.planType.name}
                              index_value={AddFeatureexclusionIndex}
                              productType={addPoliciesdata.productType.name}
                              setShowLoader={setShowLoader}
                            />
                          ) : null}

                          <SelectvoluntryPlans
                            open_status={selectvoluntryPlans}
                            attrName={setSelectVoluntryPlans}
                            value_update={updateMasterState}
                          />
                        </Grid>
                      </Grid>
                      {/* Plan Settings */}
                      <Grid container spacing={3} className="px-0 ">
                        <Grid xs={12}>
                          <Box
                            className="detailSection mb-0"
                            onMouseEnter={() => {
                              SetSectionStatus("PlansSettings");
                            }}
                            ref={PlansSettings}
                          >
                            <div className="sectionTitle">
                              <h4>Plans Settings</h4>
                            </div>
                            <Grid container spacing={3}>
                              <Grid
                                xs={4}
                                className="switchFields"
                                display={"flex"}
                                alignItems="center"
                                sx={{ height: "70px" }}
                              >
                                <span>Nomination</span>
                                {addPoliciesdata?.policy?.nomination ===
                                  true ? (
                                  <input
                                    disabled={hide}
                                    type="checkbox"
                                    checked
                                    className="switch ml-3"
                                    onClick={(e) => {
                                      toggleShowNominee(e);
                                    }}
                                  />
                                ) : (
                                  <input
                                    disabled={hide}
                                    type="checkbox"
                                    className="switch ml-3"
                                    onClick={(e) => {
                                      toggleShowNominee(e);
                                    }}
                                  />
                                )}

                                {addPoliciesdata?.policy?.nomination ===
                                  true ? (
                                  <div
                                    className="ml-2"
                                    style={{ width: "300px" }}
                                  >
                                    <SelectDropdown
                                      class_name="inputField"
                                      disabled={hide}
                                      title="Maximum No. of Nominee"
                                      value={
                                        addPoliciesdata?.policy?.maxNomination
                                      }
                                      attrName={[
                                        "addPoliciesdata",
                                        "policy",
                                        "maxNomination",
                                        "drop_down_common",
                                      ]}
                                      value_update={updateMasterState}
                                      dropdown_data={one_to_ten_data}
                                      warn_status={
                                        addPoliciesdataWarn.policy.maxNomination
                                      }
                                    />
                                  </div>
                                ) : null}
                              </Grid>
                              <Grid
                                xs={4}
                                className="switchFields"
                                display={"flex"}
                                alignItems="center"
                                sx={{ height: "70px" }}
                              >
                                <span>Health Card</span>
                                {addPoliciesdata?.policy?.healthCard ===
                                  true ? (
                                  <input
                                    disabled={hide}
                                    type="checkbox"
                                    checked
                                    className="switch ml-3"
                                    onClick={(e) => {
                                      toggleShowhealth(e);
                                    }}
                                  />
                                ) : (
                                  <input
                                    disabled={hide}
                                    type="checkbox"
                                    className="switch ml-3"
                                    onClick={(e) => {
                                      toggleShowhealth(e);
                                    }}
                                  />
                                )}

                                {addPoliciesdata?.policy?.healthCard ===
                                  true ? (
                                  <div
                                    className="ml-2"
                                    style={{ width: "300px" }}
                                  >
                                    <SelectDropdown
                                      class_name="inputField"
                                      disabled={hide}
                                      title="Card Type"
                                      value={addPoliciesdata?.policy?.cardType}
                                      attrName={[
                                        "addPoliciesdata",
                                        "policy",
                                        "cardType",
                                        "drop_down_common",
                                      ]}
                                      value_update={updateMasterState}
                                      dropdown_data={cardType_data}
                                      warn_status={
                                        addPoliciesdataWarn.policy.cardType
                                      }
                                    />
                                  </div>
                                ) : null}
                              </Grid>
                              <Grid xs={4}>
                                <SelectDropdown
                                  class_name="inputField"
                                  title="Health Card Issued"
                                  value={
                                    addPoliciesdata.policy.healthCardIssued
                                  }
                                  attrName={[
                                    "addPoliciesdata",
                                    "policy",
                                    "healthCardIssued",
                                    "drop_down_common",
                                  ]}
                                  value_update={updateMasterState}
                                  dropdown_data={healthcardIssued_data}
                                  warn_status={
                                    addPoliciesdataWarn.policy.healthCardIssued
                                  }
                                  disabled={
                                    (addPoliciesdata.policy.cardType &&
                                      addPoliciesdata.policy.healthCard) ||
                                      !hide
                                      ? false
                                      : true
                                  }
                                />
                              </Grid>
                              <Grid xs={4}>
                                <SelectDropdown
                                  class_name="inputField"
                                  title="Pro- Rata Premium Pricing By"
                                  value={addPoliciesdata.policy.proreta_by}
                                  attrName={[
                                    "addPoliciesdata",
                                    "policy",
                                    "proreta_by",
                                    "drop_down_common",
                                  ]}
                                  value_update={updateMasterState}
                                  dropdown_data={prorata}
                                  warn_status={false}
                                  required
                                />
                                <Grid xs={12} className="px-0">
                                  <>
                                    <Grid container spacing={3}>
                                      {/* <Grid sm={12} md={7} lg={4}>
                                      <SelectDropdown
                                        class_name="inputField"
                                        title="Health Card Issued"
                                        value={
                                          addPoliciesdata.policy.healthCardIssued
                                        }
                                        attrName={[
                                          "addPoliciesdata",
                                          "policy",
                                          "healthCardIssued",
                                          "drop_down_common",
                                        ]}
                                        value_update={updateMasterState}
                                        dropdown_data={healthcardIssued_data}
                                        warn_status={
                                          addPoliciesdataWarn.policy
                                            .healthCardIssued
                                        }
                                        disabled={
                                          (addPoliciesdata.policy.cardType &&
                                            addPoliciesdata.policy.healthCard) ||
                                            !hide
                                            ? false
                                            : true
                                        }
                                      />
                                    </Grid> */}

                                      <Grid xs={2}></Grid>
                                      {/* <Grid
                                    sm={12}
                                    md={8}
                                    lg={6}
                                    className="switchFields"
                                    display={"flex"}
                                    alignItems="center"
                                    sx={{ height: "70px" }}
                                  >
                                    <span>Corporate Buffer</span>
                                    {addPoliciesdata?.policy
                                      ?.isCorporateBuffer === true ? (
                                      <input
                                        disabled={hide}
                                        type="checkbox"
                                        checked
                                        className="switch ml-3"
                                        onClick={(e) => {
                                          toggleShowcorporate(e);
                                        }}
                                      />
                                    ) : (
                                      <input
                                        disabled={hide}
                                        type="checkbox"
                                        className="switch ml-3"
                                        onClick={(e) => {
                                          toggleShowcorporate(e);
                                        }}
                                      />
                                    )}

                                    {addPoliciesdata?.policy
                                      ?.isCorporateBuffer === true ? (
                                      <div
                                        className="ml-2"
                                        style={{ width: "350px" }}
                                      >
                                        <RKTextField
                                          disabled={hide}
                                          class_name="inputField"
                                          title="Corporate Buffer Value"
                                          value={
                                            addPoliciesdata?.policy
                                              ?.corporateBuffer
                                          }
                                          attrName={[
                                            "addPoliciesdata",
                                            "policy",
                                            "corporateBuffer",
                                            "drop_down_common",
                                          ]}
                                          value_update={updateMasterState}
                                          warn_status={
                                            addPoliciesdataWarn.policy
                                              .corporateBuffer
                                          }
                                          validation_type="numeric"
                                          required
                                          error_messg="Please enter corporate buffer value"
                                        />
                                      </div>
                                    ) : null}
                                  </Grid> */}
                                    </Grid>
                                  </>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                      {/* INSURER Details */}
                      <Grid container spacing={3} className="px-0">
                        <Grid xs={12}>
                          <Box
                            className="detailSection"
                            onMouseEnter={() => {
                              SetSectionStatus("InsurerDetails");
                            }}
                            ref={InsurerDetails}
                          >
                            {" "}
                            <div className="sectionTitle">
                              <h4>Insurer Details</h4>
                              <div className="actionBtns">
                                {!hide && (
                                  <Link
                                    className="blueBtn addfile"
                                    sx={{
                                      textDecoration: "none",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      let data: quotesDTONew = {
                                        ...addPoliciesdata,
                                      };
                                      let dataWarn: any = {
                                        ...addPoliciesdataWarn,
                                      };
                                      data.insurerDetails = [
                                        ...data.insurerDetails,
                                        {
                                          id: "",
                                          name: "",
                                          office: "",
                                          email: "",
                                        },
                                      ];
                                      dataWarn.insurerDetails = [
                                        ...data.insurerDetails,
                                        {
                                          name: false,
                                          office: false,
                                          email: false,
                                        },
                                      ];
                                      setAddPoliciesdataWarn(dataWarn);
                                      setAddPoliciesdata(data);
                                    }}
                                  >
                                    Add Insurer
                                  </Link>
                                )}
                              </div>
                            </div>
                            <>
                              {addPoliciesdata?.insurerDetails?.map(
                                (data: any, index: any) => (
                                  <Grid container spacing={3}>
                                    <Grid xs={3}>
                                      <SearchDropdown
                                        class_name="inputField"
                                        title="Select Insurer"
                                        disabled={hide}
                                        value={
                                          addPoliciesdata?.insurerDetails[index]
                                            ?.name
                                        }
                                        attrName={[
                                          "addPoliciesdata",
                                          "insurerDetails",
                                          "name",
                                          "dropdown",
                                          index,
                                        ]}
                                        value_update={updateMasterState}
                                        data={insurerList}
                                        warn_status={
                                          addPoliciesdataWarn.insurerDetails[
                                            index
                                          ]?.name
                                        }
                                        error_message="Please select insurer"
                                      />
                                    </Grid>
                                    <Grid xs={3}>
                                      <SelectDropdown
                                        class_name="inputField"
                                        title="Location"
                                        disabled={hide}
                                        value={
                                          addPoliciesdata?.insurerDetails[index]
                                            ?.office
                                        }
                                        attrName={[
                                          "addPoliciesdata",
                                          "insurerDetails",
                                          "office",
                                          "dropdown",
                                          index,
                                        ]}
                                        value_update={updateMasterState}
                                        dropdown_data={
                                          dynamicLocationOffice[index]
                                        }
                                        warn_status={
                                          addPoliciesdataWarn.insurerDetails[
                                            index
                                          ]?.office
                                        }
                                        error_messg="Please select office address"
                                      />
                                    </Grid>
                                    <Grid xs={3}>
                                      <RKTextField
                                        class_name="inputField"
                                        title="Email"
                                        disabled={hide}
                                        value={
                                          addPoliciesdata?.insurerDetails[index]
                                            ?.email
                                        }
                                        attrName={[
                                          "addPoliciesdata",
                                          "insurerDetails",
                                          "email",
                                          "dropdown",
                                          index,
                                        ]}
                                        value_update={updateMasterState}
                                        warn_status={
                                          addPoliciesdataWarn.insurerDetails[
                                            index
                                          ]?.email
                                        }
                                        error_messg="Please select email"
                                      />
                                    </Grid>
                                    <Grid
                                      xs={2}
                                      style={{
                                        alignSelf: "Center",
                                        display: "flex",
                                        justifyContent: "end",
                                      }}
                                    >
                                      {index === 0 ? null : (
                                        <div
                                          className="text-right"
                                          style={{ alignSelf: "center" }}
                                        >
                                          <Link
                                            className="redBtn delete ml-3"
                                            underline="none"
                                            onClick={() => {
                                              let data = { ...addPoliciesdata };
                                              let dataWarn = {
                                                ...addPoliciesdataWarn,
                                              };

                                              let dataLocation = [
                                                ...dynamicLocationOffice,
                                              ];

                                              data.insurerDetails.splice(
                                                index,
                                                1
                                              );
                                              dataWarn.insurerDetails.splice(
                                                index,
                                                1
                                              );

                                              dataLocation.splice(index, 1);
                                              setdynamicLocationOffice(
                                                dataLocation
                                              );
                                              setAddPoliciesdataWarn(dataWarn);
                                              setAddPoliciesdata(data);
                                            }}
                                          >
                                            Remove
                                          </Link>
                                        </div>
                                      )}
                                    </Grid>
                                  </Grid>
                                )
                              )}
                            </>
                          </Box>
                        </Grid>
                      </Grid>

                      <Box
                        className={`detailSection ${!addPoliciesdata.is_rider ? "lastsection" : ""
                          }`}
                        onMouseEnter={() => {
                          SetSectionStatus("RelationshipLives");
                        }}
                        ref={RelationshipLives}
                      >
                        {" "}
                        <div className="sectionTitle">
                          <h4>Relationship-Lives Information</h4>
                          <div className="total_lives">
                            <p>
                              {indivisualGradeLives ? indivisualGradeLives : 0}{" "}
                              <span>Total Lives</span>
                            </p>
                          </div>
                        </div>
                        <div className="policy_lives">
                          <Grid container spacing={3}>
                            {addPoliciesdata?.planType?.name === "FLAT" ? (
                              <>
                                <Grid xs={12}>
                                  <div className="table">
                                    <div className="livesinformation-table">
                                      <ul className="heading">
                                        <li>Age Band (in years)</li>
                                        <li>
                                          Premium Pricing for Addition/Deletion
                                          per Member
                                        </li>
                                        {addPoliciesdata?.planType?.coverageType?.map(
                                          (data: any) => (
                                            <li>{titleCase(data.name)}</li>
                                          )
                                        )}

                                        <li>Total Lives Count/Age Band</li>
                                      </ul>
                                      {modifiedArrayAgeBand?.map(
                                        (data: any, ageindex: any) => (
                                          <ul className="subheading">
                                            <li>
                                              {data.min}-{data.max}
                                            </li>
                                            {data.coverageTypeData?.map(
                                              (dataArr: any, index: number) => (
                                                <li>
                                                  <RKTextField
                                                    validation_type="numeric"
                                                    class_name="inputField"
                                                    title={
                                                      index === 0
                                                        ? "Amount"
                                                        : "Lives"
                                                    }
                                                    value={
                                                      dataArr.name === "amount"
                                                        ? formatCurrency(
                                                          Number(
                                                            dataArr.value
                                                          )
                                                        )
                                                        : dataArr.value
                                                    }
                                                    attrName={[
                                                      ageindex,
                                                      index,
                                                      "flatData",
                                                    ]}
                                                    value_update={
                                                      dataArr.name ===
                                                        "total Live"
                                                        ? () => { }
                                                        : updateMasterStateLives
                                                    }
                                                    warn_status={false}
                                                    charcterlimit={
                                                      dataArr.name === "amount"
                                                        ? 12
                                                        : dataArr.name ===
                                                          "total Live"
                                                          ? 10
                                                          : 5
                                                    }
                                                    disabled={
                                                      hide ||
                                                        dataArr.name ===
                                                        "total Live" ||
                                                        (dataArr.name ===
                                                          "KIDS" &&
                                                          !(
                                                            Number(
                                                              extractNumbersFromString(
                                                                data.min
                                                              )
                                                            ) <=
                                                            Number(
                                                              extractNumbersFromString(
                                                                addPoliciesdata.maxChildAge ??
                                                                "0"
                                                              )
                                                            ) &&
                                                            Number(
                                                              extractNumbersFromString(
                                                                data.max
                                                              )
                                                            ) >=
                                                            Number(
                                                              extractNumbersFromString(
                                                                addPoliciesdata.maxChildAge ??
                                                                "0"
                                                              )
                                                            )
                                                          ))
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </Grid>
                              </>
                            ) : addPoliciesdata.planType.name === "GRADED" ? (
                              <Grid sm={12} md={12} lg={12}>
                                <Grid container spacing={3}>
                                  {addPoliciesdata?.livesInfoGrade?.length >
                                    0 && (
                                      <>
                                        <Grid xs={8}>
                                          <ul className="coverageAmt mb-4">
                                            {addPoliciesdata.livesInfoGrade &&
                                              addPoliciesdata.livesInfoGrade.map(
                                                (data: any, mainIndex: any) => (
                                                  <li key={mainIndex}>
                                                    <div className="radioBox ">
                                                      <input
                                                        type="radio"
                                                        name="coveragesd"
                                                        onClick={() =>
                                                          setliveGraded(mainIndex)
                                                        }
                                                        checked={
                                                          liveGraded === mainIndex
                                                            ? true
                                                            : false
                                                        }
                                                      />
                                                      <label>
                                                        Grade {mainIndex + 1}
                                                      </label>
                                                    </div>
                                                  </li>
                                                )
                                              )}
                                          </ul>
                                        </Grid>

                                        <Grid xs={12}>
                                          <div className="table">
                                            <div className="livesinformation-table">
                                              <ul className="heading">
                                                <li>Age Band (in years)</li>
                                                <li>
                                                  Premium Pricing for
                                                  Addition/Deletion per Members
                                                </li>
                                                {addPoliciesdata?.planType?.coverageType?.map(
                                                  (data: any) => (
                                                    <li>
                                                      {titleCase(data.name)}
                                                    </li>
                                                  )
                                                )}

                                                <li>
                                                  Total Lives Count/Age Band
                                                </li>
                                              </ul>

                                              {modifiedArrayAgeBand[liveGraded]
                                                ?.data?.length > 0 &&
                                                modifiedArrayAgeBand[
                                                  liveGraded
                                                ]?.data?.map(
                                                  (data: any, ageindex: any) => (
                                                    <ul
                                                      key={ageindex}
                                                      className="subheading"
                                                    >
                                                      <li>
                                                        {data.min}-{data.max}
                                                      </li>
                                                      {data?.coverageTypeData?.map(
                                                        (
                                                          dataArr: any,
                                                          index: number
                                                        ) => (
                                                          <li>
                                                            <RKTextField
                                                              validation_type="numeric"
                                                              class_name="inputField"
                                                              title={
                                                                // data
                                                                //   ?.coverageTypeData
                                                                //   .length -
                                                                //   2 ===
                                                                index === 0
                                                                  ? "Amounts"
                                                                  : "Lives"
                                                              }
                                                              // value={
                                                              //   index === 0
                                                              //     ? formatCurrencyAccToUser(
                                                              //         dataArr?.value,
                                                              //         getCurrencyCodeRegex(
                                                              //           userState
                                                              //             .localInformation
                                                              //             .currency
                                                              //         ),
                                                              //         userState
                                                              //           .localInformation
                                                              //           .countryCurrencyCode,
                                                              //         0
                                                              //       )
                                                              //     : dataArr?.value
                                                              // }
                                                              value={
                                                                index === 0
                                                                  ? formatCurrencyAccToUser(
                                                                    dataArr?.value
                                                                      ?.toString()
                                                                      .replace(
                                                                        /^0+(?!$)/,
                                                                        ""
                                                                      ), // Remove leading zeros
                                                                    getCurrencyCodeRegex(
                                                                      userState
                                                                        .localInformation
                                                                        .currency
                                                                    ),
                                                                    userState
                                                                      .localInformation
                                                                      .countryCurrencyCode,
                                                                    0
                                                                  )
                                                                  : dataArr?.value
                                                                    ?.toString()
                                                                    .replace(
                                                                      /^0+(?!$)/,
                                                                      ""
                                                                    ) // Remove leading zeros
                                                              }
                                                              attrName={[
                                                                ageindex,
                                                                index,
                                                                "gradeData",
                                                              ]}
                                                              value_update={
                                                                dataArr?.name ===
                                                                  "total Live"
                                                                  ? () => { }
                                                                  : updateMasterStateLives
                                                              }
                                                              warn_status={false}
                                                              charcterlimit={
                                                                dataArr?.name ===
                                                                  "amount"
                                                                  ? 12
                                                                  : dataArr?.name ===
                                                                    "total Live"
                                                                    ? 10
                                                                    : 5
                                                              }
                                                              disabled={
                                                                hide ||
                                                                  dataArr?.name ===
                                                                  "total Live" ||
                                                                  (dataArr.name ===
                                                                    "KIDS" &&
                                                                    !(
                                                                      Number(
                                                                        extractNumbersFromString(
                                                                          data.min
                                                                        )
                                                                      ) <=
                                                                      Number(
                                                                        extractNumbersFromString(
                                                                          addPoliciesdata.maxChildAge ??
                                                                          "0"
                                                                        )
                                                                      ) &&
                                                                      Number(
                                                                        extractNumbersFromString(
                                                                          data.max
                                                                        )
                                                                      ) >=
                                                                      Number(
                                                                        extractNumbersFromString(
                                                                          addPoliciesdata.maxChildAge ??
                                                                          "0"
                                                                        )
                                                                      )
                                                                    ))
                                                                  ? true
                                                                  : false
                                                              }
                                                            />
                                                          </li>
                                                        )
                                                      )}
                                                    </ul>
                                                  )
                                                )}
                                            </div>
                                          </div>
                                        </Grid>
                                      </>
                                    )}
                                </Grid>
                              </Grid>
                            ) : (
                              ""
                            )}
                          </Grid>
                        </div>
                      </Box>

                      {/* prorata rates for riders in group Term */}
                      {addPoliciesdata.is_rider && (
                        <Grid container spacing={3} className="px-0">
                          <Grid xs={12}>
                            <Box
                              className="detailSection lastsection"
                              ref={RiderDetail}
                              onMouseEnter={() => {
                                SetSectionStatus("RiderDetail");
                              }}
                            >
                              <div className="sectionTitle">
                                <h4>Rider Details</h4>
                              </div>

                              {addPoliciesdata?.planType?.name ===
                                PolicyCoverageType.FLAT &&
                                addPoliciesdata.rider_premium_flat.map(
                                  (
                                    rider_premium_flat: any,
                                    riderIndex: number
                                  ) => (
                                    <>
                                      <Grid xs={12}>
                                        <h5 className="subHeading">
                                          {rider_premium_flat.rider_name}
                                        </h5>
                                      </Grid>
                                      <Grid
                                        container
                                        spacing={3}
                                        className="ml-1 mr-1"
                                      >
                                        <Grid xs={12}>
                                          <Box className="detailSection">
                                            <div className="sectionTitle">
                                              <h4>
                                                Rider Premium Split
                                                Configuration & Rider Premium
                                                Pricing
                                              </h4>
                                            </div>

                                            <div className="policy_lives">
                                              <Grid container spacing={3}>
                                                <>
                                                  <Grid xs={12}>
                                                    <Grid xs={4}>
                                                      <Grid xs={12}>
                                                        <h5 className="subHeadingh">
                                                          Premium value
                                                        </h5>
                                                      </Grid>
                                                      <SelectDropdown
                                                        class_name="inputField"
                                                        title="Pro- Rata Premium Pricing By"
                                                        value={
                                                          rider_premium_flat.proreta_by
                                                        }
                                                        attrName={[
                                                          "addPoliciesdata",
                                                          "rider_premium_flat",
                                                          "proreta_by",
                                                          riderIndex,
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        dropdown_data={prorata}
                                                        warn_status={false}
                                                        required
                                                        disabled={hide}
                                                      />
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                      <Grid
                                                        sm={6}
                                                        md={6}
                                                        lg={3}
                                                        xl={3}
                                                      >
                                                        <Grid xs={12}>
                                                          <h5 className="subHeadingh">
                                                            Premium Split
                                                            Configuration
                                                          </h5>
                                                        </Grid>
                                                        <div className="payment_inner_section">
                                                          <RKTextField
                                                            class_name="inputField"
                                                            // type="number"
                                                            title={"Employee"}
                                                            value={
                                                              rider_premium_flat?.premium_split
                                                            }
                                                            attrName={[
                                                              "addPoliciesdata",
                                                              "rider_premium_flat",
                                                              "premium_split",
                                                              riderIndex,
                                                            ]}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            warn_status={false}
                                                            inputProps={{
                                                              endAdornment: (
                                                                <InputAdornment position="end">
                                                                  %
                                                                </InputAdornment>
                                                              ),
                                                            }}
                                                            error_messg="Enter Split amount"
                                                            disabled={hide}
                                                          />
                                                        </div>
                                                      </Grid>
                                                    </Grid>
                                                    <div className="table mt-2">
                                                      <div className="livesinformation-table">
                                                        <Grid xs={12}>
                                                          <h5 className="subHeadingh">
                                                            Premium Pricingssss
                                                          </h5>
                                                        </Grid>
                                                        <ul className="heading">
                                                          {rider_premium_flat?.livesInfoFlat?.map(
                                                            (
                                                              data: any,
                                                              ageindex: any
                                                            ) => (
                                                              <li>
                                                                {data.min}-
                                                                {data.max}
                                                              </li>
                                                            )
                                                          )}
                                                        </ul>

                                                        <ul className="subheading">
                                                          {rider_premium_flat?.livesInfoFlat?.map(
                                                            (
                                                              data: any,
                                                              ageindex: any
                                                            ) => (
                                                              <li>
                                                                <RKTextField
                                                                  validation_type="numeric"
                                                                  class_name="inputField"
                                                                  title={
                                                                    "Amount"
                                                                  }
                                                                  value={formatCurrency(
                                                                    Number(
                                                                      data
                                                                        .coverageTypeData[0]
                                                                        .value
                                                                    )
                                                                  )}
                                                                  attrName={[
                                                                    "addPoliciesdata",
                                                                    "rider_premium_flat",
                                                                    riderIndex,
                                                                    ageindex,
                                                                  ]}
                                                                  value_update={
                                                                    updateMasterState
                                                                  }
                                                                  warn_status={
                                                                    false
                                                                  }
                                                                  disabled={
                                                                    hide
                                                                  }
                                                                />
                                                              </li>
                                                            )
                                                          )}
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  </Grid>
                                                </>
                                              </Grid>
                                            </div>
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    </>
                                  )
                                )}

                              {addPoliciesdata?.planType?.name ===
                                PolicyCoverageType.GRADED &&
                                addPoliciesdata.rider_premium_graded.map(
                                  (
                                    rider_premium_flat: any,
                                    riderIndex: number
                                  ) => (
                                    <>
                                      <Grid xs={12}>
                                        <h5 className="subHeading">
                                          {rider_premium_flat.rider_name}
                                        </h5>
                                      </Grid>
                                      <Grid
                                        container
                                        spacing={3}
                                        className="ml-1 mr-1"
                                      >
                                        <Grid xs={12}>
                                          <Box className="detailSection lastsection">
                                            <div className="sectionTitle">
                                              <h4>
                                                Rider Premium Split
                                                Configuration & Rider Premium
                                                Pricing
                                              </h4>
                                            </div>

                                            <div className="policy_lives">
                                              <Grid container spacing={3}>
                                                <Grid sm={12} md={12} lg={12}>
                                                  <Grid container spacing={3}>
                                                    <Grid
                                                      sm={6}
                                                      md={6}
                                                      lg={4}
                                                      xl={4}
                                                      width={"12%"}
                                                    >
                                                      <Grid xs={12}>
                                                        <h5 className="subHeadingh">
                                                          Premium value
                                                        </h5>
                                                      </Grid>
                                                      <div className="payment_inner_section">
                                                        <SelectDropdown
                                                          class_name="inputField"
                                                          title="Pro- Rata Premium Pricing By"
                                                          value={
                                                            rider_premium_flat.proreta_by
                                                          }
                                                          attrName={[
                                                            "addPoliciesdata",
                                                            "rider_premium_graded",
                                                            "proreta_by",
                                                            riderIndex,
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          dropdown_data={
                                                            prorata
                                                          }
                                                          warn_status={false}
                                                          required
                                                          disabled={hide}
                                                        />
                                                      </div>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid container spacing={3}>
                                                    <Grid
                                                      sm={6}
                                                      md={6}
                                                      lg={3}
                                                      xl={3}
                                                    >
                                                      <Grid xs={12}>
                                                        <h5 className="subHeadingh">
                                                          Premium Split
                                                          Configuration
                                                        </h5>
                                                      </Grid>
                                                      <div className="payment_inner_section">
                                                        <RKTextField
                                                          class_name="inputField"
                                                          // type="number"
                                                          title={"Employee"}
                                                          value={
                                                            rider_premium_flat.premium_split
                                                          }
                                                          attrName={[
                                                            "addPoliciesdata",
                                                            "rider_premium_graded",
                                                            "premium_split",
                                                            riderIndex,
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          warn_status={false}
                                                          inputProps={{
                                                            endAdornment: (
                                                              <InputAdornment position="end">
                                                                %
                                                              </InputAdornment>
                                                            ),
                                                          }}
                                                          error_messg="Enter Split amount"
                                                          disabled={hide}
                                                        />
                                                      </div>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid container spacing={3}>
                                                    <>
                                                      <Grid xs={8}>
                                                        <ul
                                                          className="coverageAmt mb-4"
                                                          key={riderIndex}
                                                        >
                                                          {rider_premium_flat.livesInfoGrade &&
                                                            rider_premium_flat.livesInfoGrade.map(
                                                              (
                                                                data: any,
                                                                mainIndex: any
                                                              ) => (
                                                                <li
                                                                  key={`${riderIndex}-${mainIndex}`}
                                                                >
                                                                  <div
                                                                    className="radioBox"
                                                                    key={`${riderIndex}-${mainIndex}`}
                                                                  >
                                                                    <input
                                                                      key={`${riderIndex}-${mainIndex}`}
                                                                      type="radio"
                                                                      name={`abc${riderIndex}-${mainIndex}`}
                                                                      onClick={() => {
                                                                        setlivesSelectedGrade(
                                                                          (
                                                                            prev
                                                                          ) => {
                                                                            const updatedGrades =
                                                                              [
                                                                                ...prev,
                                                                              ];
                                                                            updatedGrades[
                                                                              riderIndex
                                                                            ] =
                                                                            {
                                                                              selectedGrade:
                                                                                mainIndex,
                                                                            };
                                                                            return updatedGrades;
                                                                          }
                                                                        );
                                                                      }}
                                                                      checked={
                                                                        livesSelectedGrade[
                                                                          riderIndex
                                                                        ]
                                                                          ?.selectedGrade ==
                                                                        mainIndex
                                                                      }
                                                                    />
                                                                    <label>
                                                                      Grade{" "}
                                                                      {mainIndex +
                                                                        1}
                                                                    </label>
                                                                  </div>
                                                                </li>
                                                              )
                                                            )}
                                                        </ul>
                                                      </Grid>

                                                      <Grid xs={12}>
                                                        <div className="table">
                                                          <div className="livesinformation-table">
                                                            <Grid xs={12}>
                                                              <h5 className="subHeadingh">
                                                                Premium Pricing
                                                              </h5>
                                                            </Grid>
                                                            <ul className="heading">
                                                              {rider_premium_flat?.livesInfoGrade[
                                                                livesSelectedGrade[
                                                                  riderIndex
                                                                ]?.selectedGrade
                                                              ]?.data?.map(
                                                                (data: any) => (
                                                                  <li>
                                                                    {data.min}-
                                                                    {data.max}
                                                                  </li>
                                                                )
                                                              )}
                                                            </ul>

                                                            <ul className="subheading">
                                                              {rider_premium_flat?.livesInfoGrade[
                                                                livesSelectedGrade[
                                                                  riderIndex
                                                                ]?.selectedGrade
                                                              ]?.data?.map(
                                                                (
                                                                  data: any,
                                                                  ageindex: any
                                                                ) => (
                                                                  <li>
                                                                    <RKTextField
                                                                      validation_type="numeric"
                                                                      class_name="inputField"
                                                                      title={
                                                                        "Amount"
                                                                      }
                                                                      value={formatCurrency(
                                                                        Number(
                                                                          data
                                                                            .coverageTypeData[0]
                                                                            .value
                                                                        )
                                                                      )}
                                                                      attrName={[
                                                                        "addPoliciesdata",
                                                                        "rider_premium_graded",
                                                                        riderIndex,
                                                                        livesSelectedGrade[
                                                                          riderIndex
                                                                        ]
                                                                          ?.selectedGrade,
                                                                        ageindex,
                                                                      ]}
                                                                      value_update={
                                                                        updateMasterState
                                                                      }
                                                                      warn_status={
                                                                        false
                                                                      }
                                                                      disabled={
                                                                        hide
                                                                      }
                                                                    />
                                                                  </li>
                                                                )
                                                              )}
                                                            </ul>
                                                          </div>
                                                        </div>
                                                      </Grid>
                                                    </>
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            </div>
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    </>
                                  )
                                )}
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                    </>
                  </Grid>
                </Grid>
              </>
            </Grid>
          </Grid>
          {/* Footer Section */}
          {!hide && (
            <Grid container spacing={3} className="footerSection">
              <Grid xs={12} className="ctaBtn">
                <Button
                  variant="outlined"
                  className="borderBtn"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  className="submitBtn"
                  disabled={disableSave}
                  onClick={() => {
                    submithandle();
                  }}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>

        <PoliciesWarningpopup
          popupStatus={warningpopup}
          setPopupStatus={setwarningpopup}
          title={`Only the values of the Premium, Sum Insured, Features and Exclusions may be altered for this quote  - ${addPoliciesdata?.quoteId}-${addPoliciesdata.productType.name}`}
        />
      </>
    </>
  );
}

export default EditQuotes;
