import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Button, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { EMPLOYER_URL_CONSTANTS } from "../../../../../APIVar/EmployerUrlConstant";
import NotesSection from "../../../../../Components/Broker/NotesSection/NotesSection";
import BulkUploadForm from "../../../../../Components/Common/BulkUploadFormSP/BulkUploadForm";
import PostQueryForm from "../../../../../Components/Common/PostQuerySP/PostQueryForm";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";
import { EMPLOYER_ALL_ROUTES } from "../../../../../Routes/employer_all_routes";
import {
  employeeDTO,
  locationDTO,
} from "../../../../../Services/DTO/Employee/EmployeeDTO";
import { EMPLOYEE_POLICY_SERVICES } from "../../../../../Services/Employee/EmployeePolicyService";
import { EMPLOYEE_SERVICES } from "../../../../../Services/Employee/EmployeeService";
import { EMPLOYER_EMPLOYEE_SERVICES } from "../../../../../Services/EmployerServices/Employee/EmployeeServices";
import { TUserData } from "../../../../../Services/Types/TUserData";
import { RootState } from "../../../../../Store/Store";
import { useAppSelector } from "../../../../../Store/hooks";
import { Get } from "../../../../../Supporting files/CallAPI";
import CheckBox from "../../../../../Supporting files/CheckBox/CheckBox";
import CurrencyTextField from "../../../../../Supporting files/Currency/CurrencyTextField";
import { useFormatCurrency } from "../../../../../Supporting files/CustomHooks";
import DatePicker from "../../../../../Supporting files/DatePicker/DatePicker";
import {
  extractNumbersFromString,
  formatCurrencyAccToUser,
  getCurrencyCodeRegex,
  get_city_list_via_name,
  get_country_list,
  get_state_list,
  validateEmail,
  validateMobileNumber,
  validatePincode,
  validateUrl,
} from "../../../../../Supporting files/HelpingFunction";
import MultipleSearchDropdown from "../../../../../Supporting files/MultipleSearchDropdown/MultipleSearchDropdown";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import SearchDropdown from "../../../../../Supporting files/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import TextAreaField from "../../../../../Supporting files/TextAreaField/TextAreaField";
import "../../../../../formLayout.scss";
import NewLoader from "../../../../../Components/NewLoader/NewLoader";

function AddNewEmployee() {
  const navigate = useNavigate();
  const [showloader, setshowloader] = useState<boolean>(true);
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const usertypeinfo = useAppSelector(
    (state) => state.userDetailsSlice.userType
  );
  const { formatCurrency } = useFormatCurrency();
  const [formData, setFormData] = useState<employeeDTO[]>([]);
  const [formDataPermanent, setFormDataPermanent] = useState<employeeDTO[]>([]);
  const userState: TUserData = useAppSelector(
    (state: RootState) => state.userDetailsSlice
  );
  const [selectedRelationShip, setselectedRelationShip] = useState<string>("");
  const [isRealtionExist, setIsRealtionExist] = useState<boolean>(false);
  const [officialMailId, setOfficialMailId] = useState<any>("");
  const [countryData, setCountryData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [personalAddress, setPersonalAddress] = useState<locationDTO>({
    street: "",
    area: "",
    country: "",
    state: "",
    city: "",
    zip: null,
    warnStreet: false,
    warnArea: false,
    warnCountry: false,
    warnState: false,
    warnCity: false,
    warnZip: false,
  });
  const [selectedPolicyFordependent, setSelectedPolicyFordependent] = useState<
    any[]
  >([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [stateData, setStateData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [cityData, setCityData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [notesData, setNotesData] = useState<
    Array<{ name: string; date: Date; description: string }>
  >([]);
  const [attachmentData, setAttachmentData] = useState<
    Array<{
      doc: string;
      description: string;
      date: Date;
      time: string;
      doc_name?: string;
    }>
  >([]);
  const [postQueryForm, setPostQueryForm] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [sectionStatus, SetSectionStatus] = useState<string>(
    "Member Type Details"
  );
  const [subsectionStatus, SetsubSectionStatus] =
    useState<any>("Member Type Info");
  const [primaryEmployeeOptions, setPrimaryEmployeeOptions] = useState<any>([]);
  const [selectedMember, setselectedMember] = useState<string>("");
  const [popedData, setpopedData] = useState<any>({});
  const [attachedClient, setattachedClient] = useState<string>("");
  const [attachedPrimaryEmployee, setAttachedPrimaryEmployee] =
    useState<string>("");
  const [uploadFile, setUploadFile] = useState(false);
  const [addClass, setAddClass] = useState<boolean>(false);
  const [locationIdData, setLocationIdData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [departmentDropDownData, setdepartmentDropDownData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [designationDropDowndata, setdesignationDropDowndata] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [salaryDropDowndata, setsalaryDropDowndata] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [salaryDropDowndataOriginal, setsalaryDropDowndataOriginal] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [selectedState, setSelectedState] = useState({});
  const [salaryBracketPath, setsalaryBracketPath] = useState<any[]>([]);
  const [salaryPath, setsalaryPath] = useState<any[]>([]);
  const [memberTypePath, setmemberTypePath] = useState<any[]>([]);
  const [selectedPrimaryEmployee, setSelectedPrimaryEmployee] = useState<{
    _id: string;
    id: string;
    label: string;
  }>({ _id: "", id: "", label: "" });
  const [primaryEmployeePath, setPrimaryEmployeePath] = useState<any[]>([]);
  const [employeeAssignedPolicies, setEmployeeAssignedPolicies] = useState<
    any[]
  >([]);
  const [selectedPolicies, setSelectedPolicies] = useState<Array<string>>([]);
  const AddressDetail = useRef(null);
  const [empDob, setEmpDob] = useState<any>();
  const [empDoj, setEmpDoj] = useState<any>();
  const [empDojSet, setEmpDojSet] = useState<any[]>([]);
  const [empLdwSet, setEmpLdwSet] = useState<any[]>([]);

  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    // get_client_list();

    let timeoutId: any;

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const delayedApiCall = () => {
      get_employee_layout();
      get_country_list((cb: any) => {
        setCountryData(cb);
      });
      changeDatalayout();
      setattachedClient(userState.id);
      get_dep_designation_salary("0");
      get_dep_designation_salary("1");
      get_dep_designation_salary("2");
      get_dropdown_data(userState.id);
      get_location_list(userState.id);
    };

    timeoutId = setTimeout(delayedApiCall, 500);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  useEffect(() => {
    if (Object.keys(selectedCountry).length > 0) {
      selectedCountry &&
        get_state_list(selectedCountry, (cb: any) => {
          setStateData(cb);
        });
    }
    if (
      Object.keys(selectedCountry).length > 0 &&
      Object.keys(selectedState).length > 0
    ) {
      get_city_list_via_name(selectedCountry, selectedState, (cb: any) => {
        setCityData(cb);
      });
    }
  }, [selectedState, selectedCountry]);

  useEffect(() => {
    changeDatalayout();
  }, [selectedMember]);

  // const get_client_list = () => {
  //   const onSuccess = (res: any) => {
  //     setClientIdData(res.data.data);
  //   };
  //   const onError = (err: any) => {
  //     console.log(err);
  //   };
  //   EMPLOYEE_SERVICES.get_client_list(onSuccess, onError);
  // };

  const get_location_list = (clientId: string) => {
    const onSuccess = (res: any) => {
      setLocationIdData(res.data.data);
    };
    const onError = (err: any) => {
      console.log(err);
    };
    EMPLOYER_EMPLOYEE_SERVICES.get_location_list(onSuccess, onError, clientId);
  };
  const getYearsDiff = (dob: Date) => {
    const today = new Date();
    const diffMilliseconds = today.getTime() - dob.getTime();
    const ageInYears = diffMilliseconds / (1000 * 60 * 60 * 24 * 365.25); // Consider leap years
    const roundedAge = Math.ceil(ageInYears);
    return roundedAge;
  };
  const checkMaxAgeForChildren = (
    maxChildAge: string,
    selectedMemberDOB: any,
    selectedRelation: string
  ) => {
    if (selectedRelation === "Son" || selectedRelation === "Daughter") {
      const dobDate = new Date(selectedMemberDOB ? selectedMemberDOB : "");
      const ageInYears = getYearsDiff(dobDate);
      let maxage: number = +extractNumbersFromString(maxChildAge);
      if (ageInYears > maxage) {
        toast.warning(`Permissible maximum age for children is ${maxChildAge}`);
        return false;
      }
      return true;
    } else {
      return true;
    }
  };
  // Check for age bands
  const checkAllowedAgeBand = (dob: any, policyBeingWorkedOn: any) => {
    let policy: any = policyBeingWorkedOn;
    const dobDate = new Date(dob);
    const ageInYears = getYearsDiff(dobDate);
    for (const band of policy.ageBand) {
      let minAge = band.min.includes("Up to 1 Year") ? 0 : parseInt(band.min);
      let maxAge = band.max.includes("Years")
        ? parseInt(band.max)
        : Number.MAX_SAFE_INTEGER;

      if (ageInYears >= minAge && ageInYears <= maxAge) {
        return true;
      }
    }

    toast.warning("This age band not allowed in this policy");
    return false;
  };

  const get_dep_designation_salary = (type: any) => {
    const onSuccess = (res: any) => {
      const resp = res.data.data[0].value;
      if (type === "0") {
        let arrDep: any = [];
        resp.map((data: any) => {
          arrDep.push({ label: data?.label, id: data?._id });
        });
        setdepartmentDropDownData(arrDep);
      } else if (type === "1") {
        let arrDesig: any = [];
        resp.map((data: any) => {
          arrDesig.push({ label: data?.label, id: data?._id });
        });
        setdesignationDropDowndata(arrDesig);
      } else if (type === "2") {
        setsalaryDropDowndataOriginal(resp);
        let arr = [];
        resp.forEach((data: any) => {
          let splitdata = data.label.split("-");
          arr.push({
            id: `${formatCurrency(splitdata[0])} - ${formatCurrency(
              splitdata[1]
            )}`,
            label: `${formatCurrency(splitdata[0])} - ${formatCurrency(
              splitdata[1]
            )}`,
          });
        });

        let above = resp[resp.length - 1].label.split("-");
        arr.push({
          label: `${formatCurrency(above[1])} - Above`,
          id: `${formatCurrency(above[1])} - Above`,
        });
        if (resp.length === 0 || (resp.length === 1 && !resp[0].label)) {
          setsalaryDropDowndata([]);
        } else {
          setsalaryDropDowndata(arr);
        }
      }
    };
    const onError = (err: any) => {
      console.log(err);
    };
    EMPLOYER_EMPLOYEE_SERVICES.get_dep_designation_salary(
      onSuccess,
      onError,
      type
    );
  };

  const checkIsRelationExist = (
    attachedPrimaryEmployee: string,
    relation: string
  ) => {
    const onSuccess = (res: any) => {
      setIsRealtionExist(res.data.data.exists);
      if (res.data.data.exists) {
        toast.warning(
          "This relationship already exists for the Primary Employee"
        );
      }
    };
    const onError = (err: any) => {
      console.log(err);
    };
    if (
      !attachedPrimaryEmployee ||
      !relation ||
      selectedMember !== "Dependent" ||
      relation === "Son" ||
      relation === "Daughter"
    ) {
      return;
    }
    EMPLOYEE_SERVICES.checkIsRelationExist(
      onSuccess,
      onError,
      attachedPrimaryEmployee,
      relation
    );
  };

  // get form layout dynamically
  const get_employee_layout = () => {
    setshowloader(true);
    Get(EMPLOYER_URL_CONSTANTS.get_employee_layout)
      .then((res: any) => {
        let response = res.data?.data[0]?.layout;
        setpopedData(response[1]?.children[1]);
        let newData = response?.map(
          (dataMainsection: employeeDTO, dataMainsectionIndex: any) => {
            let newMainSection = { ...dataMainsection };
            newMainSection.children = dataMainsection?.children?.map(
              (dataSubsection: any, dataSubsectionIndex: any) => {
                let newSubsection = { ...dataSubsection };
                newSubsection.children = dataSubsection?.children?.map(
                  (dataRow: any, dataRowIndex: any) => {
                    let newRow = { ...dataRow };
                    newRow.children = dataRow?.children?.map(
                      (dataColumn: any, dataColumnIndex: any) => {
                        let newColumn = { ...dataColumn };
                        newColumn.children = dataColumn.children;
                        newColumn.children = dataColumn?.children?.map(
                          (dataInput: any, dataInputIndex: any) => {
                            if (dataInput?.field_lable === "Salary") {
                              setsalaryPath([
                                dataMainsectionIndex,
                                dataSubsectionIndex,
                                dataRowIndex,
                                dataColumnIndex,
                                dataInputIndex,
                              ]);
                            }
                            if (dataInput?.field_type === "searchClient") {
                              return {
                                ...dataInput,
                                required: true,
                                value:
                                  userState.clientId +
                                  " - " +
                                  userState.Organization,
                              };
                            }

                            if (dataInput?.field_lable === "Member Type") {
                              setmemberTypePath([
                                dataMainsectionIndex,
                                dataSubsectionIndex,
                                dataRowIndex,
                                dataColumnIndex,
                                dataInputIndex,
                              ]);
                            }
                            if (dataInput?.field_lable === "Salary Bracket") {
                              setsalaryBracketPath([
                                dataMainsectionIndex,
                                dataSubsectionIndex,
                                dataRowIndex,
                                dataColumnIndex,
                                dataInputIndex,
                              ]);
                            }
                            if (dataInput?.field_type === "hideid") {
                              return {
                                ...dataInput,
                                field_lable: "hide",
                                field_type: "hide",
                              };
                            } else {
                              return { ...dataInput };
                            }
                          }
                        );
                        return newColumn;
                      }
                    );
                    return newRow;
                  }
                );
                return newSubsection;
              }
            );
            return newMainSection;
          }
        );
        setFormData(newData);
        setFormDataPermanent(newData);
        setshowloader(false);
      })
      ?.catch((err) => {
        console.log("err", err);
        setshowloader(false);
      });
  };

  const changeDatalayout = () => {
    const updatedItems = [...formDataPermanent];
    if (selectedMember === "Dependent") {
      updatedItems[1].children.splice(1, 1);
    } else if (selectedMember === "Employee") {
      if (updatedItems[1]?.children[1]?.name === "Professional Details") {
      } else {
        updatedItems[1].children.push(popedData);
      }
    }
    let response = updatedItems;
    let newData: any = response?.map(
      (dataMainsection: any, dataMainsectionIndex: any) => {
        let newMainSection = { ...dataMainsection };
        newMainSection.children = dataMainsection?.children?.map(
          (dataSubsection: any, dataSubsectionIndex: any) => {
            let newSubsection = { ...dataSubsection };
            newSubsection.children = dataSubsection?.children?.map(
              (dataRow: any, dataRowIndex: any) => {
                let newRow = { ...dataRow };
                newRow.children = dataRow?.children?.map(
                  (dataColumn: any, dataColumnIndex: any) => {
                    let newColumn = { ...dataColumn };
                    newColumn.children = dataColumn.children;
                    newColumn.children = dataColumn?.children?.map(
                      (dataInput: any, dataInputIndex: any) => {
                        if (dataInput?.field_lable === "Salary") {
                          setsalaryPath([
                            dataMainsectionIndex,
                            dataSubsectionIndex,
                            dataRowIndex,
                            dataColumnIndex,
                            dataInputIndex,
                          ]);
                        }
                        if (dataInput?.field_lable === "Member Type") {
                          return {
                            ...dataInput,
                            value: selectedMember,
                          };
                        }
                        if (dataInput?.field_lable === "Salary Bracket") {
                          setsalaryBracketPath([
                            dataMainsectionIndex,
                            dataSubsectionIndex,
                            dataRowIndex,
                            dataColumnIndex,
                            dataInputIndex,
                          ]);
                        }
                        if (selectedMember === "Employee") {
                          if (dataInput?.field_lable === "Primary Employee") {
                            return {
                              ...dataInput,
                              field_lable: "hide",
                              field_type: "hide",
                              required: false,
                            };
                          }
                        } else if (selectedMember !== "Employee") {
                          if (dataInput?.field_lable === "Primary Employee") {
                            return {
                              ...dataInput,
                              required: true,
                            };
                          }
                        }

                        if (
                          dataInput?.field_lable ===
                          "Relationship With Employee"
                        ) {
                          if (selectedMember === "Employee") {
                            return {
                              ...dataInput,
                              pickliststep: [
                                {
                                  field_name: "Self",
                                  label: "Self",
                                  value: "Self",
                                },
                              ],
                            };
                          } else {
                            return {
                              ...dataInput,
                              pickliststep: dataInput.pickliststep.filter(
                                (data: any) => {
                                  return data.field_name !== "Self";
                                }
                              ),
                            };
                          }
                        }
                        if (dataInput?.field_type === "hideid") {
                          return {
                            ...dataInput,
                            field_lable: "hide",
                            field_type: "hide",
                          };
                        } else {
                          return { ...dataInput };
                        }
                      }
                    );
                    return newColumn;
                  }
                );
                return newRow;
              }
            );
            return newSubsection;
          }
        );
        return newMainSection;
      }
    );
    setFormData(newData);
  };

  const locateFieldIndexFromCustomlayout = (attrName: [string, any]) => {
    let returnVal;

    formDataPermanent?.map(
      (dataMainsection: any, dataMainsectionIndex: any) => {
        let newMainSection = { ...dataMainsection };
        newMainSection.children = dataMainsection?.children?.map(
          (dataSubsection: any, dataSubsectionIndex: any) => {
            let newSubsection = { ...dataSubsection };
            newSubsection.children = dataSubsection?.children?.map(
              (dataRow: any, dataRowIndex: any) => {
                let newRow = { ...dataRow };
                newRow.children = dataRow?.children?.map(
                  (dataColumn: any, dataColumnIndex: any) => {
                    let newColumn = { ...dataColumn };
                    newColumn.children = dataColumn.children;
                    newColumn.children = dataColumn?.children?.map(
                      (dataInput: any, dataInputIndex: any) => {
                        if (dataInput?.field_lable === attrName[0]) {
                          if (attrName[1]) {
                            attrName[1]([
                              dataMainsectionIndex,
                              dataSubsectionIndex,
                              dataRowIndex,
                              dataColumnIndex,
                              dataInputIndex,
                            ]);
                          }
                          returnVal = [
                            dataMainsectionIndex,
                            dataSubsectionIndex,
                            dataRowIndex,
                            dataColumnIndex,
                            dataInputIndex,
                          ];
                        }
                      }
                    );
                    return newColumn;
                  }
                );
                return newRow;
              }
            );
            return newSubsection;
          }
        );
        return newMainSection;
      }
    );

    return returnVal;
  };

  const updateMasterState = (attrName: any, value: any) => {
    let mainState = attrName[0];
    let key = attrName[1];
    if (mainState === "personalAddress") {
      // Create an updated address object
      let updatedAddress = {
        ...personalAddress,
        [key]: value,
        [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
      };

      // Update the address based on the key
      if (key === "country") {
        setSelectedCountry(value);
        updatedAddress = {
          ...updatedAddress,
          country: value,
          state: "",
          city: "",
        };
      } else if (key === "state") {
        setSelectedState(value);
        updatedAddress = {
          ...updatedAddress,
          state: value,
          city: value.label === "N.A." ? { label: "N.A.", id: "N.A." } : "",
        };
      }

      // Update the personalAddress state once
      setPersonalAddress(updatedAddress);
    } else {
      if (attrName === "notes_new_data") {
        setNotesData((notesData) => [
          ...notesData,
          { name: "Umesh", date: new Date(), description: value },
        ]);
      } else if (attrName === "attachment_new_data") {
        setAttachmentData((prevState) => [
          ...prevState,
          {
            doc: value.doc,
            description: value.description,
            date: new Date(),
            time: new Date().getTime().toString(),
            doc_name: value.doc_name,
          },
        ]);
      } else if (attrName === setPostQueryForm) {
        attrName(value);
      } else {
        let data: employeeDTO[] = formData;
        let record =
          data[attrName[0]].children[attrName[1]].children[attrName[2]]
            .children[attrName[3]].children[attrName[4]];
        //For required fields
        if (record.required === true && value != null && value.length === 0) {
          record.error = true;
        } else {
          record.error = false;
        }
        let debounceTimeoutId;
        let lastValue = "";
        if (record.field_lable === "Official Email") {
          setOfficialMailId(value);
        }
        //update value
        record.value = value ? value : "";
        if (
          record.field_lable === "Designation" ||
          record.field_lable === "Department"
        ) {
          record.value = value.label;
        }
        if (record.field_lable === "Member Type") {
          setSelectedPrimaryEmployee({ _id: "", id: "", label: "" });
          record.value = value;
          setselectedMember(value);
          if (value === "Employee") {
            const selectedRelationLayoutPath = locateFieldIndexFromCustomlayout(
              ["Relationship With Employee", null]
            );
            let selectedRelation = null;
            if (selectedRelationLayoutPath) {
              selectedRelation = formData[
                selectedRelationLayoutPath[0]
              ].children[selectedRelationLayoutPath[1]].children[
                selectedRelationLayoutPath[2]
              ].children[selectedRelationLayoutPath[3]].children[
                selectedRelationLayoutPath[4]
              ].value = "Self";
            }
          }

          if (primaryEmployeePath[0] !== undefined) {
            setSelectedPrimaryEmployee({ _id: "", id: "", label: "" });
            data[primaryEmployeePath[0]].children[
              primaryEmployeePath[1]
            ].children[primaryEmployeePath[2]].children[
              primaryEmployeePath[3]
            ].children[primaryEmployeePath[4]].value = "";
          }
        }
        if (record.field_lable === "Relationship With Employee") {
          setselectedRelationShip(value);
          checkIsRelationExist(selectedPrimaryEmployee._id, value);
          checkIsRelationExist(attachedPrimaryEmployee, value);
        }
        if (record.field_lable === "Client") {
          record.value = value.label;
          //get_dropdown_data(value.label);
          if (primaryEmployeePath[0] === undefined) {
            locateFieldIndexFromCustomlayout([
              "Primary Employee",
              setPrimaryEmployeePath,
            ]);
          }
          if (primaryEmployeePath[0] !== undefined) {
            setSelectedPrimaryEmployee({ _id: "", id: "", label: "" });
            data[primaryEmployeePath[0]].children[
              primaryEmployeePath[1]
            ].children[primaryEmployeePath[2]].children[
              primaryEmployeePath[3]
            ].children[primaryEmployeePath[4]].value = "";
          }
        }
        if (record.field_lable === "Primary Employee") {
          checkIsRelationExist(value, selectedRelationShip);
          setSelectedPrimaryEmployee(value);
          get_employee_assigned_policies(value._id);
        }
        if (record.field_type === "searchClient") {
          // setattachedClient(userState.clientId)
          // get_dep_designation_salary(userState.clientId, "DEPARTMENTS");
          // get_dep_designation_salary(userState.clientId, "DESIGNATIONS");
          // get_dep_designation_salary(userState.clientId, "SALARY_BRACKETS");
        } else if (record.field_type === "primaryEmloyee") {
          setAttachedPrimaryEmployee(record.value._id);
        } else if (record.field_type === "salary") {
          record.value = value.label;
        } else if (record.field_lable === "Salary") {
          data[salaryBracketPath[0]].children[salaryBracketPath[1]].children[
            salaryBracketPath[2]
          ].children[salaryBracketPath[3]].children[
            salaryBracketPath[4]
          ].value = "";
          if(!value){
            data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
              attrName[3]
            ].children[attrName[4]].value = ""
            data[salaryBracketPath[0]].children[salaryBracketPath[1]].children[
              salaryBracketPath[2]
            ].children[salaryBracketPath[3]].children[salaryBracketPath[4]].value =
              "";
          }
          for (var i = 0; i < salaryDropDowndata.length; i++) {
            var range: any = salaryDropDowndata[i].label
              ?.split("-")
              ?.map((item) => {
                if (!["Below", "Above"].includes(item.trim())) {
                  return Number(extractNumbersFromString(item));
                } else {
                  return item.trim();
                }
              });
            var min: any = null;
            var max: any = null;

            if (range[0] === "Below") {
              min = -Infinity;
              max = Number(range[1]);
            } else if (range[1] === "Above") {
              min = Number(range[0]);
              max = Infinity;
            } else {
              min = range[0];
              max = range[1];
            }

            // Check if the input number is within the range
            if (Number(value) >= min && Number(value) <= max&& value) {
              var slab = salaryDropDowndata[i].label;

              data[salaryBracketPath[0]].children[
                salaryBracketPath[1]
              ].children[salaryBracketPath[2]].children[
                salaryBracketPath[3]
              ].children[salaryBracketPath[4]].value = slab;

              break;
            }else{
              data[salaryBracketPath[0]].children[
                salaryBracketPath[1]
              ].children[salaryBracketPath[2]].children[
                salaryBracketPath[3]
              ].children[salaryBracketPath[4]].value = "";
            }
          }
        }
        if (record.field_type === "datepicker") {
          if (record.field_lable === "Date Of Birth") {
            let val = record.value;
            setEmpDob(val);
            locateFieldIndexFromCustomlayout(["Date Of Joining", setEmpDojSet]);
            // if (empDojSet?.length > 0 && empDojSet[0] !== undefined) {
            //   data[empDojSet[0]].children[empDojSet[1]].children[empDojSet[2]].children[empDojSet[3]].children[empDojSet[4]].value = "";
            // }
            if (empDojSet?.length > 0 && empDojSet[0] !== undefined) {
              data[empDojSet[0]] &&
                data[empDojSet[0]].children[empDojSet[1]] &&
                data[empDojSet[0]].children[empDojSet[1]].children[
                  empDojSet[2]
                ] &&
                data[empDojSet[0]].children[empDojSet[1]].children[empDojSet[2]]
                  .children[empDojSet[3]] &&
                data[empDojSet[0]].children[empDojSet[1]].children[empDojSet[2]]
                  .children[empDojSet[3]].children[empDojSet[4]] &&
                (data[empDojSet[0]].children[empDojSet[1]].children[
                  empDojSet[2]
                ].children[empDojSet[3]].children[empDojSet[4]].value = "");
            }
          } else if (record.field_lable === "Date Of Joining") {
            let val = record.value;
            setEmpDoj(val);
            locateFieldIndexFromCustomlayout([
              "Last Day of Working",
              setEmpLdwSet,
            ]);

            if (empLdwSet?.length > 0 && empLdwSet[0] !== undefined) {
              data[empLdwSet[0]].children[empLdwSet[1]].children[
                empLdwSet[2]
              ].children[empLdwSet[3]].children[empLdwSet[4]].value = "";
            }
          }
        }

        setFormData([...data]);
      }
    }
  };
  const CreateEndorsementForNomineeAndMember = (
    endorsementFor: string,
    depId?: string,
    policyId?: string,
    policyNumber?: string,
    depPrefix?: string
  ) => {
    let data = {
      memberId: depId,
      member: depPrefix,
      policyId: policyId,
      policy: policyNumber,
      clientId: attachedClient,
      client: userState.clientId + " - " + userState.Organization,
      type: endorsementFor.includes("Member") ? "COMMERCIAL" : "NON COMMERCIAL",
      endorsementFor: endorsementFor,
      old: "",
      new: {
        dob: "",
      },
      attachment: {},
    };
    const onSuccess = (res: any) => {};

    const onError = (err: any) => {
      console.log(err);
    };

    EMPLOYEE_POLICY_SERVICES.CreateEndorsementForNominee(
      onSuccess,
      onError,
      data,
      usertypeinfo.toLocaleLowerCase()
    );
  };
  // form submission of contact
  const detail_form_submit = () => {
    if (disableSave) return;
    let hasError = false;
    let newData = formData.map(
      (dataMainsection: employeeDTO, dataMainsectionIndex: any) => {
        let newMainSection = { ...dataMainsection };
        newMainSection.children = dataMainsection.children.map(
          (dataSubsection, dataSubsectionIndex: any) => {
            let newSubsection = { ...dataSubsection };
            newSubsection.children = dataSubsection.children.map(
              (dataRow, dataRowIndex: any) => {
                let newRow = { ...dataRow };
                newRow.children = dataRow.children.map(
                  (dataColumn, dataColumnIndex: any) => {
                    let newColumn = { ...dataColumn };
                    newColumn.children = dataColumn.children.map(
                      (dataInput, dataInputIndex: any) => {
                        if (dataInput.field_lable === "Primary Employee") {
                          if (!dataInput.value) {
                            hasError = true;
                            // return dataInput
                            return { ...dataInput, error: true };
                          }
                        }
                        if (
                          dataInput.required === true &&
                          dataInput.value.length === 0
                        ) {
                          hasError = true;
                          return { ...dataInput, error: true };
                        } else if (
                          dataInput.characterLimit &&
                          dataInput.value.length > dataInput.characterLimit
                        ) {
                          hasError = true;
                          return { ...dataInput, error: true };
                        } else if (
                          dataInput.required === true &&
                          dataInput.field_type === "email"
                        ) {
                          if (
                            validateEmail(dataInput.value.toString()) === true
                          ) {
                            return { ...dataInput, error: false };
                          } else {
                            hasError = true;
                            return { ...dataInput, error: true };
                          }
                        } else if (dataInput.field_type === "datepicker") {
                          if (dataInput.field_lable === "Date Of Birth") {
                            setEmpDob(dataInput.value);
                          }
                          if (dataInput.field_lable === "Date Of Joining") {
                            setEmpDoj(dataInput.value);
                          }
                          // if (dataInput.field_lable?.toLowerCase() === "last day of working") { ldw = dataInput.value; setEmpLdw(dataInput.value) }
                          return { ...dataInput };
                        } else if (dataInput.field_type === "phone") {
                          if (validateMobileNumber(dataInput.value)) {
                            return { ...dataInput, error: false };
                          } else {
                            hasError = true;
                            return { ...dataInput, error: true };
                          }
                        } else if (dataInput.field_lable === "Salary Bracket") {
                          if (dataInput.value.length != "") {
                            return { ...dataInput, error: false };
                          } else {
                            //hasError = true;
                            return { ...dataInput, error: false };
                          }
                        } else if (dataInput.field_type === "email") {
                          if (
                            validateEmail(dataInput.value.toString()) ===
                              false &&
                            dataInput.value.length >= 1
                          ) {
                            hasError = true;
                            return { ...dataInput, error: true };
                          } else {
                            return { ...dataInput, error: false };
                          }
                        } else if (
                          dataInput.required === true &&
                          dataInput.field_type === "url"
                        ) {
                          if (
                            validateUrl(dataInput.value.toString()) === true
                          ) {
                            return { ...dataInput, error: false };
                          } else {
                            hasError = true;
                            return { ...dataInput, error: true };
                          }
                        } else if (dataInput.field_type === "url") {
                          if (
                            validateUrl(dataInput.value.toString()) === false &&
                            dataInput.value.length >= 1
                          ) {
                            hasError = true;
                            return { ...dataInput, error: true };
                          } else {
                            return { ...dataInput, error: false };
                          }
                        } else if (
                          dataInput.required === true &&
                          dataInput.field_type === "address"
                        ) {
                          hasError = true;
                          return { ...dataInput, error: true };
                        } else {
                          return { ...dataInput, error: false };
                        }
                      }
                    );
                    return newColumn;
                  }
                );
                return newRow;
              }
            );
            return newSubsection;
          }
        );
        return newMainSection;
      }
    );

    setFormData(newData);
    // address

    let dataAddress: any = personalAddress;
    dataAddress.warnStreet = personalAddress.street.length === 0 ? true : false;
    // dataAddress.warnArea = personalAddress.area.length === 0 ? true : false;
    dataAddress.warnCountry =
      personalAddress.country.length === 0 ? true : false;
    dataAddress.warnState = personalAddress.state.length === 0 ? true : false;
    dataAddress.warnCity = personalAddress.city.length === 0 ? true : false;
    dataAddress.warnZip = !validatePincode(
      personalAddress.zip?.toString() ? personalAddress.zip?.toString() : ""
    );

    setPersonalAddress({ ...dataAddress });
    // update management
    const updatedAttachmentData: any = attachmentData.map((item) => {
      const { date, time, doc_name, ...newItem } = item;
      return newItem;
    });
    setAttachmentData(updatedAttachmentData);
    if (isRealtionExist) {
      return toast.error(
        "This relationship already exists for the Primary Employee"
      );
    }
    // api call code
    if (
      hasError === false &&
      personalAddress.warnStreet === false &&
      personalAddress.warnArea === false &&
      personalAddress.warnCountry === false &&
      personalAddress.warnState === false &&
      personalAddress.warnCity === false &&
      personalAddress.warnZip === false &&
      !isRealtionExist
    ) {
      let params = {
        layout: formData,
        personalAddress: {
          street: personalAddress.street,
          area: personalAddress.area,
          country: personalAddress.country.label,
          state: personalAddress.state.label,
          city: personalAddress.city.label,
          zip: personalAddress.zip,
        },
        policy: selectedPolicyFordependent.map((item) => {
          return {
            memberId: [],
            ecardId: [],
            nomineeId: [],
            policyId: item._id,
          };
        }),
        members: [],
        kyc: [],
        attachments: attachmentData,
        notes: notesData,
        type: selectedMember.toUpperCase(),
        attachedClient: attachedClient,
        ...(attachedPrimaryEmployee && { attachedPrimaryEmployee }),
      };

      const onSuccess = (res: any) => {
        const response = res.data.data;
        const id = response._id;
        selectedPolicyFordependent?.map((newPolicy: any) => {
          const policyEndDate = new Date(newPolicy.policyDetail.endDate);
          const policyStartDate = new Date(newPolicy.policyDetail.startDate);
          const currentDate = new Date();
          const isTodayBetweenPolicyDates =
            currentDate >= policyStartDate && currentDate <= policyEndDate;
          if (
            isTodayBetweenPolicyDates &&
            (newPolicy.status === 41 || newPolicy.status === 60)
          ) {
            CreateEndorsementForNomineeAndMember(
              "Member Addition",
              id,
              newPolicy._id,
              newPolicy.policyNumber,
              `${response.preFix}-${response.employeeId}`
            );
          }
        });
        toast.success("Data added successfully");
        navigate(`${EMPLOYER_ALL_ROUTES.EMPLOYEE}`, {
          replace: true,
        });
      };

      const onError = (err: any) => {
        setDisableSave(false);
        toast.error(err?.response?.data?.errors[0]?.message);
      };

      // check if member being added is of type dependent then dont let him proceed without the dependent being assigned a policy
      if (
        formData[memberTypePath[0]].children[memberTypePath[1]].children[
          memberTypePath[2]
        ].children[memberTypePath[3]].children[memberTypePath[4]].value ===
        "Dependent"
      ) {
        if (params.policy.length > 0) {
          setDisableSave(true);
          EMPLOYER_EMPLOYEE_SERVICES.create_employee(
            params,
            onSuccess,
            {},
            onError
          );
        } else {
          toast.error("Kindly assign atleast 1 policy for dependent");
        }
      } else {
        setDisableSave(true);
        EMPLOYER_EMPLOYEE_SERVICES.create_employee(
          params,
          onSuccess,
          {},
          onError
        );
      }
    } else {
      toast.error("Kindly enter the valid detail");
    }
  };

  // for scrolling
  const handleClicks = (id: any) => {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const get_dropdown_data = (clientId: any) => {
    const onSuccess = (res: any) => {
      setPrimaryEmployeeOptions(
        res.data.data.filter(
          (item: { _id: string; id: string; label: string }) =>
            !item.label.includes("DEP")
        )
      );
    };
    const onError = (err: any) => {
      console.log("Employee Drop Down", err);
    };
    EMPLOYER_EMPLOYEE_SERVICES.get_dropdown_data(onSuccess, onError, clientId);
  };

  const get_employee_assigned_policies = (empId: string) => {
    const onSuccess = (res: any) => {
      setEmployeeAssignedPolicies(res.data.data);
    };

    const onError = (err: any) => {};

    EMPLOYER_EMPLOYEE_SERVICES.get_employee_assigned_policies_for_dependants(
      onSuccess,
      onError,
      empId
    );
  };

  const handlePolicyCheck = (checked: boolean, data: any) => {
    let newSelectedPolicies: string[] = [...selectedPolicies];

    if (checked) {
      newSelectedPolicies.push(data.policyId);
    } else {
      newSelectedPolicies = newSelectedPolicies.filter(
        (e) => e !== data.policyId
      );
    }

    setSelectedPolicies(newSelectedPolicies);
  };
  const check_policy_config = (checked: boolean, data: any) => {
    return new Promise((resolve, reject) => {
      const onSuccess = (res: any) => {
        handlePolicyCheck(checked, data);
        resolve(true);
      };

      const onError = (err: any) => {
        toast.error(err.response.data.errors[0].message);
        reject(false);
      };

      const selectedRelationLayoutPath = locateFieldIndexFromCustomlayout([
        "Relationship With Employee",
        null,
      ]);

      let selectedRelation = null;
      if (selectedRelationLayoutPath) {
        selectedRelation =
          formData[selectedRelationLayoutPath[0]].children[
            selectedRelationLayoutPath[1]
          ].children[selectedRelationLayoutPath[2]].children[
            selectedRelationLayoutPath[3]
          ].children[selectedRelationLayoutPath[4]].value;
      }

      if (selectedRelation) {
        EMPLOYEE_SERVICES.dependent_policy_config_check(
          onSuccess,
          onError,
          data._id,
          selectedRelation,
          selectedPrimaryEmployee._id,
          usertypeinfo?.toLowerCase()
        );
      } else {
        toast.error("Please select relationship with employee");
        reject(false);
      }
    });
  };

  const checkMailIsExist = (currentValue: string) => {
    const onSuccess = (res: any) => {
      if (res.data.message === "Email not used previously") return;
      else {
        toast.info(res.data.message, {
          autoClose: false,
        });
      }
    };
    const onError = (err: any) => {
      toast.error(err.response.data.errors[0].message);
    };

    EMPLOYEE_SERVICES.checkIsMailUsed(
      onSuccess,
      onError,
      currentValue,
      userState.userType.toLowerCase(),
      "employee"
    );
  };

  let timeoutId: any;
  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const delayedApiCall = () => {
      if (officialMailId) {
        checkMailIsExist(officialMailId);
      }
    };

    timeoutId = setTimeout(delayedApiCall, 1000);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [officialMailId]);

  return (
    <>
      {showloader && <NewLoader />}
      <Box className="layoutWrapper">
        <BulkUploadForm
          open_status={uploadFile}
          attrName={setUploadFile}
          value_update={updateMasterState}
        />
        {/* Page header */}
        <Grid container spacing={3} className="pageHeader">
          <Grid xs={6} className="pageTitle">
            <Link
              className="backStep"
              //
              onClick={() => {
                navigate(ALL_ROUTES.EMPLOYEE);
              }}
            >
              <ChevronLeftIcon />
            </Link>

            <div>
              <h3>Add New Member</h3>
            </div>
          </Grid>
          <Grid xs={6}></Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid xs={12}>
            <Grid container spacing={3}>
              <Grid xs className="leftSection">
                {formData.map((dataMainSection: employeeDTO, index: number) => (
                  <>
                    <Link
                      className={
                        sectionStatus === dataMainSection.name
                          ? `active sectionLink`
                          : "sectionLink personalDetailsSection"
                      }
                      onClick={() => {
                        handleClicks(dataMainSection.name);
                        setAddClass(true);
                        SetSectionStatus(
                          dataMainSection.name === undefined
                            ? ""
                            : dataMainSection.name
                        );
                      }}
                    >
                      <span>{dataMainSection.name}</span>
                    </Link>
                    {dataMainSection.children.map((dataSubsection) => (
                      <ul className="accordion_list">
                        <li>
                          <Link
                            className={
                              subsectionStatus === dataSubsection.name
                                ? `inner_link active`
                                : `inner_link`
                            }
                            onClick={() => {
                              handleClicks(dataSubsection.name);
                              SetsubSectionStatus(dataSubsection.name);
                              SetSectionStatus(
                                dataMainSection.name === undefined
                                  ? ""
                                  : dataMainSection.name
                              );
                            }}
                          >
                            {dataSubsection.name}
                          </Link>
                        </li>
                      </ul>
                    ))}
                  </>
                ))}
                <Link
                  className={
                    sectionStatus === "AddressDetail"
                      ? "active sectionLink AddressDetail"
                      : "sectionLink AddressDetail"
                  }
                  onClick={() => {
                    scrollToSection(AddressDetail);
                    SetSectionStatus("AddressDetail");
                    SetsubSectionStatus("");
                  }}
                >
                  <span>Address Detail</span>
                </Link>
              </Grid>
              <Grid xs className="rightSection">
                {formData?.map(
                  (dataMainSection: employeeDTO, indexMainSection: number) => (
                    // Main section
                    <Box
                      onMouseEnter={() => {
                        SetSectionStatus(
                          dataMainSection.name === undefined
                            ? ""
                            : dataMainSection.name
                        );
                      }}
                      className="detailSection"
                    >
                      <div id={dataMainSection.name}>
                        <div className="sectionTitle">
                          <h4>{dataMainSection.name}</h4>
                        </div>
                        {dataMainSection?.children?.map(
                          (dataSubsection, indexSubsection) => (
                            // Sub Section
                            <Grid
                              onMouseEnter={() => {
                                SetsubSectionStatus(
                                  dataSubsection.name === undefined
                                    ? ""
                                    : dataSubsection.name
                                );
                                SetSectionStatus(
                                  dataMainSection.name === undefined
                                    ? ""
                                    : dataMainSection.name
                                );
                              }}
                              container
                            >
                              <Grid xs={12}>
                                <h5
                                  id={dataSubsection?.name}
                                  key={dataSubsection?.type}
                                  className="subHeading"
                                >
                                  {dataSubsection?.name}
                                </h5>
                                {dataSubsection?.children?.map(
                                  (dataRow, indexRow) => (
                                    // Row
                                    <Grid
                                      container
                                      className="subRow"
                                      spacing={3}
                                    >
                                      {dataRow?.children?.map(
                                        (dataColumn, indexColumn) => (
                                          // Columns created
                                          <Grid xs className="subColumn">
                                            {dataColumn?.children?.map(
                                              (dataInput, indexInput) => (
                                                // input Component will be here
                                                <form>
                                                  {dataInput.field_type ===
                                                  "singleselect" ? (
                                                    // <SearchDropdown
                                                    //   class_name="inputField"
                                                    //   title={dataInput.field_lable}
                                                    //   value={dataInput.value}
                                                    //   attrName={[
                                                    //     indexMainSection,
                                                    //     indexSubsection,
                                                    //     indexRow,
                                                    //     indexColumn,
                                                    //     indexInput,
                                                    //     "value",
                                                    //   ]}
                                                    //   value_update={updateMasterState}
                                                    //   data={dataInput.pickliststep
                                                    //     ? dataInput.pickliststep : []}
                                                    //   warn_status={dataInput.error}
                                                    //   required={dataInput.required}
                                                    //   info_status={dataInput.toolTip}
                                                    //   info_message={dataInput.tipText}
                                                    // />
                                                    <SelectDropdown
                                                      class_name="inputField"
                                                      title={
                                                        dataInput.field_lable
                                                      }
                                                      value_update={
                                                        updateMasterState
                                                      }
                                                      value={dataInput.value}
                                                      attrName={[
                                                        indexMainSection,
                                                        indexSubsection,
                                                        indexRow,
                                                        indexColumn,
                                                        indexInput,
                                                        "value",
                                                      ]}
                                                      info_status={
                                                        dataInput.toolTip
                                                      }
                                                      info_message={
                                                        dataInput.tipText
                                                      }
                                                      dropdown_data={
                                                        dataInput.pickliststep
                                                          ? dataInput.pickliststep
                                                          : []
                                                      }
                                                      warn_status={
                                                        dataInput.error
                                                      }
                                                      required={
                                                        dataInput.required
                                                      }
                                                    />
                                                  ) : dataInput.field_type ===
                                                    "searchClient" ? (
                                                    <RKTextField
                                                      class_name="inputField"
                                                      title={
                                                        dataInput.field_lable
                                                      }
                                                      value={dataInput.value}
                                                      attrName={[
                                                        indexMainSection,
                                                        indexSubsection,
                                                        indexRow,
                                                        indexColumn,
                                                        indexInput,
                                                        "value",
                                                      ]}
                                                      value_update={
                                                        updateMasterState
                                                      }
                                                      warn_status={
                                                        dataInput.error
                                                      }
                                                      required={
                                                        dataInput.required
                                                      }
                                                      error_messg={
                                                        "Select Client"
                                                      }
                                                      disabled
                                                    />
                                                  ) : dataInput.field_type ===
                                                    "searchLocation" ? (
                                                    <SearchDropdown
                                                      class_name="inputField"
                                                      title={
                                                        dataInput.field_lable
                                                      }
                                                      value={dataInput.value}
                                                      attrName={[
                                                        indexMainSection,
                                                        indexSubsection,
                                                        indexRow,
                                                        indexColumn,
                                                        indexInput,
                                                        "value",
                                                      ]}
                                                      value_update={
                                                        updateMasterState
                                                      }
                                                      data={locationIdData}
                                                      warn_status={
                                                        dataInput.error
                                                      }
                                                      required={
                                                        dataInput.required
                                                      }
                                                      error_message={
                                                        "Select Location"
                                                      }
                                                    />
                                                  ) : dataInput.field_type ===
                                                    "phone" ? (
                                                    <RKTextField
                                                      validation_type="numeric"
                                                      class_name="inputField"
                                                      title={
                                                        dataInput.field_lable
                                                      }
                                                      value={dataInput.value}
                                                      info_status={
                                                        dataInput.toolTip
                                                      }
                                                      info_message={
                                                        dataInput.tipText
                                                      }
                                                      attrName={[
                                                        indexMainSection,
                                                        indexSubsection,
                                                        indexRow,
                                                        indexColumn,
                                                        indexInput,
                                                        "value",
                                                      ]}
                                                      value_update={
                                                        updateMasterState
                                                      }
                                                      warn_status={
                                                        dataInput.error
                                                      }
                                                      error_messg={
                                                        dataInput.value
                                                          .length === 0
                                                          ? `Enter ${dataInput.field_lable}`
                                                          : dataInput.characterLimit &&
                                                            dataInput.value
                                                              .length >
                                                              dataInput.characterLimit
                                                          ? `Maximum ${dataInput.characterLimit} limit`
                                                          : `Enter Valid ${dataInput.field_lable}`
                                                      }
                                                      required={
                                                        dataInput.required
                                                      }
                                                      charcterlimit={
                                                        !dataInput.characterLimit
                                                          ? 15
                                                          : dataInput.characterLimit
                                                      }
                                                    />
                                                  ) : dataInput.field_type ===
                                                    "email" ? (
                                                    <RKTextField
                                                      validation_type="email"
                                                      type="email"
                                                      class_name="inputField"
                                                      title={
                                                        dataInput.field_lable
                                                      }
                                                      value={dataInput.value}
                                                      info_status={
                                                        dataInput.field_lable ===
                                                        "Official Email"
                                                          ? true
                                                          : dataInput.toolTip
                                                      }
                                                      info_message={
                                                        dataInput.field_lable ===
                                                        "Official Email"
                                                          ? "This Email id will be the username for Employee Portal"
                                                          : dataInput.tipText
                                                      }
                                                      attrName={[
                                                        indexMainSection,
                                                        indexSubsection,
                                                        indexRow,
                                                        indexColumn,
                                                        indexInput,
                                                        "value",
                                                      ]}
                                                      value_update={
                                                        updateMasterState
                                                      }
                                                      warn_status={
                                                        dataInput.error
                                                      }
                                                      error_messg={
                                                        dataInput.value
                                                          .length === 0
                                                          ? `Enter ${dataInput.field_lable}`
                                                          : validateEmail(
                                                              dataInput.value
                                                            ) === false
                                                          ? `Enter a valid Official Email`
                                                          : ""
                                                      }
                                                      required={
                                                        dataInput.required
                                                      }
                                                      charcterlimit={
                                                        !dataInput.characterLimit
                                                          ? 50
                                                          : dataInput.characterLimit
                                                      }
                                                    />
                                                  ) : dataInput.field_type ===
                                                    "department" ? (
                                                    <SearchDropdown
                                                      class_name="inputField"
                                                      title={
                                                        dataInput.field_lable
                                                      }
                                                      value={dataInput.value}
                                                      attrName={[
                                                        indexMainSection,
                                                        indexSubsection,
                                                        indexRow,
                                                        indexColumn,
                                                        indexInput,
                                                        "value",
                                                      ]}
                                                      value_update={
                                                        updateMasterState
                                                      }
                                                      data={
                                                        departmentDropDownData
                                                      }
                                                      warn_status={
                                                        dataInput.error ===
                                                        undefined
                                                          ? false
                                                          : dataInput.error
                                                      }
                                                      required={
                                                        dataInput.required
                                                      }
                                                      error_message="Select Department"
                                                    />
                                                  ) : dataInput.field_lable ===
                                                    "Primary Employee" ? (
                                                    <SearchDropdown
                                                      class_name="inputField"
                                                      title={
                                                        dataInput.field_lable
                                                      }
                                                      value={dataInput.value}
                                                      attrName={[
                                                        indexMainSection,
                                                        indexSubsection,
                                                        indexRow,
                                                        indexColumn,
                                                        indexInput,
                                                        "value",
                                                      ]}
                                                      value_update={
                                                        updateMasterState
                                                      }
                                                      data={
                                                        primaryEmployeeOptions
                                                      }
                                                      warn_status={
                                                        dataInput.error ===
                                                        undefined
                                                          ? false
                                                          : dataInput.error
                                                      }
                                                      required={
                                                        dataInput.required
                                                      }
                                                      error_message="Select Primary Employee"
                                                    />
                                                  ) : dataInput.field_type ===
                                                    "designation" ? (
                                                    <SearchDropdown
                                                      class_name="inputField"
                                                      title={
                                                        dataInput.field_lable
                                                      }
                                                      value={dataInput.value}
                                                      attrName={[
                                                        indexMainSection,
                                                        indexSubsection,
                                                        indexRow,
                                                        indexColumn,
                                                        indexInput,
                                                        "value",
                                                      ]}
                                                      value_update={
                                                        updateMasterState
                                                      }
                                                      data={
                                                        designationDropDowndata
                                                      }
                                                      warn_status={
                                                        dataInput.error ===
                                                        undefined
                                                          ? false
                                                          : dataInput.error
                                                      }
                                                      required={
                                                        dataInput.required
                                                      }
                                                      error_message="Select Designation"
                                                    />
                                                  ) : dataInput.field_type ===
                                                    "salaryInput" ? (
                                                    <RKTextField
                                                      validation_type="numeric"
                                                      class_name="inputField"
                                                      title={
                                                        dataInput.field_lable
                                                      }
                                                      value={formatCurrencyAccToUser(
                                                        dataInput?.value,
                                                        getCurrencyCodeRegex(
                                                          userState
                                                            ?.localInformation
                                                            .currency
                                                        ),
                                                        userState
                                                          .localInformation
                                                          ?.countryCurrencyCode,
                                                        0
                                                      )}
                                                      info_status={
                                                        dataInput.toolTip
                                                      }
                                                      info_message={
                                                        dataInput.tipText
                                                      }
                                                      attrName={[
                                                        indexMainSection,
                                                        indexSubsection,
                                                        indexRow,
                                                        indexColumn,
                                                        indexInput,
                                                        "value",
                                                      ]}
                                                      value_update={
                                                        updateMasterState
                                                      }
                                                      warn_status={
                                                        dataInput.error
                                                      }
                                                      error_messg={
                                                        dataInput.value
                                                          .length === 0
                                                          ? `Enter ${dataInput.field_lable}`
                                                          : dataInput.characterLimit &&
                                                            dataInput.value
                                                              .length >
                                                              dataInput.characterLimit
                                                          ? `Maximum ${dataInput.characterLimit} limit`
                                                          : ""
                                                      }
                                                      required={
                                                        dataInput.required
                                                      }
                                                      charcterlimit={
                                                        !dataInput.maxDigits
                                                          ? 12
                                                          : dataInput.maxDigits
                                                      }
                                                    />
                                                  ) : dataInput.field_type ===
                                                    "salary" ? (
                                                    <>
                                                      <SearchDropdown
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value={dataInput.value}
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        data={
                                                          salaryDropDowndata
                                                        }
                                                        warn_status={
                                                          dataInput.error ===
                                                          undefined
                                                            ? false
                                                            : dataInput.error
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        disabled={
                                                          formData[
                                                            salaryPath[0]
                                                          ]?.children[
                                                            salaryPath[1]
                                                          ]?.children[
                                                            salaryPath[2]
                                                          ]?.children[
                                                            salaryPath[3]
                                                          ]?.children[
                                                            salaryPath[4]
                                                          ].value
                                                            ? true
                                                            : false
                                                        }
                                                        error_message="Select Salary Bracket"
                                                      />
                                                    </>
                                                  ) : // <RKTextField
                                                  //   class_name="inputField"
                                                  //   title={dataInput.field_lable}
                                                  //   value={dataInput.value}
                                                  //   info_status={dataInput.toolTip}
                                                  //   info_message={dataInput.tipText}
                                                  //   attrName={[
                                                  //     indexMainSection,
                                                  //     indexSubsection,
                                                  //     indexRow,
                                                  //     indexColumn,
                                                  //     indexInput,
                                                  //     "value",
                                                  //   ]}
                                                  //   value_update={updateMasterState}
                                                  //   warn_status={false}
                                                  //   required={dataInput.required}
                                                  //   disabled={true
                                                  //     //   formData[salaryPath[0]]
                                                  //     //   ?.children[
                                                  //     //   salaryPath[1]
                                                  //     // ]?.children[
                                                  //     //   salaryPath[2]
                                                  //     // ]?.children[
                                                  //     //   salaryPath[3]
                                                  //     // ]?.children[
                                                  //     //   salaryPath[4]
                                                  //     // ].value
                                                  //     //   ? true
                                                  //     //   : false
                                                  //   }
                                                  // />
                                                  dataInput.field_type ===
                                                    "datepicker" ? (
                                                    <DatePicker
                                                      class_name="inputField"
                                                      title={
                                                        dataInput.field_lable
                                                      }
                                                      value={
                                                        dataInput.value || null
                                                      }
                                                      max_date={
                                                        dataInput.field_lable?.toLowerCase() ===
                                                          "date of birth" ||
                                                        dataInput.field_lable?.toLowerCase() ===
                                                          "date of joining"
                                                          ? new Date()
                                                          : null
                                                      }
                                                      min_date={
                                                        dataInput.field_lable?.toLowerCase() ===
                                                          "date of joining" &&
                                                        empDob
                                                          ? new Date(empDob)
                                                          : dataInput.field_lable?.toLowerCase() ===
                                                              "last day of working" &&
                                                            empDoj
                                                          ? new Date(empDoj)
                                                          : dataInput.field_lable?.toLowerCase() ===
                                                            "last day of working"
                                                          ? new Date()
                                                          : null
                                                      }
                                                      attrName={[
                                                        indexMainSection,
                                                        indexSubsection,
                                                        indexRow,
                                                        indexColumn,
                                                        indexInput,
                                                        "value",
                                                      ]}
                                                      value_update={
                                                        updateMasterState
                                                      }
                                                      error_message={
                                                        "Enter " +
                                                        dataInput.field_lable
                                                      }
                                                      warn_status={
                                                        dataInput.error ===
                                                        undefined
                                                          ? false
                                                          : dataInput.error
                                                      }
                                                      info_status={
                                                        dataInput.toolTip
                                                      }
                                                      info_message={
                                                        dataInput.tipText
                                                      }
                                                      required={
                                                        dataInput.required
                                                      }
                                                    />
                                                  ) : dataInput.field_type ===
                                                    "searchselect" ? (
                                                    <SearchDropdown
                                                      class_name="inputField"
                                                      title={
                                                        dataInput.field_lable
                                                      }
                                                      value_update={
                                                        updateMasterState
                                                      }
                                                      value={dataInput.value}
                                                      attrName={[
                                                        indexMainSection,
                                                        indexSubsection,
                                                        indexRow,
                                                        indexColumn,
                                                        indexInput,
                                                        "value",
                                                      ]}
                                                      data={
                                                        dataInput.pickliststep
                                                          ? dataInput.pickliststep
                                                          : []
                                                      }
                                                      warn_status={
                                                        dataInput.error ===
                                                        undefined
                                                          ? false
                                                          : dataInput.error
                                                      }
                                                      required={
                                                        dataInput.required
                                                      }
                                                      error_message={
                                                        "Select the value"
                                                      }
                                                      info_status={
                                                        dataInput.toolTip
                                                      }
                                                      info_message={
                                                        dataInput.tipText
                                                      }
                                                    />
                                                  ) : dataInput.field_type ===
                                                    "checkbox" ? (
                                                    <CheckBox
                                                      title={
                                                        dataInput.field_lable
                                                      }
                                                      name={
                                                        dataInput.field_lable
                                                      }
                                                      id={dataInput._id}
                                                      value={Boolean(
                                                        dataInput.value
                                                      )}
                                                      attrName={[
                                                        indexMainSection,
                                                        indexSubsection,
                                                        indexRow,
                                                        indexColumn,
                                                        indexInput,
                                                        "value",
                                                      ]}
                                                      value_update={
                                                        updateMasterState
                                                      }
                                                      default_check={true}
                                                    />
                                                  ) : dataInput.field_type ===
                                                    "number" ? (
                                                    <RKTextField
                                                      validation_type="numeric"
                                                      class_name="inputField"
                                                      title={
                                                        dataInput.field_lable
                                                      }
                                                      value={
                                                        dataInput.field_lable ===
                                                        "Salary"
                                                          ? formatCurrency(
                                                              dataInput.value
                                                            )
                                                          : dataInput.value
                                                      }
                                                      info_status={
                                                        dataInput.toolTip
                                                      }
                                                      info_message={
                                                        dataInput.tipText
                                                      }
                                                      attrName={[
                                                        indexMainSection,
                                                        indexSubsection,
                                                        indexRow,
                                                        indexColumn,
                                                        indexInput,
                                                        "value",
                                                      ]}
                                                      value_update={
                                                        updateMasterState
                                                      }
                                                      warn_status={
                                                        dataInput.error
                                                      }
                                                      error_messg={
                                                        dataInput.value
                                                          .length === 0
                                                          ? `Enter ${dataInput.field_lable}`
                                                          : dataInput.characterLimit &&
                                                            dataInput.value
                                                              .length >
                                                              dataInput.characterLimit
                                                          ? `Maximum ${dataInput.characterLimit} limit`
                                                          : ""
                                                      }
                                                      required={
                                                        dataInput.required
                                                      }
                                                      charcterlimit={
                                                        !dataInput.maxDigits
                                                          ? 12
                                                          : dataInput.maxDigits
                                                      }
                                                    />
                                                  ) : dataInput.field_type ==
                                                    "currency" ? (
                                                    <CurrencyTextField
                                                      validation_type="numeric"
                                                      class_name="inputField"
                                                      title={
                                                        dataInput.field_lable
                                                      }
                                                      value={dataInput.value}
                                                      info_status={
                                                        dataInput.toolTip
                                                      }
                                                      info_message={
                                                        dataInput.tipText
                                                      }
                                                      attrName={[
                                                        indexMainSection,
                                                        indexSubsection,
                                                        indexRow,
                                                        indexColumn,
                                                        indexInput,
                                                        "value",
                                                      ]}
                                                      value_update={
                                                        updateMasterState
                                                      }
                                                      warn_status={
                                                        dataInput.error
                                                      }
                                                      error_messg={
                                                        dataInput.value
                                                          .length === 0
                                                          ? `Enter ${dataInput.field_lable}`
                                                          : dataInput.characterLimit &&
                                                            dataInput.value
                                                              .length >
                                                              dataInput.characterLimit
                                                          ? `Maximum ${dataInput.characterLimit} Limit`
                                                          : ""
                                                      }
                                                      required={
                                                        dataInput.required
                                                      }
                                                      charcterlimit={
                                                        !dataInput.maxDigits
                                                          ? 12
                                                          : dataInput.maxDigits
                                                      }
                                                    />
                                                  ) : dataInput.field_type ===
                                                    "multiselect" ? (
                                                    <MultipleSearchDropdown
                                                      class_name="inputField"
                                                      title={
                                                        dataInput.field_lable
                                                      }
                                                      value_update={
                                                        updateMasterState
                                                      }
                                                      value={dataInput.value}
                                                      attrName={[
                                                        indexMainSection,
                                                        indexSubsection,
                                                        indexRow,
                                                        indexColumn,
                                                        indexInput,
                                                        "value",
                                                      ]}
                                                      multiple_data={
                                                        dataInput.pickliststep
                                                          ? dataInput.pickliststep
                                                          : []
                                                      }
                                                      info_status={
                                                        dataInput.toolTip
                                                      }
                                                      info_message={
                                                        dataInput.tipText
                                                      }
                                                      warn_status={
                                                        dataInput.error ===
                                                        undefined
                                                          ? false
                                                          : dataInput.error
                                                      }
                                                      error_messg={
                                                        "Select the value"
                                                      }
                                                      required={
                                                        dataInput.required
                                                      }
                                                    />
                                                  ) : dataInput.field_type ===
                                                    "multiline" ? (
                                                    <TextAreaField
                                                      specialcharacter={
                                                        !dataInput.speacialChar
                                                          ? false
                                                          : dataInput.speacialChar
                                                      }
                                                      alphanumeric={
                                                        !dataInput.alphaNumeric
                                                          ? false
                                                          : dataInput.alphaNumeric
                                                      }
                                                      class_name="inputField"
                                                      title={
                                                        dataInput.field_lable
                                                      }
                                                      value_update={
                                                        updateMasterState
                                                      }
                                                      value={dataInput.value}
                                                      attrName={[
                                                        indexMainSection,
                                                        indexSubsection,
                                                        indexRow,
                                                        indexColumn,
                                                        indexInput,
                                                        "value",
                                                      ]}
                                                      info_status={
                                                        dataInput.toolTip
                                                      }
                                                      info_message={
                                                        dataInput.tipText
                                                      }
                                                      warn_status={
                                                        dataInput.error ===
                                                        undefined
                                                          ? false
                                                          : dataInput.error
                                                      }
                                                      error_messg={
                                                        dataInput.value
                                                          .length === 0
                                                          ? `Enter ${dataInput.field_lable}`
                                                          : dataInput.characterLimit &&
                                                            dataInput.value
                                                              .length >
                                                              dataInput.characterLimit
                                                          ? `Maximum ${dataInput.characterLimit} charcter`
                                                          : ""
                                                      }
                                                      charcterlimit={
                                                        !dataInput.characterLimit
                                                          ? 30
                                                          : dataInput.characterLimit
                                                      }
                                                      required={
                                                        dataInput.required
                                                      }
                                                    />
                                                  ) : dataInput.field_type ===
                                                    "url" ? (
                                                    <RKTextField
                                                      validation_type="url"
                                                      class_name="inputField"
                                                      title={
                                                        dataInput.field_lable
                                                      }
                                                      value={dataInput.value}
                                                      info_status={
                                                        dataInput.toolTip
                                                      }
                                                      info_message={
                                                        dataInput.tipText
                                                      }
                                                      attrName={[
                                                        indexMainSection,
                                                        indexSubsection,
                                                        indexRow,
                                                        indexColumn,
                                                        indexInput,
                                                        "value",
                                                      ]}
                                                      value_update={
                                                        updateMasterState
                                                      }
                                                      warn_status={
                                                        dataInput.error
                                                      }
                                                      error_messg={
                                                        dataInput.value
                                                          .length === 0
                                                          ? `Enter ${dataInput.field_lable}`
                                                          : validateUrl(
                                                              dataInput.value
                                                            ) === false
                                                          ? `Enter a valid Url`
                                                          : ""
                                                      }
                                                      required={
                                                        dataInput.required
                                                      }
                                                      charcterlimit={
                                                        !dataInput.characterLimit
                                                          ? 50
                                                          : dataInput.characterLimit
                                                      }
                                                    />
                                                  ) : dataInput.field_type ===
                                                    "country" ? (
                                                    <SearchDropdown
                                                      class_name="inputField"
                                                      title="Country"
                                                      value={dataInput.value}
                                                      attrName={[
                                                        indexMainSection,
                                                        indexSubsection,
                                                        indexRow,
                                                        indexColumn,
                                                        indexInput,
                                                        "value",
                                                      ]}
                                                      value_update={
                                                        updateMasterState
                                                      }
                                                      data={countryData}
                                                      warn_status={
                                                        dataInput.error
                                                      }
                                                      required={
                                                        dataInput.required
                                                      }
                                                      error_message="Select country"
                                                    />
                                                  ) : dataInput.field_type ===
                                                    "state" ? (
                                                    <SearchDropdown
                                                      class_name="inputField"
                                                      title="State"
                                                      value={dataInput.value}
                                                      attrName={[
                                                        indexMainSection,
                                                        indexSubsection,
                                                        indexRow,
                                                        indexColumn,
                                                        indexInput,
                                                        "value",
                                                      ]}
                                                      value_update={
                                                        updateMasterState
                                                      }
                                                      data={stateData}
                                                      warn_status={
                                                        dataInput.error
                                                      }
                                                      required={
                                                        dataInput.required
                                                      }
                                                      error_message="Select state"
                                                    />
                                                  ) : dataInput.field_type ===
                                                    "city" ? (
                                                    <SearchDropdown
                                                      class_name="inputField"
                                                      title="City"
                                                      value={dataInput.value}
                                                      attrName={[
                                                        indexMainSection,
                                                        indexSubsection,
                                                        indexRow,
                                                        indexColumn,
                                                        indexInput,
                                                        "value",
                                                      ]}
                                                      value_update={
                                                        updateMasterState
                                                      }
                                                      data={cityData}
                                                      warn_status={
                                                        dataInput.error
                                                      }
                                                      required={
                                                        dataInput.required
                                                      }
                                                      error_message="Select city"
                                                    />
                                                  ) : dataInput.field_type ===
                                                    "hide" ? null : (
                                                    <RKTextField
                                                      specialcharacter={
                                                        !dataInput.speacialChar
                                                          ? false
                                                          : dataInput.speacialChar
                                                      }
                                                      alphanumeric={
                                                        !dataInput.alphaNumeric
                                                          ? false
                                                          : dataInput.alphaNumeric
                                                      }
                                                      // validation_type="name"
                                                      class_name="inputField"
                                                      title={
                                                        dataInput.field_lable
                                                      }
                                                      value={dataInput.value}
                                                      info_status={
                                                        dataInput.toolTip
                                                      }
                                                      info_message={
                                                        dataInput.tipText
                                                      }
                                                      attrName={[
                                                        indexMainSection,
                                                        indexSubsection,
                                                        indexRow,
                                                        indexColumn,
                                                        indexInput,
                                                        "value",
                                                      ]}
                                                      value_update={
                                                        updateMasterState
                                                      }
                                                      warn_status={
                                                        dataInput.error
                                                      }
                                                      error_messg={
                                                        dataInput.value
                                                          .length === 0
                                                          ? `Enter ${dataInput.field_lable}`
                                                          : dataInput.characterLimit &&
                                                            dataInput.value
                                                              .length >
                                                              dataInput.characterLimit
                                                          ? `Maximum ${dataInput.characterLimit} charcter`
                                                          : ""
                                                      }
                                                      required={
                                                        dataInput.required
                                                      }
                                                      charcterlimit={
                                                        !dataInput.characterLimit
                                                          ? 30
                                                          : dataInput.characterLimit
                                                      }
                                                    />
                                                  )}
                                                </form>
                                              )
                                            )}
                                          </Grid>
                                        )
                                      )}
                                    </Grid>
                                  )
                                )}
                              </Grid>
                            </Grid>
                          )
                        )}
                      </div>
                    </Box>
                  )
                )}

                {selectedPrimaryEmployee._id && (
                  <Box className="detailSection">
                    <div className="sectionTitle">
                      <h4>Assign Policy</h4>
                    </div>
                    <Grid
                      container
                      spacing={3}
                      alignItems="center"
                      sx={{ paddingTop: "0" }}
                    >
                      <Grid xs={12}>
                        <div className="">
                          <Grid container spacing={3}>
                            <Grid xs={12}>
                              <div className="policy_table">
                                <div
                                  className="policy_table_inner"
                                  style={{ overflow: "hidden" }}
                                >
                                  <ul className="heading">
                                    <li>Choose Policy</li>
                                    <li>Active Policy Number</li>
                                    <li>Product Type</li>
                                    <li>Insurer</li>
                                    <li>Basic configuration</li>
                                    <li>Individual configuration</li>
                                    <li>Family Defination</li>
                                  </ul>

                                  {employeeAssignedPolicies.map(
                                    (data, index) => (
                                      <ul className="subheading">
                                        <li>
                                          <div className="Checkbox">
                                            <input
                                              checked={selectedPolicyFordependent.some(
                                                (pol: any) =>
                                                  pol._id === data._id
                                              )}
                                              onChange={async (e) => {
                                                if (
                                                  data.status === 61 ||
                                                  data.status === 42
                                                ) {
                                                  return toast.warn(
                                                    "Since the Employee has been Opt-Out/Opt-Out Requested from this policy, no new member may be added to this Policy."
                                                  );
                                                }
                                                let allowed;
                                                let checked = e.target.checked;
                                                if (checked) {
                                                  try {
                                                    await check_policy_config(
                                                      e.target.checked,
                                                      data
                                                    );
                                                    allowed = true;
                                                  } catch {
                                                    allowed = false;
                                                  }
                                                }
                                                if (allowed === undefined) {
                                                  allowed =
                                                    allowed === undefined
                                                      ? true
                                                      : false;
                                                }
                                                if (!allowed) {
                                                  return;
                                                }
                                                if (allowed) {
                                                  const selectedRelationLayoutPath =
                                                    locateFieldIndexFromCustomlayout(
                                                      [
                                                        "Relationship With Employee",
                                                        null,
                                                      ]
                                                    );

                                                  let selectedRelation = null;
                                                  if (
                                                    selectedRelationLayoutPath
                                                  ) {
                                                    selectedRelation =
                                                      formData[
                                                        selectedRelationLayoutPath[0]
                                                      ].children[
                                                        selectedRelationLayoutPath[1]
                                                      ].children[
                                                        selectedRelationLayoutPath[2]
                                                      ].children[
                                                        selectedRelationLayoutPath[3]
                                                      ].children[
                                                        selectedRelationLayoutPath[4]
                                                      ].value;
                                                  }

                                                  const dobPath =
                                                    locateFieldIndexFromCustomlayout(
                                                      ["Date Of Birth", null]
                                                    );

                                                  let dob = null;
                                                  if (dobPath) {
                                                    dob =
                                                      formData[dobPath[0]]
                                                        .children[dobPath[1]]
                                                        .children[dobPath[2]]
                                                        .children[dobPath[3]]
                                                        .children[dobPath[4]]
                                                        .value;
                                                    if (!dob) {
                                                      return toast.error(
                                                        "Please select DOB"
                                                      );
                                                    }
                                                  }
                                                  if (
                                                    checked &&
                                                    checkAllowedAgeBand(
                                                      dob,
                                                      data
                                                    ) &&
                                                    checkMaxAgeForChildren(
                                                      data.maxChildAge,
                                                      dob,
                                                      selectedRelation
                                                    )
                                                  ) {
                                                    setSelectedPolicyFordependent(
                                                      [
                                                        ...selectedPolicyFordependent,
                                                        data,
                                                      ]
                                                    );
                                                  } else if (!checked) {
                                                    let updated =
                                                      selectedPolicyFordependent?.filter(
                                                        (policy) =>
                                                          policy._id !==
                                                          data._id
                                                      );
                                                    setSelectedPolicyFordependent(
                                                      updated
                                                    );
                                                  }
                                                }
                                              }}
                                              type="checkbox"
                                              name="check"
                                            />
                                            <label className="employee"></label>
                                          </div>
                                        </li>

                                        <li>{data.policyNumber}</li>
                                        <li>{data.productType}</li>
                                        <li>{data.insurer}</li>
                                        <li>{data.basicConfig}</li>
                                        <li>{data.individualConfig}</li>
                                        <li>{data.familyDefinition}</li>
                                      </ul>
                                    )
                                  )}
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                <Box
                  onMouseEnter={() => {
                    SetSectionStatus("AddressDetail");
                    SetsubSectionStatus("");
                  }}
                  className="detailSection"
                  ref={AddressDetail}
                >
                  <div className="sectionTitle">
                    <h4>Address Details</h4>
                  </div>
                  <div>
                    <Grid container spacing={3}>
                      <Grid xs={12}>
                        <h5 className="subHeading">Personal Address Details</h5>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid sm={6}>
                        <RKTextField
                          class_name="inputField"
                          title="Building/Street Name"
                          value={personalAddress.street}
                          attrName={["personalAddress", "street"]}
                          value_update={updateMasterState}
                          warn_status={personalAddress.warnStreet}
                          required={true}
                        />
                      </Grid>

                      <Grid sm={6}>
                        <RKTextField
                          class_name="inputField"
                          title="Area Name"
                          value={personalAddress.area}
                          attrName={["personalAddress", "area"]}
                          value_update={updateMasterState}
                          warn_status={personalAddress.warnArea}
                          required={false}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid sm={6} md={4} lg={3} xl={3}>
                        <SearchDropdown
                          class_name="inputField"
                          title="Country"
                          value={personalAddress.country}
                          attrName={["personalAddress", "country"]}
                          value_update={updateMasterState}
                          data={countryData}
                          warn_status={personalAddress.warnCountry}
                          required={true}
                          error_message="Select Country"
                        />
                      </Grid>

                      <Grid sm={6} md={4} lg={3} xl={3}>
                        <SearchDropdown
                          class_name="inputField"
                          title="State"
                          value={personalAddress.state}
                          attrName={["personalAddress", "state"]}
                          value_update={updateMasterState}
                          data={stateData}
                          warn_status={personalAddress.warnState}
                          error_message="Select state"
                          required={true}
                        />
                      </Grid>

                      <Grid sm={6} md={4} lg={3} xl={3}>
                        <SearchDropdown
                          class_name="inputField"
                          title="City"
                          value={personalAddress.city}
                          attrName={["personalAddress", "city"]}
                          value_update={updateMasterState}
                          data={cityData}
                          warn_status={personalAddress.warnCity}
                          error_message="Select City"
                          required={true}
                        />
                      </Grid>

                      <Grid sm={6} md={4} lg={3} xl={3}>
                        <RKTextField
                          class_name="inputField"
                          title={"Pincode"}
                          value={personalAddress.zip}
                          attrName={["personalAddress", "zip"]}
                          value_update={updateMasterState}
                          warn_status={personalAddress.warnZip}
                          required={true}
                          charcterlimit={6}
                          validation_type={"numeric"}
                          error_messg={
                            personalAddress?.zip &&
                            personalAddress?.zip?.toString().length > 0
                              ? "Enter valid Pincode"
                              : "Enter Pincode"
                          }
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Box>
                {/* <Box className="detailSection">
                <div className="sectionTitle">
                  <h4>Attachments</h4>
                  <div className="actionBtns">
                    <Link
                      className="blueBtn addfile"
                      sx={{ textDecoration: "none", cursor: "pointer" }}
                      onClick={() => {
                        setAttachment(true);
                      }}>
                      Add Document
                    </Link>
                  </div>
                </div>
                <BulkUploadForm
                  open_status={attachment}
                  attrName={setAttachment}
                  value_update={updateMasterState}
                />
                <Attachment
                  open_status={attachment}
                  attrName={setAttachment}
                  value_update={updateMasterState}
                  dataArray={attachmentData}
                />
              </Box>
              <Box className="detailSection">
                <div className="sectionTitle">
                  <h4>Notes</h4>
                  <div className="actionBtns">
                    <Link
                      className="blueBtn addfile"
                      sx={{ textDecoration: "none", cursor: "pointer" }}
                      onClick={() => {
                        setPostQueryForm(true);
                      }}>
                      Add Notes
                    </Link>
                  </div>
                </div>
                <NotesSection data={notesData} />
              </Box> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* <PostQueryForm
        attrName={setPostQueryForm}
        open_status={postQueryForm}
        value_update={updateMasterState}
      /> */}

        {/* Footer Section */}
        <Grid container spacing={3} className="footerSection">
          <Grid xs={12} className="ctaBtn">
            <Button
              onClick={() => {
                navigate(ALL_ROUTES.EMPLOYEE);
                setDisableSave(false);
              }}
              variant="outlined"
              className="borderBtn"
            >
              Cancel
            </Button>
            <Button
              onClick={detail_form_submit}
              variant="contained"
              className="submitBtn"
              disabled={disableSave}
            >
              Save
            </Button>
          </Grid>
        </Grid>
        <PostQueryForm
          attrName={setPostQueryForm}
          open_status={postQueryForm}
          value_update={updateMasterState}
        />
        {/* <Attachment
        open_status={attachment}
        attrName={setAttachment}
        value_update={updateMasterState}
      /> */}
        <NotesSection data={[]} />

        <Modal open={deleteStatus} className="modalWrapper">
          <Box className="modalInner">
            <Grid container spacing={1}>
              <Grid xs={12}>
                <Link
                  className="close-button"
                  onClick={() => {
                    setDeleteStatus(false);
                  }}
                />
                <h5 className="popup-heading">Are you sure ?</h5>
              </Grid>
              <Grid xs={12}>
                <Link className="md-link" underline="none">
                  You are about to Delete this Attatchment.
                </Link>
              </Grid>
              <Grid xs={12} className="ctaBtn" textAlign={"center"}>
                <Button variant="contained" className="submitBtn">
                  Yes, Delete
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Box>
    </>
  );
}

export default AddNewEmployee;
