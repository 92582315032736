import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import React from "react";

interface Props {
  title: string;
  value: any;
  attrName: any;
  value_update: any;
  warn_status: boolean | undefined;
  info_status?: boolean;
  info_message?: string;
  class_name: string;
  error_messg?: string;
  validation_type?: string;
  ref?: any;
  password_status?: boolean;
  type?: string;
  required?: boolean;
  charcterlimit?: number;
  disable?: boolean;
}

const defaultProps: Props = {
  title: "",
  value: "",
  type: "",
  attrName: "",
  value_update: () => { },
  warn_status: false,
  info_status: false,
  class_name: "inputField",
  error_messg: "Please enter valid value",
  info_message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
  password_status: false,

};

const CurrencyTextField: React.FC<Props> = ({
  title,
  type,
  value,
  value_update,
  attrName,
  warn_status,
  class_name,
  error_messg,
  info_status,
  info_message,
  validation_type,
  required,
  charcterlimit,
  disable,
}) => {
  const onChangeText = (updatedValue: string) => {
    if (validation_type === "numeric") {
      let valid_value = updatedValue.replace(/[^0-9]/g, "");
      valid_value = valid_value.trimStart();
      valid_value = valid_value.toString();
      if (charcterlimit && valid_value.toString().length > charcterlimit) {
        return;
      }
      value_update(attrName, valid_value);
    } else {
      value_update(attrName, updatedValue);
    }
  };

  return (
    <div className={class_name}>
      <FormControl fullWidth>
        <InputLabel htmlFor="outlined-adornment-amount">{title}</InputLabel>
        <OutlinedInput
          disabled={disable}
          id="outlined-adornment-amount"
          // startAdornment={<InputAdornment position="start">$</InputAdornment>}
          label={title}
          value={value ? value:""}
          onChange={(e) => {
            onChangeText(e.target.value);
          }}
          required={required}
        />
      </FormControl>

      {info_status === true ? (
        <Tooltip title={info_message === undefined ? "" : info_message}>
          <span className="infoMessage">
            <InfoOutlinedIcon />
          </span>
        </Tooltip>
      ) : null}
      {warn_status === true ? (
        <span className="error">{error_messg}</span>
      ) : null}
    </div>
  );
};

CurrencyTextField.defaultProps = defaultProps;
export default CurrencyTextField;
