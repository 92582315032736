import Employed from "@mui/icons-material/Badge";
import Active from "@mui/icons-material/CheckCircle";
import Terminated from "@mui/icons-material/DirectionsRun";
import Void from "@mui/icons-material/DoDisturbOff";
import Optout from "@mui/icons-material/ExitToApp";
import Inactive from "@mui/icons-material/HighlightOff";
import {
  default as Enrolled
} from "@mui/icons-material/HowToReg";
import EnrollmentRequested from "@mui/icons-material/MarkChatRead";
import Probation from "@mui/icons-material/ModelTraining";
import NA from "@mui/icons-material/NotAccessible";
import Resigned from "@mui/icons-material/SubtitlesOff";
import {
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Tooltip
} from "@mui/material";

import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import { URL_CONSTANTS } from "../../../../../../APIVar/URLConstants";
import NewLoader from "../../../../../../Components/NewLoader/NewLoader";
import { CLIENT_SERVICES } from "../../../../../../Services/Clients/ClientService";
import { EMPLOYEE_SERVICES } from "../../../../../../Services/Employee/EmployeeService";
import { E_BE_FILTER_CODE } from "../../../../../../Services/Enum/E_UGTable";
import { TUserData } from "../../../../../../Services/Types/TUserData";
import { useAppSelector } from "../../../../../../Store/hooks";
import { RootState } from "../../../../../../Store/Store";
import { useFormatCurrency } from "../../../../../../Supporting files/CustomHooks";
import {
  capitalize,
  convertSingleBracketToCurrencyFormat,
  date_time_format,
  getCurrencyCodeRegex
} from "../../../../../../Supporting files/HelpingFunction";
import UGTable from "../../../../../../Supporting files/UGTable/UGTable";

const ClientMemberTab = ({
  clientInfo,
  policyNumber,
  tpa,
  isHealthCard,
}: {
  clientInfo?: any;
  tpa?: any;
  policyNumber?: string;
  isHealthCard?: boolean;
}) => {
  const { formatCurrency } = useFormatCurrency();
  const windowURL = window.location.search;
  const params = new URLSearchParams(windowURL);
  const { timeZone, dateFormat } =
    useAppSelector(
      (state: RootState) => state.userDetailsSlice.localInformation
    );

  const clientId = params.get("id");
  const [displayData, setDisplayData] = useState<any>({
    doc: "",
    warning: false,
    doc_name: "",
  });
  const usertypeinfo = useAppSelector(
    (state: RootState) => state.userDetailsSlice.userType
  );
  const user_type_dateFormat = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation.dateFormat
  );
  const tz = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation.timeZone
  );
  const [showLoader, setShowLoader] = useState(true);
  const id = params.get("id");
  const [contactList, setcontactList] = useState<any[]>([]);
  const updateMasterState = (attrName: any, value: any) => { };

  const [prioritystatusData, setprioritystatusData] = useState<any>([]);
  const [employementStatusData, setEmployementStatusData] = useState<any[]>([]);
  const [prioritystatusDataNominee, setprioritystatusDataNominee] = useState<
    any[]
  >([]);
  const userState: TUserData = useAppSelector(
    (state: RootState) => state.userDetailsSlice
  );
  const [prioritystatusEnrollmentData, setprioritystatusEnrollmentData] =
    useState<any>([]);
  const [headerData, setHeaderData] = useState<Array<{}>>([]);
  const [tableLimit, setTableLimit] = useState(25);
  const [tableSkip, setTableSkip] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [showError, setshowError] = useState(false);
  const [errorSuccessData, setErrorSuccessData] = useState({
    error: [],
    success: [],
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [openUploadEcards, setopenUploadEcards] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadAll = () => {
    memberlistAccordingPolicy(totalRecords, 0, "csv");
    handleClose();
  };

  const currentRoute = E_BE_FILTER_CODE.CLIENTDETAILMEMBER.toString();
  const rowPerPage = sessionStorage.getItem("rowPerPage");
  const parsedData = rowPerPage ? JSON.parse(rowPerPage) : [];
  const existingEntryIndex = parsedData.find(
    (entry: any) => entry.route === currentRoute
  );
  const { BROKER } = useAppSelector((state) => state.permissionSlice);
  const [csvPermission, setCsvPermission] = useState<any>(null);

  const handleDownloadSelected = () => {
    memberlistAccordingPolicy(tableLimit ?? 25, tableSkip ?? 0, "csv");
    handleClose();
  };
  useEffect(() => {
    setCsvPermission(
      BROKER?.Policies.csv === undefined ? false : BROKER.Policies.csv
    );
    get_status_nominee();
    get_status_employement();
    get_status();
    get_status_enrollment();
  }, []);
  let timeout: any;

  useEffect(() => {
    if (timeout) {
      clearTimeout(timeout);
    }
    const delayedApiCall = () => {
      memberlistAccordingPolicy();
    };
    timeout = setTimeout(delayedApiCall, 500);
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [prioritystatusData, prioritystatusEnrollmentData]);


  const uploadEcards = () => {
    if (showError) {
      setshowError(false);
      setopenUploadEcards(false);
      setDisplayData({
        doc: "",
        warning: false,
        doc_name: "",
      });
      return;
    }
    if (!displayData.doc_name) {
      setDisplayData({ ...displayData, warning: true });
      return;
    }
    // Create FormData instance
    const formData = new FormData();
    formData.append("policyId", id ?? "");
    formData.append("file", displayData.doc);

    try {
      setShowLoader(true);
      const user_token = sessionStorage.getItem("usertoken");
      axios
        .post(URL_CONSTANTS.uploadEcards, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            auth: user_token,
          },
        })
        .then((res: any) => {
          let results = res.data.data;
          setshowError(true);
          setShowLoader(false);
          setErrorSuccessData(results);
        });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const get_status = () => {
    const onSuccess = (res: any) => {
      let statusData = res?.data?.data;
      // ?.map((data: any) => ({
      //   label: data.value,
      //   value: data.label,
      // }));
      setprioritystatusData(statusData);
      //memberlistAccordingPolicy();
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    EMPLOYEE_SERVICES.get_status(onSuccess, onError);
  };

  const get_status_enrollment = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data;
      // .map((data: any) => ({
      //   label: data.value,
      //   value: data.label,
      // }));
      setprioritystatusEnrollmentData(statusData);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    EMPLOYEE_SERVICES.get_status_enrolment(onSuccess, onError);
  };
  const get_status_employement = () => {
    const onSuccess = (res: any) => {
      console.log("res", res.data.data);
      let statusData = res.data.data;
      // .map((data: any) => ({
      //   label: data.value,
      //   value: data.label,
      // }));
      setEmployementStatusData(statusData);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    EMPLOYEE_SERVICES.get_status_employement(onSuccess, onError);
  };

  const get_status_nominee = () => {
    const onSuccess = (res: any) => {
      // let statusData = res.data.data.map((data: any) => ({
      //   label: data.value,
      //   value: data.label,
      // }));
      setprioritystatusDataNominee(res.data.data);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    EMPLOYEE_SERVICES.get_status_nominee(onSuccess, onError);
  };

  // get client contact list
  const memberlistAccordingPolicy = (
    limit?: number,
    skip?: number,
    csv?: any
  ) => {
    setShowLoader(true);
    const onSuccess = (res: any) => {
      console.log("response", res);
      let modified = res?.data?.data?.data?.map((data: any) => {
        return {
          ...data,
          id: Date.now() + Math.floor(Math.random() * 1000),
        };
      });
      if (!csv) {
        setcontactList(modified);
        setTotalRecords(res?.data?.data?.countData);
        let riderArray =
          modified[0]?.riders?.length > 0 &&
          modified[0]?.riders
            .map((rider: any) => [
              {
                field: `rider/${rider.name}`,
                headerName: `Member-Rider/${rider.name}`,
                flex: 2,
                minWidth: 200,
                check_status: true,
                renderCell: (ValueFormatterParams: any) => {
                  const { row } = ValueFormatterParams;
                  // console.log("rider",rider.name, row, )
                  return formatCurrency(
                    row?.Userledger?.find(
                      (data: any) => data.riderName === rider.name
                    )?.memberContribution ?? 0
                  );
                },
              },
              {
                field: `CD-Rider/${rider.name}`,
                headerName: `CD-Rider/${rider.name}`,
                flex: 2,
                minWidth: 200,
                check_status: true,
                renderCell: (ValueFormatterParams: any) => {
                  const { row } = ValueFormatterParams;
                  return formatCurrency(
                    row.member.Userledger?.find(
                      (data: any) => data.riderName === rider.name
                    )?.debit ?? 0
                  );
                },
              },
            ])
            .flat();
        let newarr = modified[0]?.riders?.length > 0 ? riderArray : [];
        setHeaderData([
          ...newarr,
          {
            field: "Id",
            headerName: "Member ID",
            flex: 2,
            minWidth: 200,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return row.Id ? row.Id : "---";
            },
          },
          {
            field: "First Name",
            headerName: "First Name",
            flex: 2,
            minWidth: 150,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return row["First Name"];
            },
          },
          {
            field: "Last Name",
            headerName: "Last Name",
            flex: 2,
            minWidth: 150,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return row["Last Name"];
            },
          },
          // {
          //   field: "Userledger",
          //   headerName: "Member Contribution",
          //   flex: 2,
          //   minWidth: 150,
          //   check_status: true,
          //   renderCell: (ValueFormatterParams: any) => {
          //     const { row } = ValueFormatterParams;
          //     return formatCurrency(
          //       row?.member?.Userledger?.find((value: any) => !value.riderName)
          //         ?.memberContribution ?? 0
          //     );
          //   },
          // },
          // {
          //   field: "deletionCredit",
          //   headerName: "Deletion Credit",
          //   flex: 2,
          //   minWidth: 150,
          //   check_status: true,
          //   renderCell: (ValueFormatterParams: any) => {
          //     const { row } = ValueFormatterParams;
          //     return formatCurrency(
          //       row.member.Userledger?.find((value: any) => !value.riderName)
          //         ?.credit ?? 0
          //     );
          //   },
          // },
          // {
          //   field: "edcontribution",
          //   headerName: "CD Contribution",
          //   flex: 2,
          //   minWidth: 150,
          //   check_status: true,
          //   renderCell: (ValueFormatterParams: any) => {
          //     const { row } = ValueFormatterParams;
          //     return formatCurrency(
          //       row.member.Userledger?.find((value: any) => !value.riderName)
          //         ?.debit ?? 0
          //     );
          //   },
          // },
          {
            field: "status",
            headerName: "Member Status",
            minWidth: 200,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { status } = ValueFormatterParams.row;
              return (
                <div id={`_${status}`}>
                  <div className="status_dropdown" id={`_${status}`}>
                    {status === 32 ? (
                      <Active id={`_${status.toString()}`} />
                    ) : status === 33 ? (
                      <Inactive id={`_${status.toString()}`} />
                    ) : status === 34 ? (
                      <Void id={`_${status.toString()}`} />
                    ) : null}
                    <span>
                      {
                        prioritystatusData?.find(
                          (item: any) => item?.value === status
                        )?.label
                      }
                    </span>
                    {/* <SelectDropdown
                      class_name="inputFieldd"
                      title=""
                      value={member.status}
                      attrName={["setPriorityStatus", member]}
                      value_update={updateMasterState}
                      dropdown_data={prioritystatusData}
                      warn_status={false}
                      disabled
                    /> */}
                  </div>
                </div>
              );
            },
          },
          // {
          //   field: "enrollment_status",
          //   headerName: "Enrollment Status",
          //   minWidth: 250,
          //   check_status: true,
          //   renderCell: (ValueFormatterParams: any) => {
          //     const { status} = ValueFormatterParams.row;
          //     return (
          //       <div id={`_${member.enrollment_status}`}>
          //         <div
          //           className="status_dropdown"
          //           id={`_${member.enrollment_status}`}
          //         >
          //           {member.enrollment_status === 40 ? (
          //             <EnrolmentInitated
          //               id={`_${member.enrollment_status?.toString()}`}
          //             />
          //           ) : member.enrollment_status === 41 ? (
          //             <Enrolled
          //               id={`_${member.enrollment_status?.toString()}`}
          //             />
          //           ) : member.enrollment_status === 42 ? (
          //             <Optout id={`_${member.enrollment_status?.toString()}`} />
          //           ) : member.enrollment_status === 43 ? (
          //             <NA id={`_${member.enrollment_status?.toString()}`} />
          //           ) : member.enrollment_status === 60 ? (
          //             <HowToRegIcon
          //               id={`_${member.enrollment_status?.toString()}`}
          //             />
          //           ) : member.enrollment_status === 61 ? (
          //             <Requested
          //               id={`_${member.enrollment_status?.toString()}`}
          //             />
          //           ) : null}
          //           <span>
          //             {
          //               prioritystatusEnrollmentData?.find(
          //                 (item: any) =>
          //                   item?.value === member?.enrollment_status
          //               )?.label
          //             }
          //           </span>
          //           {/* <SelectDropdown
          //             class_name="inputFieldd"
          //             title=""
          //             value={member.enrollment_status}
          //             attrName={["setPriorityStatus", member]}
          //             value_update={updateMasterState}
          //             dropdown_data={prioritystatusEnrollmentData}
          //             warn_status={false}
          //             disabled
          //           /> */}
          //         </div>
          //       </div>
          //     );
          //   },
          // },
          {
            field: "employement_status",
            headerName: "Employment Status",
            minWidth: 200,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { index } = ValueFormatterParams;
              let { employement_status } = ValueFormatterParams.row;
              const row = ValueFormatterParams.row;
              employement_status =
                row["Member Type"] === "Employee" ? row.employement_status : 39;
              return (
                <div
                  id={`_${row.employement_status}`}
                  style={{ borderRadius: "20px" }}
                >
                  <div
                    className="status_dropdown"
                    id={`_${row.employement_status?.toString()}`}
                  >
                    {row["Member Type"] !== "Employee" ? (
                      <NA id={`_${row.employement_status?.toString()}`} />
                    ) : row.employement_status === 35 ? (
                      <Resigned id={`_${row.employement_status?.toString()}`} />
                    ) : row.employement_status === 36 ? (
                      <Terminated
                        id={`_${row.employement_status?.toString()}`}
                      />
                    ) : row.employement_status === 37 ? (
                      <Employed id={`_${row.employement_status?.toString()}`} />
                    ) : row.employement_status === 38 ? (
                      <Probation
                        id={`_${row.employement_status?.toString()}`}
                      />
                    ) : row.employement_status === 39 ? (
                      <NA id={`_${row.employement_status?.toString()}`} />
                    ) : null}
                    {row["Member Type"] === "Employee" ? (
                      <span>
                        {
                          employementStatusData?.find(
                            (item: any) =>
                              item?.value === row?.employement_status
                          )?.label
                        }
                      </span>
                    ) : (
                      // <SelectDropdown
                      //   class_name="inputFieldd"
                      //   title=""
                      //   value={member.employement_status}
                      //   attrName={["updatestatusEmployment", member, index]}
                      //   value_update={updateMasterState}
                      //   dropdown_data={employementStatusData}
                      //   warn_status={false}
                      //   disabled
                      // />
                      <span>NA</span>
                    )}
                  </div>
                </div>
              );
            },
          },
          {
            field: "Gender",
            headerName: "Gender",
            flex: 2,
            minWidth: 150,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return capitalize(row.Gender);
            },
          },
          {
            field: "Date Of Birth",
            headerName: "Date Of Birth",
            flex: 2,
            minWidth: 200,
            check_status: true,
            valueGetter: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return date_time_format(
                row["Date Of Birth"],
                dateFormat,
                timeZone
              );
            },
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return date_time_format(
                row["Date Of Birth"],
                dateFormat,
                timeZone
              );
            },
          },
          {
            field: "Contact Number",
            headerName: "Contact Number",
            flex: 2,
            minWidth: 200,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return row["Contact Number"];
            },
          },
          {
            field: "Email",
            headerName: "Email",
            flex: 2,
            minWidth: 200,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return row["Email"];
            },
          },
          {
            field: "Department",
            headerName: "Department",
            flex: 2,
            minWidth: 200,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return row["Department"];
            },
          },
          {
            field: "Designation",
            headerName: "Designation",
            flex: 2,
            minWidth: 200,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return row["Designation"];
            },
          },
          {
            field: "Date Of Joining",
            headerName: "Date Of Joining",
            flex: 2,
            minWidth: 200,
            check_status: true,
            valueGetter: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return row["Date Of Joining"]
                ? date_time_format(row["Date Of Joining"], dateFormat, timeZone)
                : "--";
            },
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return row["Date Of Joining"]
                ? date_time_format(row["Date Of Joining"], dateFormat, timeZone)
                : "--";
            },
          },
          {
            field: "Official Email",
            headerName: "Official Email",
            flex: 2,
            minWidth: 200,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return row["Official Email"];
            },
          },
          {
            field: "Office Contact Number",
            headerName: "Office Contact Number",
            flex: 2,
            minWidth: 200,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return row["Office Contact Number"];
            },
          },
          {
            field: "Salary Bracket",
            headerName: "Salary Bracket",
            flex: 2,
            minWidth: 200,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return row["Salary Bracket"];
            },
          },
          {
            field: "Salary",
            headerName: "Salary",
            flex: 2,
            minWidth: 200,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return row["Salary"] ? formatCurrency(row["Salary"]) : "---";
            },
          },
          {
            field: "Member Type",
            headerName: "Member Type",
            flex: 2,
            minWidth: 200,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return row["Member Type"];
            },
          },
          {
            field: "Organization Employee ID",
            headerName: "Organization Employee ID",
            flex: 2,
            minWidth: 200,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return row["Organization Employee ID"];
            },
          },
          {
            field: "Organization Location",
            headerName: "Organization Location",
            flex: 2,
            minWidth: 200,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return row["Location"];
            },
          },
          {
            field: "Relationship With Employee",
            headerName: "Relationship With Employee",
            flex: 2,
            minWidth: 200,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return row["Relationship With Employee"];
            },
          },
          {
            field: "Primary Employee",
            headerName: "Primary Employee",
            flex: 2,
            minWidth: 200,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return row["Primary Employee"] ? row["Primary Employee"] : "---";
            },
          },
          {
            field: "Last Day of Working",
            headerName: "Last Day of Working",
            flex: 2,
            minWidth: 200,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return row["Last Day of Working"]
                ? date_time_format(
                  row["Last Day of Working"],
                  dateFormat,
                  timeZone
                )
                : "---";
            },
          },
          {
            field: "policyId",
            headerName: "Policy Id",
            flex: 2,
            minWidth: 200,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return row["policyId"];
            },
          },
          {
            field: "policyNumber",
            headerName: "Policy Number",
            flex: 2,
            minWidth: 200,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return row["policyNumber"];
            },
          },
          {
            field: "productType",
            headerName: "Product Type",
            flex: 2,
            minWidth: 200,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return row["productType"];
            },
          },
          {
            field: "grade",
            headerName: "Grade",
            flex: 2,
            minWidth: 200,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return row["grade"] ?? "--";
            },
          },
          {
            field: "sumInsured",
            headerName: "SI/SA",
            flex: 2,
            minWidth: 200,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return row["sumInsured"]
                ? formatCurrency(row["sumInsured"])
                : "--";
            },
          },
          {
            field: "policyStartDate",
            headerName: "Policy StartDate",
            flex: 2,
            minWidth: 200,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return date_time_format(
                row["policyStartDate"],
                dateFormat,
                timeZone
              );
            },
          },
          {
            field: "policyEndDate",
            headerName: "Policy EndDate",
            flex: 2,
            minWidth: 200,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return date_time_format(
                row["policyEndDate"],
                dateFormat,
                timeZone
              );
            },
          },
          {
            field: "tpa",
            headerName: "Tpa",
            flex: 2,
            minWidth: 200,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return row["tpa"];
            },
          },
          {
            field: "insurer",
            headerName: "Insurer",
            flex: 2,
            minWidth: 200,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return row["insurer"];
            },
          },
          {
            field: "createdAt",
            minWidth: 200,
            check_status: true,
            headerName: "Created On",
            valueGetter: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return date_time_format(row.createdAt, dateFormat, timeZone)
            },
          },
          {
            field: "updatedAt",
            minWidth: 200,
            check_status: true,
            headerName: "Updated On",
            valueGetter: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return date_time_format(row.updatedAt, dateFormat, timeZone)
            },
          },
          // {
          //   field: "fullName",
          //   headerName: "Nominee Name",
          //   flex: 2,
          //   minWidth: 200,
          //   check_status: true,
          //   renderCell: (ValueFormatterParams: any) => {
          //     const { row } = ValueFormatterParams;
          //     return row.member.nomineeDetail?.length > 0
          //       ? row.member.nomineeDetail
          //           ?.slice(0, 1)
          //           ?.map((nom: any) => nom?.fullName)
          //           ?.map((item: any) => `${item}`) + " ..." // ?.join(", ")
          //       : "--";
          //     // return row.member.nomineeDetail?.length > 0
          //     //   ? row.member.nomineeDetail?.map((nom: any) => nom?.fullName)
          //     //     ?.map((item: any) => `${item}`)
          //     //     ?.join(", ")
          //     //   : "--";
          //   },
          // },
          {
            field: "fullName",
            headerName: "Nominee Name",
            flex: 2,
            minWidth: 200,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;

              const nomineeNames =
                row.nomineeDetail?.length > 0
                  ? row.nomineeDetail
                    .slice(0, 1)
                    .map((nom: any) => nom?.fullName)
                    .map((item: any) => `${item}`) + " ..."
                  : "--";

              const fullNomineeList =
                row.nomineeDetail
                  ?.map((nom: any) => nom?.fullName)
                  ?.join(", ") || "--";

              return (
                <Tooltip
                  title={fullNomineeList}
                  arrow
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: "white",
                        color: "black",
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Add shadow
                        fontSize: "14px",
                      },
                    },
                    arrow: {
                      sx: {
                        color: "white", // Arrow color matches the tooltip background
                      },
                    },
                  }}
                >
                  <span>{nomineeNames}</span>
                </Tooltip>
              );
            },
          },
          // {
          //   field: "relation",
          //   headerName: "Nominee Relationship With Employee",
          //   flex: 2,
          //   minWidth: 350,
          //   check_status: true,
          //   renderCell: (ValueFormatterParams: any) => {
          //     const { row } = ValueFormatterParams;
          //     return row.member.nomineeDetail?.length > 0
          //       ? row.member.nomineeDetail
          //           ?.slice(0, 1)
          //           ?.map((nom: any) => nom?.relation)
          //           ?.map((item: any) => `${item}`) + " ..." // ?.join(", ")
          //       : "--";
          //   },
          // },
          {
            field: "relation",
            headerName: "Nominee Relationship With Employee",
            flex: 2,
            minWidth: 350,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;

              const nomineeRelation =
                row.nomineeDetail?.length > 0
                  ? row.nomineeDetail
                    ?.slice(0, 1)
                    ?.map((nom: any) => nom?.relation)
                    ?.map((item: any) => `${item}`) + " ..."
                  : "--";

              const fullRelationList =
                row.nomineeDetail
                  ?.map((nom: any) => nom?.relation)
                  ?.join(", ") || "--";

              return (
                <Tooltip
                  title={fullRelationList}
                  arrow
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: "white",
                        color: "black",
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Add shadow
                        fontSize: "14px",
                      },
                    },
                    arrow: {
                      sx: {
                        color: "white", // Arrow color matches the tooltip background
                      },
                    },
                  }}
                >
                  <span>{nomineeRelation}</span>
                </Tooltip>
              );
            },
          },
          // {
          //   field: "dob",
          //   headerName: "Nominee DOB",
          //   flex: 2,
          //   minWidth: 200,
          //   check_status: true,
          //   renderCell: (ValueFormatterParams: any) => {
          //     const { row } = ValueFormatterParams;
          //     return row.member.nomineeDetail?.length > 0
          //       ? row.member.nomineeDetail
          //           ?.slice(0, 1)
          //           ?.map((nom: any) => nom?.dob)
          //           ?.map((item: any) =>
          //             date_time_format(item, dateFormat, timeZone)
          //           ) + " ..." // ?.join(", ")
          //       : "--";
          //   },
          // },
          {
            field: "dob",
            headerName: "Nominee DOB",
            flex: 2,
            minWidth: 200,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;

              const nomineeDOB =
                row?.nomineeDetail?.length > 0
                  ? row?.nomineeDetail
                    ?.slice(0, 1)
                    ?.map((nom: any) => nom?.dob)
                    ?.map((item: any) =>
                      date_time_format(item, dateFormat, timeZone)
                    ) + " ..."
                  : "--";

              const fullDOBList =
                row?.nomineeDetail
                  ?.map((nom: any) =>
                    date_time_format(nom?.dob, dateFormat, timeZone)
                  )
                  ?.join(", ") || "--";

              return (
                <Tooltip
                  title={fullDOBList}
                  arrow
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: "white",
                        color: "black",
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Add shadow
                        fontSize: "14px",
                      },
                    },
                    arrow: {
                      sx: {
                        color: "white", // Arrow color matches the tooltip background
                      },
                    },
                  }}
                >
                  <span>{nomineeDOB}</span>
                </Tooltip>
              );
            },
          },
          // {
          //   field: "Nominee Status",
          //   headerName: "Nominee Status",
          //   flex: 2,
          //   minWidth: 250,
          //   check_status: true,
          //   renderCell: (ValueFormatterParams: any) => {
          //     const { row } = ValueFormatterParams;
          //     if (
          //       row?.nomineeDetail?.length > 0 &&
          //       prioritystatusDataNominee?.length > 0
          //     ) {
          //       return (
          //         <div
          //           style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}
          //         >
          //           {row?.nomineeDetail?.slice(0, 1)?.map((nom: any) => {
          //             const status = prioritystatusDataNominee?.find(
          //               (status) => status.value === nom.enrolment_status
          //             );
          //             if (!status) {
          //               return null;
          //             }
          //             return (
          //               <div
          //                 key={nom.enrolment_status}
          //                 id={`_${nom.enrolment_status?.toString()}`}
          //                 style={{ width: "fit-content" }}
          //               >
          //                 <div
          //                   className="status_dropdown"
          //                   id={`_${nom.enrolment_status?.toString()}`}
          //                 >
          //                   {nom.enrolment_status === 44 ? (
          //                     <Enrolled
          //                       id={`_${nom.enrolment_status?.toString()}`}
          //                     />
          //                   ) : nom.enrolment_status === 45 ? (
          //                     <Optout
          //                       id={`_${nom.enrolment_status?.toString()}`}
          //                     />
          //                   ) : (
          //                     (nom.enrolment_status === 63 ||
          //                       nom.enrolment_status === 64) && (
          //                       <EnrollmentRequested
          //                         id={`_${nom.enrolment_status?.toString()}`}
          //                       />
          //                     )
          //                   )}
          //                   <span>{status.label}</span>
          //                 </div>
          //               </div>
          //             );
          //           })}
          //         </div>
          //       );
          //     } else {
          //       return "--";
          //     }
          //   },
          // },
          {
            field: "Nominee Status",
            headerName: "Nominee Status",
            flex: 2,
            minWidth: 250,
            check_status: true,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;

              if (
                row?.nomineeDetail?.length > 0 &&
                prioritystatusDataNominee?.length > 0
              ) {
                // Get all statuses for the tooltip
                const allStatuses = row?.nomineeDetail
                  ?.map((nom: any) => {
                    const status = prioritystatusDataNominee?.find(
                      (status) => status.value === nom.enrolment_status
                    );
                    return status ? `${status?.label}` : null;
                  })
                  .filter(Boolean); // Filter out null values

                // Join the statuses for tooltip display
                const statusInfo = allStatuses.join(", ");

                // Display only the first nominee's status in the cell
                const firstNominee = row?.nomineeDetail[0];
                const firstStatus = prioritystatusDataNominee?.find(
                  (status) => status.value === firstNominee.enrolment_status
                );

                return (
                  <Tooltip
                    title={statusInfo} // Show all statuses in the tooltip
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: "white",
                          color: "black",
                          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Add shadow
                          fontSize: "14px",
                        },
                      },
                      arrow: {
                        sx: {
                          color: "white", // Arrow color matches tooltip background
                        },
                      },
                    }}
                  >
                    <div
                      style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}
                    >
                      {firstNominee && firstStatus && (
                        <div
                          key={firstNominee.enrolment_status}
                          id={`_${firstNominee.enrolment_status?.toString()}`}
                          style={{ width: "fit-content" }}
                        >
                          <div
                            className="status_dropdown"
                            id={`_${firstNominee.enrolment_status?.toString()}`}
                          >
                            {firstNominee.enrolment_status === 44 ? (
                              <Enrolled
                                id={`_${firstNominee.enrolment_status?.toString()}`}
                              />
                            ) : firstNominee.enrolment_status === 45 ? (
                              <Optout
                                id={`_${firstNominee.enrolment_status?.toString()}`}
                              />
                            ) : (
                              (firstNominee.enrolment_status === 63 ||
                                firstNominee.enrolment_status === 64) && (
                                <EnrollmentRequested
                                  id={`_${firstNominee.enrolment_status?.toString()}`}
                                />
                              )
                            )}
                            <span>{firstStatus.label}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </Tooltip>
                );
              } else {
                return "--";
              }
            },
          },
        ]);
      }
      setTimeout(() => {
        setShowLoader(false);
      }, 1000);

      if (csv && modified.length > 0) {
        console.log("modified", modified)
        downloadCsv(modified);
      }
    };
    const onError = (err: any) => {
      setShowLoader(false);
      console.log(".....error", err);
    };
    EMPLOYEE_SERVICES.memberlistAccordingClient(
      onSuccess,
      onError,
      clientId ?? "",
      usertypeinfo.toLowerCase(),
      limit ? limit : existingEntryIndex?.pageNumber ?? 25,
      skip ? skip : 0
    );
  };

  // const downloadCsv = (csvData: any) => {

  //   console.log("csvData", csvData);
  //   const onSuccess = async (res: any) => {
  //     const data = await res;
  //     const blob = new Blob([data.data], { type: "text/csv" });
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = "members.csv";
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //     window.URL.revokeObjectURL(url);
  //     alert("hhh");
  //   };
  //   const onError = (err: any) => {
  //     console.log("sucess", err);
  //   };
  //   const transformedData = csvData.map((item: any) => {
  //     const { member, ...rest } = item;
  //     return { ...member, ...rest };
  //   });
  //   const updatedArr = transformedData?.map((item: any) => {
  //     const statusObj: any =
  //       prioritystatusData &&
  //       prioritystatusData?.find((status: any) => status.value === item.status);
  //     const employement_status: any =
  //       employementStatusData &&
  //       employementStatusData?.find(
  //         (status: any) => status.value === item.employement_status
  //       );
  //     const enrollment_status: any =
  //       prioritystatusEnrollmentData &&
  //       prioritystatusEnrollmentData?.find(
  //         (status: any) => status.value === item.enrollment_status
  //       );

  //     const getNomineeStatus = () => {
  //       if (
  //         item?.nomineeDetail?.length > 0 &&
  //         prioritystatusDataNominee?.length > 0
  //       ) {
  //         return item?.nomineeDetail
  //           .map((nom: any) => {
  //             const status = prioritystatusDataNominee?.find(
  //               (status) => status.value === nom.enrolment_status
  //             );
  //             return status && status.label;
  //           })
  //           .join(", ");
  //       } else {
  //         return "--";
  //       }
  //     };
  //     let riderObject: any = {};

  //     if (contactList[0]?.riders?.length > 0) {
  //       contactList[0].riders.forEach((rider: any) => {
  //         riderObject[`Member-Rider/${rider.name}`] = formatCurrency(
  //           item.Userledger?.find((data: any) => data.riderName === rider.name)
  //             ?.memberContribution ?? 0
  //         );
  //         riderObject[`CD-Rider/${rider.name}`] = formatCurrency(
  //           item.Userledger?.find((data: any) => data.riderName === rider.name)
  //             ?.debit ?? 0
  //         );
  //       });
  //     }

  //     // Now `riderObject` contains the key-value pairs.

  //     if (statusObj) {
  //       return {
  //         ...riderObject,
  //         "Member Contribution": formatCurrency(
  //           item.Userledger?.find((value: any) => !value.riderName)
  //             ?.memberContribution ?? 0
  //         )?.replace(/₹/g, "Rs"),
  //         "Deletion Credit": formatCurrency(
  //           item.Userledger?.find((value: any) => !value.riderName)?.credit ?? 0
  //         )?.replace(/₹/g, "Rs"),
  //         "CD Contribution": formatCurrency(
  //           item.Userledger?.find((value: any) => !value.riderName)?.debit ?? 0
  //         )?.replace(/₹/g, "Rs"),
  //         Title: item?.Title,
  //         "Member ID": item?.Id,
  //         "Member Status": statusObj.label,
  //         "Employment Status": employement_status.label,
  //         "Enrollment Status": enrollment_status.label,
  //         "First Name": item["First Name"],
  //         "Last Name": item["Last Name"],
  //         Gender: item?.Gender,
  //         "Date Of Birth":
  //           item["Date Of Birth"] !== "--"
  //             ? date_time_format(item["Date Of Birth"], dateFormat, timeZone)
  //             : item["Date Of Birth"],
  //         "Contact Number": item["Contact Number"],
  //         Email: item.Email,
  //         Department: item.Department,
  //         Designation: item.Designation,
  //         "Date Of Joining":
  //           item["Date Of Joining"] !== "--" && item["Date Of Joining"] !== ""
  //             ? date_time_format(item["Date Of Joining"], dateFormat, timeZone)
  //             : item["Date Of Joining"],
  //         "Last Day of Working":
  //           item["Last Day of Working"] !== "" &&
  //           item["Last Day of Working"] !== undefined
  //             ? date_time_format(
  //                 item["Last Day of Working"],
  //                 dateFormat,
  //                 timeZone
  //               )
  //             : "--",
  //         "Official Email": item["Official Email"],
  //         "Office Contact Number": item["Office Contact Number"],
  //         "SI/SA": formatSumInsured(item?.sumInsured, formatCurrency),
  //         "Salary Bracket":
  //           item["Salary Bracket"] !== "" &&
  //           item["Salary Bracket"] !== undefined
  //             ? convertSingleBracketToCurrencyFormat(
  //                 item["Salary Bracket"],
  //                 getCurrencyCodeRegex(currency),
  //                 countryCurrencyCode,
  //                 true
  //               ).replace(/₹/g, "Rs")
  //             : item["Salary Bracket"],
  //         Salary:
  //           item?.Salary !== "--" && item?.Salary !== undefined
  //             ? formatCurrency(item["Salary"])?.replace(/₹/g, "Rs")
  //             : item?.Salary,
  //         "Policy Id": item?.policyId,
  //         "Policy Number": item?.policyNumber,
  //         "Policy StartDate":
  //           item?.policyStartDate !== "--"
  //             ? date_time_format(item.policyStartDate, dateFormat, timeZone)
  //             : item.policyStartDate,
  //         "Policy EndDate":
  //           item?.policyEndDate !== "--"
  //             ? date_time_format(item.policyEndDate, dateFormat, timeZone)
  //             : item.policyEndDate,
  //         "Product Type": item?.productType,
  //         Tpa: item?.tpa,
  //         Insurer: item?.insurer,
  //         Grade: item?.grade,
  //         "Member Type": item["Member Type"],
  //         "Organization Employee ID": item["Organization Employee ID"],
  //         "Organisation Location": item.Location,
  //         "Relationship With Employee": item["Relationship With Employee"],
  //         "Primary Employee": item["Primary Employee"],
  //         Client: item.Client,
  //         "Nominee Name":
  //           item?.nomineeDetail?.length > 0
  //             ? item?.nomineeDetail
  //                 ?.map((nom: any) => nom?.fullName)
  //                 ?.map((item: any) => `${item}`)
  //                 ?.join(", ")
  //             : "--",
  //         "Nominee Relationship With Employee":
  //           item?.nomineeDetail?.length > 0
  //             ? item?.nomineeDetail
  //                 ?.map((nom: any) => nom?.relation)
  //                 ?.map((item: any) => `${item}`)
  //                 ?.join(", ")
  //             : "--",
  //         "Nominee DOB":
  //           item?.nomineeDetail?.length > 0
  //             ? item?.nomineeDetail
  //                 ?.map((nom: any) => nom?.dob)
  //                 ?.map(
  //                   (item: any) =>
  //                     `${date_time_format(item, dateFormat, timeZone)}`
  //                 )
  //                 ?.join(", ")
  //             : "--",
  //         "Nominee Status": getNomineeStatus(),
  //         "Created On": date_time_format(item?.createdAt, dateFormat, timeZone),
  //         "Updated On": date_time_format(item?.updatedAt, dateFormat, timeZone),
  //       };
  //     }
  //     // const empStatusObj: any = employementStatusData && employementStatusData?.find((status: any) => status.label === item.status);
  //     // if (empStatusObj) {
  //     //   return {
  //     //     ...item, employement_status: empStatusObj?.value,
  //     //   };
  //     // }

  //     return item;
  //   });

  //   headerData.forEach((item: any) => {
  //     item.field = item.headerName;
  //   });
  //   // const columnMap: any = {};
  //   // headerData.forEach((item: any) => {
  //   //   columnMap[item.field] = item
  //   // })

  //   let dto = {
  //     headers: headerData,
  //     data: updatedArr,
  //   };

  //   CLIENT_SERVICES.csvDownload(
  //     onSuccess,
  //     onError,
  //     dto,
  //     usertypeinfo.toLowerCase(),
  //     "member"
  //   );
  // };

  const downloadCsv = (data: any) => {
    const onSuccess = async (res: any) => {
      const data = await res;
      const blob = new Blob([data.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "members.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    };
    const onError = (err: any) => {
      console.log("sucess", err);
    };
    const updatedArr = data?.map((item: any) => {
      const statusObj: any =
        prioritystatusData?.find((status: any) => status.value === item.status);
      const employeeStatusObj: any =
        employementStatusData?.find(
          (status: any) => status.value === item.employement_status
        );
      return {
        Title: item?.Title,
        Id: item?.Id,
        "First Name": item["First Name"],
        "Last Name": item["Last Name"],
        Gender: item?.Gender,
        "Date Of Birth": item["Date Of Birth"] !== "--"
          ? date_time_format(item["Date Of Birth"], dateFormat, timeZone)
          : item["Date Of Birth"],
        "status": statusObj.label,
        "employement_status": employeeStatusObj?.label,
        "Contact Number": item["Contact Number"],
        Email: item.Email,
        Department: item.Department,
        Designation: item.Designation,
        "Date Of Joining":
        item["Date Of Joining"] === "" ? "--":
          item["Date Of Joining"] !== "--"
            ? date_time_format(
              item["Date Of Joining"],
              user_type_dateFormat,
              tz
            )
            : item["Date Of Joining"],
        "Last Day of Working":
          item["Last Day of Working"] && item["Last Day of Working"] !== "--"
            ? date_time_format(
              item["Last Day of Working"],
              user_type_dateFormat,
              tz
            )
            : "--",
        "Official Email": item["Official Email"],
        "Office Contact Number": item["Office Contact Number"],
        "Salary Bracket":item["Salary Bracket"] === "" ? "--" : 
          item["Salary Bracket"] !== "--" &&
            item["Salary Bracket"] !== undefined
            ? convertSingleBracketToCurrencyFormat(
              item["Salary Bracket"],
              getCurrencyCodeRegex(userState.localInformation.currency),
              userState?.localInformation?.countryCurrencyCode,
              true
            ).replace(/₹/g, "Rs")
            : item["Salary Bracket"],
        Salary:
          item?.Salary !== "--" && item?.Salary !== undefined
            ? formatCurrency(item["Salary"])?.replace(/₹/g, "Rs")
            : item?.Salary,
        "Member Type": item["Member Type"],
        "Organization Employee ID": item["Organization Employee ID"],
        "Organisation Location": item.Location,
        "Relationship With Employee": item["Relationship With Employee"],
        "Primary Employee": item["Primary Employee"],
        Client: item.Client,
        "Created On": date_time_format(
          item?.createdAt,
          user_type_dateFormat,
          tz
        ),
        "Updated On": date_time_format(
          item?.updatedAt,
          user_type_dateFormat,
          tz
        ),
      };


    });

    columns.forEach((item: any) => {
      item.field = item.headerName;
    });

    let dto = {
      headers: headerData,
      data: updatedArr,
    };

    CLIENT_SERVICES.csvDownload(
      onSuccess,
      onError,
      dto,
      usertypeinfo.toLowerCase(),
      "member"
    );
  };
  const downloadEcards = async () => {
    try {
      const response = await axios({
        url: URL_CONSTANTS.download_ecard(id ?? "", usertypeinfo.toLowerCase()),
        method: "GET",
        responseType: "blob",
        headers: {
          auth: `${sessionStorage.getItem("usertoken")}`,
        },
      });

      // Extract filename from Content-Disposition header if available
      const contentDisposition: any = response.headers["content-disposition"];
      let fileName = `ecards.zip`;

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(
          /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        );
        if (fileNameMatch && fileNameMatch[1]) {
          fileName = fileNameMatch[1].replace(/['"]/g, "");
        }
      }

      // Create a URL for the Blob and initiate a download
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;

      // Set the filename from the blob metadata or fallback if no filename was provided
      link.setAttribute("download", fileName);
      // Append link to the body and trigger a click
      document.body.appendChild(link);
      link.click();

      // Clean up after download
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error: any) {
      if (error.response.status === 404) {
        toast.error("No E-Cards Available");
      }
      console.error("Error downloading the ZIP file:", error);
    }
  };
  const columns = [
    {
      field: "fileName",
      headerName: "File Name",
      minWidth: 250,
      // disableColumnMenu: true,
      sortable: false,
      // check_status: true,
    },
    {
      field: "reason",
      headerName: "Reason",
      minWidth: 250,
      check_status: true,
      // disableColumnMenu: true,
      sortable: false,
    },
  ];

  return (
    <>
      {showLoader && <NewLoader />}

      <Grid container padding={0}>
        {/* <Members /> */}

        <Grid xs={12} textAlign={"right"}>
          <Box>
            <Grid container>
              <Grid xs={12} className="mt-7">
                {contactList?.length > 0 && csvPermission && (
                  <>
                    <Button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                      className="blueBtn download"
                      style={{ paddingLeft: 30, textTransform: "unset" }}
                    >
                      Download CSV
                    </Button>

                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={handleDownloadAll}>All Items</MenuItem>
                      <MenuItem onClick={handleDownloadSelected}>
                        Selected Items
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </Grid>
              <Grid xs={12} marginTop={"12px"}>
                <UGTable
                  header_data={headerData}
                  data={contactList}
                  value_update={updateMasterState}
                  attrName={"UGTable"}
                  totalRecords={totalRecords}
                  BE_filter_code={E_BE_FILTER_CODE.CLIENTDETAILMEMBER}
                  refetch_data={memberlistAccordingPolicy}
                  setTableLimit={setTableLimit}
                  setTableSkip={setTableSkip}
                  setPageNumberData={setPageNumber}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {/* <Grid style={{ height: "600px", width: "100%" }}>
          <UGTable
            header_data={headerData}
            data={contactList}
            value_update={updateMasterState}
            attrName={"UGTable"}
            totalRecords={totalRecords}
            BE_filter_code={E_BE_FILTER_CODE.EMPLOYEE}
            refetch_data={memberlistAccordingPolicy}
            setTableLimit={setTableLimit}
            setTableSkip={setTableSkip}
            setPageNumberData={setPageNumber}
          />
        </Grid> */}
      </Grid>
    </>
  );
};

export default ClientMemberTab;
