import Rejected from "@mui/icons-material/Cancel";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Expired from "@mui/icons-material/GppBad";
import Requested from "@mui/icons-material/MarkChatRead";
import SentToTPA from "@mui/icons-material/MarkEmailRead";
import Required from "@mui/icons-material/TipsAndUpdates";
import Approved from "@mui/icons-material/Verified";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Link,
  Modal,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Attachment from "../../../../../Components/Broker/Attachment/Attachment";
import NotesSection from "../../../../../Components/Broker/NotesSection/NotesSection";
import BulkUploadForm from "../../../../../Components/Common/BulkUploadFormSP/BulkUploadForm";
import PostQueryForm from "../../../../../Components/Common/PostQuerySP/PostQueryForm";
import { employeeDTO } from "../../../../../Services/DTO/Employee/EmployeeDTO";
import { ENDORSEMENT_SERVICES } from "../../../../../Services/Endorsement/EndorsementService";
import { RootState } from "../../../../../Store/Store";
import { useAppSelector } from "../../../../../Store/hooks";
import CheckBox from "../../../../../Supporting files/CheckBox/CheckBox";
import CurrencyTextField from "../../../../../Supporting files/Currency/CurrencyTextField";
import { useFormatCurrency } from "../../../../../Supporting files/CustomHooks";
import DatePicker from "../../../../../Supporting files/DatePicker/DatePicker";
import {
  customFormatDate,
  customFormatTime,
  date_time_format,
  validateEmail,
  validateMobileNumber,
} from "../../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import TextAreaField from "../../../../../Supporting files/TextAreaField/TextAreaField";
import "../../../../../formLayout.scss";
import { URL_CONSTANTS } from "../../../../../APIVar/URLConstants";
import ConfirmationPopup from "../../../../../Components/Broker/ConfirmationPopUp/ConfirmationPopup";

function EndorsementDetailMemberAddition() {
  const navigate = useNavigate();
  const { formatCurrency } = useFormatCurrency();
  const dateFormat = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation.dateFormat
  );
  const tz = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation.timeZone
  );
  const { ["Organization Email"]: userEmail } = useAppSelector(
    (state: RootState) => state.userDetailsSlice
  );
  const windowURL = window.location.search;
  const params = new URLSearchParams(windowURL);
  const id = params.get("id");
  const policyId = params.get("policyId");
  const memberId = params.get("memberId");
  const [formData, setFormData] = useState<employeeDTO[]>([]);
  const [dataArray, setDataArray] = useState<any[]>([]);
  const [endorsementData, setendorsementData] = useState<any>({});
  const [personalAddressDetails, setpersonalAddressDetails] = useState<any>({});
  const [employeeDetails, setemployeeDetails] = useState<any>({});
  const [policyData, setpolicyData] = useState<any>({});
  const [clientId, setclientId] = useState<string>("");
  const [memberType, setmemberType] = useState<string>("");
  const [viewTabsStatus, setViewTabsStatus] = useState("overview");
  const [notesData, setNotesData] = useState<
    Array<{ name: string; date: Date; description: string }>
  >([]);
  const [attachment, setAttachment] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [removeStatus, setRemoveStatus] = useState(false);
  const [uploadFile, setUploadFile] = useState(false);
  const [priorityStatus, setPriorityStatus] = useState("Pending");
  const [sectionStatus, SetSectionStatus] = useState<string>("personalDetails");
  const [subSection, setSubSection] = useState("memberdetails");
  const [postQueryForm, setPostQueryForm] = useState(false);
  const [prioritystatusData, setprioritystatusData] = useState([]);
  const endorsementInfo = useRef(null);
  const attatchmentsdetails = useRef(null);
  const endorsementDetails = useRef(null);
  const AddressDetails = useRef(null);
  const PersonalAddressDetails = useRef(null);
  const PoliciesDetails = useRef(null);
  const { userType } = useAppSelector(
    (state: RootState) => state.userDetailsSlice
  );
  const [hrStatusData, setHrStatusData] = useState([]);
  const [hrStatusValue, sethrStatusValue] = useState<any>(75);
  const [selectedEndorsementValue, setselectedEndorsementValue] = useState<any>(
    {}
  );
  const [statusWarningPopoup, setstatusWarningPopoup] =
    useState<boolean>(false);
  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClicks = (id: any) => {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const get_status = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => {
        return { label: data.value, value: data.label };
      });
      setprioritystatusData(statusData);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    ENDORSEMENT_SERVICES.get_status(onSuccess, onError);
  };

  useEffect(() => {
    get_Hr_status();
    get_status();
    getDetails();
    get_Endorsement_notes();
  }, []);

  const update_status = (value: number, id: string) => {
    const onSuccess = (res: any) => {
      getDetails();
    };
    const onError = (err: any) => {
      toast.warn(err.response.data.errors[0].message);
    };
    ENDORSEMENT_SERVICES.hr_update_status(
      {
        value: value,
      },
      onSuccess,
      onError,
      id
    );
  };

  const endorsement_notes = (dto: any) => {
    let params = {
      endorsementId: id,
      notes: {
        description: dto,
        name: userEmail,
        date: new Date(),
      },
    };
    const onSuccess = (res: any) => {
      get_Endorsement_notes();
    };
    const onError = (err: any) => {
      console.log("errr", err);
    };

    ENDORSEMENT_SERVICES.endorsement_notes(
      params,
      onSuccess,
      onError,
      userType.toLowerCase()
    );
  };

  const get_Endorsement_notes = () => {
    const onSuccess = (res: any) => {
      setNotesData(res.data.data.notes);
    };
    const onError = (err: any) => {
      console.log("err2", err);
    };
    ENDORSEMENT_SERVICES.get_addition_deletion_notes(
      URL_CONSTANTS.endorsement_member_addition_notes(
        id || "",
        userType?.toLowerCase()
      ),
      onSuccess,
      onError
    );
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "notes_new_data") {
      endorsement_notes(value);
    } else if (attrName === setPriorityStatus) {
      if (endorsementData?.status !== 54) {
        return toast.warning("Endorsement status should be Requested");
      }
      if (hrStatusValue !== 77) {
        return toast.warning("This status can't be changed");
      }
      if (value === 76) {
        setstatusWarningPopoup(true);
        setselectedEndorsementValue(value);
        return;
      }
      if (hrStatusValue === 76 && (value === 77 || value === 75)) {
        return toast.warning("This status can't be changed");
      }
      if (value === 77) {
        return toast.warning("This status is Automated");
      }
      update_status(value, id ?? "");
    } else if (attrName === "attachment_updated") {
      // console.log(value, "value1");
    } else if (attrName === "attachment_new_data") {
      postAattachment(value);
      setDataArray([...dataArray, value]);
    } else {
      attrName(value);
    }
  };

  const postAattachment = (data: any) => {
    let attachments = data;
    delete attachments.warnDescription;
    delete attachments.warning;
    let dto = {
      endorsementId: id,
      attachments,
    };

    const onSuccess = (res: any) => {
      getDetails();
    };
    const onError = (err: any) => {
      console.log(err);
    };
    ENDORSEMENT_SERVICES.get_Endorsement_supp_docs(
      dto,
      onSuccess,
      onError,
      userType?.toLowerCase()
    );
  };
  const delete_Endorsement_attachment = (id: any, attachmentId: any) => {
    const onSuccess = (res: any) => {
      getDetails();
    };
    const onError = (err: any) => {
      console.log("err2", err);
    };
    ENDORSEMENT_SERVICES.delete_attachment_endorsement(
      onSuccess,
      onError,
      id,
      attachmentId,
      userType?.toLowerCase()
    );
  };

  const getDetails = () => {
    const onSuccess = (res: any) => {
      sethrStatusValue(res.data.data.endorsementData.hr_status)
      setendorsementData(res.data.data.endorsementData);
      setpersonalAddressDetails(res.data.data.employeeInfo.personalAddress);
      setemployeeDetails(res.data.data.employeeInfo);
      setpolicyData(res.data.data.policyData);
      let memberName: any;

      let newData = res.data.data.employeeInfo.layout.map(
        (dataMainsection: employeeDTO, dataMainsectionIndex: any) => {
          let newMainSection = { ...dataMainsection };
          newMainSection.children = dataMainsection.children.map(
            (dataSubsection: any, dataSubsectionIndex: any) => {
              let newSubsection = { ...dataSubsection };
              newSubsection.children = dataSubsection.children.map(
                (dataRow: any, dataRowIndex: any) => {
                  let newRow = { ...dataRow };
                  newRow.children = dataRow.children.map(
                    (dataColumn: any, dataColumnIndex: any) => {
                      let newColumn = { ...dataColumn };
                      newColumn.children = dataColumn.children;
                      newColumn.children = dataColumn.children.map(
                        (dataInput: any, dataInputIndex: any) => {
                          if (dataInput?.field_lable === "Member Type") {
                            memberName = dataInput.value;
                            setmemberType(dataInput.value);
                          }
                          if (dataInput?.field_type === "searchClient") {
                            setclientId(dataInput.value);
                          }
                          if (memberName === "Employee") {
                            if (dataInput?.field_lable === "Primary Employee") {
                              return {
                                ...dataInput,
                                field_lable: "hide",
                                field_type: "hide",
                              };
                            }
                          }
                          if (memberName === "Employee") {
                            if (
                              dataInput?.field_lable ===
                              "Relationship With Employee"
                            ) {
                              return {
                                ...dataInput,
                                pickliststep: [
                                  {
                                    field_name: "Self",
                                    label: "Self",
                                    value: "Self",
                                  },
                                ],
                              };
                            }
                          }

                          if (dataInput.field_type === "hideid") {
                            return {
                              ...dataInput,
                              field_lable: "hide",
                              field_type: "hide",
                            };
                          } else {
                            return { ...dataInput };
                          }
                        }
                      );
                      return newColumn;
                    }
                  );
                  return newRow;
                }
              );
              return newSubsection;
            }
          );
          return newMainSection;
        }
      );
      const updatedItems = newData;
      if (memberName === "Dependent" || memberName === "Depandent") {
        updatedItems[1]?.children.splice(1, 1);
      }
      setFormData(updatedItems);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    ENDORSEMENT_SERVICES.getDetaisForMemberNomineeAdditionDeletion(
      onSuccess,
      onError,
      id || "",
      memberId || "",
      policyId || "",
      userType.toLowerCase()
    );
  };
  const get_Hr_status = () => {
    const onSuccess = (res: any) => {
      let HrstatusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setHrStatusData(HrstatusData);

    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    ENDORSEMENT_SERVICES.get_Hr_status(onSuccess, onError);
  };
  return (
    <>
      <ConfirmationPopup
        statusWarningPopoup={statusWarningPopoup}
        setstatusWarningPopoup={setstatusWarningPopoup}
        update_status={() => {
          update_status(selectedEndorsementValue, endorsementData?._id);
        }}
        title={`Rejecting the endorsement ${endorsementData?.endorsementId} will halt the change of ${endorsementData?.endorsementFor} for ${endorsementData?.memberId}, ${endorsementData?.memberName} &  ${endorsementData?.policyNumber}`}
      />
      <Box className="layoutWrapper">
        {/* Page header */}
        <Grid container spacing={3} className="pageHeader">
          <Grid xs={6} className="pageTitle">
            <Link
              className="backStep"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ChevronLeftIcon />
            </Link>
            <span className="detailIcon">E</span>
            <div>
              <h3>Endorsements - {endorsementData?.endorsementFor}</h3>
              <p>{`${endorsementData?.endorsementId
                }${"  "}${"  "}Created On ${" "}${date_time_format(
                  employeeDetails?.createdAt,
                  dateFormat,
                  tz
                )}`}</p>
            </div>
          </Grid>
          <Grid
            xs={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            {/* Status Dropdown */}
            {endorsementData?.status && (
              <div id={`_${endorsementData?.status?.toString()}`}>
                <div
                  className="status_dropdown"
                  id={`_${endorsementData?.status?.toString()}`}
                >
                  {endorsementData?.status === 54 ? (
                    <Requested id={`_${endorsementData?.status?.toString()}`} />
                  ) : endorsementData?.status === 55 ? (
                    <SentToTPA id={`_${endorsementData?.status?.toString()}`} />
                  ) : endorsementData?.status === 56 ? (
                    <Required id={`_${endorsementData?.status?.toString()}`} />
                  ) : endorsementData?.status === 57 ? (
                    <Approved id={`_${endorsementData?.status?.toString()}`} />
                  ) : endorsementData?.status === 58 ? (
                    <Rejected id={`_${endorsementData?.status?.toString()}`} />
                  ) : endorsementData?.status === 59 ? (
                    <Expired id={`_${endorsementData?.status?.toString()}`} />
                  ) : null}
                  <SelectDropdown
                    class_name="inputFieldd"
                    title=""
                    value={endorsementData?.status}
                    attrName={setPriorityStatus}
                    value_update={updateMasterState}
                    dropdown_data={prioritystatusData}
                    warn_status={false}
                    disabled
                  />
                </div>
              </div>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <Grid container spacing={3}>
              <Grid xs className="leftSection h-100">
                <Link
                  className={
                    sectionStatus === "endorsementInfo"
                      ? "active sectionLink endorsementInfo"
                      : "sectionLink endorsementInfo"
                  }
                  onClick={() => {
                    scrollToSection(endorsementInfo);
                    SetSectionStatus("endorsementInfo");
                    setSubSection("endorsementDetails");
                  }}
                >
                  <span>Endorsement Info</span>
                </Link>
                <ul className="accordion_list">
                  <li>
                    <Link
                      className={
                        subSection === "endorsementDetails"
                          ? "inner_link active endorsementDetails"
                          : "inner_link endorsementDetails"
                      }
                      onClick={() => {
                        setSubSection("endorsementDetails");
                        handleClicks(subSection);
                        scrollToSection(endorsementDetails);
                      }}
                    >
                      Endorsement Details
                    </Link>
                  </li>
                </ul>
                {formData.map((dataMainSection: employeeDTO, index: number) => (
                  <>
                    <Link
                      className={
                        sectionStatus === dataMainSection.name
                          ? `active sectionLink`
                          : "sectionLink personalDetailsSection"
                      }
                      onClick={() => {
                        handleClicks(dataMainSection.name);
                        SetSectionStatus(
                          dataMainSection.name === undefined
                            ? ""
                            : dataMainSection.name
                        );
                      }}
                    >
                      <span>{dataMainSection.name}</span>
                    </Link>
                    {dataMainSection.children.map((dataSubsection: any) => (
                      <ul className="accordion_list">
                        <li>
                          <Link
                            className={
                              subSection === dataSubsection.name
                                ? `inner_link active`
                                : `inner_link`
                            }
                            onClick={() => {
                              handleClicks(dataSubsection.name);
                              setSubSection(dataSubsection.name);
                              SetSectionStatus(
                                dataMainSection.name === undefined
                                  ? ""
                                  : dataMainSection.name
                              );
                            }}
                          >
                            {dataSubsection.name}
                          </Link>
                        </li>
                      </ul>
                    ))}
                  </>
                ))}
                <Link
                  className={
                    sectionStatus === "AddressDetails"
                      ? "active sectionLink AddressDetails"
                      : "sectionLink AddressDetails"
                  }
                  onClick={() => {
                    scrollToSection(AddressDetails);
                    SetSectionStatus("AddressDetails");
                  }}
                >
                  <span> Address Details</span>
                </Link>
                <ul className="accordion_list">
                  <li>
                    <Link
                      className={
                        subSection === "PersonalAddressDetails"
                          ? "inner_link active PersonalAddressDetails"
                          : "inner_link PersonalAddressDetails"
                      }
                      onClick={() => {
                        setSubSection("PersonalAddressDetails");
                        handleClicks(subSection);
                        scrollToSection(PersonalAddressDetails);
                      }}
                    >
                      Personal Address Details
                    </Link>
                  </li>
                </ul>
                <Link
                  className={
                    sectionStatus === "PoliciesDetails"
                      ? "active sectionLink PoliciesDetails"
                      : "sectionLink PoliciesDetails"
                  }
                  onClick={() => {
                    handleClicks(sectionStatus);
                    scrollToSection(PoliciesDetails);
                    SetSectionStatus("PoliciesDetails");
                  }}
                >
                  <span>Policies Details</span>
                </Link>
                <Link
                  className={
                    sectionStatus === "attatchmentsdetails"
                      ? "active sectionLink attatchmentsdetails"
                      : "sectionLink attatchmentsdetails"
                  }
                  onClick={() => {
                    handleClicks(sectionStatus);
                    scrollToSection(attatchmentsdetails);
                    SetSectionStatus("attatchmentsdetails");
                  }}
                >
                  <span>Supporting Documents</span>
                </Link>
              </Grid>
              <Grid xs className="rightSection h-100">
                <Grid
                  container
                  spacing={3}
                  alignItems="center"
                  padding={"0 12px"}
                >
                  <Grid
                    xs={6}
                    style={{ width: "50%", margin: "12px 0" }}
                    className="pl-0"
                  >
                    <div className="productTabs ">
                      <Button
                        className={
                          viewTabsStatus === "overview"
                            ? "defaultTab active"
                            : "defaultTab"
                        }
                        onClick={() => {
                          updateMasterState(setViewTabsStatus, "overview");
                        }}
                      >
                        Overview
                      </Button>
                      <Button
                        className={
                          viewTabsStatus === "notes"
                            ? "defaultTab active"
                            : "defaultTab"
                        }
                        onClick={() => {
                          updateMasterState(setViewTabsStatus, "notes");
                        }}
                      >
                        Notes
                      </Button>
                    </div>
                  </Grid>
                  <Grid
                    // lg={5}
                    // md={5}
                    xs={6}
                    style={{ textAlign: "right", width: "50%", margin: "12px 0" }}
                    className="text-sm-center"
                  >
                    <p className="lastupdate">
                      Last Update:
                      {date_time_format(
                        endorsementData?.createdAt,
                        dateFormat,
                        tz
                      )}
                    </p>
                    <Grid xs={6}>
                      <div
                        className="client_info_section"
                        style={{ justifyContent: "end" }}
                      >
                        <p
                          className="client_info_p user"
                          style={{ width: "unset" }}
                        >
                          HR Status:{" "}
                          <span>
                            <div
                              className="ml-2"
                              id={`_${hrStatusValue}`}
                              style={{ borderRadius: "20px" }}
                            >
                              <div
                                className="status_dropdown"
                                id={`_${hrStatusValue?.toString()}`}
                              >
                                {hrStatusValue === 77 ? (
                                  <Requested id={`_${hrStatusValue.toString()}`} />
                                ) : hrStatusValue === 75 ? (
                                  <Approved id={`_${hrStatusValue.toString()}`} />
                                ) : hrStatusValue === 76 ? (
                                  <Rejected id={`_${hrStatusValue.toString()}`} />
                                ) : null}

                                <SelectDropdown
                                  class_name="inputFieldd"
                                  title=""
                                  value={hrStatusValue}
                                  attrName={setPriorityStatus}
                                  value_update={updateMasterState}
                                  dropdown_data={hrStatusData}
                                  warn_status={false}
                                />

                              </div>
                            </div>
                          </span>
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid xs={12} className="mb-6" padding={"0 12px"}>
                    <div className="client_info_section">
                      <p className="client_info_p empID md_width">
                        Client ID : <span>{clientId.split("-")[0]}</span>
                      </p>
                      {employeeDetails?.primaryEmployeeId && (
                        <p className="client_info_p user md_width">
                          Primary Employee ID:{" "}
                          <span>{employeeDetails?.primaryEmployeeId}</span>
                        </p>
                      )}

                      <p className="client_info_p user md_width">
                        Member ID:{" "}
                        <span>
                          {employeeDetails?.preFix +
                            `${employeeDetails.employeeId}`}
                        </span>
                      </p>
                      <p className="client_info_p dependents">
                        Endorsement ID:{" "}
                        <span>{endorsementData?.endorsementId}</span>
                      </p>
                    </div>
                  </Grid>
                </Grid>
                {viewTabsStatus === "overview" ? (
                  <>
                    <Box className="detailSection" ref={endorsementInfo}>
                      <div className="sectionTitle">
                        <h4>Endorsement Info</h4>
                      </div>
                      <div ref={endorsementDetails}>
                        <Grid container spacing={3}>
                          <Grid xs={12}>
                            <h5 className="subHeading">Endorsement Details</h5>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid xs={6}>
                            <RKTextField
                              class_name="inputField"
                              title="Endorsement Value"
                              value={endorsementData?.endorsementFor}
                              attrName={""}
                              value_update={() => { }}
                              warn_status={false}
                            />
                          </Grid>
                          <Grid xs={6}>
                            <RKTextField
                              class_name="inputField"
                              title="Endorsement Type"
                              value={endorsementData?.type}
                              attrName={""}
                              value_update={() => { }}
                              warn_status={false}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Box>

                    {formData.map(
                      (
                        dataMainSection: employeeDTO,
                        indexMainSection: number
                      ) => (
                        // Main section
                        <Box
                          className="detailSection"
                          onMouseEnter={() => {
                            let newvar: string = dataMainSection.name ?? "";
                            SetSectionStatus(newvar);
                          }}
                        >
                          <div id={dataMainSection.name}>
                            <div className="sectionTitle">
                              <h4>{dataMainSection.name}</h4>
                            </div>
                            {dataMainSection.children.map(
                              (dataSubsection, indexSubsection) => (
                                // Sub Section
                                <Grid
                                  container
                                  onMouseEnter={() => {
                                    let newvarsub: string =
                                      dataSubsection.name ?? "";

                                    setSubSection(newvarsub);
                                  }}
                                >
                                  <Grid xs={12}>
                                    <h5
                                      id={dataSubsection.name}
                                      key={dataSubsection.type}
                                      className="subHeading"
                                    >
                                      {dataSubsection.name}
                                    </h5>
                                    {dataSubsection.children.map(
                                      (dataRow, indexRow) => (
                                        // Row
                                        <Grid
                                          container
                                          className="subRow"
                                          spacing={3}
                                        >
                                          {dataRow.children.map(
                                            (dataColumn, indexColumn) => (
                                              // Columns created
                                              <Grid xs className="subColumn">
                                                {dataColumn.children.map(
                                                  (dataInput, indexInput) => (
                                                    // input Component will be here
                                                    <form>
                                                      {dataInput.field_lable ===
                                                        "Member Type" ? (
                                                        <RKTextField
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={dataInput.value}
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={() => { }}
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          error_messg={
                                                            dataInput.value
                                                              .length === 0
                                                              ? `Enter ${dataInput.field_lable}`
                                                              : dataInput.characterLimit &&
                                                                dataInput.value
                                                                  .length >
                                                                dataInput.characterLimit
                                                                ? `Maximum ${dataInput.characterLimit} limit`
                                                                : ""
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          charcterlimit={
                                                            !dataInput.characterLimit
                                                              ? 16
                                                              : dataInput.characterLimit
                                                          }
                                                          disabled
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "singleselect" ? (
                                                        <RKTextField
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value_update={() => { }}
                                                          value={dataInput.value}
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                        />
                                                      ) : dataInput.field_lable ===
                                                        "Primary Employee" ? (
                                                        <RKTextField
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={
                                                            employeeDetails?.primaryEmployeeName
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={() => { }}
                                                          warn_status={
                                                            dataInput.error ===
                                                              undefined
                                                              ? false
                                                              : dataInput.error
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "department" ? (
                                                        <RKTextField
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={dataInput.value}
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={() => { }}
                                                          warn_status={
                                                            dataInput.error ===
                                                              undefined
                                                              ? false
                                                              : dataInput.error
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          error_messg="Please select Department"
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "designation" ? (
                                                        <RKTextField
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={dataInput.value}
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={() => { }}
                                                          warn_status={
                                                            dataInput.error ===
                                                              undefined
                                                              ? false
                                                              : dataInput.error
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          error_messg="Please select Designation"
                                                        />
                                                      ) : dataInput.field_lable ===
                                                        "Salary" ? (
                                                        <RKTextField
                                                          validation_type="numeric"
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={formatCurrency(
                                                            dataInput.value
                                                          )}
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={() => { }}
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          error_messg={
                                                            dataInput.value
                                                              .length === 0
                                                              ? `Enter ${dataInput.field_lable}`
                                                              : dataInput.characterLimit &&
                                                                dataInput.value
                                                                  .length >
                                                                dataInput.characterLimit
                                                                ? `Maximum ${dataInput.characterLimit} limit`
                                                                : ""
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          charcterlimit={
                                                            !dataInput.maxDigits
                                                              ? 12
                                                              : dataInput.maxDigits
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "salary" ? (
                                                        <>
                                                          <RKTextField
                                                            class_name="inputField"
                                                            title={
                                                              dataInput.field_lable
                                                            }
                                                            value={
                                                              dataInput.value
                                                            }
                                                            attrName={[
                                                              indexMainSection,
                                                              indexSubsection,
                                                              indexRow,
                                                              indexColumn,
                                                              indexInput,
                                                              "value",
                                                            ]}
                                                            value_update={() => { }}
                                                            warn_status={
                                                              dataInput.error ===
                                                                undefined
                                                                ? false
                                                                : dataInput.error
                                                            }
                                                            required={
                                                              dataInput.required
                                                            }
                                                            error_messg="Please select Salary Bracket"
                                                          />
                                                        </>
                                                      ) : dataInput.field_type ===
                                                        "searchClient" ? (
                                                        <RKTextField
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={dataInput.value}
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={() => { }}
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          error_messg={
                                                            "Select Client"
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "searchLocation" ? (
                                                        <RKTextField
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={dataInput.value}
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={() => { }}
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          error_messg={
                                                            "Select Client"
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "phone" ? (
                                                        <RKTextField
                                                          validation_type="numeric"
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={dataInput.value}
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={() => { }}
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          error_messg={
                                                            validateMobileNumber(
                                                              dataInput.value
                                                            ) === false
                                                              ? `Enter ${dataInput.field_lable}`
                                                              : dataInput.characterLimit &&
                                                                dataInput.value
                                                                  .length >
                                                                dataInput.characterLimit
                                                                ? `Maximum ${dataInput.characterLimit} limit`
                                                                : ""
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          charcterlimit={
                                                            !dataInput.characterLimit
                                                              ? 16
                                                              : dataInput.characterLimit
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "email" ? (
                                                        <RKTextField
                                                          validation_type="email"
                                                          type="email"
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={dataInput.value}
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={() => { }}
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          error_messg={
                                                            dataInput.value
                                                              .length === 0
                                                              ? `Enter ${dataInput.field_lable}`
                                                              : validateEmail(
                                                                dataInput.value
                                                              ) === false
                                                                ? `Enter a valid email Id`
                                                                : ""
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          charcterlimit={
                                                            !dataInput.characterLimit
                                                              ? 50
                                                              : dataInput.characterLimit
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "datepicker" ? (
                                                        <DatePicker
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={
                                                            dataInput.value ||
                                                            null
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={() => { }}
                                                          error_message="Enter a Valid Date"
                                                          warn_status={
                                                            dataInput.error ===
                                                              undefined
                                                              ? false
                                                              : dataInput.error
                                                          }
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "searchselect" ? (
                                                        <RKTextField
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value_update={() => { }}
                                                          value={dataInput.value}
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          warn_status={
                                                            dataInput.error ===
                                                              undefined
                                                              ? false
                                                              : dataInput.error
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          error_messg={
                                                            "Select the value"
                                                          }
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "checkbox" ? (
                                                        <CheckBox
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          name={
                                                            dataInput.field_lable
                                                          }
                                                          id={dataInput._id}
                                                          value={Boolean(
                                                            dataInput.value
                                                          )}
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={() => { }}
                                                          default_check={true}
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "number" ? (
                                                        <RKTextField
                                                          validation_type="numeric"
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          disabled={
                                                            dataInput.field_lable ===
                                                            "Salary"
                                                          }
                                                          value=""
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={() => { }}
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          error_messg={
                                                            dataInput.value
                                                              .length === 0
                                                              ? `Enter ${dataInput.field_lable}`
                                                              : dataInput.characterLimit &&
                                                                dataInput.value
                                                                  .length >
                                                                dataInput.characterLimit
                                                                ? `Maximum ${dataInput.characterLimit} limit`
                                                                : ""
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          charcterlimit={
                                                            !dataInput.maxDigits
                                                              ? 12
                                                              : dataInput.maxDigits
                                                          }
                                                        />
                                                      ) : dataInput.field_type ==
                                                        "currency" ? (
                                                        <CurrencyTextField
                                                          validation_type="numeric"
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={dataInput.value}
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={() => { }}
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          error_messg={
                                                            dataInput.value
                                                              .length === 0
                                                              ? `Enter ${dataInput.field_lable}`
                                                              : dataInput.characterLimit &&
                                                                dataInput.value
                                                                  .length >
                                                                dataInput.characterLimit
                                                                ? `Maximum ${dataInput.characterLimit} Limit`
                                                                : ""
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          charcterlimit={
                                                            !dataInput.maxDigits
                                                              ? 12
                                                              : dataInput.maxDigits
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "multiselect" ? (
                                                        <RKTextField
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value_update={() => { }}
                                                          value={dataInput.value}
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          warn_status={
                                                            dataInput.error ===
                                                              undefined
                                                              ? false
                                                              : dataInput.error
                                                          }
                                                          error_messg={
                                                            "Select the value"
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "multiline" ? (
                                                        <TextAreaField
                                                          specialcharacter={
                                                            !dataInput.speacialChar
                                                              ? false
                                                              : dataInput.speacialChar
                                                          }
                                                          alphanumeric={
                                                            !dataInput.alphaNumeric
                                                              ? false
                                                              : dataInput.alphaNumeric
                                                          }
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value_update={() => { }}
                                                          value={dataInput.value}
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          warn_status={
                                                            dataInput.error ===
                                                              undefined
                                                              ? false
                                                              : dataInput.error
                                                          }
                                                          error_messg={
                                                            dataInput.value
                                                              .length === 0
                                                              ? `Enter ${dataInput.field_lable}`
                                                              : dataInput.characterLimit &&
                                                                dataInput.value
                                                                  .length >
                                                                dataInput.characterLimit
                                                                ? `Maximum ${dataInput.characterLimit} charcter`
                                                                : ""
                                                          }
                                                          charcterlimit={
                                                            !dataInput.characterLimit
                                                              ? 30
                                                              : dataInput.characterLimit
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "url" ? (
                                                        <RKTextField
                                                          validation_type="url"
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={dataInput.value}
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={() => { }}
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          charcterlimit={
                                                            !dataInput.characterLimit
                                                              ? 50
                                                              : dataInput.characterLimit
                                                          }
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "country" ? (
                                                        <RKTextField
                                                          class_name="inputField"
                                                          title="Country"
                                                          value={dataInput.value}
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={() => { }}
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          error_messg="Please select country"
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "state" ? (
                                                        <RKTextField
                                                          class_name="inputField"
                                                          title="State"
                                                          value={dataInput.value}
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={() => { }}
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          error_messg="Please select state"
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "city" ? (
                                                        <RKTextField
                                                          class_name="inputField"
                                                          title="City"
                                                          value={dataInput.value}
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={() => { }}
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          error_messg="Please select city"
                                                        />
                                                      ) : dataInput.field_type ===
                                                        "hide" ? null : (
                                                        <RKTextField
                                                          specialcharacter={
                                                            !dataInput.speacialChar
                                                              ? false
                                                              : dataInput.speacialChar
                                                          }
                                                          alphanumeric={
                                                            !dataInput.alphaNumeric
                                                              ? false
                                                              : dataInput.alphaNumeric
                                                          }
                                                          validation_type="name"
                                                          class_name="inputField"
                                                          title={
                                                            dataInput.field_lable
                                                          }
                                                          value={dataInput.value}
                                                          info_status={
                                                            dataInput.toolTip
                                                          }
                                                          info_message={
                                                            dataInput.tipText
                                                          }
                                                          attrName={[
                                                            indexMainSection,
                                                            indexSubsection,
                                                            indexRow,
                                                            indexColumn,
                                                            indexInput,
                                                            "value",
                                                          ]}
                                                          value_update={() => { }}
                                                          warn_status={
                                                            dataInput.error
                                                          }
                                                          error_messg={
                                                            dataInput.value
                                                              .length === 0
                                                              ? `Enter ${dataInput.field_lable}`
                                                              : dataInput.characterLimit &&
                                                                dataInput.value
                                                                  .length >
                                                                dataInput.characterLimit
                                                                ? `Maximum ${dataInput.characterLimit} charcter`
                                                                : ""
                                                          }
                                                          required={
                                                            dataInput.required
                                                          }
                                                          charcterlimit={
                                                            !dataInput.characterLimit
                                                              ? 30
                                                              : dataInput.characterLimit
                                                          }
                                                        />
                                                      )}
                                                    </form>
                                                  )
                                                )}
                                              </Grid>
                                            )
                                          )}
                                        </Grid>
                                      )
                                    )}
                                  </Grid>
                                </Grid>
                              )
                            )}
                          </div>
                        </Box>
                      )
                    )}

                    <Box
                      className="detailSection"
                      ref={AddressDetails}
                      onMouseEnter={() => {
                        SetSectionStatus("AddressDetails");
                      }}
                    >
                      <div className="sectionTitle">
                        <h4>Address Details</h4>
                      </div>
                      <div
                        ref={PersonalAddressDetails}
                        onMouseEnter={() => {
                          setSubSection("PersonalAddressDetails");
                        }}
                      >
                        <Grid container spacing={3}>
                          <Grid xs={12}>
                            <h5 className="subHeading">
                              Personal Address Details
                            </h5>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid xs={6} md={6} lg={6}>
                            <RKTextField
                              class_name="inputField"
                              title="Building/Street Name"
                              value={personalAddressDetails.street}
                              attrName={""}
                              value_update={() => { }}
                              warn_status={false}
                            />
                          </Grid>
                          <Grid xs={6} md={6} lg={6}>
                            <RKTextField
                              class_name="inputField"
                              title="Area Name"
                              value={personalAddressDetails.area}
                              attrName={""}
                              value_update={() => { }}
                              warn_status={false}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid xs={6} md={6} lg={3}>
                            <RKTextField
                              class_name="inputField"
                              title="Country"
                              value={personalAddressDetails.country}
                              attrName={""}
                              value_update={() => { }}
                              warn_status={false}
                            />
                          </Grid>
                          <Grid xs={6} md={6} lg={3}>
                            <RKTextField
                              class_name="inputField"
                              title="State"
                              value={personalAddressDetails.state}
                              attrName={""}
                              value_update={() => { }}
                              warn_status={false}
                            />
                          </Grid>
                          <Grid xs={6} md={6} lg={3}>
                            <RKTextField
                              class_name="inputField"
                              title="City"
                              value={personalAddressDetails.city}
                              attrName={""}
                              value_update={() => { }}
                              warn_status={false}
                            />
                          </Grid>
                          <Grid xs={6} md={6} lg={3}>
                            <RKTextField
                              class_name="inputField"
                              title="Pincode"
                              value={personalAddressDetails.zip}
                              attrName={""}
                              value_update={() => { }}
                              warn_status={false}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Box>

                    <Box
                      className="detailSection mt-4"
                      ref={PoliciesDetails}
                      onMouseEnter={() => {
                        SetSectionStatus("PoliciesDetails");
                      }}
                    >
                      <div className="sectionTitle">
                        <h4>Policies Details</h4>
                      </div>
                      <Box className="employee_detailpolicy">
                        <Accordion
                          expanded={true}
                          className="Accordion_section employee_policiesSection accordion_padding"
                          // onChange={handleChange("panel1")}
                          defaultExpanded={true}
                        >
                          <Grid container spacing={0}>
                            <Grid xs={12} className="px-0">
                              <AccordionSummary
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{
                                  display: "inline-block",
                                  width: "100%",
                                  padding: "0",
                                }}
                              >
                                <Typography
                                  className="title"
                                  style={{ width: "100%" }}
                                >
                                  <Grid
                                    container
                                    spacing={3}
                                    style={{ paddingLeft: "0" }}
                                  >
                                    <Grid xs={6}>
                                      <h5>{policyData.policyNumber}</h5>
                                    </Grid>
                                  </Grid>
                                </Typography>
                              </AccordionSummary>
                            </Grid>
                          </Grid>
                          <AccordionDetails style={{ padding: "0" }}>
                            <Grid container spacing={3}>
                              <Grid xs={12} className="policies_detail">
                                <div>
                                  <h4>
                                    Policy Number
                                    <span>{policyData?.policyNumber}</span>
                                  </h4>
                                </div>
                                <div>
                                  <h4>
                                    Sum Insured{" "}
                                    <span>
                                      {formatCurrency(policyData?.sumInsured)}
                                    </span>
                                  </h4>
                                </div>
                                <div>
                                  <h4>
                                    Start Date{" "}
                                    <span>
                                      {" "}
                                      {date_time_format(
                                        policyData?.startDate,
                                        dateFormat,
                                        tz
                                      )}
                                    </span>
                                  </h4>
                                </div>
                                <div>
                                  <h4>
                                    End Date{" "}
                                    <span>
                                      {" "}
                                      {date_time_format(
                                        policyData?.endDate,
                                        dateFormat,
                                        tz
                                      )}
                                    </span>
                                  </h4>
                                </div>
                                <div>
                                  <h4>
                                    Insurer Name<span>{policyData?.insurer}</span>
                                  </h4>
                                </div>
                                <div>
                                  <h4>
                                    Product Type{" "}
                                    <span>{policyData?.productType}</span>
                                  </h4>
                                </div>
                              </Grid>
                            </Grid>
                            {!endorsementData?.endorsementFor?.includes(
                              "Nominee"
                            ) && (
                                <>
                                  <Grid container spacing={3}>
                                    <Grid xs={6}>
                                      <h4 className="subHeading">Member Details</h4>
                                    </Grid>
                                  </Grid>

                                  {/* member section */}
                                  <Grid container spacing={3}>
                                    <Grid xs={12}>
                                      <div className="employeeDetailtable">
                                        <div
                                          className="employeePolicy_table"
                                          style={{ minWidth: "unset" }}
                                        >
                                          <ul className="heading">
                                            <li style={{ minWidth: "unset" }}>
                                              Member ID{" "}
                                            </li>
                                            <li style={{ minWidth: "unset" }}>
                                              Member Type
                                            </li>
                                            <li style={{ minWidth: "unset" }}>
                                              Member Name
                                            </li>
                                            <li style={{ minWidth: "220px" }}>
                                              Relationship with Employee
                                            </li>
                                            <li style={{ minWidth: "unset" }}>
                                              DOB
                                            </li>
                                            <li style={{ minWidth: "unset" }}>
                                              Enrolment Date
                                            </li>
                                            <li style={{ minWidth: "unset" }}>
                                              Enrolment Time
                                            </li>
                                          </ul>

                                          {policyData.members &&
                                            policyData.members.map(
                                              (data: any, index: number) => (
                                                <ul className="subheading">
                                                  <li style={{ minWidth: "unset" }}>
                                                    {employeeDetails?.preFix +
                                                      employeeDetails?.employeeId}
                                                  </li>
                                                  <li style={{ minWidth: "unset" }}>
                                                    {employeeDetails.type ===
                                                      "Employee"
                                                      ? "Employee"
                                                      : "Dependent"}
                                                  </li>
                                                  <li style={{ minWidth: "unset" }}>
                                                    {employeeDetails?.name}
                                                  </li>
                                                  <li style={{ minWidth: "220px" }}>
                                                    {employeeDetails?.relationship}
                                                  </li>
                                                  <li style={{ minWidth: "unset" }}>
                                                    {date_time_format(
                                                      employeeDetails?.dob,
                                                      dateFormat,
                                                      tz
                                                    )}
                                                  </li>
                                                  <li style={{ minWidth: "unset" }}>
                                                    {date_time_format(
                                                      employeeDetails?.enrollment_date,
                                                      dateFormat,
                                                      tz
                                                    )}
                                                  </li>
                                                  <li style={{ minWidth: "unset" }}>
                                                    {date_time_format(
                                                      employeeDetails?.enrollment_date,
                                                      "hh:mm:ss A",
                                                      tz
                                                    )}
                                                  </li>
                                                </ul>
                                              )
                                            )}
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </>
                              )}

                            {/* nominee section */}
                            {policyData?.nominee?.length > 0 && (
                              <>
                                <Grid container spacing={3}>
                                  <Grid xs={6}>
                                    <h4 className="subHeading">Nominee Details</h4>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                  <Grid xs={12}>
                                    <div className="employeeDetailtable">
                                      <div
                                        className="employeePolicy_table"
                                        style={{ minWidth: "unset" }}
                                      >
                                        <ul className="heading">
                                          <li>Nominee Name</li>
                                          <li>Relationship with Employee</li>
                                          <li>DOB</li>
                                          <li>Age</li>
                                          <li>Enrolment Date</li>
                                          <li>Enrolment Time</li>
                                        </ul>
                                        {policyData?.nominee?.map((data: any, index: number) => (
                                          <ul className="subheading">
                                            <li>{data.fullName}</li>
                                            <li>{data.relation}</li>
                                            <li>
                                              {" "}
                                              {date_time_format(
                                                data?.dob,
                                                dateFormat,
                                                tz
                                              )}{" "}
                                            </li>
                                            <li>{data.age}</li>
                                            <li>
                                              {customFormatDate(data.createdAt)}
                                            </li>
                                            <li>
                                              {customFormatTime(data.createdAt)}
                                            </li>
                                          </ul>
                                        )
                                        )}
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              </>
                            )}

                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    </Box>

                    <Box
                      className="detailSection"
                      ref={attatchmentsdetails}
                      onMouseEnter={() => {
                        SetSectionStatus("attatchmentsdetails");
                      }}
                    >
                      <div className="sectionTitle">
                        <h4>Supporting Documents </h4>
                        <div className="actionBtns">
                          <Link
                            className="blueBtn addfile"
                            sx={{ textDecoration: "none", cursor: "pointer" }}
                            onClick={() => setUploadFile(true)}
                          >
                            {" "}
                            Add Document{" "}
                          </Link>
                        </div>
                      </div>
                      <Attachment
                        open_status={attachment}
                        attrName={setAttachment}
                        value_update={updateMasterState}
                        dataArray={endorsementData?.attachments}
                        deleteurl={delete_Endorsement_attachment}
                      />
                    </Box>
                  </>
                ) : null}

                {viewTabsStatus === "notes" ? (
                  <>
                    <Box className="detailSection">
                      <div className="sectionTitle">
                        <h4>Notes</h4>
                        <div className="actionBtns">
                          <Link
                            className="blueBtn addfile"
                            sx={{ textDecoration: "none", cursor: "pointer" }}
                            onClick={() => {
                              setPostQueryForm(true);
                            }}
                          >
                            Add Notes
                          </Link>
                        </div>
                      </div>
                      <NotesSection data={notesData} />
                    </Box>
                  </>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <BulkUploadForm
          open_status={uploadFile}
          attrName={setUploadFile}
          value_update={updateMasterState}
        />
        <PostQueryForm
          attrName={setPostQueryForm}
          open_status={postQueryForm}
          value_update={updateMasterState}
        />
        <Modal open={deleteStatus} className="modalWrapper">
          <Box className="modalInner">
            <Grid container spacing={1}>
              <Grid xs={12}>
                <Link
                  className="close-button"
                  onClick={() => {
                    setDeleteStatus(false);
                  }}
                />
                <h5 className="popup-heading">Are you sure ?</h5>
              </Grid>
              <Grid xs={12}>
                <Link className="md-link" underline="none">
                  You are about to Delete this Attatchment.
                </Link>
              </Grid>
              <Grid xs={12} className="ctaBtn" textAlign={"center"}>
                <Button variant="contained" className="submitBtn">
                  Yes, Delete
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal open={removeStatus} className="modalWrapper">
          <Box className="modalInner">
            <Grid container spacing={1}>
              <Grid xs={12}>
                <Link
                  className="close-button"
                  onClick={() => {
                    setRemoveStatus(false);
                  }}
                />
                <h5 className="popup-heading">Are you sure ?</h5>
              </Grid>
              <Grid xs={12}>
                <Link className="md-link" underline="none">
                  You are want to remove this Endorsement.
                </Link>
              </Grid>
              <Grid xs={12} className="ctaBtn" textAlign={"center"}>
                <Button variant="contained" className="submitBtn">
                  Yes, Delete
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Box>
    </>

  );
}
export default EndorsementDetailMemberAddition;
