export enum TPA_TYPE {
    PARAMOUNT = "paramount",
    MEDIASSIST= "medi assist",
    HEALTHINDIA = "health india",
    VOLO ="volo",
    ERICSSON = "ericsson",
    VIDAL = "vidal",
    MDINDIA = "md india",
    SAFEWAY = "safeway",
  }
  